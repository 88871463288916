import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import AddUserIcon from '@material-ui/icons/PersonAdd';
import { isUndefined } from 'lodash';
import { useState } from 'react';
import { TableCellProps } from 'react-virtualized';

import strings from 'common/strings';
import Table from 'components/shared/Table';
import { AssignableUser } from 'models';

import AssignStepUsersDialog from '../AssignStepUsersDialog';

import './AssignableUsers.scss';

interface AssignableUsersProps {
  orgId: string;
  assignableUsers: AssignableUser[];
  currentStepName: string;
  onRemoveUserId: (userId: string) => void;
  onAddUserIds: (users: AssignableUser[]) => void;
}

var AssignableUsers = ({
  orgId,
  assignableUsers,
  currentStepName,
  onRemoveUserId,
  onAddUserIds,
}: AssignableUsersProps) => {
  const [isAssignStepUsersDialogOpen, setIsAssignStepUsersDialogOpen] =
    useState(false);
  const openAssignStepUsersDialog = () => setIsAssignStepUsersDialogOpen(true);
  const closeAssignStepUsersDialog = () =>
    setIsAssignStepUsersDialogOpen(false);

  return (
    <div className="AssignableUsers">
      <div className="AssignableUsers__header">
        <div className="AssignableUsers__header-text">{strings.USERS}</div>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={openAssignStepUsersDialog}
        >
          {strings.ADD_USER}
        </Button>
      </div>
      {assignableUsers?.length > 0 ? (
        <Table
          columns={[
            {
              heading: 'Name',
              dataKey: 'name',
              content: ({ rowData }: TableCellProps) =>
                `${rowData.firstName || ''} ${rowData.lastName || ''}`,
              width: 150,
            },
            {
              heading: 'Title',
              dataKey: 'title',
              content: ({ rowData }: TableCellProps) => rowData.jobTitle || '',
              flexGrow: 1,
            },
            {
              heading: '',
              dataKey: 'actions',
              content: ({ rowData: user }: TableCellProps) => (
                <Delete
                  onClick={() => {
                    onRemoveUserId(user.id);
                  }}
                />
              ),
              width: 20,
              minWidth: 0,
            },
          ]}
          items={assignableUsers}
        />
      ) : (
        <div className="AssignableUsers__empty">
          <div className="AssignableUsers__empty-message">
            {strings.NO_ASSIGNABLE_USERS}
          </div>
          <div>
            <IconButton color="inherit" onClick={openAssignStepUsersDialog}>
              <AddUserIcon />
            </IconButton>
          </div>
        </div>
      )}
      {isAssignStepUsersDialogOpen && (
        <AssignStepUsersDialog
          orgId={orgId}
          open={isAssignStepUsersDialogOpen}
          onClose={closeAssignStepUsersDialog}
          title={strings.ASSIGN_STEP_USERS_DIALOG_TITLE(currentStepName)}
          currentlyAssignableUsers={assignableUsers}
          onAddUsers={async (users: AssignableUser[]) => {
            const filteredUsers = users.filter((user) => !isUndefined(user.id));
            onAddUserIds(filteredUsers);
            closeAssignStepUsersDialog();
          }}
        />
      )}
    </div>
  );
};

export default AssignableUsers;
