import { TableCell, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { FC, useCallback, useMemo, useState } from 'react';
import { SingleValue } from 'react-select';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { useOrganizationHistory } from 'api/organizations/history';
import { ROW_HEIGHT } from 'common/constants';
import { useDebouncedInput } from 'common/hooks';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { OrganizationHistory, SelectOption } from 'models';

import HistoryHeader from './HistoryHeader';
import HistoryListRow from './HistoryListRow';
import { Column, TableColumnsProps } from './types';

import './HistoryView.scss';

export interface HistoryViewProps {
  organizationId: string;
}

const headerColumns: Column[] = [
  {
    dataKey: 'date',
    label: 'Date',
    width: '150px',
  },
  {
    dataKey: 'user',
    label: 'User',
    width: '150px',
  },
  {
    dataKey: 'messageType',
    label: 'Message Type',
    width: '150px',
  },
  {
    dataKey: 'message',
    label: 'Message',
    width: '350px',
  },
];

const HistoryView: FC<HistoryViewProps> = ({ organizationId }) => {
  const [search, setSearch] = useState<string>('');
  const [searchInput, handleTextChange] = useDebouncedInput(setSearch);
  const [userId, setUserId] = useState<SelectOption>({
    label: 'Select User...',
    value: '',
  });

  const {
    data: historyData,
    isLoading: isHistoryLoading,
    fetchNextPage,
  } = useOrganizationHistory(organizationId, {
    s: search,
    userId: userId?.value,
  });

  const history = useMemo(() => {
    return (
      historyData?.pages?.reduce(
        (acc: OrganizationHistory[], page) => [...acc, ...page.data],
        []
      ) ?? []
    );
  }, [historyData]);
  const isItemLoaded = (index: number) => history && history.length >= index;
  const totalRecords = useMemo(() => {
    return historyData?.pages && historyData.pages?.length > 0
      ? historyData.pages[0].meta.totalRecords
      : 0;
  }, [historyData]);
  const loadMoreItems = useCallback(
    (_startIndex: number, stopIndex: number) => {
      if (history.length < stopIndex) {
        return fetchNextPage();
      }
      return Promise.resolve();
    },
    [fetchNextPage, history.length]
  );

  const TableColumns = ({ columns }: TableColumnsProps) => {
    return (
      <TableRow component="div" className={`row header`}>
        {columns.map((column) => (
          <TableCell
            key={`${column.dataKey}columnHeader`}
            component="div"
            variant="head"
            align="left"
            className="cell header-cell"
            style={{
              minWidth: column.width,
              height: ROW_HEIGHT,
            }}
            scope="col"
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  if (isHistoryLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="HistoryView">
      <HistoryHeader
        organizationId={organizationId}
        searchInput={searchInput}
        onSearchChange={handleTextChange}
        userId={userId}
        onUserIdChange={(option: SingleValue<SelectOption>) =>
          setUserId(option!)
        }
      />
      <TableColumns columns={headerColumns} />
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={totalRecords}
            minimumBatchSize={500}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered, ref }) => (
              <List
                className="list"
                itemCount={totalRecords}
                onItemsRendered={onItemsRendered}
                ref={ref}
                height={height}
                width={width}
                itemSize={ROW_HEIGHT}
              >
                {({ index, style }) => {
                  let currentHistory = history[index];
                  if (!isItemLoaded(index)) {
                    return <Skeleton />;
                  } else {
                    return (
                      <HistoryListRow
                        index={index}
                        style={style}
                        history={currentHistory}
                        headerColumns={headerColumns}
                      />
                    );
                  }
                }}
              </List>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </div>
  );
};

export default HistoryView;
