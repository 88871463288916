import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { configuredFetch } from 'api/base';
import { SelectOption } from 'models';

type UseOrganizationHistoryUserOptions = (
  orgId: string
) => UseQueryResult<SelectOption[], Error>;

export const useOrganizationHistoryUserOptions: UseOrganizationHistoryUserOptions =
  (orgId: string) => {
    const url = `/orgs/${orgId}/history/users`;

    return useQuery([url], async () => {
      const { data } = await configuredFetch(url);

      return data;
    });
  };
