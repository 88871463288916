import { capitalize } from 'lodash';
import { Fragment } from 'react';
import NumberFormat from 'react-number-format';

import testIds, { generateTestId } from 'common/testIds';
import { DAYS, Organization, OrganizationDepartment } from 'models';
import utils from 'utils';

import { DepartmentType } from './EditOrganizationDepartmentDialog';

interface DepartmentViewProps {
  organization?: Organization;
  type: DepartmentType;
  department?: OrganizationDepartment | null;
}

const DepartmentView = ({
  organization,
  type,
  department,
}: DepartmentViewProps) => {
  if (!organization) {
    return null;
  }

  function convertSecondsToTime(seconds: number): string {
    return utils.time.getAmPmTimeStringFromSecondsAfterMidnight(
      utils.time.offsetSecondsAfterMidnight(seconds, 0)
    );
  }

  const organizationTimezone =
    organization?.timezone?.timezone !== 'UNKNOWN'
      ? organization?.timezone
      : undefined;

  return (
    <div className="DepartmentView flex-grow">
      <div>
        <h4 className="inline margin-right">{type}</h4>
      </div>

      <div className="DepartmentView__schedule-grid">
        <label>Phone:</label>
        <span
          data-vas-testing={generateTestId(testIds.ORGANIZATION_DEPT_PHONE, {
            type: type.toLowerCase(),
          })}
        >
          {department?.phone ? (
            <NumberFormat
              value={department?.phone}
              displayType="text"
              format="###-###-####"
            />
          ) : (
            'None'
          )}
        </span>
        <label>Email:</label>
        <div
          data-vas-testing={generateTestId(testIds.ORGANIZATION_DEPT_EMAIL, {
            type: type.toLowerCase(),
          })}
        >
          {department?.email ? (
            <a href={`mailto:${department.email}`}>{department.email}</a>
          ) : (
            'None'
          )}
        </div>

        <div className="separator" />

        {DAYS.map((day) => {
          const daySchedule = department?.schedule?.[day];
          return (
            <Fragment key={day}>
              <label>{capitalize(day)}:</label>
              <div>
                {daySchedule
                  ? [
                      convertSecondsToTime(daySchedule.openInSecondsUTC),
                      '-',
                      convertSecondsToTime(daySchedule.closedInSecondsUTC),
                      ' ',
                      organizationTimezone?.timezone ?? '',
                    ].join('')
                  : 'Closed'}
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

interface DepartmentsViewProps {
  organization?: Organization;
}

const DepartmentsView = ({ organization }: DepartmentsViewProps) => {
  if (!organization) {
    return null;
  }

  return (
    <div className="OverviewView_departments">
      <DepartmentView
        key="Sales"
        type="Sales"
        department={organization?.salesDepartment}
        organization={organization}
      />
      <DepartmentView
        key="Service"
        type="Service"
        department={organization?.serviceDepartment}
        organization={organization}
      />
      <DepartmentView
        key="Parts"
        type="Parts"
        department={organization?.partsDepartment}
        organization={organization}
      />
    </div>
  );
};

export default DepartmentsView;
