import { FolderRounded } from '@material-ui/icons';
import { useState } from 'react';

import { useFeatureFlagApplications } from 'api';

import FeatureFlagsListDetailLayout from './FeatureFlagsListDetailLayout';

import '../SettingsView.scss';

var ApplicationsListDetailLayout = () => {
  const { data: applications, isLoading } = useFeatureFlagApplications();
  const [applicationName, setApplicationName] = useState<string>(
    applications && applications?.length > 1 ? applications[0].name : ''
  );
  return (
    <div className="full-height flex-columns">
      <div className="Settings-container" id="application-list-container">
        <div className="Settings-title">
          {isLoading
            ? 'Loading...'
            : `${applications?.length ?? 0} Applications`}
        </div>
        <div className="flex-grow">
          {applications?.map((application) => (
            <div
              id={application.name}
              key={application.name}
              role="button"
              tabIndex={-1}
              className="Settings-row Settings-selectable"
              onClick={() => setApplicationName(application?.name)}
            >
              <span className="valign-center">
                <FolderRounded />
                <span className="margin-left-md">{application.name}</span>
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="flex-grow">
        {applicationName ? (
          <FeatureFlagsListDetailLayout applicationName={applicationName} />
        ) : (
          <div className="flex-column align-center valign-center margin-lg full-height">
            <div>
              <FolderRounded fontSize="large" />
            </div>
            <h3>Select an application</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApplicationsListDetailLayout;
