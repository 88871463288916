import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  Theme,
} from '@material-ui/core';
import {
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { useOrganizationsFlat } from 'api';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { OrganizationItemLink, User } from 'models';

import UserOrganizationsHeader from './UserOrganizationsHeader';

import './UserOrganizationsDialog.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      paddingLeft: 16,
      paddingRight: 0,
    },
    checkbox: {
      margin: 0,
      marginRight: theme.spacing(1),
      padding: 0,
    },
  })
);

interface UserOrganizationsDialogProps {
  onClose?: () => void;
  checkedOrganizations: { [key: string]: OrganizationItemLink };
  setCheckedOrganizations: (checkedOrganizations: {
    [key: string]: OrganizationItemLink;
  }) => void;
  userOrganizations: OrganizationItemLink[];
  userId: User['id'];
  setUser: (user: SetStateAction<User>) => void;
}

const UserOrganizationsDialog: FC<UserOrganizationsDialogProps> = ({
  onClose,
  checkedOrganizations,
  setCheckedOrganizations,
  setUser,
}) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [totalRecords, setTotalRecords] = useState<number | undefined>(
    undefined
  );
  const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useOrganizationsFlat(searchText);

  useEffect(() => {
    if (searchText || !data?.pages.length) {
      return;
    }

    setTotalRecords(data.pages[0].meta.totalRecords);
  }, [data?.pages, searchText]);

  const flatOrganizations = useMemo(() => {
    return (
      data?.pages.reduce<OrganizationItemLink[]>(
        (acc, page) => [...acc, ...page.data],
        []
      ) ?? []
    );
  }, [data?.pages]);

  const isItemLoaded = (index: number) =>
    flatOrganizations && flatOrganizations.length >= index;

  const loadMoreItems = useCallback(() => {
    if (!hasNextPage || isFetchingNextPage) {
      return Promise.resolve();
    }

    return fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  const onPrimary = async () => {
    const organizations = Object.values(checkedOrganizations);
    setUser((userState) => {
      return {
        ...userState,
        defaultOrganization:
          userState.defaultOrganization || organizations?.[0],
        organizations,
      };
    });
    onClose?.();
  };

  const handleToggleOrganization = (value: OrganizationItemLink) => () => {
    if (checkedOrganizations[value.id]) {
      const { [value.id]: removedCheckedOrg, ...newCheckedOrganizationIds } =
        checkedOrganizations;
      setCheckedOrganizations(newCheckedOrganizationIds);
    } else {
      setCheckedOrganizations({ [value.id]: value, ...checkedOrganizations });
    }
  };

  const totalChecked = Object.keys(checkedOrganizations)?.length;

  return (
    <Dialog open className="UserOrganizationsDialog">
      <DialogTitle>Add User Organizations</DialogTitle>
      <DialogContent className={classes.content}>
        {totalRecords !== undefined && (
          <UserOrganizationsHeader
            totalRecords={totalRecords}
            totalChecked={totalChecked}
            onSearch={setSearchText}
          />
        )}
        <div className="OrganizationsList">
          {isLoading || totalRecords === undefined ? (
            <LoadingIndicator />
          ) : (
            <AutoSizer>
              {({ height, width }) => (
                <InfiniteLoader
                  isItemLoaded={isItemLoaded}
                  itemCount={totalRecords}
                  loadMoreItems={loadMoreItems}
                >
                  {({ onItemsRendered, ref }) => (
                    <FixedSizeList
                      height={height}
                      width={width}
                      itemCount={flatOrganizations.length}
                      itemSize={30}
                      onItemsRendered={onItemsRendered}
                      ref={ref}
                    >
                      {({ index, style }) => {
                        const organization = flatOrganizations[index];
                        const itemId = `organization-list-item-${index}`;

                        return (
                          <div style={style}>
                            <div className="ListItem">
                              <FormControlLabel
                                className="ListItem-Control"
                                control={
                                  <Checkbox
                                    id={itemId}
                                    className={classes.checkbox}
                                    checked={
                                      !!checkedOrganizations[organization.id]
                                    }
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': itemId }}
                                    onClick={handleToggleOrganization(
                                      organization
                                    )}
                                  />
                                }
                                label={organization.name}
                              />
                              <div>{organization.type}</div>
                            </div>

                            {index === flatOrganizations.length - 1 &&
                              isFetchingNextPage && (
                                <LoadingIndicator
                                  className="LoadingIndicator-FetchingNextPage"
                                  size={24}
                                />
                              )}
                          </div>
                        );
                      }}
                    </FixedSizeList>
                  )}
                </InfiniteLoader>
              )}
            </AutoSizer>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose?.()}>Cancel</Button>
        <Button
          id="add-org-button"
          color="secondary"
          style={{ color: 'white' }}
          variant="contained"
          onClick={() => onPrimary()}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserOrganizationsDialog;
