import NumberFormat from 'react-number-format';

import { useOrganization } from 'api/organizations';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { OrganizationType } from 'models';
import { displayCityStateZip, displayFullName } from 'utils/strings';

import DepartmentsView from './DepartmentsView';
import LogoUrl from './LogoUrl';

import './OverviewView.scss';

interface OverviewProps {
  organizationId: string;
}

var OverviewView = (props: OverviewProps) => {
  const { organizationId } = props;
  const { data: organization, isLoading } = useOrganization(organizationId);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const isVendor = organization?.type === OrganizationType.VENDOR;

  return (
    <div className="OverviewView">
      <div className="OverviewView_header">
        <div className="flex-grow">
          <div>
            <h4 className="inline margin-right">Location Address/Contact</h4>
          </div>
          <p>
            <span className="block">
              {organization?.mailingAddress?.street1}
            </span>
            <span className="block">
              {organization?.mailingAddress?.street2}
            </span>
            <span className="block">
              {displayCityStateZip(organization?.mailingAddress)}
            </span>
          </p>
          <p>
            <span className="block">
              {displayFullName(organization?.mailingContact) ||
                'No Contact Name'}
            </span>
            <span className="block">
              {organization?.mailingContact?.jobTitle}
            </span>
            <span className="block">
              <NumberFormat
                value={
                  organization?.mailingContact?.mobilePhone?.number ?? 'None'
                }
                displayType="text"
                format="###-###-####"
              />
            </span>
            <span className="block">
              <a href={`mailto:${organization?.mailingContact?.email}`}>
                {organization?.mailingContact?.email}
              </a>
            </span>
          </p>
        </div>

        <div className="flex-grow">
          <div>
            <h4 className="inline margin-right">Billing Address/Contact</h4>
          </div>
          <p>
            <span className="block">
              {organization?.billingAddress?.street1}
            </span>
            <span className="block">
              {organization?.billingAddress?.street2}
            </span>
            <span className="block">
              {displayCityStateZip(organization?.billingAddress)}
            </span>
          </p>
          <p>
            <span className="block">
              {displayFullName(organization?.billingContact) ||
                'No Contact Name'}
            </span>
            <span className="block">
              {organization?.billingContact?.jobTitle}
            </span>
            <span className="block">
              <NumberFormat
                value={
                  organization?.billingContact?.mobilePhone?.number ?? 'None'
                }
                displayType="text"
                format="###-###-####"
              />
            </span>
            <span className="block">
              <a href={`mailto:${organization?.billingContact?.email}`}>
                {organization?.billingContact?.email}
              </a>
            </span>
          </p>
        </div>
      </div>

      {isVendor ? (
        <LogoUrl organization={organization} />
      ) : (
        <DepartmentsView organization={organization} />
      )}
    </div>
  );
};

export default OverviewView;
