import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { Business, Group, Person, VpnKey } from '@material-ui/icons';
import { invert } from 'lodash';

import { DeciderData, DeciderDataMembers, FeatureFlagMember } from 'models';

export const memberTypeToDeciderDataKey: {
  [key in FeatureFlagMember['memberType']]: keyof DeciderData;
} = {
  ORGANIZATION: DeciderDataMembers.allowedOrganizations,
  ORGANIZATION_USER: DeciderDataMembers.allowedOrganizationUsers,
  SECURITY_GROUP: DeciderDataMembers.allowedSecurityGroups,
  USER: DeciderDataMembers.allowedUsers,
};

export const deciderDataKeyToMemberType = invert(memberTypeToDeciderDataKey);

export const memberTypeLabels: {
  [key in FeatureFlagMember['memberType']]: string;
} = {
  ORGANIZATION: 'Organization',
  ORGANIZATION_USER: 'Organization Group',
  SECURITY_GROUP: 'Security Group',
  USER: 'User',
};

export const memberTypeIcons: {
  [key in FeatureFlagMember['memberType']]: OverridableComponent<
    SvgIconTypeMap<Record<string, unknown>, 'svg'>
  >;
} = {
  ORGANIZATION: Business,
  ORGANIZATION_USER: Group,
  SECURITY_GROUP: VpnKey,
  USER: Person,
};
