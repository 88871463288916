export enum ReconVelocityTagDefinitionType {
  DEALER = 'DEALER',
  SYSTEM = 'SYSTEM',
  UNKNOWN = 'UNKNOWN',
}

export interface ReconVelocityTagDefinition {
  id?: string;
  label: string;
  colorHex: string;
  type: ReconVelocityTagDefinitionType;
}
