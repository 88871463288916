import { Divider } from '@material-ui/core';
import { Star } from '@material-ui/icons';
import { ItemDrag, MuuriComponent } from 'muuri-react';
import React, { Key, useRef } from 'react';

import strings from 'common/strings';
import { FolioDocumentTemplate, FolioFolder, FolioTemplate } from 'models';

import MediaContainer from './MediaContainer';

import './VelocityEngageMediaTab.scss';

interface VelocityEngageMediaTabProps {
  folioId: string;
  orgId: string;
  folioTemplateData: FolioTemplate;
  setFolioTemplateData: (folioTemplate: FolioTemplate) => void;
}

const VelocityEngageMediaTab: React.FC<VelocityEngageMediaTabProps> = ({
  folioId,
  orgId,
  folioTemplateData,
  setFolioTemplateData,
}: VelocityEngageMediaTabProps) => {
  const folderListState = JSON.parse(
    JSON.stringify(folioTemplateData.folders)
  ) as FolioFolder[];
  const setFolderListState = (folders: FolioFolder[]) => {
    setFolioTemplateData({
      ...folioTemplateData,
      folders,
    });
  };
  const scrollRef = useRef<HTMLDivElement>(null);

  const buildCombinedOrder = (folderList: FolioFolder[]) => {
    let combinedFolioSections: Array<{ sortOrder: number; name: string }> = [];
    combinedFolioSections.push(
      {
        sortOrder: parseInt(
          folioTemplateData?.settings?.RECOMMENDED_VEHICLE_ORDER ?? '1'
        ),
        name: strings.SIMILAR_VEHICLES,
      },
      {
        sortOrder: parseInt(
          folioTemplateData?.settings?.VEHICLE_SPECS_ORDER ?? '5'
        ),
        name: strings.VEHICLE_SPECIFICATIONS,
      }
    );

    folderList.forEach((folder) => {
      combinedFolioSections.push({
        sortOrder: folder.sortOrder,
        name: folder.name,
      });
    });

    combinedFolioSections = combinedFolioSections.sort((a, b) => {
      // featured should always be at the top
      if (b.name === strings.FEATURED) return 1;
      if (a.name === strings.FEATURED) return -1;
      else return a.sortOrder - b.sortOrder;
    });
    return combinedFolioSections.map((entry) => entry.name);
  };

  const combinedFolderOrder = buildCombinedOrder(folderListState);

  const lastFolderIndex = combinedFolderOrder.length - 1;

  const onDeleteDocument = (folioDocument: FolioDocumentTemplate) => {
    const updatedFolders = folderListState.map((x) => x);
    updatedFolders.forEach((folder) => {
      const docIndex = folder.documents.findIndex(
        (doc) => doc.id === folioDocument.id
      );
      if (docIndex > -1) {
        folder.documents.splice(docIndex, 1);
        setFolderListState(updatedFolders);
        setFolioTemplateData({
          ...folioTemplateData,
          folders: updatedFolders,
          availableDocuments: [
            ...folioTemplateData.availableDocuments,
            folioDocument,
          ],
        });
      }
    });
  };

  const onEditDocument = async (
    folioDocument: FolioDocumentTemplate,
    folderId: string,
    title: string,
    previewImageUrl: string,
    isAddMedia: boolean = false,
    mediaUrl?: string,
    originalPreviewImageUrl?: string
  ) => {
    const updatedFolders = folderListState.map((x) => x);
    const folderToModify = updatedFolders.find((folder) => {
      return folder.id === folderId;
    });

    if (folderToModify === undefined) {
      return;
    }

    if (isAddMedia) {
      // Create new doc my adding it to the folder.
      folioDocument.displayLabel = title;
      folioDocument.url = mediaUrl || null;
      folioDocument.thumbnailUrl = previewImageUrl;
      folioDocument.originalThumbnailUrl = originalPreviewImageUrl || '';
      folderToModify.documents.push(folioDocument);
      if (folioDocument.id) {
        setFolioTemplateData({
          ...folioTemplateData,
          folders: updatedFolders,
          availableDocuments: folioTemplateData.availableDocuments.filter(
            (doc) => doc.id !== folioDocument.id
          ),
        });
      }
    } else {
      // Update existing doc.
      const item = folderToModify.documents.find(
        (document) => document.id === folioDocument.id
      );
      if (item) {
        item.displayLabel = title;
        item.url = mediaUrl || null;
        item.thumbnailUrl = previewImageUrl;
        item.originalThumbnailUrl = originalPreviewImageUrl || '';
      }
    }

    setFolderListState(updatedFolders);
  };

  // Function to use for MUURI "reparenting" (moving docs across folders).
  // Params The MUURI framework passes to onSend are detailed here:
  // https://paol-imi.github.io/muuri-react/docs/usage/reparenting
  const onSendDocumentToFolder = ({
    key,
    fromId,
    toId,
    toIndex,
  }: {
    key: Key;
    fromId: string | undefined;
    toId: string | undefined;
    toIndex: number;
  }) => {
    const fromFolder = folderListState.find((folder) => {
      return folder.id === fromId;
    });
    const movedDoc = fromFolder?.documents.find((doc) => {
      return doc.id ? doc.id === key : doc.uiKey === key;
    });
    const updatedFolders = folderListState.map((folder) => {
      if (folder.id === fromId && fromFolder !== undefined) {
        const filteredDocs = folder?.documents?.filter((x) =>
          x.id ? x.id !== key : x.uiKey !== key
        );
        fromFolder.documents = filteredDocs;
        return fromFolder;
      } else if (folder.id === toId && movedDoc !== undefined) {
        folder.documents.splice(toIndex, 0, movedDoc);
      }
      return folder;
    });
    setFolderListState(updatedFolders);
  };

  /**
   * Update folder order within a folio after drag-and-drop reordering.
   * @param reorderObject - A map where keys are folder IDs and values are the new index within the folio.
   */
  const reorderFolders = async (reorderObject: Record<string, number>) => {
    const updatedFolderList = folderListState.map((x) => x);
    for (const key in reorderObject) {
      const moduleFolder = updatedFolderList.find((x) => x.name === key);
      if (moduleFolder) {
        moduleFolder.sortOrder = reorderObject[key];
      }
    }
    setFolderListState(updatedFolderList);
    setFolioTemplateData({
      ...folioTemplateData,
      folders: updatedFolderList,
      settings: {
        ...(folioTemplateData?.settings ?? {}),
        VEHICLE_SPECS_ORDER:
          reorderObject[strings.VEHICLE_SPECIFICATIONS].toString(),
        RECOMMENDED_VEHICLE_ORDER:
          reorderObject[strings.SIMILAR_VEHICLES].toString(),
      },
    });
  };

  /**
   * Update document order within a folder after drag-and-drop reordering.
   * @param folderId - The folder for which documents have been reordered.
   * @param reorderObject - A map where keys are document IDs and values are the new index within the folder.
   */
  const reorderDocument = async (
    folderId: string,
    reorderObject: Record<string, number>
  ) => {
    function sortedDocuments(documents: readonly FolioDocumentTemplate[]) {
      const result = [...documents];
      result.sort(
        (left, right) =>
          (reorderObject[left.id] ?? 0) - (reorderObject[right.id] ?? 0)
      );
      return result;
    }
    setFolderListState(
      folderListState.map((folder) => {
        if (folder.id === folderId) {
          return {
            ...folder,
            documents: sortedDocuments(folder.documents),
          };
        } else {
          return folder;
        }
      })
    );
  };

  return (
    <div className="VelocityEngageMediaTab" ref={scrollRef}>
      <MuuriComponent
        dragEnabled
        dragStartPredicate={(item: any) => {
          return item.getKey() !== strings.FEATURED;
        }}
        dragSortPredicate={(item: any) => {
          const result = ItemDrag.defaultSortPredicate(item);
          return result && result.index === 0 ? false : result;
        }}
        dragHandle={'.MediaContainer-title'}
        onDragEnd={async (item: any) => {
          const reorderObject = item
            .getGrid()
            .getItems()
            .reduce(
              (acc: Record<string, number>, listItem: any, idx: number) => {
                if (listItem.getKey()) {
                  acc[listItem.getKey()] = idx;
                }
                return acc;
              },
              {}
            );

          reorderFolders(reorderObject);
        }}
      >
        {combinedFolderOrder.map((folderName: string, idx: number) => {
          const folder = folderListState.find((x) => x.name === folderName);
          if (folder) {
            return (
              <div
                key={folder.name}
                style={{
                  width: '100%',
                }}
              >
                <MediaContainer
                  orgId={orgId}
                  folderId={folder.id}
                  folioId={folioId}
                  icon={
                    folder.name === strings.FEATURED ? (
                      <Star className="Featured-icon" />
                    ) : null
                  }
                  title={folder.name}
                  media={folder.documents}
                  scrollRef={scrollRef}
                  onDelete={onDeleteDocument}
                  onEdit={onEditDocument}
                  onReorder={reorderDocument}
                  onSendDocumentToFolder={onSendDocumentToFolder}
                  availableDocuments={folioTemplateData.availableDocuments}
                />
                {idx !== lastFolderIndex && <Divider />}
              </div>
            );
          } else {
            return (
              <div key={folderName} style={{ width: '100%' }}>
                <div className="MediaContainer" ref={scrollRef}>
                  <div className="MediaContainer-title">{folderName}</div>
                  {idx !== lastFolderIndex && <Divider />}
                </div>
              </div>
            );
          }
        })}
      </MuuriComponent>
    </div>
  );
};

export default VelocityEngageMediaTab;
