import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import pluralize from 'pluralize';

import { ReconVelocityWorkflowStep } from 'models';

interface WorkflowStepExistingVehiclesSectionProps {
  step: ReconVelocityWorkflowStep;
  action?: string;
  vehicleCount: number;
  otherSteps: ReconVelocityWorkflowStep[];
  forUpdating?: boolean;
  selectedStepId?: ReconVelocityWorkflowStep['id'];
  onSelectStep?: (newStepId: ReconVelocityWorkflowStep['id']) => void;
}

const WorkflowStepExistingVehiclesSection = ({
  step,
  action = 'update',
  vehicleCount,
  otherSteps,
  forUpdating = false,
  selectedStepId,
  onSelectStep,
}: WorkflowStepExistingVehiclesSectionProps) => {
  if (vehicleCount === 0) return null;

  const actionPastTense = action.endsWith('e') ? `${action}d` : `${action}ed`;

  return (
    <div className="margin-bottom">
      <div>
        {`${pluralize('vehicle', vehicleCount, true)} 
          ${pluralize('is', vehicleCount)} currently in the `}
        <b>{step.name}</b>
        {` step. Before this step can be ${actionPastTense}, ${pluralize(
          'this',
          vehicleCount
        )} ${pluralize(
          'vehicle',
          vehicleCount
        )} must be moved to another step.`}
      </div>

      {forUpdating && (
        <FormControl
          className="margin-top"
          variant="outlined"
          margin="dense"
          style={{ width: 250 }}
        >
          <InputLabel shrink variant="outlined" margin="dense">
            {`Move ${pluralize('these', vehicleCount)} ${pluralize(
              'vehicles',
              vehicleCount
            )} to step`}
          </InputLabel>
          <Select
            variant="outlined"
            margin="dense"
            value={selectedStepId ?? ''}
            label={`Move ${pluralize('these', vehicleCount)} ${pluralize(
              'vehicles',
              vehicleCount
            )} to step`}
            displayEmpty
            onChange={({ target: { value } }) =>
              onSelectStep?.(value as string)
            }
          >
            <MenuItem value="">Select a Step</MenuItem>
            {otherSteps.map(({ id: otherStepId, name: otherStepName }) => (
              <MenuItem
                key={`existing-vehicles-workflow-step-${otherStepId}`}
                value={otherStepId}
              >
                {otherStepName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
};

export default WorkflowStepExistingVehiclesSection;
