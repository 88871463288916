import {
  Box,
  Button,
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  Theme,
  Typography,
} from '@material-ui/core';
import { Publish } from '@material-ui/icons';
import React, { useState } from 'react';

import api from 'api';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { VelocityEngagePluginItem } from 'models';

import './MarketingTagsTab.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    input: {
      display: 'none',
    },
    formControl: {
      minWidth: 175,
      cursor: 'pointer',
    },
  })
);

interface MarketingTagsTabProps {
  orgId: string;
  saveDealerdotcomLocation: (dealerdotcomLocation: string) => void;
  toggleEnabledBadgeSwitch: (badgeEnabled: boolean) => void;
  toggleOpenEmbeddedSwitch: (openEmbeddedEnabled: boolean) => void;
  toggleDealerSiteShowModules: (dealerSiteShowModules: boolean) => void;
  engagePluginSettings: {
    data: VelocityEngagePluginItem | undefined;
    loading: boolean;
    error?: Error;
  };
}

const MarketingTagsTab = ({
  orgId,
  saveDealerdotcomLocation,
  toggleEnabledBadgeSwitch,
  toggleOpenEmbeddedSwitch,
  toggleDealerSiteShowModules,
  engagePluginSettings,
}: MarketingTagsTabProps) => {
  const [buttonImageUrl, setButtonImageUrl] = useState<string | null>(null);

  const classes = useStyles();

  const uploadCustomBadgeImage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.currentTarget.files && orgId) {
      const imageData = new FormData();
      imageData.append('file', event.currentTarget.files[0]);
      const mediaUrl =
        await api.organizations.plugins.velocityengage.media.createMediaBadgeImage(
          {
            orgId,
            image: imageData,
            pluginName: 'velocityvdp',
            settingName: 'DEALERDOTCOM_IMAGE_URL',
          }
        );
      setButtonImageUrl(mediaUrl);
    }
  };

  const uploadedImageUrl =
    buttonImageUrl || engagePluginSettings?.data?.dealerdotcomImageURL;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Typography variant="h6" className="align-left">
            Website Button/Badge Integration
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end">
            <FormControlLabel
              className="margin-right-md"
              label={
                engagePluginSettings.loading ? (
                  <LoadingIndicator size={26} />
                ) : (
                  <FormLabel>
                    {engagePluginSettings.data?.dealerdotcomEnabled
                      ? 'Enabled'
                      : 'Disabled'}
                  </FormLabel>
                )
              }
              labelPlacement="start"
              control={
                <Switch
                  inputProps={{
                    'aria-label':
                      'Velocity Engage Website button integration Enable checkbox',
                  }}
                  checked={
                    engagePluginSettings.data?.dealerdotcomEnabled ?? false
                  }
                  disabled={engagePluginSettings.loading}
                  onChange={(_, checked) => toggleEnabledBadgeSwitch(checked)}
                />
              }
            />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" className="align-left">
            Badge Image
          </Typography>
          <Typography variant="body2" className="align-left">
            Choose an image file for Dealer.com badges or HTML button links. PNG
            and JPEG formats accepted.
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end">
            <input
              type="file"
              id="dealerdotcom-badge-image"
              accept="image/png, image/jpeg"
              className={classes.input}
              multiple
              onChange={uploadCustomBadgeImage}
            />
            <label htmlFor="dealerdotcom-badge-image">
              <Button
                variant="outlined"
                component="span"
                className={classes.button}
                disableElevation
              >
                <Publish />
                Upload
              </Button>
            </label>
            {uploadedImageUrl ? (
              <img
                src={uploadedImageUrl}
                alt="badge"
                className="badge-image-preview"
              />
            ) : (
              <p>No image uploaded</p>
            )}
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" className="align-left">
            Open badge links within dealer site.
          </Typography>
          <Typography variant="body2" className="align-left">
            Check the box to open links in an iFrame embedded on dealer&apos;s
            Website. Uncheck the box to open links in a new tab.
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end">
            <FormControlLabel
              className="margin-right-md"
              id="dealer-badge-toggle"
              label={
                engagePluginSettings.loading ? (
                  <LoadingIndicator size={26} />
                ) : (
                  <FormLabel>
                    {engagePluginSettings.data?.badgeOpenEmbedded
                      ? 'Enabled'
                      : 'Disabled'}
                  </FormLabel>
                )
              }
              labelPlacement="start"
              control={
                <Switch
                  inputProps={{
                    'aria-label':
                      'Velocity Engage Website button integration Enable checkbox',
                  }}
                  checked={
                    engagePluginSettings.data?.badgeOpenEmbedded ?? false
                  }
                  disabled={engagePluginSettings.loading}
                  onChange={(_, checked) => toggleOpenEmbeddedSwitch(checked)}
                />
              }
            />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" className="align-left">
            Velocity Engage Button Location
          </Typography>
          <Typography variant="body2" className="align-left">
            This configuration option determines whether the button goes in the
            vehicle badge or pricing section for Dealer.com
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="select-outlined-label">
                Vehicle Badge Location
              </InputLabel>
              <Select
                labelId="select-outlined-label-location"
                id="select-outlined-location"
                value={engagePluginSettings.data?.dealerdotcomLocation}
                onChange={(event) => {
                  saveDealerdotcomLocation(event.target.value as string);
                }}
                label="Location"
                disabled={engagePluginSettings.loading}
                variant="outlined"
              >
                <MenuItem value="vehicle-badge">Vehicle Badge</MenuItem>
                <MenuItem value="vehicle-pricing">Vehicle Pricing</MenuItem>
                <MenuItem value="vehicle-payments">Vehicle Payments</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" className="align-left">
            Velocity Engage Display Modules
          </Typography>
          <Typography variant="body2" className="align-left">
            This configuration option determines whether the modules or vehicle
            badge is used for the dealer site
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end">
            <FormControlLabel
              className="margin-right-md"
              label={
                engagePluginSettings.loading ? (
                  <LoadingIndicator size={26} />
                ) : (
                  <FormLabel>
                    {engagePluginSettings.data?.dealerSiteShowModules
                      ? 'Enabled'
                      : 'Disabled'}
                  </FormLabel>
                )
              }
              labelPlacement="start"
              control={
                <Switch
                  inputProps={{
                    'aria-label':
                      'Velocity Engage Website show modules Enable checkbox',
                  }}
                  checked={
                    engagePluginSettings.data?.dealerSiteShowModules ?? false
                  }
                  disabled={engagePluginSettings.loading}
                  onChange={(_, checked) =>
                    toggleDealerSiteShowModules(checked)
                  }
                />
              }
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default MarketingTagsTab;
