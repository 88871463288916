import {
  Box,
  Button,
  createStyles,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  Dialog as MaterialDialog,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import { Close, Publish } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useIMask } from 'react-imask';
import { useParams } from 'react-router';

import {
  createMediaBadgeImage,
  useOrganization,
  usePlugin,
  usePluginCreate,
  usePluginUpdate,
  useUpgradeOrganization,
} from 'api';
import Alert, { useAlert } from 'components/shared/Alert';
import Dialog from 'components/shared/Dialog';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import {
  DealerdotcomBadgeLocation,
  PluginItem,
  VelocityWindowStickerPluginItem,
} from 'models';

import '../PluginDialog.scss';

const TrialFormFragment: React.VFC = () => {
  const params = useParams();
  const { organizationId } = params;
  const { data: organization } = useOrganization(organizationId);
  const isTrialExpired =
    organization?.trialOrganizationExpiration &&
    Date.parse(organization.trialOrganizationExpiration) < Date.now();
  const trialStatusString =
    organization?.trialOrganizationExpiration &&
    `Trial status: ${isTrialExpired ? 'ended' : 'ending'} ${new Date(
      organization.trialOrganizationExpiration
    ).toLocaleString()}`;
  const [open, setOpen] = useState(false);
  const { mutateAsync, isLoading: isMutating } =
    useUpgradeOrganization(organizationId);

  if (!organization?.trialOrganizationExpiration) {
    return null;
  }
  return (
    <>
      <Grid item xs={8}>
        <Box display="flex" alignItems="center" height="100%">
          <Typography variant="body1" className="align-left">
            {trialStatusString}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="outlined"
            component="span"
            disableElevation
            onClick={() => setOpen(true)}
          >
            Remove trial
          </Button>
        </Box>
      </Grid>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        primaryButtonLabel="Remove"
        height={70}
        title="Confirmation"
        onPrimary={async () => {
          await mutateAsync();
          setOpen(false);
        }}
        block={isMutating}
      >
        Are you sure you want to remove the window sticker trial for{' '}
        {organization.name} ?
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    previewImage: {
      maxWidth: '300px',
    },
    button: {
      margin: theme.spacing(1),
    },
    input: {
      display: 'none',
    },
    formControl: {
      minWidth: 175,
      cursor: 'pointer',
    },
  })
);

export interface VelocityWindowStickerPluginDialogProps {
  plugin: PluginItem;
  onSave?: () => void;
  onClose?: () => void;
  orgId: string;
}

const VelocityWindowStickerPluginDialog: React.FC<VelocityWindowStickerPluginDialogProps> =
  (props) => {
    const { plugin, onClose, orgId } = props;

    const { isAlertOpen, alertMessage, openAlert, closeAlert, variant } =
      useAlert();
    const [enabled, setEnabled] = useState(true);
    const [
      dealerWebsiteIntegrationEnabled,
      setDealerWebsiteIntegrationEnabled,
    ] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [badgeIframeEnabled, setBadgeIframeEnabled] = useState(false);
    const [badgeImageUrl, setBadgeImageUrl] = useState('');
    const [dealerdotcomBadgeLocation, setDealerdotcomBadgeLocation] =
      useState<DealerdotcomBadgeLocation>('vehicle-badge');
    const [displayPriceFieldEnabled, setDisplayPriceFieldEnabled] =
      useState(true);
    const [dealerLogoImageUrl, setDealerLogoImageUrl] = useState('');
    const [dealerLogoErrorMessage, setDealerLogoErrorMesssage] = useState('');
    const [showUrlEnabled, setShowUrlEnabled] = useState(false);
    const [customDisclaimerEnabled, setCustomDisclaimerEnabled] =
      useState(false);
    const [customDisclaimer, setCustomDisclaimer] = useState('');
    const [displayWebsiteUrlEnabled, setDisplayWebsiteUrlEnabled] =
      useState(false);
    const [customPhoneEnabled, setCustomPhoneEnabled] = useState(false);
    const [customPhone, setCustomPhone] = useState('');
    const [hasUnsavedTextFieldChanges, setHasUnsavedTextFieldChanges] =
      useState(false);
    const [sharedInventoryEnabled, setSharedInventoryEnabled] = useState(false);
    const [hideStandardOptionsEnabled, setHideStandardOptionsEnabled] =
      useState(false);
    // If plugin has no ID, it's newly created, so initial settings are unsaved.
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(
      !plugin?.id ? true : false
    );

    const classes = useStyles();

    const pluginTypeId = plugin.pluginName.toLowerCase().replace(/_/g, '');
    const { data: pluginData, isInitialLoading: isReading } = usePlugin(
      orgId,
      pluginTypeId,
      plugin.id
    );
    const { updatePluginAsync, isLoading: isUpdating } = usePluginUpdate(
      orgId,
      pluginTypeId
    );
    const { createPluginAsync, isLoading: isCreating } = usePluginCreate(
      orgId,
      pluginTypeId
    );

    useEffect(() => {
      setIsLoading(isReading || isUpdating || isCreating);
    }, [isReading, isCreating, isUpdating]);

    useEffect(() => {
      if (!isReading && pluginData) {
        setEnabled(pluginData.enabled ?? true);
        setDealerWebsiteIntegrationEnabled(
          pluginData?.dealerWebsiteIntegrationEnabled ?? false
        );
        setBadgeIframeEnabled(pluginData?.badgeIframeEnabled ?? false);
        setBadgeImageUrl(pluginData?.badgeImageUrl ?? '');
        setDealerdotcomBadgeLocation(
          pluginData?.dealerdotcomBadgeLocation ?? 'vehicle-badge'
        );
        setDealerLogoImageUrl(pluginData?.dealerLogoImageUrl ?? '');
        setDisplayPriceFieldEnabled(
          pluginData?.displayPriceFieldEnabled ?? false
        );
        setShowUrlEnabled(pluginData?.showUrlEnabled ?? false);
        setCustomDisclaimerEnabled(
          pluginData?.customDisclaimerEnabled ?? false
        );
        setCustomDisclaimer(pluginData?.customDisclaimer ?? '');
        setSharedInventoryEnabled(pluginData?.sharedInventoryEnabled ?? false);
        setHideStandardOptionsEnabled(
          pluginData?.hideStandardOptionsEnabled ?? false
        );
        setDisplayWebsiteUrlEnabled(
          pluginData?.displayWebsiteUrlEnabled ?? true
        );
        setCustomPhoneEnabled(pluginData?.customPhoneEnabled ?? false);
        setCustomPhone(pluginData?.customPhone ?? '');
      }
    }, [pluginData, isReading]);

    const save = useCallback(async () => {
      const data: Partial<VelocityWindowStickerPluginItem> = {
        enabled,
        dealerWebsiteIntegrationEnabled,
        badgeIframeEnabled,
        badgeImageUrl,
        dealerdotcomBadgeLocation,
        dealerLogoImageUrl,
        displayPriceFieldEnabled,
        showUrlEnabled,
        customDisclaimerEnabled,
        customDisclaimer,
        sharedInventoryEnabled,
        hideStandardOptionsEnabled,
        displayWebsiteUrlEnabled,
        customPhoneEnabled,
        customPhone,
      };
      try {
        if (plugin.id) {
          await updatePluginAsync(data);
        } else {
          const newPlugin = { ...plugin, ...data };
          await createPluginAsync(newPlugin);
        }
      } catch (error) {
        console.error(error);
        openAlert(
          'An error occurred when saving this plug-in to the server.',
          'error'
        );
        return false;
      }
      return true;
    }, [
      badgeIframeEnabled,
      badgeImageUrl,
      createPluginAsync,
      dealerWebsiteIntegrationEnabled,
      dealerdotcomBadgeLocation,
      dealerLogoImageUrl,
      displayPriceFieldEnabled,
      enabled,
      openAlert,
      plugin,
      showUrlEnabled,
      updatePluginAsync,
      customDisclaimerEnabled,
      customDisclaimer,
      sharedInventoryEnabled,
      hideStandardOptionsEnabled,
      displayWebsiteUrlEnabled,
      customPhoneEnabled,
      customPhone,
    ]);

    useEffect(() => {
      const asyncSave = async () => await save();
      if (hasUnsavedChanges) {
        asyncSave();
        setHasUnsavedChanges(false);
      }
    }, [
      hasUnsavedChanges,
      enabled,
      dealerWebsiteIntegrationEnabled,
      badgeIframeEnabled,
      badgeImageUrl,
      dealerdotcomBadgeLocation,
      dealerLogoImageUrl,
      displayPriceFieldEnabled,
      showUrlEnabled,
      save,
    ]);

    const uploadBadgeImage = async (files: FileList | null) => {
      if (files && orgId) {
        const imageData = new FormData();
        imageData.append('file', files[0]);
        const imageUrl = await createMediaBadgeImage({
          orgId,
          image: imageData,
          pluginName: 'velocitywindowsticker',
          settingName: 'BADGE_IMAGE_URL',
        });
        setBadgeImageUrl(imageUrl);
      }
    };

    const handleClose = async () => {
      if (dealerLogoErrorMessage) {
        openAlert('Enter a valid dealer logo URL or clear the field.', 'error');
        return;
      }
      if (!maskRef.current?.masked.isComplete && customPhoneEnabled) {
        openAlert(
          'Enter a valid custom phone number or disable the different phone number setting.',
          'error'
        );
        return;
      }
      if (hasUnsavedTextFieldChanges) {
        await save();
      }
      onClose?.();
    };

    const { ref: customPhoneInputRef, maskRef } = useIMask(
      {
        mask: [
          {
            mask: '(###) ###-####',
            definitions: {
              '#': /[A-Z0-9]/,
            },
            overwrite: 'shift',
          },
          {
            mask: '###-###-####',
            definitions: {
              '#': /[A-Z0-9]/,
            },
            overwrite: 'shift',
          },
          {
            mask: '### ### ####',
            definitions: {
              '#': /[A-Z0-9]/,
            },
            overwrite: 'shift',
          },
        ],
      },
      {
        onAccept: (value) => {
          setCustomPhone(value);
          setHasUnsavedTextFieldChanges(true);
        },
      }
    );

    return (
      <MaterialDialog open fullWidth maxWidth="sm" scroll="paper">
        <DialogTitle
          className="flex-row flex-space-between valign-center flex-grow padding-bottom-md"
          disableTypography
        >
          <Typography variant="h6">
            {`Configure ${plugin.title} Plug-in`}
          </Typography>
          <div className="flex-row valign-center">
            <FormControlLabel
              className="margin-right-md"
              label={
                isLoading ? (
                  <LoadingIndicator size={26} />
                ) : (
                  <FormLabel>{enabled ? 'Enabled' : 'Disabled'}</FormLabel>
                )
              }
              labelPlacement="start"
              control={
                <Switch
                  inputProps={{
                    'aria-label':
                      'Velocity Window Sticker Plugin Dialog Enable switch',
                  }}
                  checked={enabled}
                  disabled={isLoading}
                  onChange={(event) => {
                    setEnabled(event.target.checked);
                    setHasUnsavedChanges(true);
                  }}
                />
              }
            />

            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="padding-lg" dividers>
          <Grid container spacing={3}>
            <TrialFormFragment />
            <Grid item xs={8}>
              <Typography variant="body1" className="align-left">
                Website Button/Badge Integration
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <FormControlLabel
                  className="margin-right-md"
                  label={
                    isLoading ? (
                      <LoadingIndicator size={26} />
                    ) : (
                      <FormLabel>
                        {dealerWebsiteIntegrationEnabled
                          ? 'Enabled'
                          : 'Disabled'}
                      </FormLabel>
                    )
                  }
                  labelPlacement="start"
                  control={
                    <Switch
                      inputProps={{
                        'aria-label':
                          'Velocity Window Sticker website button integration enable checkbox.',
                      }}
                      checked={dealerWebsiteIntegrationEnabled}
                      disabled={isLoading}
                      onChange={(_, checked) => {
                        setDealerWebsiteIntegrationEnabled(checked);
                        setHasUnsavedChanges(true);
                      }}
                    />
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" className="align-left">
                Badge Image
              </Typography>
              <Typography variant="body2" className="align-left">
                Choose an image file for dealer site links. PNG and JPEG formats
                accepted.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <input
                  type="file"
                  id="dealerdotcom-badge-image"
                  accept="image/png, image/jpeg"
                  className={classes.input}
                  multiple
                  onChange={(e) => uploadBadgeImage(e.currentTarget.files)}
                />
                <label htmlFor="dealerdotcom-badge-image">
                  <Button
                    variant="outlined"
                    component="span"
                    className={classes.button}
                    disableElevation
                  >
                    <Publish />
                    Upload
                  </Button>
                </label>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" className="align-left">
                Current badge image:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Box display="flex" justifyContent="flex-end">
                {badgeImageUrl ? (
                  <img
                    src={badgeImageUrl}
                    alt="badge"
                    className={classes.previewImage}
                  />
                ) : (
                  <p>* Currently using default image.</p>
                )}
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" className="align-left">
                Open badge links within dealer site.
              </Typography>
              <Typography variant="body2" className="align-left">
                Enable to open links in an iFrame embedded on dealer&apos;s
                Website. Disable to open links in a new tab.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <FormControlLabel
                  className="margin-right-md"
                  id="dealer-badge-toggle"
                  label={
                    isLoading ? (
                      <LoadingIndicator size={26} />
                    ) : (
                      <FormLabel>
                        {badgeIframeEnabled ? 'Enabled' : 'Disabled'}
                      </FormLabel>
                    )
                  }
                  labelPlacement="start"
                  control={
                    <Switch
                      inputProps={{
                        'aria-label':
                          'Velocity Engage Website button integration Enable checkbox',
                      }}
                      checked={badgeIframeEnabled}
                      disabled={isLoading || !dealerWebsiteIntegrationEnabled}
                      onChange={(_, checked) => {
                        setBadgeIframeEnabled(checked);
                        setHasUnsavedChanges(true);
                      }}
                    />
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" className="align-left">
                Badge Location (Dealer.com only)
              </Typography>
              <Typography variant="body2" className="align-left">
                Controls where the badge displays on Dealer.com vehicle
                listings.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="select-outlined-label">
                    Vehicle Badge Location
                  </InputLabel>
                  <Select
                    labelId="select-outlined-label-location"
                    id="select-outlined-location"
                    value={dealerdotcomBadgeLocation}
                    onChange={(event) => {
                      setDealerdotcomBadgeLocation(
                        event.target.value as DealerdotcomBadgeLocation
                      );
                      setHasUnsavedChanges(true);
                    }}
                    label="Location"
                    disabled={isLoading || !dealerWebsiteIntegrationEnabled}
                    variant="outlined"
                  >
                    <MenuItem value="vehicle-badge">Vehicle Badge</MenuItem>
                    <MenuItem value="vehicle-pricing">Vehicle Pricing</MenuItem>
                    <MenuItem value="vehicle-payments">
                      Vehicle Payments
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="align-left">
                Dealer Logo URL
              </Typography>
              <TextField
                style={{ marginTop: 8 }}
                label="URL"
                variant="outlined"
                size="small"
                fullWidth
                value={dealerLogoImageUrl || ''}
                onChange={(event) => {
                  setDealerLogoImageUrl(event.target.value);
                  setHasUnsavedTextFieldChanges(true);
                }}
                error={!!dealerLogoErrorMessage}
                helperText={dealerLogoErrorMessage}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" className="align-left">
                Current dealer logo image:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Box display="flex" justifyContent="flex-end">
                {dealerLogoImageUrl ? (
                  <img
                    src={dealerLogoImageUrl}
                    alt="Dealer logo"
                    className={classes.previewImage}
                    onLoad={() => setDealerLogoErrorMesssage('')}
                    onError={() =>
                      setDealerLogoErrorMesssage('Error loading image from URL')
                    }
                  />
                ) : (
                  <p>* No dealer logo set.</p>
                )}
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" className="align-left">
                Display Pricing on window sticker
              </Typography>
              <Typography variant="body2" className="align-left">
                Enable to display all pricing on the window sticker.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <FormControlLabel
                  className="margin-right-md"
                  label={
                    isLoading ? (
                      <LoadingIndicator size={26} />
                    ) : (
                      <FormLabel>
                        {displayPriceFieldEnabled ? 'Enabled' : 'Disabled'}
                      </FormLabel>
                    )
                  }
                  labelPlacement="start"
                  control={
                    <Switch
                      inputProps={{
                        'aria-label':
                          'Velocity Engage Website window sticker show price checkbox',
                      }}
                      checked={displayPriceFieldEnabled}
                      disabled={isLoading}
                      onChange={(_, checked) => {
                        setDisplayPriceFieldEnabled(checked);
                        setHasUnsavedChanges(true);
                      }}
                    />
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" className="align-left">
                Display OEM window sticker if available
              </Typography>
              <Typography variant="body2" className="align-left">
                Enable to display an OEM window sticker first. Then, we'll
                display the available custom window sticker information.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <FormControlLabel
                  className="margin-right-md"
                  label={
                    isLoading ? (
                      <LoadingIndicator size={26} />
                    ) : (
                      <FormLabel>
                        {showUrlEnabled ? 'Enabled' : 'Disabled'}
                      </FormLabel>
                    )
                  }
                  labelPlacement="start"
                  control={
                    <Switch
                      inputProps={{
                        'aria-label':
                          'Velocity Engage Website window sticker show OEM checkbox',
                      }}
                      checked={showUrlEnabled}
                      disabled={isLoading}
                      onChange={(_, checked) => {
                        setShowUrlEnabled(checked);
                        setHasUnsavedChanges(true);
                      }}
                    />
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" className="align-left">
                Hide standard options window sticker
              </Typography>
              <Typography variant="body2" className="align-left">
                Enable to hide standard options window sticker if an OEM or
                custom window sticker cannot be found
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <FormControlLabel
                  className="margin-right-md"
                  label={
                    isLoading ? (
                      <LoadingIndicator size={26} />
                    ) : (
                      <FormLabel>
                        {hideStandardOptionsEnabled ? 'Enabled' : 'Disabled'}
                      </FormLabel>
                    )
                  }
                  labelPlacement="start"
                  control={
                    <Switch
                      inputProps={{
                        'aria-label': 'Hide standard option window sticker',
                      }}
                      checked={hideStandardOptionsEnabled}
                      disabled={isLoading}
                      onChange={(_, checked) => {
                        setHideStandardOptionsEnabled(checked);
                        setHasUnsavedChanges(true);
                      }}
                    />
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" className="align-left">
                Shared Inventory
              </Typography>
              <Typography variant="body2" className="align-left">
                Enable to use the inventory from the group.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <FormControlLabel
                  className="margin-right-md"
                  id="dealer-badge-toggle"
                  label={
                    isLoading ? (
                      <LoadingIndicator size={26} />
                    ) : (
                      <FormLabel>
                        {sharedInventoryEnabled ? 'Enabled' : 'Disabled'}
                      </FormLabel>
                    )
                  }
                  labelPlacement="start"
                  control={
                    <Switch
                      inputProps={{
                        'aria-label':
                          'Velocity Engage Website button shared inventory Enable checkbox',
                      }}
                      checked={sharedInventoryEnabled}
                      disabled={isLoading}
                      onChange={(_, checked) => {
                        setSharedInventoryEnabled(checked);
                        setHasUnsavedChanges(true);
                      }}
                    />
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={8} style={{ paddingRight: 0 }}>
              <Typography variant="body1" className="align-left">
                Display custom disclaimer on window sticker
              </Typography>
              <Typography variant="body2" className="align-left">
                Enable to display custom disclaimer
              </Typography>
              {customDisclaimerEnabled && (
                <TextField
                  style={{ marginTop: 16 }}
                  label="Disclaimer"
                  variant="outlined"
                  multiline
                  rows={3}
                  size="small"
                  fullWidth
                  value={customDisclaimer || ''}
                  onChange={(event) => {
                    setCustomDisclaimer(event.target.value);
                    setHasUnsavedTextFieldChanges(true);
                  }}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <FormControlLabel
                  className="margin-right-md"
                  label={
                    isLoading ? (
                      <LoadingIndicator size={26} />
                    ) : (
                      <FormLabel>
                        {customDisclaimerEnabled ? 'Enabled' : 'Disabled'}
                      </FormLabel>
                    )
                  }
                  labelPlacement="start"
                  control={
                    <Switch
                      inputProps={{
                        'aria-label':
                          'Velocity Engage Website window sticker show custom disclaimer',
                      }}
                      checked={customDisclaimerEnabled}
                      disabled={isLoading}
                      onChange={(_, checked) => {
                        setCustomDisclaimerEnabled(checked);
                        setCustomDisclaimer(
                          checked ? pluginData?.customDisclaimer : ''
                        );
                        setHasUnsavedChanges(true);
                      }}
                    />
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" className="align-left">
                Display website URL.
              </Typography>
              <Typography variant="body2" className="align-left">
                Enable to display the dealership website URL.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <FormControlLabel
                  className="margin-right-md"
                  id="display-url-toggle"
                  label={
                    isLoading ? (
                      <LoadingIndicator size={26} />
                    ) : (
                      <FormLabel>
                        {displayWebsiteUrlEnabled ? 'Enabled' : 'Disabled'}
                      </FormLabel>
                    )
                  }
                  labelPlacement="start"
                  control={
                    <Switch
                      inputProps={{
                        'aria-label':
                          'Velocity Engage Website button display URL checkbox',
                      }}
                      checked={displayWebsiteUrlEnabled}
                      disabled={isLoading}
                      onChange={(_, checked) => {
                        setDisplayWebsiteUrlEnabled(checked);
                        setHasUnsavedChanges(true);
                      }}
                    />
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={8} style={{ paddingRight: 0 }}>
              <Typography variant="body1" className="align-left">
                Display different phone number
              </Typography>
              <Typography variant="body2" className="align-left">
                Enable to display a different phone number on window sticker
                (Capital letters are allowed)
              </Typography>
              {customPhoneEnabled && (
                <TextField
                  style={{ marginTop: 16 }}
                  label="Custom phone number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={!maskRef.current?.masked.isComplete}
                  helperText={
                    !maskRef.current?.masked.isComplete &&
                    'Invalid phone number'
                  }
                  placeholder="(XXX) XXX-XXXX"
                  value={customPhone || ''}
                  InputProps={{
                    inputRef: customPhoneInputRef,
                  }}
                  autoFocus
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <FormControlLabel
                  className="margin-right-md"
                  label={
                    isLoading ? (
                      <LoadingIndicator size={26} />
                    ) : (
                      <FormLabel>
                        {customPhoneEnabled ? 'Enabled' : 'Disabled'}
                      </FormLabel>
                    )
                  }
                  labelPlacement="start"
                  control={
                    <Switch
                      inputProps={{
                        'aria-label':
                          'Velocity Engage Website window sticker show custom disclaimer',
                      }}
                      checked={customPhoneEnabled}
                      disabled={isLoading}
                      onChange={(_, checked) => {
                        setCustomPhoneEnabled(checked);
                        setCustomPhone(checked ? pluginData?.customPhone : '');
                        setHasUnsavedChanges(true);
                        if (checked) {
                          customPhoneInputRef.current?.focus();
                        }
                      }}
                    />
                  }
                />
              </Box>
            </Grid>
          </Grid>
          <Alert
            open={isAlertOpen}
            message={alertMessage}
            onClose={closeAlert}
            duration={3500}
            variant={variant}
          />
        </DialogContent>
      </MaterialDialog>
    );
  };

export default VelocityWindowStickerPluginDialog;
