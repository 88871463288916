import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { HttpMethod } from 'api/lib';
import { defaultMutationFn } from 'api/useAPI';
import { Team } from 'models/team';

export const useTeams = (orgId: string) => {
  const queryClient = useQueryClient();
  return useQuery<Team[], Error>([`/user-team/org/${orgId}`], {
    enabled: !!orgId,
    onError: (error) => {
      if (error.message.includes('404')) {
        queryClient.setQueryData([`/user-team/org/${orgId}`], []);
      }
    },
  });
};

export const useTeam = (teamId?: string) => {
  const queryClient = useQueryClient();
  return useQuery<Team, Error>([`/user-team/${teamId}`], {
    enabled: !!teamId,
    onError: (error) => {
      if (error.message.includes('404')) {
        queryClient.setQueryData([`/user-team/${teamId}`], {});
      }
    },
  });
};

export const useTeamMutation = (orgId: string, teamId?: string) => {
  const queryClient = useQueryClient();
  const path = '/user-team';
  return useMutation<Team, Error, Team & { delete?: boolean }>(
    (team) => {
      let method: HttpMethod = teamId ? 'PUT' : 'POST';
      if (team.delete) {
        method = 'DELETE';
      }

      const url = teamId ? `${path}/${teamId}` : path;
      return defaultMutationFn(url, method, team);
    },
    {
      onSettled: () => {
        if (teamId) {
          queryClient.invalidateQueries([`/user-team/${teamId}`]);
        }
        if (orgId) {
          queryClient.invalidateQueries([`/user-team/org/${orgId}`]);
        }
      },
    }
  );
};
