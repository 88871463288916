import {
  GetOrganizationUserNotificationSubscriptionsResponse,
  ScheduleType,
} from 'models';
import {
  addSubscriptionActionTypes,
  getSubscriptionsActionTypes,
  NotificationsAction,
  subscribeStepActionTypes,
  unsubscribeActionTypes,
  unsubscribeStepActionTypes,
} from 'store/actions/organizations/users/notifications';
import { FetchedData } from 'store/reducers/types';

export type NotificationsState =
  FetchedData<GetOrganizationUserNotificationSubscriptionsResponse>;

export const initialState: NotificationsState = {
  loading: false,
  data: {
    channels: [],
    eventCategories: [],
    subscriptions: [],
    subscribedStepDefinitionIds: {},
    stepDefinitions: [],
  },
  error: undefined,
};

export default function notificationsReducer(
  state: NotificationsState = initialState,
  action: NotificationsAction
): NotificationsState {
  switch (action.type) {
    case getSubscriptionsActionTypes.pending: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
    case getSubscriptionsActionTypes.fulfilled: {
      const { data = state.data } = action.payload ?? {};

      return {
        ...state,
        loading: false,
        error: undefined,
        data,
      };
    }
    case addSubscriptionActionTypes.fulfilled: {
      const { data } = action.payload ?? {};

      if (!data) return state;

      const { subscriptions = [] } = state.data ?? initialState.data;

      const index = subscriptions.findIndex(
        ({ channelId, eventName }) =>
          channelId === data?.channelId && eventName === data?.eventName
      );
      subscriptions[index] = data;

      return {
        ...state,
        data: {
          ...state.data,
          subscriptions,
        },
      };
    }
    case unsubscribeActionTypes.fulfilled: {
      const { subscriptions = [] } = state.data;
      const { data } = action.payload ?? {};

      if (!data) return state;

      const { eventName, channelId } = data;

      const index = subscriptions.findIndex(
        (subscription) =>
          subscription.eventName === eventName &&
          subscription.channelId === channelId
      );
      if (index === -1) return state;

      subscriptions[index] = {
        ...subscriptions[index],
        scheduleType: ScheduleType.DISABLED,
      };

      return {
        ...state,
        data: {
          ...state.data,
          subscriptions,
        },
        loading: false,
      };
    }

    case getSubscriptionsActionTypes.rejected:
    case addSubscriptionActionTypes.rejected:
    case unsubscribeActionTypes.rejected: {
      const { error } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        error,
      };
    }

    case subscribeStepActionTypes.fulfilled:
    case unsubscribeStepActionTypes.fulfilled: {
      const { data } = action.payload ?? {};
      const { stepDefinitionId, subscribed, eventName } = data ?? {};

      if (!stepDefinitionId || !eventName) return state;

      const updatedSubscribedStepDefinitionIds = {
        ...state.data.subscribedStepDefinitionIds,
        [eventName]: subscribed
          ? [
              ...state.data.subscribedStepDefinitionIds[eventName],
              stepDefinitionId,
            ]
          : state.data.subscribedStepDefinitionIds[eventName]?.filter(
              (id) => id !== stepDefinitionId
            ),
      };

      return {
        ...state,
        data: {
          ...state.data,
          subscribedStepDefinitionIds: updatedSubscribedStepDefinitionIds,
        },
      };
    }

    default:
      return state;
  }
}
