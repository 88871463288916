import api from 'api';
import { PluginItem } from 'models/plugins';
import {
  ActionTypeFromCreators,
  createAsyncAction,
} from 'store/actions/helpers';

export const actions = {
  getSupportedPlugins: 'Get Supported Plugins',
} as const;

export const {
  types: getSupportedPluginsActionTypes,
  creators: getSupportedPluginsActionCreators,
  action: getSupportedPlugins,
} = createAsyncAction<typeof actions.getSupportedPlugins, void, PluginItem[]>(
  actions.getSupportedPlugins,
  () => api.utility.plugins.getSupported()
);

type GetSupportedPluginsAction = ActionTypeFromCreators<
  typeof actions.getSupportedPlugins,
  PluginItem[],
  typeof getSupportedPluginsActionCreators
>;

export type PluginsAction = GetSupportedPluginsAction;
