import { Button } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';

import permissions from 'common/permissions';
import strings from 'common/strings';
import theme from 'common/theme';
import PermissionGate from 'components/PermissionGate';
import KeyValueFieldTable from 'components/shared/KeyValueFieldTable';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { MatchCriteriaForIngest, ReconVelocityIngestProcess } from 'models';

import './IngestProcessDetailView.scss';

export interface EditIngestProcessDetailViewProps {
  isBeginningStep: boolean;
  ingestProcess: ReconVelocityIngestProcess;
  onSaveIngestionProcessConfig: (
    ingestProcess: ReconVelocityIngestProcess
  ) => Promise<void>;
  onDeleteIngestionProcessConfig: (
    ingestProcess: ReconVelocityIngestProcess
  ) => Promise<void>;
  onSetBeginningStep: () => Promise<void>;
}

const IngestProcessDetailView: FC<EditIngestProcessDetailViewProps> = ({
  isBeginningStep,
  ingestProcess,
  onSaveIngestionProcessConfig,
  onDeleteIngestionProcessConfig,
  onSetBeginningStep,
}) => {
  const [isCancelButtonDisabled, setIsCancelButtonDisabled] = useState(true);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [isSavingIngestProcess, setIsSavingIngestProcess] = useState(false);
  const [isSavingBeginningStep, setIsSavingBeginningStep] = useState(false);
  const [draftIngestProcess, setDraftIngestProcess] = useState(ingestProcess);

  // Reset draft ingest process when selected ingest process changes.
  useEffect(() => {
    setDraftIngestProcess(ingestProcess);
  }, [ingestProcess]);

  // Update button states whenever the selected ingest process changes, or the current draft changes.
  useEffect(() => {
    const partiallyFilledMatchCriteriaRows =
      draftIngestProcess.matchCriteriaForIngest.filter(
        (matchCriteria) =>
          matchCriteria.regexPattern !== '' || matchCriteria.columnName !== ''
      );
    const isMatchCriteriaEmpty = !partiallyFilledMatchCriteriaRows.length;
    const hasValidMatchCriteria = partiallyFilledMatchCriteriaRows.every(
      (matchCriteria) =>
        matchCriteria.regexPattern !== '' && matchCriteria.columnName !== ''
    );
    const isDraftIngestProcessDirty = draftIngestProcess !== ingestProcess;

    const shouldDisableSaveButton =
      draftIngestProcess.stepTargetId === '' ||
      !hasValidMatchCriteria ||
      !isDraftIngestProcessDirty;

    const shouldDisableCancelButton =
      !isDraftIngestProcessDirty ||
      (!ingestProcess._id && isMatchCriteriaEmpty);

    setIsCancelButtonDisabled(shouldDisableCancelButton);
    setIsSaveButtonDisabled(shouldDisableSaveButton);
  }, [draftIngestProcess, ingestProcess]);

  const onChangeMatchCriteria = (index: number, key: string, value: string) => {
    const isUpdatingRegex = key === 'value';

    setDraftIngestProcess((currentDraftIngestProcess) => {
      const updatedMatchCriteria =
        currentDraftIngestProcess.matchCriteriaForIngest.map(
          (mapValue, mapIndex) => {
            if (mapIndex !== index) {
              return mapValue;
            }
            if (isUpdatingRegex) {
              return {
                ...mapValue,
                regexPattern: value,
              };
            } else {
              return {
                ...mapValue,
                columnName: value,
              };
            }
          }
        );

      return {
        ...currentDraftIngestProcess,
        matchCriteriaForIngest: updatedMatchCriteria,
      };
    });
  };

  const onDeleteMatchCriteria = (index: number) => {
    const emptyMatchCriteria: MatchCriteriaForIngest = {
      columnName: '',
      regexPattern: '',
    };

    setDraftIngestProcess((currentDraftIngestProcess) => {
      const updatedMatchCriteria =
        currentDraftIngestProcess.matchCriteriaForIngest.filter(
          (_mapValue, mapIndex) => {
            return mapIndex !== index;
          }
        );

      return {
        ...currentDraftIngestProcess,
        matchCriteriaForIngest: [...updatedMatchCriteria, emptyMatchCriteria],
      };
    });
  };

  const saveIngestionProcessConfig = async () => {
    const isMatchCriteriaEmpty =
      draftIngestProcess.matchCriteriaForIngest.every(
        (matchCriteria) =>
          !matchCriteria.columnName && !matchCriteria.regexPattern
      );

    if (!isMatchCriteriaEmpty) {
      return onSaveIngestionProcessConfig(draftIngestProcess);
    }

    if (ingestProcess._id) {
      return onDeleteIngestionProcessConfig(draftIngestProcess);
    }
  };

  const cancel = () => {
    setDraftIngestProcess(ingestProcess);
  };

  const save = async () => {
    if (isSavingIngestProcess) {
      return;
    }

    setIsCancelButtonDisabled(true);
    setIsSavingIngestProcess(true);
    await saveIngestionProcessConfig();
    setIsSavingIngestProcess(false);
  };

  const setBeginningStep = async () => {
    if (isSavingBeginningStep) {
      return;
    }

    setIsSavingBeginningStep(true);
    await onSetBeginningStep();
    setIsSavingBeginningStep(false);
  };

  return (
    <div className="IngestProcessDetailView full-height flex-rows">
      <div className="padding">
        <h3 className="inline">Match Criteria</h3>
      </div>
      <div className="flex-columns flex-justify-center valign-center padding">
        <KeyValueFieldTable
          matchCriteria={draftIngestProcess.matchCriteriaForIngest.map(
            (row) => ({ key: row.columnName, value: row.regexPattern })
          )}
          onDeleteRow={onDeleteMatchCriteria}
          onChange={onChangeMatchCriteria}
        />
      </div>
      <div className="buttons-wrapper full-width flex-columns flex-justify-end padding">
        <Button
          id="cancel-button"
          className="inline"
          variant="outlined"
          onClick={cancel}
          disabled={isCancelButtonDisabled}
        >
          {strings.CANCEL}
        </Button>
        <div>
          <PermissionGate
            permissions={[permissions.ORGS_PLUGINS_RECONVELOCITY_UPDATE]}
          >
            <Button
              id="save-button"
              color="secondary"
              variant="contained"
              style={{
                color: theme.palette.common.white,
              }}
              startIcon={
                isSavingIngestProcess && (
                  <LoadingIndicator color="inherit" size={13} />
                )
              }
              onClick={save}
              disabled={isSaveButtonDisabled}
            >
              <b>{strings.SAVE}</b>
            </Button>
          </PermissionGate>
        </div>
      </div>
      <PermissionGate
        permissions={[permissions.ORGS_PLUGINS_RECONVELOCITY_UPDATE]}
      >
        <div className="BeginningStep">
          <div className="padding">
            <h3 className="inline">{strings.BEGINNING_STEP}</h3>
          </div>
          <div className="padding">
            {isBeginningStep ? (
              <p>
                "{ingestProcess.stepTargetName}" is the current default
                beginning step.
              </p>
            ) : (
              <Button
                id="set-beginning-step"
                color="secondary"
                variant="contained"
                style={{ color: theme.palette.common.white }}
                startIcon={
                  isSavingBeginningStep && (
                    <LoadingIndicator color="inherit" size={13} />
                  )
                }
                onClick={setBeginningStep}
                disabled={isSavingBeginningStep}
              >
                Set "{ingestProcess.stepTargetName}" as beginning step
              </Button>
            )}
          </div>
        </div>
      </PermissionGate>
    </div>
  );
};
export default IngestProcessDetailView;
