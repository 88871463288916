import { combineReducers } from 'redux';

import generalReducer, { initialState as generalInitialState } from './general';

const velocityengageReducer = combineReducers({
  general: generalReducer,
  // media
  // integrations
  // website
});

export type VelocityEngagePluginState = ReturnType<
  typeof velocityengageReducer
>;

export const initialState: VelocityEngagePluginState = {
  general: generalInitialState,
};

export default velocityengageReducer;
