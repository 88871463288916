import TextField from '@material-ui/core/TextField';
import { useState } from 'react';
import Select, { components } from 'react-select';

import { useCreateTagDefinition } from 'api/organizations/tags';
import Dialog from 'components/shared/Dialog';
import { Organization, Tag } from 'models';

import './CreateTagsDialog.scss';

export const TAG_COLOR_OPTIONS = [
  {
    value: '#EEEFEF',
    label: 'Silver',
  },
  {
    value: '#FFDFDD',
    label: 'Red',
  },
  {
    value: '#FFEAD0',
    label: 'Orange',
  },
  {
    value: '#FFF7CC',
    label: 'Yellow',
  },
  {
    value: '#E2F9DA',
    label: 'Green',
  },
  {
    value: '#D5F6EE',
    label: 'Aqua',
  },
  {
    value: '#DEF1F9',
    label: 'Light Blue',
  },
  {
    value: '#DBE8FF',
    label: 'Dark Blue',
  },
  {
    value: '#EFE6FD',
    label: 'Purple',
  },
  {
    value: '#FCE3F6',
    label: 'Pink',
  },
];

var CustomOption = (props: any): any => {
  const {
    data,
    isSelected,
    isFocused,
    theme: { colors },
  } = props;

  let backgroundColor;
  if (isSelected) {
    backgroundColor = colors.primary;
  } else if (isFocused) {
    backgroundColor = colors.primary25;
  } else {
    backgroundColor = 'transparent';
  }

  return (
    <div className="CreateTagsDialogOption">
      <div
        className="CreateTagsDialogOption-circle-container"
        style={{ backgroundColor }}
      >
        <div
          style={{ backgroundColor: data.value }}
          className="CreateTagsDialogOption-color-circle"
        />
      </div>
      <components.Option {...props} />
    </div>
  );
};

interface CreateTagsDialogProps {
  currentLabel: string;
  open: boolean;
  onClose: () => void;
  onCreate: (newTag: Tag) => void;
  orgId: Organization['id'];
}

var CreateTagsDialog = ({
  currentLabel,
  open,
  onClose,
  onCreate,
  orgId,
}: CreateTagsDialogProps) => {
  const { createTagDefinitionAsync } = useCreateTagDefinition(orgId);
  const [label, setLabel] = useState(currentLabel);
  const [color, setColor] = useState({
    value: TAG_COLOR_OPTIONS[0].value,
    label: TAG_COLOR_OPTIONS[0].label,
  });

  return (
    <Dialog
      className="CreateTagsDialog"
      open={open}
      primaryButtonLabel="Create"
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      height={132}
      title="Create Tag"
      onPrimary={async () => {
        const newTag = {
          label,
          color: color.value,
        };
        const createdTag = await createTagDefinitionAsync(newTag);
        onCreate(createdTag);
        onClose();
      }}
    >
      <div className="CreateTagsDialog-container">
        <TextField
          className="CreateTagsDialog-text-input"
          label="Tag Label"
          variant="outlined"
          value={label}
          onChange={(event) => setLabel(event.target.value)}
          margin="dense"
        />
        <Select
          className="CreateTagsDialog-select"
          options={TAG_COLOR_OPTIONS}
          menuPortalTarget={document.body}
          value={color}
          onChange={(newColor) => newColor && setColor(newColor)}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            option: (provided: any) => ({
              ...provided,
              color: 'black',
              paddingTop: 16,
              paddingBottom: 16,
            }),
            control: (provided: any) => ({
              ...provided,
              color: 'black',
              padding: 0,
            }),
            singleValue: (provided: any) => ({
              ...provided,
              color: 'black',
            }),
            menu: (provided: any) => ({
              ...provided,
              paddingTop: 0,
              paddingBottom: 0,
            }),
            menuList: (provided: any) => ({
              ...provided,
              paddingTop: 0,
              paddingBottom: 0,
            }),
          }}
          components={{
            Option: CustomOption,
          }}
        />
      </div>
    </Dialog>
  );
};

export default CreateTagsDialog;
