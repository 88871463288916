import { Create } from '@material-ui/icons';
import React from 'react';

import permissions from 'common/permissions';
import PermissionGate from 'components/PermissionGate';
import { ReconVelocityTagDefinition } from 'models/plugins/reconVelocityPlugin';

import './TagDefinitionsView.scss';

interface TagDefinitionDetailViewProps {
  tagDefinition?: ReconVelocityTagDefinition;
  openEditTagDefinitionDialog: (
    tagDefinition: ReconVelocityTagDefinition
  ) => void;
}

const TagDefinitionDetailView: React.FC<TagDefinitionDetailViewProps> = (
  props: TagDefinitionDetailViewProps
) => {
  const { tagDefinition, openEditTagDefinitionDialog } = props;

  if (!tagDefinition) return null;

  const handleEditButtonClick = () => {
    openEditTagDefinitionDialog(tagDefinition);
  };

  return (
    <div className="flex-rows">
      <div className="full-width flex-columns padding">
        <div className="full-width flex-row">
          <div className="inline-block">
            <h2 className="inline-block">{tagDefinition.label}</h2>
          </div>
          <div
            className="tag__color"
            style={{
              backgroundColor: `${tagDefinition.colorHex || '#cccccc'}`,
            }}
          />
        </div>
        <PermissionGate
          permissions={[
            permissions.ORGS_PLUGINS_RECONVELOCITY_TAG_DEFINITIONS_UPDATE,
          ]}
        >
          <Create onClick={handleEditButtonClick} />
        </PermissionGate>
      </div>
    </div>
  );
};

export default TagDefinitionDetailView;
