import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import { compact } from 'lodash';
import React, { useEffect, useState } from 'react';

import { usePlugin, usePluginCreate, usePluginUpdate } from 'api';
import strings from 'common/strings';
import { AlertVariant } from 'components/shared/Alert/Alert';
import ConfirmationDialog, {
  useConfirmationDialog,
} from 'components/shared/ConfirmationDialog';
import Dialog from 'components/shared/Dialog';
import KeyValueFieldTable, {
  BLANK_REGEXP,
  BLANK_ROW_COUNT,
} from 'components/shared/KeyValueFieldTable';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { DataProviderPluginItem } from 'models';

import './PluginDialog.scss';

export interface FileNameDealerIdAndMatchCriteriaPluginDialogProps {
  plugin: DataProviderPluginItem;
  onSave?: () => void;
  isInventoryEnabled?: boolean;
  onClose?: () => void;
  orgId: string;
  openAlert: (message: string, variant: AlertVariant) => void;
}

const FileNameDealerIdAndMatchCriteriaPluginDialog: React.FC<FileNameDealerIdAndMatchCriteriaPluginDialogProps> =
  (props) => {
    const { plugin, onClose, orgId, isInventoryEnabled, openAlert } = props;
    const [enabled, setEnabled] = useState(true);
    const [dealerId, setDealerId] = useState('');
    const [fileName, setFileName] = useState('');
    const [notes, setNotes] = useState('');
    const [matchCriteria, setMatchCriteria] = useState<
      { key: string; value: string }[]
    >([]);

    const pluginTypeId = plugin.pluginName.toLowerCase().replace('_', '');
    const { data: pluginData, isInitialLoading: isReading } = usePlugin(
      orgId,
      pluginTypeId,
      plugin.id
    );
    const { updatePluginAsync, isLoading: isUpdating } = usePluginUpdate(
      orgId,
      pluginTypeId
    );
    const { createPluginAsync, isLoading: isCreating } = usePluginCreate(
      orgId,
      pluginTypeId
    );
    const isLoading = isReading || isUpdating || isCreating;

    useEffect(() => {
      const blankRows = Array.from(Array(BLANK_ROW_COUNT), () => {
        return { key: '', value: '' };
      });

      if (!isReading && pluginData) {
        setEnabled(pluginData.enabled ?? true);
        setDealerId(pluginData.dealerId || '');
        setFileName(pluginData.fileName || '');
        setNotes(pluginData.notes || '');

        if (pluginData.matchCriteria) {
          setMatchCriteria(
            pluginData.matchCriteria
              .map((criterion: any) => {
                return {
                  key: criterion.columnName,
                  value: criterion.regexPattern,
                };
              })
              .concat(blankRows)
          );
        }
      } else if (!isReading) {
        // Done loading but no data exists (true for create new plugin).
        setMatchCriteria(blankRows);
      }
    }, [pluginData, isReading]);

    const {
      isConfirmationDialogOpen,
      isConfirmationLoading,
      confirmationMessage,
      onAccept,
      onDeny,
      openConfirmationDialog,
      primaryButtonLabel,
    } = useConfirmationDialog();

    const onDeleteRow = (rowIndex: number, key: string) => {
      openConfirmationDialog({
        messageOverride: `Remove column "${key}"?`,
        primaryButtonLabelOverride: 'Delete',
        onAcceptOverride: () => {
          setMatchCriteria(
            matchCriteria.filter((criteria) => criteria.key !== key)
          );
        },
      });
    };

    const save = async () => {
      const data: Partial<DataProviderPluginItem> = {
        enabled,
        fileName,
        dealerId,
        notes,
        matchCriteria: compact(
          matchCriteria.map(({ key, value }) =>
            BLANK_REGEXP.test(key) && BLANK_REGEXP.test(value)
              ? undefined
              : { columnName: key, regexPattern: value }
          )
        ),
      };
      try {
        if (plugin.id) {
          await updatePluginAsync(data);
        } else {
          const newPlugin = { ...plugin, ...data };
          await createPluginAsync(newPlugin);
        }
        onClose?.();
      } catch (error) {
        console.error(error);
        openAlert(
          'An error occurred when saving this plug-in to the server.',
          'error'
        );
        return false;
      }
      return true;
    };

    const disabledPluginSaveButton = () => {
      if (fileName === '') {
        openAlert('File Name is required', 'error');
        return true;
      } else if (
        (!isInventoryEnabled && !pluginData) ||
        (isInventoryEnabled && pluginData) ||
        (isInventoryEnabled && isLoading)
      ) {
        return false;
      } else if (isInventoryEnabled && plugin?.subCategory === 'IMS') {
        openAlert('Only one inventory provider can be enabled.', 'error');
        return true;
      }
    };

    return (
      <>
        <Dialog
          open
          title={`Configure ${plugin.title} Plug-in`}
          maxWidth="sm"
          fullWidth
          primaryButtonLabel="Save"
          onPrimary={save}
          onClose={onClose}
          block={isLoading}
          primaryButtonDisabled={disabledPluginSaveButton()}
          className="PluginDialog ImsPluginDialog"
        >
          <div>
            <FormControlLabel
              className="PluginDialog__switch"
              label={
                isLoading ? (
                  <LoadingIndicator size={26} />
                ) : (
                  <FormLabel>{enabled ? 'Enabled' : 'Disabled'}</FormLabel>
                )
              }
              labelPlacement="start"
              control={
                <Switch
                  inputProps={{
                    'aria-label': 'IMS Plugin Dialog Enable switch',
                  }}
                  checked={enabled ?? false}
                  disabled={isLoading}
                  onChange={(_, checked) => {
                    setEnabled(checked);
                  }}
                />
              }
            />
          </div>
          <div>
            <TextField
              autoFocus
              className="PluginDialog__text-input"
              label="File Name"
              variant="outlined"
              required
              value={fileName}
              onChange={(e) => {
                setFileName(e.target.value);
              }}
              margin="dense"
            />
            <div>
              <TextField
                autoFocus
                className="PluginDialog__text-input"
                label="Dealer ID"
                variant="outlined"
                required
                value={dealerId}
                onChange={(e) => {
                  setDealerId(e.target.value);
                }}
                margin="dense"
              />
            </div>
          </div>

          <KeyValueFieldTable
            label="Match Criteria"
            matchCriteria={matchCriteria}
            onDeleteRow={onDeleteRow}
            onChange={(index, property, newValue) => {
              const currentKeyValuePair = matchCriteria[index];
              let newKeyValuePair = { ...currentKeyValuePair };
              newKeyValuePair[property] = newValue;
              let newMatchCriteria = [...matchCriteria];
              newMatchCriteria.splice(index, 1, newKeyValuePair);
              setMatchCriteria(newMatchCriteria);
            }}
          />
          <TextareaAutosize
            className="PluginDialog__text-area"
            placeholder="Notes"
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value);
            }}
            rowsMax={4}
            rowsMin={4}
          />

          <div>
            {strings.INVENTORY_INGESTION_DATE_PLUGINS(
              pluginData?.ingestFileReceivedDate,
              pluginData?.ingestFileVehicleCount,
              pluginData?.dealerId
            )}
          </div>
        </Dialog>
        <ConfirmationDialog
          open={isConfirmationDialogOpen}
          primaryButtonLabel={primaryButtonLabel}
          onPrimaryButtonClick={onAccept}
          onSecondaryButtonClick={onDeny}
          isLoading={isConfirmationLoading}
          message={confirmationMessage}
        />
      </>
    );
  };

export default FileNameDealerIdAndMatchCriteriaPluginDialog;
