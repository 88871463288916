import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useSession, { setTokens } from 'api/session';

const Authenticate: React.FC = ({ children }) => {
  const queryParam = new URLSearchParams(useLocation().search);
  const refreshToken = queryParam.get('refreshToken');
  if (refreshToken) {
    setTokens('', refreshToken, '');
  }

  const navigate = useNavigate();
  const { isLoading, isError } = useSession();

  if (isError) {
    navigate('/login');
  }

  if (isLoading || isError) {
    return null;
  }

  return <>{children}</>;
};

export default Authenticate;
