import {
  Box,
  Button,
  createStyles,
  InputLabel,
  makeStyles,
  Dialog as MaterialDialog,
  DialogActions as MaterialDialogActions,
  DialogContent as MaterialDialogContent,
  DialogTitle as MaterialDialogTitle,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { useRef, useState } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';

import strings from 'common/strings';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { FolioDocumentTemplate } from 'models';

import api from '../../../../../../../api';

import 'cropperjs/dist/cropper.css';

export async function dataUrlToImageUrl({
  dataUrl,
  folioId,
  orgId,
}: {
  dataUrl: string;
  orgId: string;
  folioId: string;
}) {
  const blob = await fetch(dataUrl).then((res) => res.blob());
  const formData = new FormData();
  formData.append('file', blob);
  return api.organizations.plugins.velocityengage.folioTemplate.createMediaFolioDocumentMedia(
    {
      orgId,
      folioTemplateId: folioId,
      file: formData,
    }
  );
}

interface EngageMediaItemEditModalProps {
  open: boolean;
  onClose: () => void;
  orgId: string;
  folioId: string;
  folderId: string;
  media: FolioDocumentTemplate;
  onEdit: (
    document: FolioDocumentTemplate,
    folderId: string,
    title: string,
    previewImageUrl: string,
    isAddMedia: boolean,
    mediaUrl?: string,
    originalPrevieImageUrl?: string
  ) => void;
  availableDocuments: FolioDocumentTemplate[];
}

const useStyles = makeStyles(() =>
  createStyles({
    engageMediaItemEditModal: {
      width: '560px',
    },
    editFields: {
      width: '100%',
    },
    input: {
      display: 'none',
    },
  })
);

const EngageMediaItemEditModal: React.FC<EngageMediaItemEditModalProps> = ({
  open,
  onClose,
  folioId,
  folderId,
  orgId,
  media,
  onEdit,
  availableDocuments,
}) => {
  const isAddMedia = !media.id && !media.url;
  const [availableDocsClone] = useState(availableDocuments.map((x) => x));
  const firstAvailableDoc = { ...availableDocsClone[0] };
  const [selectedMedia, setSelectedMedia] = useState<FolioDocumentTemplate>(
    isAddMedia ? firstAvailableDoc ?? media : media
  );
  const [mediaTitle, setMediaTitle] = useState(
    selectedMedia.displayLabel ?? ''
  );
  const [isMediaFileUpload, setIsMediaFileUpload] = useState(false);
  const [mediaUrl, setMediaUrl] = useState(selectedMedia.url ?? '');
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isMediaPreviewFileUpload, setIsMediaPreviewFileUpload] =
    useState(false);
  const [mediaPreviewUrl, setMediaPreviewUrl] = useState(
    selectedMedia.thumbnailUrl
  );
  const [originalMediaPreviewUrl, setOriginalMediaPreviewUrl] = useState(
    selectedMedia.originalThumbnailUrl ?? ''
  );
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const cropperRef = useRef<ReactCropperElement>(null);
  const [dataUrl, setDataUrl] = useState<string | null>(null);
  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setDataUrl(cropper?.getCroppedCanvas().toDataURL() || null);
  };

  const handleChangeSelectedMedia = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    let newSelectedMedia;
    if (event.target.value === 0) {
      newSelectedMedia = { ...media };
    } else {
      newSelectedMedia = availableDocsClone.find((doc) => {
        return event.target.value === doc.id;
      });
    }

    if (newSelectedMedia) {
      setSelectedMedia({ ...newSelectedMedia, uiKey: media.uiKey });
      setMediaTitle(newSelectedMedia.displayLabel);
      setMediaUrl(newSelectedMedia.url ?? '');
      setSelectedFile(undefined);
      setIsMediaPreviewFileUpload(false);
      setMediaPreviewUrl(newSelectedMedia.thumbnailUrl);
      setOriginalMediaPreviewUrl(newSelectedMedia.originalThumbnailUrl ?? '');
    }
  };

  const handleChangeEditMediaType = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setIsMediaFileUpload(event.target.value === 1);
  };

  const handleChangeEditMediaPreviewImageType = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setIsMediaPreviewFileUpload(event.target.value === 1);
  };

  const handleSelectFile = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.currentTarget.files) {
      const file = event.currentTarget.files[0];
      setSelectedFile(file);
      setIsUpdating(true);
      const uploadUrl =
        await api.organizations.plugins.velocityengage.folioTemplate.getPresignedUploadUrl(
          orgId,
          folioId,
          file.name
        );
      await api.organizations.plugins.velocityengage.folioTemplate.uploadWithPresignedUrl(
        uploadUrl,
        file
      );
      setMediaUrl(uploadUrl.split('?')[0]);
      setIsUpdating(false);
    }
  };

  const handleSelectPreviewImageFile = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.currentTarget.files) {
      const file = event.currentTarget.files[0];
      setSelectedFile(file);
      const imageData = new FormData();
      imageData.append('file', file);
      const imageUrl =
        await api.organizations.plugins.velocityengage.folioTemplate.createMediaFolioDocumentMedia(
          {
            orgId,
            folioTemplateId: folioId,
            file: imageData,
          }
        );
      setOriginalMediaPreviewUrl(imageUrl);
    }
  };

  const handleCancelEdit = () => {
    onClose();
  };
  const classes = useStyles();
  return (
    <MaterialDialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      aria-labelledby="edit-media-title-dialog-title"
    >
      <MaterialDialogTitle
        id="edit-media-title-dialog-title"
        className={classes.engageMediaItemEditModal}
      >
        {isAddMedia || (media.id && !media.url && media.type !== 'DYNMAIC')
          ? strings.ADD_MEDIA
          : strings.EDIT_MEDIA}
      </MaterialDialogTitle>
      <MaterialDialogContent className={classes.engageMediaItemEditModal}>
        {formErrorMessage && (
          <div style={{ color: 'rgb(198, 0, 0)' }}>{formErrorMessage}</div>
        )}
        {isAddMedia && (
          <div>
            <InputLabel shrink htmlFor="edit-media-doc-type-select">
              Choose a built-in module type or continue with "Custom".
            </InputLabel>
            <Select
              label="Module Type"
              id="edit-media-module-type-select"
              value={selectedMedia.id || 0}
              onChange={handleChangeSelectedMedia}
              name="edit-media-type-input"
              variant="outlined"
              className={classes.editFields}
            >
              {availableDocsClone
                .filter((doc) => !!doc.id)
                .map((doc) => {
                  return <MenuItem value={doc.id}>{doc.displayLabel}</MenuItem>;
                })}
              <MenuItem value={0}>
                <i>Custom</i>
              </MenuItem>
              ;
            </Select>
          </div>
        )}
        <div>
          <TextField
            label="Title"
            value={mediaTitle}
            onChange={(event) => setMediaTitle(event.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.editFields}
          />
        </div>
        {(selectedMedia.type === 'USER_UPLOAD' ||
          selectedMedia.type === 'STATIC') && (
          <div>
            <InputLabel shrink htmlFor="edit-media-doc-type-select">
              Link or File upload
            </InputLabel>
            <Select
              label="Link or File upload"
              id="edit-media-doc-type-select"
              value={isMediaFileUpload ? 1 : 0}
              onChange={handleChangeEditMediaType}
              name="edit-media-type-input"
              variant="outlined"
              className={classes.editFields}
            >
              <MenuItem value={0}>Link</MenuItem>
              <MenuItem value={1}>File Upload</MenuItem>
            </Select>
          </div>
        )}
        {(selectedMedia.type === 'USER_UPLOAD' ||
          selectedMedia.type === 'STATIC') &&
          (isMediaFileUpload ? (
            <Box my={1}>
              <input
                type="file"
                id={`${selectedMedia.id}file-input`}
                multiple
                onChange={handleSelectFile}
                className={classes.input}
              />
              <label htmlFor={`${selectedMedia.id}file-input`}>
                <Button variant="outlined" component="span" disableElevation>
                  Select File
                </Button>
              </label>
              <Box component="span" ml={2}>
                {selectedFile?.name
                  ? selectedFile?.name
                  : 'Select a file to upload.'}
              </Box>
            </Box>
          ) : (
            <div>
              <TextField
                label="URL - Use {vin} to pass the VIN to the URL, e.g., https://kbb.com/kbbreport/{vin}"
                value={mediaUrl}
                onChange={(event) => setMediaUrl(event.target.value)}
                margin="normal"
                variant="outlined"
                className={classes.editFields}
              />
            </div>
          ))}
        <div>
          <InputLabel shrink htmlFor="edit-media-preview-image-type-select">
            Thumbnail Type
          </InputLabel>
          <Select
            label="Thumbnail Type"
            id="edit-media-preview-image-type-select"
            value={isMediaPreviewFileUpload ? 1 : 0}
            onChange={handleChangeEditMediaPreviewImageType}
            name="edit-media-preview-image-type-input"
            variant="outlined"
            className={classes.editFields}
          >
            <MenuItem value={0}>Link</MenuItem>
            <MenuItem value={1}>File Upload</MenuItem>
          </Select>
        </div>
        {isMediaPreviewFileUpload ? (
          <Box my={2}>
            <input
              type="file"
              id={`${selectedMedia.id}preview-image-file-input`}
              multiple
              onChange={handleSelectPreviewImageFile}
              className={classes.input}
            />
            <label htmlFor={`${selectedMedia.id}preview-image-file-input`}>
              <Button variant="outlined" component="span" disableElevation>
                SELECT THUMBNAIL IMAGE
              </Button>
            </label>
          </Box>
        ) : (
          <div>
            <TextField
              label="Thumbnail Image URL"
              value={mediaPreviewUrl}
              onChange={(event) => setMediaPreviewUrl(event.target.value)}
              margin="normal"
              variant="outlined"
              className={classes.editFields}
            />
          </div>
        )}
        <div>
          {originalMediaPreviewUrl || mediaPreviewUrl ? (
            <Cropper
              src={originalMediaPreviewUrl || mediaPreviewUrl}
              style={{ height: 400, width: '100%' }}
              aspectRatio={200 / 130}
              guides
              crop={onCrop}
              ref={cropperRef}
              autoCrop={false}
              autoCropArea={1}
            />
          ) : (
            <div>No thumbnail loaded.</div>
          )}
        </div>
      </MaterialDialogContent>
      <MaterialDialogActions className={classes.engageMediaItemEditModal}>
        <Button onClick={handleCancelEdit} color="primary">
          {strings.CANCEL}
        </Button>
        <Button
          onClick={async () => {
            if (!onEdit) {
              return;
            }
            setFormErrorMessage('');
            if (!(dataUrl || mediaPreviewUrl)) {
              setFormErrorMessage('Thumnail image upload or URL required.');
              return;
            }
            setIsUpdating(true);
            const croppedMediaPreviewUrl = dataUrl
              ? await dataUrlToImageUrl({
                  dataUrl,
                  folioId,
                  orgId,
                })
              : mediaPreviewUrl;

            onEdit(
              selectedMedia,
              folderId,
              mediaTitle,
              croppedMediaPreviewUrl,
              isAddMedia,
              mediaUrl,
              originalMediaPreviewUrl
            );
            setIsUpdating(false);
            onClose();
          }}
          color="primary"
        >
          {isUpdating ? <LoadingIndicator /> : strings.SAVE}
        </Button>
      </MaterialDialogActions>
    </MaterialDialog>
  );
};

export default EngageMediaItemEditModal;
