import { useEffect, useState } from 'react';

export const useScrollBarDetector = (heightOfContent: number): boolean => {
  const [isScrollbar, setIsScrollbar] = useState(false);

  useEffect(() => {
    const { innerHeight: windowHeight } = window;
    if (heightOfContent > windowHeight) {
      setIsScrollbar(true);
    } else {
      setIsScrollbar(false);
    }
  }, [heightOfContent]);

  return isScrollbar;
};
