import {
  Box,
  FormControlLabel,
  FormLabel,
  Grid,
  Switch,
  Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';

import LoadingIndicator from 'components/shared/LoadingIndicator';
import { VelocityEngagePluginItem } from 'models';

interface SharedInventoryTabProps {
  saveEngageSettings: (data: VelocityEngagePluginItem) => void;
  engagePluginSettings: {
    data: VelocityEngagePluginItem | undefined;
    loading: boolean;
    error?: Error;
  };
}

var SharedInventoryTab = ({
  saveEngageSettings,
  engagePluginSettings,
}: SharedInventoryTabProps) => {
  const [ephemeralSettings, setEphemeralSettings] = useState(
    engagePluginSettings.data
  );

  useEffect(() => {
    setEphemeralSettings(engagePluginSettings.data);
  }, [engagePluginSettings]);

  const handleSettingChange = async (
    settingValue: string | boolean,
    settingKey: string,
    settingType: string = 'STRING'
  ) => {
    const updatedSettings: { [key: string]: any } = {
      ...ephemeralSettings?.settings,
      [settingKey]: {
        dataType: settingType,
        value: settingValue,
      },
    };

    saveEngageSettings({
      ...ephemeralSettings,
      settings: updatedSettings,
    } as VelocityEngagePluginItem);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Typography variant="body1" className="align-left">
            Brand folio template and send leads to organization’s account ID.
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end">
            <FormControlLabel
              className="margin-right-md"
              label={
                engagePluginSettings.loading ? (
                  <LoadingIndicator size={26} />
                ) : (
                  <FormLabel>
                    {engagePluginSettings.data?.settings
                      ?.SHARED_INVENTORY_USE_DEALER_ACCOUNT_ID?.value
                      ? 'Enabled'
                      : 'Disabled'}
                  </FormLabel>
                )
              }
              labelPlacement="start"
              control={
                <Switch
                  inputProps={{
                    'aria-label':
                      'Velocity Engage Website button share inventory by account id Enable checkbox',
                  }}
                  checked={
                    engagePluginSettings.data?.settings
                      ?.SHARED_INVENTORY_USE_DEALER_ACCOUNT_ID?.value ?? false
                  }
                  disabled={engagePluginSettings.loading}
                  onChange={(_, checked) =>
                    handleSettingChange(
                      checked,
                      'SHARED_INVENTORY_USE_DEALER_ACCOUNT_ID',
                      'BOOL'
                    )
                  }
                />
              }
            />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" className="align-left">
            Brand folio template and send leads to sales person’s default
            organization
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end">
            <FormControlLabel
              className="margin-right-md"
              label={
                engagePluginSettings.loading ? (
                  <LoadingIndicator size={26} />
                ) : (
                  <FormLabel>
                    {ephemeralSettings?.settings
                      ?.SHARED_INVENTORY_USE_DEALER_USER_TENANT?.value
                      ? 'Enabled'
                      : 'Disabled'}
                  </FormLabel>
                )
              }
              labelPlacement="start"
              control={
                <Switch
                  inputProps={{
                    'aria-label':
                      'Velocity Engage Website button share inventory by dealer user Enable checkbox',
                  }}
                  checked={
                    ephemeralSettings?.settings
                      ?.SHARED_INVENTORY_USE_DEALER_USER_TENANT?.value ?? false
                  }
                  disabled={engagePluginSettings.loading}
                  onChange={(_, checked) =>
                    handleSettingChange(
                      checked,
                      'SHARED_INVENTORY_USE_DEALER_USER_TENANT',
                      'BOOL'
                    )
                  }
                />
              }
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SharedInventoryTab;
