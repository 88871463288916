import { PluginOption } from 'models';
import {
  getPluginOptionsActionTypes,
  OrganizationPluginOptionsActionTypes,
} from 'store/actions/organizations/plugins/options';
import { FetchedData } from 'store/reducers/types';

export type OrganizationPluginOptionsState = FetchedData<
  PluginOption[] | undefined
>;

export const initialState: OrganizationPluginOptionsState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export default function organizationPluginOptionsReducer(
  state = initialState,
  action: OrganizationPluginOptionsActionTypes
): OrganizationPluginOptionsState {
  switch (action.type) {
    case getPluginOptionsActionTypes.pending: {
      return {
        ...state,
        loading: true,
      };
    }
    case getPluginOptionsActionTypes.fulfilled: {
      const { data } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        data,
        error: undefined,
      };
    }
    case getPluginOptionsActionTypes.rejected: {
      const { error } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        error,
      };
    }

    default:
      return state;
  }
}
