export enum InventoryFilterType {
  AGE = 'AGE',
  AREA = 'AREA',
  ASSIGNEE = 'ASSIGNEE',
  CONDITION = 'CONDITION',
  DISPOSITION = 'DISPOSITION',
  INVENTORY_COLOR = 'INVENTORY_COLOR',
  INVENTORY_MILEAGE = 'INVENTORY_MILEAGE',
  INVENTORY_RETAIL_PRICE = 'INVENTORY_RETAIL_PRICE',
  INVENTORY_STATE = 'INVENTORY_STATE',
  TAG = 'TAG',
  RECALLS = 'RECALLS',
  TASKS = 'TASKS',
  VEHICLE_BODY_TYPE = 'VEHICLE_BODY_TYPE',
  VEHICLE_MAKE = 'VEHICLE_MAKE',
  VEHICLE_MODEL = 'VEHICLE_MODEL',
  VEHICLE_YEAR = 'VEHICLE_YEAR',
}

export enum InventoryFilterBehavior {
  MULTI_SELECT = 'MULTI_SELECT',
  SINGLE_SELECT = 'SINGLE_SELECT',
}

export interface InventoryFilterOption {
  id: string;
  label: string;
  order: number;
  level: number;
}

export interface InventoryFilter {
  id: InventoryFilterType;
  label: string;
  filterType: InventoryFilterType;
  behavior: InventoryFilterBehavior;
  options: InventoryFilterOption[];
}
