import { PeopleAlt, VideoLibrary } from '@material-ui/icons';
import { Route, Routes } from 'react-router-dom';

import SetupSectionLink from '../SettingsView/SettingsSectionLink';
import ManageUsersSetup from './ManageUsersSetup';
import VelocityInsightSetup from './VelocityInsightSetup';
import VelocityWindowStickerSetup from './VelocityWindowStickerSetup';

var SetupView = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <div className="margin align-center">
            <SetupSectionLink
              icon={VideoLibrary}
              label="Velocity Insight"
              to="insight"
            />
            <SetupSectionLink
              icon={VideoLibrary}
              label="Velocity Window Stickers"
              to="stickers"
            />
            <SetupSectionLink
              icon={PeopleAlt}
              label="Manage Users"
              to="users"
            />
          </div>
        }
      />
      <Route path="insight" element={<VelocityInsightSetup />} />
      <Route path="stickers" element={<VelocityWindowStickerSetup />} />
      <Route path="users" element={<ManageUsersSetup />} />
    </Routes>
  );
};

export default SetupView;
