import * as yup from 'yup';

import {
  emailValidationSchema,
  phoneValidationSchema,
} from 'common/validation';

const locationAddressValidationSchema = yup
  .object({
    street1: yup.string().min(1).required(),
    street2: yup.string().optional().nullable(),
    city: yup.string().min(1).required(),
    state: yup.object({
      id: yup.string().required(),
    }),
    postalCode: yup.string().min(1).required(),
  })
  .required();

const billingAddressValidationSchema = yup
  .object({
    street1: yup.string().min(1),
    street2: yup.string().optional().nullable(),
    city: yup.string().min(1),
    state: yup.object({
      id: yup.string(),
    }),
    postalCode: yup.string().min(1),
  })
  .nullable();

const billingContactValidationSchema = yup
  .object({
    firstName: yup.string(),
    lastName: yup.string(),
    jobTitle: yup.string(),
    email: emailValidationSchema.notRequired(),
    mobilePhone: yup.object({
      number: phoneValidationSchema.notRequired(),
    }),
  })
  .nullable();

const mailingContactValidationSchema = yup
  .object({
    firstName: yup.string().min(1).required(),
    lastName: yup.string().min(1).required(),
    jobTitle: yup.string(),
    email: emailValidationSchema,
    mobilePhone: yup.object({
      number: phoneValidationSchema,
    }),
  })
  .required();

const dayValidationSchema = yup
  .object({
    openTime: yup.string().required(),
    closeTime: yup
      .string()
      .test(
        'open_close_validation',
        'Close time should be after Open time',
        (closeTime, schema) =>
          schema.parent.openTime.localeCompare(closeTime) < 0
      )
      .required(),
  })
  .nullable()
  .notRequired()
  .default(undefined);

const departmentValidationSchema = yup.object({
  phone: phoneValidationSchema.nullable().notRequired(),
  email: emailValidationSchema.nullable().notRequired(),
  schedule: yup
    .object({
      monday: dayValidationSchema,
      tuesday: dayValidationSchema,
      wednesday: dayValidationSchema,
      thursday: dayValidationSchema,
      friday: dayValidationSchema,
      saturday: dayValidationSchema,
      sunday: dayValidationSchema,
    })
    .nullable(),
});

export const validationSchema = yup.object({
  name: yup.string().min(1).required(),
  accountId: yup
    .string()
    .nullable()
    .notRequired()
    .min(3, 'Account ID must be at least 3 characters.')
    .max(100, 'Account ID cannot exceed 100 characters.')
    .matches(
      /^[a-zA-Z0-9\-._~]+$/,
      'Account ID can only contain letters, digits, and the symbols "-" , "_", ".", "~". '
    ),
  parent: yup
    .object({
      id: yup.string().nullable(),
    })
    .nullable(),
  websiteUrl: yup.string().url('Enter a valid URL').required(),
  timezone: yup.object({
    label: yup.string().required(),
    timezone: yup.string().required(),
  }),
  billingAddress: billingAddressValidationSchema,
  billingContact: billingContactValidationSchema,
  mailingAddress: locationAddressValidationSchema,
  mailingContact: mailingContactValidationSchema,
  salesDepartment: departmentValidationSchema,
  partsDepartment: departmentValidationSchema,
  serviceDepartment: departmentValidationSchema,
});

export const groupValidationSchema = yup.object({
  name: yup.string().min(1).required(),
  accountId: yup
    .string()
    .nullable()
    .notRequired()
    .min(3, 'Account ID must be at least 3 characters.')
    .max(100, 'Account ID cannot exceed 100 characters.')
    .matches(
      /^[a-zA-Z0-9\-._~]+$/,
      'Account ID can only contain letters, digits, and the symbols "-" , "_", ".", "~". '
    ),
  parent: yup
    .object({
      id: yup.string().nullable(),
    })
    .nullable(),
  websiteUrl: yup.string().url('Enter a valid URL').required(),
  timezone: yup.object({
    label: yup.string().required(),
    timezone: yup.string().required(),
  }),
});
