import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { FC, useState } from 'react';
import { Form } from 'react-bootstrap';

import {
  useCreateReconVelocityStepDefinitionAssocation,
  useDeleteReconVelocityStepDefinitionAssociation,
  useReconVelocityWorkflowSteps,
} from 'api/organizations/plugins/reconvelocity/workflows';
import CollectionHeaderBar from 'components/shared/CollectionHeaderBar';
import Dialog, { DialogProps } from 'components/shared/Dialog';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import {
  Organization,
  ReconVelocityStepDefinitionAssociation,
  ReconVelocityWorkflowStep,
} from 'models';

import './SharedStepsDialog.scss';

interface SharedStepsDialogProps extends DialogProps {
  step: ReconVelocityWorkflowStep;
  orgId: string;
  organizationsInGroup: Organization['children'];
  associatedSteps: ReconVelocityStepDefinitionAssociation[];
}

const SharedStepsDialog: FC<SharedStepsDialogProps> = ({
  step,
  orgId,
  organizationsInGroup,
  open,
  onClose,
  associatedSteps,
}) => {
  // Form State
  const [secondaryDealershipId, setSecondaryDealershipId] =
    useState<string>('');
  const [stepIdIsLoading, setStepIdIsLoading] = useState<string>('');

  // Queries
  const { data: stepsData, isLoading: stepsIsLoading } =
    useReconVelocityWorkflowSteps(secondaryDealershipId);
  const { createStepDefinitionAssocationAsync } =
    useCreateReconVelocityStepDefinitionAssocation(orgId, step.id);
  const { deleteStepDefinitionAssociationAsync } =
    useDeleteReconVelocityStepDefinitionAssociation(orgId, step.id);
  const assignableSteps =
    stepsData?.data.filter(({ parentStep }) => parentStep !== null) || [];

  // Form Helpers
  const isChecked = (step: ReconVelocityWorkflowStep): boolean =>
    associatedSteps.findIndex(
      ({ secondaryStep }) => secondaryStep.id === step.id
    ) >= 0;
  const isLoading = (step: ReconVelocityWorkflowStep): boolean =>
    stepIdIsLoading === step.id;
  const handleOnCheckedChange = async (
    step: ReconVelocityWorkflowStep
  ): Promise<void> => {
    if (step.id) {
      setStepIdIsLoading(step.id);
      const index = associatedSteps.findIndex(
        ({ secondaryStep }) => secondaryStep.id === step.id
      );

      if (index > -1) {
        await deleteStepDefinitionAssociationAsync(associatedSteps[index].id);
      } else {
        await createStepDefinitionAssocationAsync(step.id ?? '');
      }

      setStepIdIsLoading('');
    }
  };
  const handleSecondaryDealershipChange = (e: React.ChangeEvent<any>): void =>
    setSecondaryDealershipId(e.target.value);

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      title="Add Shared Step"
      fullWidth
      height={740}
      showCloseButton
      onClose={onClose}
      className="SharedStepsDialog"
      closeElement={
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      }
      hidePrimaryButton
    >
      <CollectionHeaderBar
        unit="Step"
        count={assignableSteps.length}
        isLoading={stepsIsLoading}
        onSelectChange={handleSecondaryDealershipChange}
        selectOptions={organizationsInGroup}
        selectValue={secondaryDealershipId}
        selectLabel="Secondary Dealership"
      />
      <Table>
        <TableBody>
          {assignableSteps.map((step) => (
            <TableRow key={step.id} hover>
              <TableCell>
                {isLoading(step) ? (
                  <LoadingIndicator size={20} className="text-left" />
                ) : (
                  <Form.Check
                    id={`SharedStepsDialog.steps.${step.id}`}
                    className="SharedStepsDialog-secondaryStep"
                    type="checkbox"
                    name="SharedStepsDialog.steps"
                    label={step.name}
                    value={step.id}
                    onChange={() => handleOnCheckedChange(step)}
                    checked={isChecked(step)}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Dialog>
  );
};

export default SharedStepsDialog;
