import React from 'react';

import {
  VehicleBrochureYear,
  VehicleMakeData,
  VehicleModelData,
} from 'models/settings';

export type BrochureAction =
  | { type: 'setMake'; payload: VehicleMakeData | undefined }
  | { type: 'setModel'; payload: VehicleModelData | undefined }
  | { type: 'setYear'; payload: VehicleBrochureYear | undefined }
  | { type: 'clear'; payload: any };

export type BrochureState = {
  vehicleYear: string;
  uri?: string | undefined;
  hasBrochure: boolean | undefined;
  vehicleMake: string;
  vehicleModel: string;
  data: VehicleMakeData[];
  makeOptions: VehicleMakeData[];
  modelOptions: VehicleModelData[];
  yearOptions: VehicleBrochureYear[];
};

export const initialBrochureState: BrochureState = {
  vehicleYear: '',
  vehicleMake: '',
  vehicleModel: '',
  hasBrochure: false,
  uri: '',
  makeOptions: [],
  modelOptions: [],
  yearOptions: [],
  data: [],
};

export const BrochureManagerContext = React.createContext<{
  state: BrochureState;
  dispatch: React.Dispatch<BrochureAction>;
}>({
  state: initialBrochureState,
  dispatch: () => {},
});

export function brochureReducer(
  state: BrochureState,
  action: BrochureAction
): BrochureState {
  if (!action.payload) return state;
  switch (action.type) {
    case 'setMake':
      return {
        ...state,
        vehicleMake: action.payload.vehicleMake,
        vehicleModel: action.payload.vehicleModels[0].vehicleModel,
        vehicleYear:
          action.payload.vehicleModels[0].vehicleYears[0].vehicleYear,
        yearOptions: action.payload.vehicleModels[0].vehicleYears,
        uri: action.payload.vehicleModels[0].vehicleYears[0].uri,
        hasBrochure: action.payload.vehicleModels[0].vehicleYears[0].uri !== '',
        modelOptions: action.payload.vehicleModels,
      };
    case 'setModel':
      return {
        ...state,
        vehicleModel: action.payload.vehicleModel,
        vehicleYear: action.payload.vehicleYears[0].vehicleYear,
        yearOptions: action.payload.vehicleYears,
        uri: action.payload.vehicleYears[0].uri,
        hasBrochure: action.payload.vehicleYears[0].uri !== '',
      };
    case 'setYear':
      return {
        ...state,
        vehicleYear: action.payload.vehicleYear,
        hasBrochure: action.payload.uri !== '',
        uri: action.payload.uri,
      };
    case 'clear':
      return { ...state, ...initialBrochureState };
    default:
      return state;
  }
}

export default BrochureManagerContext;
