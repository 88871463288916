import { merge } from 'lodash';

import { getAPIHeaders } from 'api';
import { ApiResponse } from 'models/api';

import { getTokens, setTokens } from './session';

const ONE_MINUTE = 1000 * 60;

const reAuthenticate = async () => {
  const { authRefreshToken, authBearerTokenExpiration } = await getTokens();

  if (!authRefreshToken) {
    return;
  }

  const expTime = new Date(authBearerTokenExpiration).getTime() - ONE_MINUTE;
  const curTime = new Date().getTime();
  if (expTime >= curTime) {
    return;
  }

  const { host, options } = getAPIHeaders();

  const requestOptions: RequestInit = merge<RequestInit, RequestInit>(options, {
    headers: {
      Authorization: `Bearer ${authRefreshToken}`,
    },
  });

  const response = await fetch(`${host}/authenticate`, requestOptions);

  if (!response.ok) {
    throw new Error(`Network error refreshing token: ${response.status}`);
  }

  const refreshData = await response.json();
  setTokens(
    refreshData.token,
    refreshData.refreshToken,
    refreshData.expirationDate
  );
};

/**
 * configuredFetch
 * @param path: String representing the path that should be appended to the host URL.
 * @param options: Standard options object for native fetch library.
 * @param omitContentType: Boolean indicating whether content-type header should be omitted (useful for file uploads
 * where the browser sets this on its own). Default is false, meaning the header is included.
 * @return: A promise that resolves to type specified by the caller. Assumes the API response will have a `data`
 * property with the indicated type.
 */
export async function configuredFetch<ResponseDataType = void>(
  path: string,
  options: RequestInit = {},
  omitContentType: boolean = false
): Promise<ApiResponse<ResponseDataType>> {
  await reAuthenticate();

  const headers = getAPIHeaders('GET', omitContentType);
  const requestOptions: RequestInit = merge<RequestInit, RequestInit>(
    headers.options,
    options
  );

  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_SERVICE_URL_OVERRIDE}${path}`,
    requestOptions
  );

  if (!response.ok) {
    if (response.status === 404) {
      throw new Error('404');
    }

    const error = await response.json();
    const message = error.message
      ? `${error.message}`
      : `Network response was not ok: ${response.status}`;
    throw new Error(message);
  }

  return response.json();
}

export default {
  configuredFetch,
};
