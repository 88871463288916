import React, { useCallback, useEffect, useState } from 'react';

import { configuredFetch } from 'api/base';
import { useInvalidateOrganizationGroups } from 'api/organizations/groups';
import { usePluginOptions, usePlugins } from 'api/organizations/plugins';
import useSession from 'api/session';
import permissions from 'common/permissions';
import Alert, { useAlert } from 'components/shared/Alert';
import { AlertVariant } from 'components/shared/Alert/Alert';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import {
  DataProviderDmsPluginItem,
  Organization,
  PluginItem,
  PluginItemCategory,
  PluginOption,
} from 'models';
import utils from 'utils';

import PermissionError from '../PermissionErrorView';
import EnhancerPluginDialog from './EnhancerPluginDialog';
import FileNameDealerIdAndMatchCriteriaPluginDialog from './FileNameDealerIdAndMatchCriteriaPluginDialog';
import PluginSection from './PluginSection';
import ReconVelocityPluginDialog from './ReconVelocityPluginDialog';
import SalesBusinessLinePluginDialog from './SalesBusinessLinePluginDialog';
import TabsForMatchCriteriaPerBusinessLinePluginDialog from './TabsForMatchCriteriaPerBusinessLinePluginDialog';
import VelocityEngagePluginDialog from './VelocityEngagePluginDialog/VelocityEngagePluginDialog';
import VelocityInsightPluginDialog from './VelocityInsightPluginDialog';
import VelocityWindowStickerPluginDialog from './VelocityWindowStickerPluginDialog/VelocityWindowStickerPluginDialog';

import './PluginsView.scss';

interface Section {
  category: PluginItemCategory;
  heading: string;
}

const SECTIONS: Section[] = [
  { category: PluginItemCategory.MODULE, heading: 'Modules' },
  { category: PluginItemCategory.DATA_PROVIDER, heading: 'Data Providers' },
  { category: PluginItemCategory.ENHANCER, heading: 'Enhancers' },
];

function getEnhancerPluginDialogComponent({
  plugin,
  orgId,
  openAlert,
  onClose,
}: {
  plugin: PluginItem;
  orgId: Organization['id'];
  openAlert: (message: string, variant: AlertVariant) => void;
  onClose?: () => void;
}) {
  return (
    <EnhancerPluginDialog
      plugin={plugin}
      orgId={orgId as string}
      openAlert={openAlert}
      onClose={onClose}
    />
  );
}

function getPluginDialogComponent({
  plugin,
  orgId,
  onSave,
  onClose,
  organization,
  isNewPlugin,
  isInventoryEnabled,
  openAlert,
}: {
  plugin: PluginItem;
  orgId: Organization['id'];
  onSave?: () => void;
  onClose?: () => void;
  organization: Organization;
  isNewPlugin: boolean;
  isInventoryEnabled: boolean;
  openAlert: (message: string, variant: AlertVariant) => void;
}) {
  let Dialog: React.FC<any> | undefined;

  if (plugin.category === 'ENHANCER') {
    return getEnhancerPluginDialogComponent({
      plugin,
      orgId,
      openAlert,
      onClose,
    });
  }

  switch (plugin.category) {
    case 'DATA_PROVIDER':
      if (
        plugin.subCategory === 'DMS' ||
        plugin.pluginName === 'AUTHENTICOM' ||
        plugin.pluginName === 'TEKION'
      ) {
        Dialog = TabsForMatchCriteriaPerBusinessLinePluginDialog;
      } else if (plugin.subCategory === 'IMS') {
        Dialog = FileNameDealerIdAndMatchCriteriaPluginDialog;
      } else if (plugin.pluginName === 'ELEAD') {
        Dialog = SalesBusinessLinePluginDialog;
      } else if (plugin.pluginName === 'RAPID_RECON') {
        Dialog = FileNameDealerIdAndMatchCriteriaPluginDialog;
      }
      break;
    case 'MODULE':
      if (plugin.pluginName === 'RECON_VELOCITY') {
        Dialog = ReconVelocityPluginDialog;
      } else if (plugin.pluginName === 'VELOCITY_INSIGHT') {
        Dialog = VelocityInsightPluginDialog;
      } else if (
        ['VELOCITY_VDP', 'VELOCITY_ENGAGE'].includes(plugin.pluginName)
      ) {
        Dialog = VelocityEngagePluginDialog;
      } else if (plugin.pluginName === 'VELOCITY_WINDOW_STICKER') {
        Dialog = VelocityWindowStickerPluginDialog;
      }
      break;
    default:
      // Do nothing, allow Dialog to be undefined.
      break;
  }

  if (!Dialog) return null;

  return (
    <Dialog
      plugin={plugin}
      orgId={orgId}
      organization={organization}
      onSave={onSave}
      onClose={onClose}
      isNewPlugin={isNewPlugin}
      isInventoryEnabled={isInventoryEnabled}
      openAlert={openAlert}
    />
  );
}

interface PluginDialogProps {
  plugin: PluginItem;
  orgId: Organization['id'];
  onSave?: () => void;
  onClose?: () => void;
  organization: Organization;
  isNewPlugin: boolean;
  isInventoryEnabled: boolean;
  openAlert: (message: string, variant: AlertVariant) => void;
}

function PluginDialog({
  plugin,
  orgId,
  onSave,
  onClose,
  organization,
  isNewPlugin,
  isInventoryEnabled,
  openAlert,
}: PluginDialogProps) {
  return getPluginDialogComponent({
    plugin,
    orgId,
    onSave,
    onClose,
    organization,
    isNewPlugin,
    isInventoryEnabled,
    openAlert,
  });
}

interface PluginsViewProps {
  organization: Organization;
}

const PluginsView = ({ organization }: PluginsViewProps) => {
  // Queries
  const { data: sessionData, isLoading: sessionIsLoading } = useSession();
  const {
    data: queryPlugins,
    isLoading: pluginsAreLoading,
    error: pluginsError,
    refetch: reloadPlugins,
  } = usePlugins(organization.id);
  const {
    data: queryPluginOptions,
    isLoading: pluginOptionsAreLoading,
    error: pluginOptionsError,
    refetch: reloadPluginOptions,
  } = usePluginOptions(organization.id);

  // State
  const [pluginBeingEdited, setPluginBeingEdited] = useState<
    PluginItem | undefined
  >();
  const [isNewPluginBeingAdded, setIsNewPluginBeingAdded] = useState(false);
  const { isAlertOpen, alertMessage, openAlert, closeAlert, variant } =
    useAlert();
  const [isARequiredModuleEnabled, setIsARequiredModuleEnabled] =
    useState<boolean>(false);
  const [
    isAnInventoryDataProviderEnabled,
    setIsAnInventoryDataProviderEnabled,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (pluginsError || pluginOptionsError) {
      openAlert('There was a network error loading the plugins', 'error');
    }
  }, [pluginOptionsError, pluginsError, openAlert]);

  const hasViewPluginsPermission = () =>
    utils.permissions.hasPermission(
      permissions.ORGS_PLUGINS_VIEW,
      sessionData?.permissions
    );

  const isLoading =
    pluginsAreLoading || sessionIsLoading || pluginOptionsAreLoading;

  const handleEditPluginClick = (plugin: PluginItem) => {
    const DialogComponent = getPluginDialogComponent({
      plugin,
      orgId: organization.id,
      organization,
      isNewPlugin: isNewPluginBeingAdded,
      isInventoryEnabled: isAnInventoryDataProviderEnabled,
      openAlert: openAlert,
    });
    if (DialogComponent) {
      setPluginBeingEdited(plugin);
      setIsNewPluginBeingAdded(false);
    } else {
      openAlert('This plugin type cannot be edited yet.', 'info');
    }
  };
  const invalidateOrganizationGroups = useInvalidateOrganizationGroups();

  // TODO - remove adding plugin until clicking save in modal because each plugin has different defaults.
  const handleAddPluginOption = (
    pluginOption: PluginOption,
    settings: Record<string, any> = {}
  ) => {
    const newPlugin = {
      title: pluginOption.title,
      pluginName: pluginOption.pluginName,
      category: pluginOption.category,
      subCategory: pluginOption.subCategory,
      iconURL: pluginOption.iconURL,
      links: pluginOption.links,
      enabled: true,
      settings,
      iconUrl: '',
      description: pluginOption.description ? pluginOption.description : '',
    };
    const DialogComponent = getPluginDialogComponent({
      plugin: newPlugin,
      orgId: organization.id,
      organization,
      isNewPlugin: isNewPluginBeingAdded,
      isInventoryEnabled: isAnInventoryDataProviderEnabled,
      openAlert: openAlert,
    });
    if (DialogComponent) {
      setPluginBeingEdited(newPlugin);
      setIsNewPluginBeingAdded(true);
      invalidateOrganizationGroups(organization.id);
    } else {
      openAlert('This plugin type cannot be added yet.', 'info');
    }
  };

  if (!isLoading && !hasViewPluginsPermission()) {
    <PermissionError feature="Plugins" organizationName={organization.name} />;
  }

  const getAuthenticomOrCdkPluginOptions = useCallback(
    async (pluginName: 'cdk' | 'authenticom') => {
      const { data } = await configuredFetch<DataProviderDmsPluginItem>(
        `/orgs/${organization.id}/plugins/${pluginName}`
      );

      return data;
    },
    [organization.id]
  );

  const handleCheckForRequiredModulesForInventory = useCallback(() => {
    if (queryPlugins) {
      const requiredModules = queryPlugins.filter(
        (plugin: PluginItem) =>
          [
            'RECON_VELOCITY',
            'VELOCITY_VDP',
            'VELOCITY_WINDOW_STICKER',
          ].includes(plugin.pluginName) && plugin.enabled
      );

      requiredModules.length > 0
        ? setIsARequiredModuleEnabled(true)
        : setIsARequiredModuleEnabled(false);
    }
  }, [queryPlugins]);
  const handleCheckForInventoryDataProvider = useCallback(async () => {
    const dataProvidersWithRepairOrdersOrSalesNames = [
      'AUTHENTICOM',
      'CDK',
      'ELEAD',
    ];

    if (queryPlugins) {
      const dataProviders: PluginItem[] = queryPlugins.filter(
        (plugin: PluginItem) =>
          plugin.category === PluginItemCategory.DATA_PROVIDER && plugin.enabled
      );

      // Rooftop has no data providers, assume inventory is disabled
      if (dataProviders.length < 1) {
        return setIsAnInventoryDataProviderEnabled(false);
      }
      // Rooftop has at least one data provider that is not Authenticom / CDK / ELead, assume inventory is enabled
      else if (
        dataProviders.filter(
          (plugin: PluginItem) =>
            !dataProvidersWithRepairOrdersOrSalesNames.includes(
              plugin.pluginName
            )
        ).length > 0
      ) {
        return setIsAnInventoryDataProviderEnabled(true);
      }
      // Rooftop has only Authenticom / CDK / ELead
      else {
        const dataProvidersWithRepairOrdersOrSales = dataProviders.filter(
          (plugin: PluginItem) =>
            dataProvidersWithRepairOrdersOrSalesNames.includes(
              plugin.pluginName
            )
        );

        // Rooftop has one data provider and it is ELead, assume inventory is disabled
        if (
          dataProvidersWithRepairOrdersOrSales.every(
            (plugin: PluginItem) => plugin.pluginName === 'ELEAD'
          )
        ) {
          return setIsAnInventoryDataProviderEnabled(false);
        }

        let authenticomPlugin = dataProvidersWithRepairOrdersOrSales.find(
          (plugin: PluginItem) => plugin.pluginName === 'AUTHENTICOM'
        );
        let cdkPlugin = dataProvidersWithRepairOrdersOrSales.find(
          (plugin: PluginItem) => plugin.pluginName === 'CDK'
        );

        if (authenticomPlugin) {
          const pluginOptions = await getAuthenticomOrCdkPluginOptions(
            'authenticom'
          ).catch((error) =>
            console.log('Error fetching plugin options: ', error)
          );

          if (pluginOptions) {
            // Rooftop has Authenticom plugin enabled and match criteria set up for Inventory, assume inventory is enabled
            if (
              pluginOptions.matchCriteria &&
              pluginOptions.matchCriteria.some(
                (criteria) =>
                  criteria.businessLine && criteria.businessLine === 'Inventory'
              )
            ) {
              return setIsAnInventoryDataProviderEnabled(true);
            } else {
              setIsAnInventoryDataProviderEnabled(false);
            }
          }
        }

        // Rooftop has CDK plugin enabled
        if (cdkPlugin) {
          const pluginOptions = await getAuthenticomOrCdkPluginOptions(
            'cdk'
          ).catch((error) =>
            console.log('Error fetching plugin options: ', error)
          );

          if (pluginOptions) {
            // Rooftop has CDK plugin enabled and match criteria is set up for Inventory, assume inventory is enabled
            if (
              pluginOptions.matchCriteria &&
              pluginOptions.matchCriteria.some(
                (criteria) =>
                  criteria.businessLine && criteria.businessLine === 'Inventory'
              )
            ) {
              return setIsAnInventoryDataProviderEnabled(true);
            } else {
              setIsAnInventoryDataProviderEnabled(false);
            }
          }
        }
      }
    }
  }, [queryPlugins, getAuthenticomOrCdkPluginOptions]);

  useEffect(() => {
    handleCheckForRequiredModulesForInventory();
    handleCheckForInventoryDataProvider();
  }, [
    handleCheckForRequiredModulesForInventory,
    handleCheckForInventoryDataProvider,
  ]);

  const checkShouldAddDropdownBeDisabled = (
    category: PluginItemCategory
  ): boolean => {
    let isAddDropdownDisabled: boolean = false;

    switch (category) {
      case PluginItemCategory.DATA_PROVIDER:
        isARequiredModuleEnabled
          ? (isAddDropdownDisabled = false)
          : (isAddDropdownDisabled = true);
        break;
      case PluginItemCategory.ENHANCER:
        isAnInventoryDataProviderEnabled
          ? (isAddDropdownDisabled = false)
          : (isAddDropdownDisabled = true);
        break;
      default:
        isAddDropdownDisabled = false;
    }

    return isAddDropdownDisabled;
  };

  return (
    <>
      {!isLoading ? (
        <div className="PluginsView tab-content padding">
          {SECTIONS.map((section) => (
            <PluginSection
              key={section.category}
              category={section.category}
              heading={section.heading}
              isAddDropdownDisabled={checkShouldAddDropdownBeDisabled(
                section.category
              )}
              onAddPluginOption={handleAddPluginOption}
              onEditPlugin={handleEditPluginClick}
              plugins={queryPlugins!}
              pluginOptions={queryPluginOptions}
              orgId={organization.id}
              onDeletePlugin={() => {
                reloadPluginOptions();
                reloadPlugins();
                setIsNewPluginBeingAdded(false);
                setPluginBeingEdited(undefined);
              }}
            />
          ))}
        </div>
      ) : (
        <LoadingIndicator />
      )}
      {pluginBeingEdited && (
        <PluginDialog
          orgId={organization.id}
          key={pluginBeingEdited.id}
          plugin={pluginBeingEdited}
          onSave={() => reloadPlugins()}
          onClose={() => {
            setPluginBeingEdited(undefined);
            setIsNewPluginBeingAdded(false);
            reloadPlugins();
            reloadPluginOptions();
          }}
          organization={organization}
          isNewPlugin={isNewPluginBeingAdded}
          isInventoryEnabled={isAnInventoryDataProviderEnabled}
          openAlert={openAlert}
        />
      )}
      <Alert
        open={isAlertOpen}
        message={alertMessage}
        duration={5000}
        variant={variant}
        onClose={closeAlert}
      />
    </>
  );
};

export default PluginsView;
