export enum ResponsiveBreakpoint {
  xs = 0,
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
}

export const isMobileViewport = () =>
  window.innerWidth < ResponsiveBreakpoint.sm ||
  window.innerHeight < ResponsiveBreakpoint.sm;
