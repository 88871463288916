import { configuredFetch } from './base';
import { HttpMethod } from './lib';

// TODO: consolidate with ApiResponse in models
export interface APIResponse<T> {
  data: T;
  meta?: {
    returnedRecords: number;
    totalRecords: number;
  };
  links?: any;
  errors?: any;
}

type DefaultQueryFnParams = { queryKey: string[] };

async function fetchApi(url: string, options: any = {}): Promise<any> {
  return configuredFetch(url, options);
}

// This function will be call anytime useQuery is used
export async function defaultQueryFn({ queryKey }: DefaultQueryFnParams) {
  const url = Array.isArray(queryKey) ? queryKey.join('/') : queryKey;
  return fetchApi(url).then((json) => (json.data ? json.data : json));
}

export async function defaultMetaQueryFn(url: string) {
  return fetchApi(url);
}

export async function defaultMutationFn(
  url: string,
  method: HttpMethod,
  data: any = {}
) {
  return fetchApi(url, {
    method,
    body: JSON.stringify(data),
  });
}
