import { VelocityEngagePluginItem } from 'models';
import {
  getVelocityEngagePluginActionTypes,
  updateVelocityEngagePluginActionTypes,
  VelocityEngagePluginActionTypes,
} from 'store/actions/organizations/plugins/velocityengage';
import { FetchedData } from 'store/reducers/types';

type VelocityEngageGeneralState = FetchedData<
  VelocityEngagePluginItem | undefined
>;

export const initialState: VelocityEngageGeneralState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export default function generalReducer(
  state = initialState,
  action: VelocityEngagePluginActionTypes
): VelocityEngageGeneralState {
  switch (action.type) {
    case getVelocityEngagePluginActionTypes.pending: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
    case getVelocityEngagePluginActionTypes.fulfilled: {
      const { data = state.data } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        error: undefined,
        data,
      };
    }
    case getVelocityEngagePluginActionTypes.rejected: {
      const { error } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        data: undefined,
        error,
      };
    }

    case updateVelocityEngagePluginActionTypes.pending: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
    case updateVelocityEngagePluginActionTypes.fulfilled: {
      const { data = state.data } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        data,
        error: undefined,
      };
    }
    case updateVelocityEngagePluginActionTypes.rejected: {
      const { error } = action.payload ?? {};

      return {
        ...state,
        loading: false,
        error,
      };
    }

    default:
      return state;
  }
}
