import React, { useEffect, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import reactCSS from 'reactcss';

interface ColorPickerButtonProps {
  currentHexValue: string;
  onChange: (color: string, colorPickerId: string) => void;
  colorPickerId: string;
}

const ColorPicker: React.FC<ColorPickerButtonProps> = ({
  currentHexValue,
  onChange,
  colorPickerId,
}: ColorPickerButtonProps) => {
  const [color, setColor] = useState(currentHexValue);
  const [isColorPickerDisplayed, setIsColorPickerDisplayed] = useState(false);

  useEffect(() => {
    setColor(currentHexValue);
  }, [currentHexValue]);

  const toggleDisplay = () => {
    setIsColorPickerDisplayed(!isColorPickerDisplayed);
  };

  const handleClose = () => {
    setIsColorPickerDisplayed(false);
  };

  const handleChange = (newColor: ColorResult) => {
    setColor(newColor.hex);
  };

  const handleChangeComplete = (
    newColor: ColorResult,
    onChangeComplete: (newColor: string, colorPickerId: string) => void
  ) => {
    setColor(newColor.hex);
    onChangeComplete(newColor.hex, colorPickerId);
  };

  const styles = reactCSS({
    default: {
      color: {
        width: '14px',
        height: '14px',
        borderRadius: '2px',
        background: `${color}`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  return (
    <div style={{ position: 'relative' }}>
      <div
        role="button"
        tabIndex={-1}
        style={styles.swatch}
        onClick={() => toggleDisplay()}
      >
        <div style={styles.color} />
      </div>
      {isColorPickerDisplayed && (
        <div style={{ position: 'absolute', zIndex: 2 }}>
          <div
            role="button"
            aria-label="Select Color"
            tabIndex={-1}
            onClick={() => handleClose()}
            style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            }}
          />
          <SketchPicker
            color={color}
            onChangeComplete={(newColor) =>
              handleChangeComplete(newColor, onChange)
            }
            onChange={(newColor) => handleChange(newColor)}
          />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
