import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { FC } from 'react';

import strings from 'common/strings';
import CurrencyTextField from 'components/shared/CurrencyTextField';
import { Currency, OdometerUnit } from 'models';
import {
  ReconTime,
  ReconVelocityPluginItem,
  ReconVelocityPluginItemReconMode,
} from 'models/plugins/reconVelocityPlugin';

import GoalTimeSetting from './GoalTimeSetting';
import WholesaleUnitsSetting from './WholesaleUnitsSetting';

export type GeneralSettingsProps = {
  currency: Currency;
  setCurrency: (value: Currency) => void;
  updatedSettings: Partial<ReconVelocityPluginItem>;
  updateSetting: (
    key: keyof ReconVelocityPluginItem,
    value: ReconVelocityPluginItem[typeof key]
  ) => void;
  updateSubsetting: (inputKey: string, inputValue: Record<any, any>) => void;
};

export const GeneralSettings: FC<GeneralSettingsProps> = ({
  currency,
  setCurrency,
  updatedSettings,
  updateSetting,
  updateSubsetting,
}) => {
  const reconTime: Record<ReconTime, boolean> = {
    CALENDAR_HOURS: true,
    BUSINESS_HOURS: false,
  };

  const getReconTime = (currentSetting: boolean): ReconTime =>
    currentSetting ? 'CALENDAR_HOURS' : 'BUSINESS_HOURS';

  return (
    <>
      <div className="flex-columns flex-wrap flex-justify-center padding">
        <div className="flex-rows padding">
          <FormControl variant="outlined" margin="dense" required>
            <InputLabel variant="outlined" margin="dense">
              {strings.RECON_MODE}
            </InputLabel>
            <Select
              name="reconMode"
              label="Recon Mode"
              value={updatedSettings.reconMode}
              variant="outlined"
              margin="dense"
              onChange={({ target: { value } }) =>
                updateSetting(
                  'reconMode',
                  value as ReconVelocityPluginItemReconMode
                )
              }
              required
            >
              <MenuItem value="ALL">{strings.ALL}</MenuItem>
              <MenuItem value="NEW">{strings.NEW}</MenuItem>
              <MenuItem value="USED">{strings.USED}</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            className="margin-top"
            variant="outlined"
            margin="dense"
            required
          >
            <InputLabel variant="outlined" margin="dense">
              {strings.ODOMETER_UNITS}
            </InputLabel>

            <Select
              name="odometerUnit"
              label="Odometer Units"
              value={updatedSettings.odometerUnit}
              variant="outlined"
              margin="dense"
              onChange={({ target: { value } }) =>
                updateSetting('odometerUnit', value as OdometerUnit)
              }
              required
            >
              <MenuItem value="MILES">{strings.MILES}</MenuItem>
              <MenuItem value="KILOMETERS">{strings.KILOMETERS}</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" margin="dense">
            <TextField
              id="session-timeout"
              style={{ minWidth: 220 }}
              variant="outlined"
              margin="dense"
              placeholder="0"
              inputMode="numeric"
              name="sessionTimeoutAsSeconds"
              label="Session Timeout (seconds)"
              value={updatedSettings.sessionTimeoutAsSeconds ?? 0}
              onChange={({ target: { value } }) =>
                updateSetting('sessionTimeoutAsSeconds', +value)
              }
              required
            />
          </FormControl>
          <FormControl
            className="margin-top"
            variant="outlined"
            margin="dense"
            required
          >
            <InputLabel variant="outlined" margin="dense">
              Recon Time
            </InputLabel>

            <Select
              name="reconTime"
              label="Recon Time"
              value={
                updatedSettings.reconTimeIgnoreSchedule !== undefined
                  ? getReconTime(updatedSettings.reconTimeIgnoreSchedule)
                  : 'CALENDAR_HOURS'
              }
              variant="outlined"
              margin="dense"
              onChange={({ target: { value } }) => {
                updateSetting(
                  'reconTimeIgnoreSchedule',
                  reconTime[value as ReconTime] as boolean
                );
                updateSubsetting('RECON_TIME_IGNORE_SCHEDULE', {
                  dataType: 'BOOL',
                  value: reconTime[value as ReconTime] as boolean,
                });
              }}
              required
            >
              <MenuItem value="CALENDAR_HOURS">Calendar Hours</MenuItem>
              <MenuItem value="BUSINESS_HOURS">Business Hours</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="padding" id="checkbox-container">
          <FormControlLabel
            control={
              <Checkbox
                id="onboarding-checkbox"
                checked={updatedSettings.onboarding ?? false}
                onChange={({ target: { checked } }) =>
                  updateSetting('onboarding', checked)
                }
              />
            }
            name="onboarding"
            label="Onboarding"
          />

          <br />

          <FormControlLabel
            control={
              <Checkbox
                checked={
                  updatedSettings.autoClearCompletedReconVehiclesAfter24Hours ??
                  false
                }
                onChange={({ target: { checked } }) =>
                  updateSetting(
                    'autoClearCompletedReconVehiclesAfter24Hours',
                    checked
                  )
                }
              />
            }
            name="autoClearCompletedReconVehiclesAfter24Hours"
            label="Force Completion for Vehicle in Ending Steps - Nightly"
          />

          <br />

          <FormControlLabel
            control={
              <Checkbox
                checked={updatedSettings.enablePostRecon ?? false}
                onChange={({ target: { checked } }) =>
                  updateSetting('enablePostRecon', checked)
                }
              />
            }
            name="enablePostRecon"
            label="Post Recon"
          />

          <br />

          <FormControlLabel
            control={
              <Checkbox
                checked={
                  updatedSettings.restrictMoveStepToStepUserList ?? false
                }
                onChange={({ target: { checked } }) =>
                  updateSetting('restrictMoveStepToStepUserList', checked)
                }
              />
            }
            name="restrictMoveStepToStepUserList"
            label="Restrict Move Step to Step User List"
          />

          <br />

          <FormControlLabel
            control={
              <Checkbox
                checked={
                  updatedSettings.enableRequireLaborAndPartsOnTasks ?? false
                }
                onChange={({ target: { checked } }) =>
                  updateSetting('enableRequireLaborAndPartsOnTasks', checked)
                }
              />
            }
            name="enableRequireLaborAndPartsOnTasks"
            label="Require Labor and Parts on Tasks"
          />

          <br />

          <WholesaleUnitsSetting
            excludeWholesaleUnitsFromImport={
              updatedSettings.excludeWholesaleUnitsFromImport
            }
            excludeWholesaleUnitsFromImportRemoveRecon={
              updatedSettings.excludeWholesaleUnitsFromImportRemoveRecon
            }
            updateSetting={updateSetting}
            updateSubsetting={updateSubsetting}
          />

          <br />

          <div className="flex-columns flex-justify-center margin-top">
            <div className="full-width flex-justify-center">
              <GoalTimeSetting
                goalTimeInSeconds={updatedSettings.retailReadyDangerAsSeconds}
                goalTimeLabel="Retail Ready Goal Time"
                goalTimeSettingKey="retailReadyDangerAsSeconds"
                updateSetting={updateSetting}
              />
              <GoalTimeSetting
                goalTimeInSeconds={updatedSettings.reconDangerAsSeconds}
                goalTimeLabel="Recon Goal Time"
                goalTimeSettingKey="reconDangerAsSeconds"
                updateSetting={updateSetting}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex-columns flex-justify-center valign-center padding-bottom">
        <FormControl
          className="margin-right"
          variant="outlined"
          margin="dense"
          required
        >
          <InputLabel variant="outlined" margin="dense">
            {strings.CURRENCY}
          </InputLabel>

          <Select
            style={{ minWidth: 100 }}
            name="currency"
            label="Currency"
            value={currency}
            variant="outlined"
            margin="dense"
            onChange={({ target: { value } }) => setCurrency(value as Currency)}
            required
          >
            <MenuItem value="USD">{strings.USD}</MenuItem>
            <MenuItem value="CAD">{strings.CAD}</MenuItem>
          </Select>
        </FormControl>

        <FormControl className="margin-sm" variant="outlined" margin="dense">
          <CurrencyTextField
            style={{ minWidth: 200 }}
            variant="outlined"
            margin="dense"
            placeholder="$0.00"
            name="monthlySubscriptionFee"
            label="Monthly Subscription Fee"
            value={updatedSettings.monthlySubscriptionFee?.amount ?? 0}
            onValueChange={({ floatValue: amount }) =>
              updateSetting('monthlySubscriptionFee', { amount, currency })
            }
            required
          />
        </FormControl>
      </div>
    </>
  );
};
