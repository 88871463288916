import { Box, Button } from '@material-ui/core';
import { FC, useState } from 'react';

import {
  useDeleteReconVelocityStepDefinitionAssociation,
  useGetReconVelocityStepDefinitionAssociationList,
} from 'api/organizations/plugins/reconvelocity/workflows';
import strings from 'common/strings';
import { Organization, ReconVelocityWorkflowStep } from 'models';

import SharedStepsDialog from '../SharedStepsDialog';
import SharedStepsMapping from './SharedStepsMapping';

import './SharedSteps.scss';

export interface SharedStepProps {
  step: ReconVelocityWorkflowStep;
  orgId: string;
  organizationsInGroup: Organization['children'];
}

const SharedStep: FC<SharedStepProps> = ({
  step,
  orgId,
  organizationsInGroup,
}) => {
  const [isSharedStepDialogOpen, setIsSharedStepDialogOpen] = useState(false);

  const { data: sharedSteps } =
    useGetReconVelocityStepDefinitionAssociationList(orgId, step.id);
  const { deleteStepDefinitionAssociationAsync } =
    useDeleteReconVelocityStepDefinitionAssociation(orgId, step.id);

  const toggleSharedStepDialogOpen = () =>
    setIsSharedStepDialogOpen(!isSharedStepDialogOpen);

  return (
    <Box className="SharedSteps">
      <Box className="section-header">
        <Box className="title-text">
          <p>{strings.SHARED_STEPS}</p>
        </Box>
        <Button
          className="dialog-control"
          variant="contained"
          color="secondary"
          onClick={toggleSharedStepDialogOpen}
        >
          {strings.ADD_SHARED_STEP}
        </Button>
      </Box>
      {sharedSteps && sharedSteps.data.length > 0 && (
        <SharedStepsMapping
          sharedSteps={sharedSteps.data}
          deleteStepAssociation={deleteStepDefinitionAssociationAsync}
        />
      )}
      {isSharedStepDialogOpen && (
        <SharedStepsDialog
          step={step}
          open={isSharedStepDialogOpen}
          orgId={orgId}
          organizationsInGroup={organizationsInGroup}
          onClose={toggleSharedStepDialogOpen}
          associatedSteps={sharedSteps?.data || []}
        />
      )}
    </Box>
  );
};

export default SharedStep;
