import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
} from '@material-ui/core';

import strings from 'common/strings';

import AdditionalPricesInput from './components/AdditionalPricesInput';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formInputFieldControl: {
      marginTop: theme.spacing(2),
      width: '100%',
      minwidth: '100%',
    },
    formField: {
      width: '260px',
      marginRight: theme.spacing(2),
    },
    noMarginTop: {
      marginTop: 0,
    },
  })
);

interface PriceSettingsProps {
  setCustomPriceLabel(value: string): void;
  customPriceLabel: string | undefined;
  setPriceTypeToShow(
    value: 'MSRP' | 'retail' | 'internet' | 'CallForPrice'
  ): void;
  priceTypeToShow: 'MSRP' | 'retail' | 'internet' | 'CallForPrice';
  additionalPrices: string | undefined;
  setAdditionalPrices: (value: string) => void;
}

const PriceSettings = ({
  customPriceLabel,
  setCustomPriceLabel,
  priceTypeToShow,
  setPriceTypeToShow,
  additionalPrices,
  setAdditionalPrices,
}: PriceSettingsProps) => {
  const classes = useStyles();
  function handlePriceTypeChange(type: unknown) {
    if (
      type === 'MSRP' ||
      type === 'retail' ||
      type === 'internet' ||
      type === 'CallForPrice'
    ) {
      setPriceTypeToShow(type);
    }
  }
  return (
    <div className="priceSettings-wrapper">
      <h2>{strings.PRICE_SETTINGS}</h2>
      <form
        className={classes.formInputFieldControl}
        noValidate
        autoComplete="off"
      >
        <TextField
          label="Custom Price Label"
          className={`customPriceTextField ${classes.formField}`}
          id="customPriceLabelInput"
          inputProps={{
            maxLength: 30,
          }}
          disabled={priceTypeToShow === 'CallForPrice'}
          variant="outlined"
          size="small"
          value={customPriceLabel ?? ''}
          onChange={(e) => setCustomPriceLabel(e.target.value)}
          helperText="*Maximum number of allowed characters is 30"
        />
        <FormControl
          variant="outlined"
          margin="dense"
          classes={{ root: classes.noMarginTop }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Price Type
          </InputLabel>
          <Select
            className={classes.formField}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={priceTypeToShow}
            onChange={(e) => handlePriceTypeChange(e.target.value)}
          >
            <MenuItem value="CallForPrice">Call For Price</MenuItem>
            <MenuItem value="internet">Internet</MenuItem>
            <MenuItem value="MSRP">MSRP</MenuItem>
            <MenuItem value="retail">Retail</MenuItem>
          </Select>
        </FormControl>
      </form>
      <AdditionalPricesInput
        settingContent={additionalPrices ?? ''}
        onChange={setAdditionalPrices}
      />
    </div>
  );
};

export default PriceSettings;
