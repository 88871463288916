import permissions from './permissions';
import * as strings from './strings';
import time from './time';
import workflow from './workflow';

export default {
  strings,
  workflow,
  time,
  permissions,
};
