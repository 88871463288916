import { IconButton } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import {
  useDeleteOrganizationGroup,
  useOrganizationGroups,
} from 'api/organizations/groups';
import useSession from 'api/session';
import PERMISSIONS from 'common/permissions';
import Alert, { useAlert } from 'components/shared/Alert';
import ConfirmationDialog, {
  useConfirmationDialog,
} from 'components/shared/ConfirmationDialog';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { OrganizationGroup } from 'models';

import EditGroupDialog from './EditGroupDialog';
import GroupDetailView from './GroupDetailView';

import './GroupsView.scss';

interface GroupsViewProps {
  organizationId: string;
}

var GroupsView = ({ organizationId }: GroupsViewProps) => {
  const [groupId, setGroupId] = useState<string>();
  const {
    data: organizationGroups = [],
    isLoading,
    refetch: reloadOrgGroups,
  } = useOrganizationGroups(organizationId);
  const { deleteOrganizationGroupAsync } =
    useDeleteOrganizationGroup(organizationId);
  const [isEditGroupDialogOpen, setIsEditGroupDialogOpen] = useState(false);
  const navigate = useNavigate();
  const [shouldNavigate, setShouldNavigate] = useState(true);
  const { data: session } = useSession();
  const {
    isConfirmationDialogOpen,
    isConfirmationLoading,
    confirmationMessage,
    primaryButtonLabel,
    onAccept,
    onDeny,
    openConfirmationDialog,
  } = useConfirmationDialog();
  const { isAlertOpen, alertMessage, openAlert, closeAlert, variant } =
    useAlert();

  useEffect(() => {
    const id = organizationGroups?.[0]?.id;

    if (shouldNavigate && id) {
      setShouldNavigate(false);
      setGroupId(id);
      navigate(`${id}`, { replace: true });
    }
  }, [navigate, organizationGroups, shouldNavigate]);

  const selectedGroup = organizationGroups.find(
    (group) => group.id === groupId
  );
  const hasGroupCreatePermission = session?.permissions.includes(
    PERMISSIONS.ORGS_GROUPS_CREATE
  );

  function handleClick(id: string) {
    setGroupId(id);
  }

  async function deleteGroup(groupIdToDelete: string) {
    try {
      await deleteOrganizationGroupAsync(groupIdToDelete);
    } catch (error) {
      openAlert('There was an error deleting the group', 'error');
    }
  }

  function handleDeleteClick(id: string) {
    setGroupId(id);
    const group = organizationGroups.find((listGroup) => listGroup.id === id);
    openConfirmationDialog({
      messageOverride: `Are you sure you want to delete the group ${group?.name}?`,
      primaryButtonLabelOverride: 'Delete',
      onAcceptOverride: () => deleteGroup(id),
    });
  }

  function hasDeletePermission(group: OrganizationGroup) {
    return (
      session?.permissions?.includes(PERMISSIONS.ORGS_GROUPS_DELETE) &&
      group.type !== 'INTERNAL'
    );
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="GroupsView">
      <div className="GroupsSideBar">
        <div className="CollectionHeaderBar">
          <div className="CollectionHeaderBar-text">
            {organizationGroups?.length} Groups
          </div>
          <div className="CollectionHeaderBar-actions">
            {hasGroupCreatePermission && (
              <IconButton
                onClick={() => {
                  setIsEditGroupDialogOpen(true);
                }}
                size="small"
              >
                <Add />
              </IconButton>
            )}
          </div>
        </div>
        <ul className="GroupList">
          {organizationGroups.map((group) => (
            <li
              key={group.id}
              onClick={() => handleClick(group.id)}
              className={group === selectedGroup ? 'selected' : ''}
            >
              <span>{group.name}</span>
              {hasDeletePermission(group) && (
                <span className="deleteIcon">
                  <Delete onClick={() => handleDeleteClick(group.id)} />
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="GroupDetails">
        <Routes>
          <Route
            path=":groupId/*"
            element={
              <GroupDetailView
                group={selectedGroup}
                organizationId={organizationId}
              />
            }
          />
        </Routes>
      </div>
      {isEditGroupDialogOpen && (
        <EditGroupDialog
          orgId={organizationId}
          onClose={async () => {
            setIsEditGroupDialogOpen(false);
            await reloadOrgGroups();
          }}
        />
      )}
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        isLoading={isConfirmationLoading}
        primaryButtonLabel={primaryButtonLabel}
        message={confirmationMessage}
        onPrimaryButtonClick={onAccept}
        onSecondaryButtonClick={onDeny}
      />
      <Alert
        open={isAlertOpen}
        message={alertMessage}
        onClose={closeAlert}
        duration={3500}
        variant={variant}
      />
    </div>
  );
};

export default GroupsView;
