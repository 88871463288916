import { TextField } from '@material-ui/core';
import { useState } from 'react';

import { useOrganizationUsers } from 'api';
import { useTeam, useTeamMutation } from 'api/organizations/teams';
import Alert, { useAlert } from 'components/shared/Alert';
import Dialog from 'components/shared/Dialog/Dialog';
import { Column } from 'components/shared/UsersList/types';
import UsersList from 'components/shared/UsersList/UsersList';
import { User } from 'models';

import './AddTeamDialog.scss';

const headerColumns = [
  {
    dataKey: 'checkbox',
    label: '',
    maxWidth: '50px',
  },
  {
    dataKey: 'fullName',
    label: 'Name',
    maxWidth: '225px',
  },
  {
    dataKey: 'jobTitle',
    label: 'Title',
    maxWidth: '225px',
  },
  {
    dataKey: 'phone',
    label: 'Phone',
    maxWidth: '100px',
  },
  {
    dataKey: 'email',
    label: 'Email',
  },
] as Column[];

type AddTeamModalProps = {
  open: boolean;
  onClose: () => void;
  teamId?: string;
  orgId: string;
};

const AddTeamModal = ({ open, onClose, teamId, orgId }: AddTeamModalProps) => {
  const teamQuery = useTeam(teamId ?? '');
  const [searchText, setSearchText] = useState('');
  const usersQueryData = useOrganizationUsers(orgId, searchText);

  const { isAlertOpen, alertMessage, openAlert, closeAlert, variant } =
    useAlert();

  const teamMutation = useTeamMutation(orgId, teamId);

  const [teamName, setTeamName] = useState(teamQuery.data?.teamName ?? '');
  const handleTeamNameOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTeamName(event.target.value);
  };

  const [checkedUsers, setCheckedUsers] = useState<{ [key: string]: boolean }>(
    teamQuery.data?.users?.reduce(
      (acc, user) => ({ ...acc, [user.id]: true }),
      {} as { [key: string]: boolean }
    ) ?? {}
  );

  const handleOnPrimary = async () => {
    const team = {
      id: teamId,
      tenantId: orgId,
      teamName,
      userIds: Object.keys(checkedUsers).filter(
        (userId) => checkedUsers[userId]
      ),
    };
    try {
      await teamMutation.mutateAsync(team);
      onClose();
    } catch (error: any) {
      openAlert(error?.message, 'error');
    }
  };

  const handleOnCheckUser = (user: User) => {
    setCheckedUsers((prevCheckedUsers) => ({
      ...prevCheckedUsers,
      [user.id]: !prevCheckedUsers[user.id],
    }));
  };

  const title = teamId ? 'Edit Team' : 'Add Team';

  return (
    <Dialog
      title={title}
      open={open}
      onClose={onClose}
      primaryButtonLabel="Save"
      onPrimary={handleOnPrimary}
      maxWidth="xl"
      fullWidth
      height={500}
      className="addTeamDialog-content"
      block={teamMutation.isLoading}
    >
      <TextField
        value={teamName}
        onChange={handleTeamNameOnChange}
        required
        label="Name"
        variant="outlined"
        margin="dense"
      />
      <UsersList
        usersQueryData={usersQueryData}
        setSearchText={setSearchText}
        headerColumns={headerColumns}
        onCheckUser={handleOnCheckUser}
        checkedUsers={checkedUsers}
        isLoading={usersQueryData.isLoading}
      />
      <Alert
        open={isAlertOpen}
        variant={variant}
        message={alertMessage}
        onClose={closeAlert}
        duration={5000}
      />
    </Dialog>
  );
};

export default AddTeamModal;
