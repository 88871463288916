import { NavLink } from 'react-router-dom';

var VelocityInsightSetup = () => {
  return (
    <div
      style={{
        padding: '25px 15%',
        fontSize: 'medium',
        height: 'calc(100% - 60px)',
        overflow: 'auto',
      }}
    >
      <h2>Follow these steps to get started with Velocity Insight</h2>
      <ol>
        <li>
          Download our Velocity Insight chrome extension by{' '}
          <a href="https://chrome.google.com/webstore/detail/velocityinsight/mkanlikcnccbgabpaphgjidigdjnekab">
            clicking here
          </a>{' '}
          to open the chrome store
        </li>
        <video style={{ margin: '10px', maxWidth: '100%' }} controls>
          <source src="https://velocityautomotive-public-docs.s3.amazonaws.com/downloadingVelocityInsight.mp4"></source>
        </video>
        <li>
          After the extension is installed, click on the “puzzle piece” in the
          top right corner of your chrome browser and select the pin next to
          Velocity Insight.
        </li>
        <li>
          Next, click on the Velocity Insight icon to login using the
          credentials provided in your email.
        </li>
        <video style={{ margin: '10px', maxWidth: '100%' }} controls>
          <source src="https://velocityautomotive-public-docs.s3.amazonaws.com/pinningVelocityInsightLogin.mp4"></source>
        </video>
        <li>Product Demo</li>
        <video style={{ margin: '10px', maxWidth: '100%' }} controls>
          <source
            src="https://velocityautomotive-public-docs.s3.amazonaws.com/VelocityInsight.webm"
            type="video/webm"
          ></source>
        </video>
        <li>
          Congrats on purchasing Velocity Insight! You are ready to go! Navigate
          to a page with a VIN and you will see the VIN underlined. Click or
          hover the VIN!
        </li>
      </ol>
      <div style={{ fontWeight: 700 }}>
        <NavLink to="/setup/stickers">Click here</NavLink> to see more about the
        Velocity Window Sticker.
      </div>
    </div>
  );
};

export default VelocityInsightSetup;
