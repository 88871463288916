export enum PermissionItemType {
  ANSWER = 'ANSWER',
  APPROVE = 'APPROVE',
  COMPLETE = 'COMPLETE',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  UNKNOWN = 'UNKNOWN',
  UPDATE = 'UPDATE',
  VIEW = 'VIEW',
}

export interface PermissionItem {
  id: string;
  name: string;
  label: string;
  applicationName: string;
  category: string;
  type: PermissionItemType;
}
