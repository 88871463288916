import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Skeleton from '@material-ui/lab/Skeleton';
import { FC, useCallback, useMemo, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import useSession from 'api/session';
import { useDeleteUser } from 'api/users';
import permissions from 'common/permissions';
import Alert, { useAlert } from 'components/shared/Alert';
import ConfirmationDialog, {
  useConfirmationDialog,
} from 'components/shared/ConfirmationDialog';
import EditUserDialog from 'components/UsersView/EditUserDialog';
import { User } from 'models';

import LoadingIndicator from '../LoadingIndicator';
import { useDebouncedInput } from './hooks';
import { TableHeaderColumns } from './TableHeaderColumns';
import {
  Column,
  ListItemProps,
  ListRendererProps,
  UsersListProps,
} from './types';
import UsersHeader from './UsersHeader';
import UsersListRow from './UsersListRow';

import './UsersList.scss';

const defaultHeaderColumns: Column[] = [
  {
    dataKey: 'fullName',
    label: 'Name',
    width: '200px',
  },
  {
    dataKey: 'jobTitle',
    label: 'Title',
    width: '100px',
  },
  {
    dataKey: 'status',
    label: 'Status',
    width: '100px',
  },
  {
    dataKey: 'phone',
    label: 'Phone',
    width: '100px',
  },
  {
    dataKey: 'email',
    label: 'Email',
    width: '100px',
  },
  {
    dataKey: `lastSession`,
    label: `Last Login`,
    width: `100px`,
  },
  {
    dataKey: 'lastActivity',
    label: 'Last Activity',
    width: '100px',
  },
  {
    dataKey: `actionButtons`,
    label: ``,
    width: `75px`,
  },
];

const ROW_HEIGHT = 30;

const UsersList: FC<UsersListProps> = ({
  usersQueryData,
  setSearchText = () => {},
  editUserDialog,
  showSearchField,
  showAddIcon,
  headerColumns,
  onDeleteUser,
  onCheckUser,
  checkedUsers,
  isLoading,
}) => {
  const [searchInput, handleTextChange] = useDebouncedInput(setSearchText);
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] =
    useState<boolean>(false);

  const { data: session } = useSession();

  const {
    data,
    status,
    fetchNextPage,
    refetch: refetchUsers,
    isFetchingNextPage,
  } = usersQueryData;

  const { deleteUserAsync } = useDeleteUser();
  const {
    isConfirmationDialogOpen,
    isConfirmationLoading,
    confirmationMessage,
    onAccept,
    onDeny,
    openConfirmationDialog,
    primaryButtonLabel,
  } = useConfirmationDialog();
  const { isAlertOpen, alertMessage, openAlert, closeAlert, variant } =
    useAlert();

  const handleDeleteClick = (user: User) => {
    openConfirmationDialog({
      messageOverride: `Delete user ${user.firstName} ${user.lastName}?`,
      primaryButtonLabelOverride: 'Delete',
      onAcceptOverride: async () => {
        const isSameUser = user.id === session?.user.id;
        const hasDeletePermission =
          session?.permissions.includes(permissions.USERS_DELETE) ||
          (isSameUser &&
            session?.permissions.includes(permissions.USERS_SELF_DELETE));
        if (hasDeletePermission) {
          await deleteUserAsync(user.id);
          await refetchUsers();
        } else {
          openAlert(
            'The current user does not have the required permissions to delete the selected user',
            'error'
          );
        }
      },
    });
  };

  const users = useMemo(
    () => data?.pages?.flatMap((item) => item.data) ?? [],
    [data?.pages]
  );
  const isItemLoaded = (index: number) => !!users && users.length >= index;
  const loadMoreItems = useCallback(
    (startIndex: number, stopIndex: number) => {
      if (users.length < stopIndex && !isFetchingNextPage) {
        return fetchNextPage();
      }
      return Promise.resolve();
    },
    [fetchNextPage, users.length, isFetchingNextPage]
  );

  const columns = headerColumns || defaultHeaderColumns;

  const totalRecords =
    data?.pages && data.pages?.length > 0 ? data.pages[0].meta.totalRecords : 0;

  return (
    <div className="UsersList">
      <div className="flex-grow">
        <div className="flex-columns UsersList-table">
          <Table className="table" component="div">
            <TableHead component="div">
              <UsersHeader
                totalRecords={totalRecords}
                searchInput={searchInput}
                handleTextChange={handleTextChange}
                status={status}
                setIsAddUserDialogOpen={setIsAddUserDialogOpen}
                enableBulkUpload={false}
                showSearchField={showSearchField}
                showAddIcon={showAddIcon}
              />
              <TableHeaderColumns
                columns={columns}
                totalRecords={totalRecords}
                rowHeight={ROW_HEIGHT}
              />
            </TableHead>
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <TableBody component="div" className="full-height">
                <AutoSizer>
                  {({ height, width }: ListRendererProps) => (
                    <InfiniteLoader
                      isItemLoaded={isItemLoaded}
                      itemCount={totalRecords}
                      loadMoreItems={loadMoreItems}
                    >
                      {({ onItemsRendered, ref }) => (
                        <List
                          className="list"
                          height={height}
                          width={width}
                          itemCount={users.length}
                          itemSize={ROW_HEIGHT}
                          onItemsRendered={onItemsRendered}
                          ref={ref}
                        >
                          {({ index, style }: ListItemProps) => {
                            if (!users) {
                              return <Skeleton />;
                            }

                            const user: User = users[index];
                            return (
                              <UsersListRow
                                key={`${user && user.id ? user.id : 0}${index}`}
                                style={style}
                                index={index}
                                user={user}
                                editUserDialog={editUserDialog}
                                headerColumns={columns}
                                onDeleteUser={onDeleteUser || handleDeleteClick}
                                onCheckUser={onCheckUser}
                                checkedUsers={checkedUsers}
                              />
                            );
                          }}
                        </List>
                      )}
                    </InfiniteLoader>
                  )}
                </AutoSizer>
              </TableBody>
            )}
          </Table>
        </div>
      </div>
      {isAddUserDialogOpen && (
        <EditUserDialog onClose={() => setIsAddUserDialogOpen(false)} />
      )}
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        isLoading={isConfirmationLoading}
        primaryButtonLabel={primaryButtonLabel}
        message={confirmationMessage}
        onPrimaryButtonClick={onAccept}
        onSecondaryButtonClick={onDeny}
      />
      <Alert
        open={isAlertOpen}
        variant={variant}
        message={alertMessage}
        onClose={closeAlert}
        duration={3500}
      />
    </div>
  );
};

export default UsersList;
