import { AutoScroller, MuuriComponent } from 'muuri-react';
import React, { Key, RefObject, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { FolioDocumentTemplate } from 'models';

import EngageMediaItem from '../EngageMediaItem';

import './MediaContainer.scss';

interface MediaContainerProps {
  title: string;
  icon?: React.ReactElement | null;
  media: FolioDocumentTemplate[];
  scrollRef: RefObject<HTMLDivElement>;
  folderId: string;
  orgId: string;
  folioId: string;
  onDelete: (document: FolioDocumentTemplate) => void;
  onEdit: (
    document: FolioDocumentTemplate,
    folderId: string,
    title: string,
    previewImageUrl: string,
    isAddMedia: boolean,
    mediaUrl?: string
  ) => void;
  onReorder: (folderId: string, reorderObject: Record<string, number>) => void;
  onSendDocumentToFolder: (payload: {
    key: Key;
    fromId: string | undefined;
    toId: string | undefined;
    toIndex: number;
  }) => void;
  availableDocuments: FolioDocumentTemplate[];
}

const MediaContainer: React.FC<MediaContainerProps> = ({
  title,
  icon,
  media,
  scrollRef,
  onDelete,
  onEdit,
  onReorder,
  folderId,
  folioId,
  orgId,
  onSendDocumentToFolder,
  availableDocuments,
}: MediaContainerProps) => {
  const [isDragging, setIsDragging] = useState(false);

  const children = media.map((item: FolioDocumentTemplate, index) => (
    <EngageMediaItem
      orgId={orgId}
      folioId={folioId}
      key={item.id || item.uiKey}
      media={item}
      onDelete={onDelete}
      onEdit={onEdit}
      isDragging={isDragging}
      availableDocuments={availableDocuments}
      folderId={folderId}
    />
  ));

  const addMediaTemplateDoc = availableDocuments.find((doc) => !doc.id);
  if (addMediaTemplateDoc) {
    children.push(
      <EngageMediaItem
        orgId={orgId}
        folioId={folioId}
        folderId={folderId}
        key={`${title}-user-upload`}
        media={{
          ...addMediaTemplateDoc,
          id: '',
          displayLabel: 'Add Media',
          uiKey: uuidv4(),
        }}
        onDelete={onDelete}
        onEdit={onEdit}
        isDragging={isDragging}
        availableDocuments={availableDocuments}
      />
    );
  }

  /**
   * Parent MUURI component was failing to re-render after MediaContainer's children
   * were rendered. This caused Engage folder stacked on top of each other or documents
   * from one folder overlapping the folder below them. The docs recommended using
   * overflow-y: scroll style on the parent container (see https://paol-imi.github.io/muuri-react/docs/usage/responsive-style),
   * but this didn't work. The library author gave triggering a window resize event
   * as an alternative: https://github.com/Paol-imi/muuri-react/issues/24
   */
  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, []);

  return (
    <div className="MediaContainer" ref={scrollRef}>
      <div className="MediaContainer-title">
        {icon && icon}
        {title}
      </div>
      <MuuriComponent
        id={folderId}
        dragEnabled
        dragStartPredicate={{ distance: 25 }}
        onDragStart={() => setIsDragging(true)}
        onDragEnd={async (item: any) => {
          const reorderObject = item
            .getGrid()
            .getItems()
            .reduce(
              (acc: Record<string, number>, listItem: any, idx: number) => {
                if (listItem.getKey()) {
                  acc[listItem.getKey()] = idx;
                }
                return acc;
              },
              {}
            );

          if (onReorder) {
            onReorder(folderId, reorderObject);
          }

          setIsDragging(false);
        }}
        dragAutoScroll={{
          targets: [
            {
              element: scrollRef,
              axis: AutoScroller.AXIS_Y,
            },
          ],
        }}
        // Group ID common to all folders to allow drag-and-drop accross folders.
        // Called "reparenting" in the MUURI docs.
        // https://paol-imi.github.io/muuri-react/docs/usage/reparenting
        groupIds={['FOLIO_DOCS']}
        // The item can only be dragged (and sorted) into
        // MuuriComponents of the group 'FOLIO_DOCS'.
        dragSort={{ groupId: 'FOLIO_DOCS' }}
        onSend={onSendDocumentToFolder}
      >
        {children}
      </MuuriComponent>
    </div>
  );
};

export default MediaContainer;
