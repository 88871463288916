import random from 'lodash/random';
import times from 'lodash/times';

import { Address, Contact } from 'models';

export function stripDashes(value = '') {
  return value.replace(/[-]/g, '');
}

// NOTE: This was only written to handle time formatting
//       So, it does not take into account negative values
//       or anything like that.
export function zeroPad(value: number) {
  return `0${value}`.slice(-2);
}

export function createRandomCharacters() {
  return times(20, () => random(35).toString(36)).join('');
}

export function cleanPhoneNumber(value: string) {
  return value ? value.replace(/[()\s-\s_]/g, '') : '';
}

export function formatPhoneNumber(phoneNumber?: string) {
  if (!phoneNumber) return '';
  const areaCode = phoneNumber.slice(0, 3);
  const exchangeCode = phoneNumber.slice(3, 6);
  const lineNumber = phoneNumber.slice(6);

  return `(${areaCode}) ${exchangeCode}-${lineNumber}`;
}

export function formatCAPostalCode(value: string) {
  if (!value) return '';
  const trimmed = value.slice(0, 6);
  const firstHalf = trimmed.slice(0, 3);
  const secondHalf = trimmed.slice(3, 6);
  return `${firstHalf}${secondHalf ? `-${secondHalf}` : ''}`;
}

export function formatUSZipCode(value: string) {
  if (!value) return '';
  return value.slice(0, 5);
}

export function displayFullName(contact?: Contact | null) {
  if (!contact) {
    return null;
  }

  return `${contact.firstName} ${contact.lastName}`.trim();
}

export function displayCityStateZip(address?: Address) {
  if (!address) {
    return null;
  }

  const array = [address.city, address.state?.shortName, address.postalCode];
  if (address.city && address.state) {
    array[0] += ',';
  }

  return array.join(' ');
}
