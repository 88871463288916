import { debounce } from 'lodash';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { DraggableChildrenFn, DraggingStyle } from 'react-beautiful-dnd';
import { createPortal } from 'react-dom';

export type UseDebouncedInputReturn = [
  searchInput: string,
  setSearchInput: (e: React.ChangeEvent<HTMLInputElement>) => void
];
type UseDebouncedInput = (
  setInputAfterDelay: (newInput: string) => void
) => UseDebouncedInputReturn;

export const useDebouncedInput: UseDebouncedInput = (setInputAfterDelay) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const sendTextChange = debounce(
    (newValue) => setInputAfterDelay(newValue),
    250,
    { leading: false, trailing: true }
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handler = useCallback(debounce(sendTextChange, 10), []);
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    handler(e.target.value);
  };

  return [searchInput, handleTextChange];
};

export function useDraggableInPortal() {
  const self = useRef<{ elt?: Element }>({}).current;

  useEffect(() => {
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.pointerEvents = 'none';
    div.style.top = '0';
    div.style.width = '100%';
    div.style.height = '100%';
    self.elt = div;
    document.body.appendChild(div);
    return () => {
      document.body.removeChild(div);
    };
  }, [self]);

  return (render: DraggableChildrenFn): DraggableChildrenFn =>
    (provided, ...args) => {
      const element = render(provided, ...args);
      if (
        (provided.draggableProps?.style as DraggingStyle)?.position === 'fixed'
      ) {
        return createPortal(element, self.elt ?? new Element());
      }
      return element;
    };
}

export function useOnChange<T>(
  callback: ((x: T) => void) | (() => void),
  value: T,
  options?: {
    callOnMount?: boolean;
    strinfigy?: boolean;
    layoutEffect?: boolean;
  }
): void {
  const [previousValue, setPreviousValue] = useState(value);
  const [firstCallPending, setFirstCallPending] = useState(
    options?.callOnMount ?? false
  );
  const checkEquals: (a: T, b: T) => boolean = options?.strinfigy
    ? (a, b) => JSON.stringify(a) === JSON.stringify(b)
    : (a, b) => a === b;
  const hook = options?.layoutEffect ? useLayoutEffect : useEffect;
  hook(() => {
    if (firstCallPending) {
      callback(value);
      setFirstCallPending(false);
      setPreviousValue(value);
    } else if (!checkEquals(value, previousValue)) {
      callback(value);
      setPreviousValue(value);
    }
  }, [callback, checkEquals, firstCallPending, previousValue, value]);
}

export default {
  useDraggableInPortal,
};
