import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { defaultMetaQueryFn, GetUserQueryData } from 'api';
import { defaultMutationFn } from 'api/useAPI';
import {
  defaultOrganizationGroup,
  Organization,
  OrganizationGroup,
  PermissionItem,
  Role,
} from 'models';

/**
 * Get Organization Groups
 * Endpoint: GET `/orgs/{organizationId}/groups`
 */
export function useOrganizationGroups(organizationId?: string) {
  const path = organizationId ? getOrganizationGroupsPath(organizationId) : '';
  return useQuery<OrganizationGroup[]>([path], {
    placeholderData: [],
    enabled: !!organizationId,
    refetchOnMount: true,
  });
}

/**
 * Invalidates data from organization groups
 * Invalidated endpoint: GET `/orgs/{organizationId}/groups`
 */
export function useInvalidateOrganizationGroups() {
  const queryClient = useQueryClient();
  return async (organizationId: string) => {
    const path = getOrganizationGroupsPath(organizationId);
    await queryClient.invalidateQueries({
      predicate: (query) => {
        if (typeof query.queryKey[0] !== 'string') {
          return false;
        }
        return query.queryKey[0].indexOf(path) === 0;
      },
    });
  };
}

/**
 * Get Organization Groups Path for other functions related to otganization groups
 * Related Endpoint: GET `/orgs/{organizationId}/groups`
 */
export function getOrganizationGroupsPath(organizationId: string) {
  return `/orgs/${organizationId}/groups`;
}

/**
 * Get Organization Group
 * Endpoint: GET `/orgs/{organizationId}/group/{groupId}`
 */
export function useOrganizationGroup(
  organizationId?: string,
  groupId?: string
) {
  const path = `/orgs/${organizationId}/groups/${groupId}`;
  return useQuery<OrganizationGroup>([path], {
    enabled: !!organizationId && !!groupId,
  });
}

/**
 * Get Organization Group roles
 * Endpoint: GET `/orgs/{organizationId}/group/{groupId}`
 */
export function useOrganizationGroupRoles(
  organizationId?: string,
  groupId?: string
) {
  const path = `/orgs/${organizationId}/groups/${groupId}/roles`;
  return useQuery<Role[]>([path], {
    enabled: !!organizationId && !!groupId,
  });
}

/**
 * Get all permissions
 * Endpoint: GET `/utility/permissions`
 * @returns list of all permissions
 */
export function usePermissions() {
  const path = '/utility/permissions';
  return useQuery<PermissionItem[]>([path]);
}

/**
 * Get Organization Group Users
 * Endpoint: GET `/orgs/{organizationId}/groups/{groupId}/users?pageSize=50&startIndex=0&s=`
 */
export function useGroupUsers(
  organizationId: string,
  groupId: string,
  searchText: string = '',
  startIndex: number = 0,
  pageSize: number = 50
) {
  const searchParam = searchText ? `&s=${searchText}` : '';
  const url = `/orgs/${organizationId}/groups/${groupId}/users?startIndex=${startIndex}&pageSize=${pageSize}${searchParam}`;
  const defaultParams = { startIndex, pageSize };
  return useInfiniteQuery<GetUserQueryData, Error>(
    [url],
    async ({ pageParam = defaultParams }) =>
      defaultMetaQueryFn(
        `/orgs/${organizationId}/groups/${groupId}/users?startIndex=${pageParam.startIndex}&pageSize=${pageParam.pageSize}${searchParam}`
      ),
    {
      getNextPageParam: (lastPage, pages) => {
        const totalUsersLoaded = pages.reduce(
          (acc, page) => acc + page.meta.returnedRecords,
          0
        );

        if (totalUsersLoaded === lastPage.meta.totalRecords) return;

        return {
          startIndex: pages.length * 50,
          pageSize,
        };
      },
      enabled: !!organizationId && !!groupId,
    }
  );
}

/**
 * Delete an organization group
 * Endpoint `/orgs/{orgId}/groups/{groupId}`
 * @param orgId - ID of the organization
 * @returns List of remaining groups
 */
export function useDeleteOrganizationGroup(orgId: Organization['id']) {
  const queryClient = useQueryClient();
  const mutation = useMutation((groupId: string) => {
    const path = `/orgs/${orgId}/groups/${groupId}`;
    return defaultMutationFn(path, 'DELETE');
  });

  async function deleteOrganizationGroupAsync(groupId: string) {
    await queryClient.cancelQueries([`/orgs/${orgId}/groups/*`]);
    const response = await mutation.mutateAsync(groupId);
    await queryClient.invalidateQueries([`/orgs/${orgId}/groups`]);

    return response;
  }

  return {
    ...mutation,
    deleteOrganizationGroupAsync,
  };
}

/**
 * Delete a user from an organization group
 * Endpoint `/orgs/{orgId}/groups/{groupId}/users/{userId}`
 * @param orgId - ID of the organization
 * @param groupId - ID of the group
 * @returns List of remaining groups
 */
export function useDeleteUserFromOrganizationGroup(
  orgId: Organization['id'],
  groupId: OrganizationGroup['id']
) {
  const queryClient = useQueryClient();
  const mutation = useMutation((userId: string) => {
    const path = `/orgs/${orgId}/groups/${groupId}/users/${userId}`;
    return defaultMutationFn(path, 'DELETE');
  });

  async function deleteOrganizationUserFromGroupAsync(userId: string) {
    await queryClient.cancelQueries([`/orgs/${orgId}/groups/*`]);
    const response = await mutation.mutateAsync(userId);
    await queryClient.invalidateQueries([`/orgs/${orgId}/groups`]);
    await queryClient.invalidateQueries([
      `/orgs/${orgId}/groups/${groupId}/users?startIndex=0&pageSize=50`,
    ]);

    return response;
  }

  return {
    ...mutation,
    deleteOrganizationUserFromGroupAsync,
  };
}

/**
 * Create an organization group
 * Endopint: `/orgs/{orgId}/groups
 * @param orgId
 * @returns Newly created group
 */
export function useCreateOrganizationGroup(orgId: Organization['id']) {
  const queryClient = useQueryClient();
  const mutation = useMutation((data) => {
    const path = `/orgs/${orgId}/groups`;
    return defaultMutationFn(path, 'POST', data);
  });

  async function createOrgGroupAsync(data: any) {
    await queryClient.cancelQueries([`/orgs/${orgId}/groups/*`]);
    const response = await mutation.mutateAsync(data);
    await queryClient.invalidateQueries([`/orgs/${orgId}/groups`]);

    return response;
  }

  return {
    ...mutation,
    createOrgGroupAsync,
  };
}

/**
 * Update an organization group
 * Endopint: `/orgs/{orgId}/groups/{groupId}`
 * @param orgId
 * @param groupId
 * @returns Newly updated group
 */
export function useUpdateOrganizationGroup(
  orgId: Organization['id'],
  groupId?: OrganizationGroup['id']
) {
  const queryClient = useQueryClient();
  const mutation = useMutation((data) => {
    const path = `/orgs/${orgId}/groups/${groupId}`;
    return defaultMutationFn(path, 'PUT', data);
  });

  async function updateOrgGroupAsync(data: any) {
    await queryClient.cancelQueries([`/orgs/${orgId}/groups/*`]);
    const response = await mutation.mutateAsync(data);
    await queryClient.invalidateQueries([`/orgs/${orgId}/groups`]);
    await queryClient.invalidateQueries([`/orgs/${orgId}/groups/${groupId}`]);

    return response;
  }

  return {
    ...mutation,
    updateOrgGroupAsync,
  };
}

export default {
  useOrganizationGroups,
  useGroupUsers,
  useDeleteOrganizationGroup,
  useCreateOrganizationGroup,
  useOrganizationGroup,
  useOrganizationGroupRoles,
  usePermissions,
  defaultOrganizationGroup,
};
