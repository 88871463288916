import { startCase } from 'lodash';

import Dialog from 'components/shared/Dialog';
import { ReconVelocityWorkflowStep } from 'models';

import WorkflowStepsChildStepsSection from './WorkflowStepChildStepsSection';
import WorkflowStepExistingVehiclesSection from './WorkflowStepExistingVehiclesSection';
import WorkflowStepsPrecedingStepsSection from './WorkflowStepPrecedingStepsSection';

interface WorkflowStepUnsupportedActionDialogProps {
  orgId: string;
  step: ReconVelocityWorkflowStep;
  action?: string;
  vehicleCount: number;
  precedingSteps: ReconVelocityWorkflowStep[];
  otherSteps: ReconVelocityWorkflowStep[];
  allSteps: ReconVelocityWorkflowStep[];
  childSteps?: ReconVelocityWorkflowStep[];
  onClose?: () => void;
}
const WorkflowStepUnsupportedActionDialog = ({
  orgId,
  step,
  action = 'update',
  vehicleCount,
  precedingSteps,
  otherSteps,
  childSteps,
  onClose,
  allSteps,
}: WorkflowStepUnsupportedActionDialogProps) => {
  return (
    <Dialog
      open
      title={`Cannot ${startCase(action)} ${step.name} Step (Step Is In Use)`}
      maxWidth="md"
      fullWidth
      showCloseButton
      onClose={onClose}
      primaryButtonLabel="OK"
    >
      {vehicleCount > 0 ? (
        <WorkflowStepExistingVehiclesSection
          step={step}
          action={action}
          vehicleCount={vehicleCount}
          otherSteps={otherSteps}
        />
      ) : (
        <>
          <WorkflowStepsPrecedingStepsSection
            step={step}
            precedingSteps={precedingSteps}
            otherSteps={otherSteps}
          />

          {childSteps && (
            <WorkflowStepsChildStepsSection
              orgId={orgId}
              step={step}
              childSteps={childSteps}
              otherSteps={otherSteps}
              allSteps={allSteps}
            />
          )}
        </>
      )}

      <br />

      <i className="margin-top margin-bottom align-center">
        We hope to support this in the future.
      </i>

      <br />
    </Dialog>
  );
};

export default WorkflowStepUnsupportedActionDialog;
