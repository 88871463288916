import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { FC } from 'react';

import { useScrollBarDetector } from './hooks';
import { Column, TableColumnsProps } from './types';

export type TableHeaderColumnsProps = TableColumnsProps & {
  totalRecords: number;
  rowHeight: number;
};

export const TableHeaderColumns: FC<TableHeaderColumnsProps> = ({
  columns,
  totalRecords,
  rowHeight,
}) => {
  const isScrollbarPresent = useScrollBarDetector(totalRecords * rowHeight);

  return (
    <TableRow
      component="div"
      className={`row header ${
        isScrollbarPresent ? 'scrollbarAdjustment' : ''
      }`}
    >
      {columns.map((column: Column) => {
        const isActionButtonsCell = column.dataKey === 'actionButtons';
        return (
          <TableCell
            key={`${column.dataKey}columnHeader`}
            component="div"
            variant="head"
            align={column.dataKey === 'actionButtons' ? 'right' : 'left'}
            className={`cell header-cell ${
              isActionButtonsCell ? 'actionButtons' : ''
            }`}
            style={{
              minWidth: column.width,
              height: rowHeight,
              maxWidth: column.maxWidth,
            }}
            scope="col"
          >
            {column.label}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
