import { useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';

import { getTokens } from 'api/session';

const SERVER_URL =
  process.env.REACT_APP_PUB_SUB_SERVER_URL || window.location.hostname;
const SERVER_PATH = process.env.REACT_APP_PUB_SUB_SERVER_PATH;
const NAMESPACE = `${process.env.REACT_APP_PUB_SUB_NAMESPACE}-rvWeb`;

const handleRealtimeConnection = (
  socket: Socket,
  setIsSocketConnected: Function
) => {
  socket.on('connect', () => {
    setIsSocketConnected(true);
  });

  socket.on('disconnect', () => {
    setIsSocketConnected(false);
  });

  socket.on('connect_error', (error) => {
    console.error(error);
    setIsSocketConnected(false);

    // From the socket.io transports option docs: https://socket.io/docs/v4/client-options/#transports
    socket.io.opts.transports = ['polling', 'websocket'];
  });
};

export const useSocket = () => {
  const [socket, setSocket] = useState<Socket>();
  const [isSocketConnected, setIsSocketConnected] = useState(false);

  useEffect(() => {
    if (SERVER_URL) {
      const { authBearerToken } = getTokens();

      const ioSocket = io(`${SERVER_URL}/${NAMESPACE}`, {
        path: `${SERVER_PATH}/socket.io`,
        auth: {
          token: authBearerToken,
          isAdmin: true,
        },
        transports: ['websocket', 'polling'],
        withCredentials: true,
      });

      handleRealtimeConnection(ioSocket, setIsSocketConnected);
      setSocket(ioSocket);

      return () => {
        ioSocket.disconnect();
      };
    }
  }, []);

  return {
    socket,
    isSocketConnected,
  };
};
