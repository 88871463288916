import TextField from '@material-ui/core/TextField';
import { FormikProps, getIn } from 'formik';
import { capitalize } from 'lodash';
import { Fragment } from 'react';
import NumberFormat from 'react-number-format';

import { DAYS, Organization } from 'models';
import utils from 'utils';

import './OrganizationDepartmentSubform.scss';

type OrganizationDepartmentType = 'SALES' | 'SERVICE' | 'PARTS';

interface OrganizationDepartmentSubformProps {
  departmentType: OrganizationDepartmentType;
  formik: FormikProps<Organization>;
}

var OrganizationDepartmentSubform = ({
  departmentType,
  formik,
}: OrganizationDepartmentSubformProps) => {
  const key =
    departmentType === 'SALES'
      ? 'salesDepartment'
      : departmentType === 'PARTS'
      ? 'partsDepartment'
      : 'serviceDepartment';
  const department = {
    values: formik.values[key],
    touched: formik.touched[key],
    errors: formik.errors[key],
  };

  return (
    <div className="OrganizationDepartmentSubform flex-rows flex-align-center flex-justify-start">
      <div className="flex-columns flex-justify-center">
        <div className="padding OrganizationDepartmentSubform__phone">
          <NumberFormat
            id={`${key}-Phone-Number`}
            customInput={TextField}
            label="Phone"
            inputMode="tel"
            type="tel"
            variant="outlined"
            margin="dense"
            format="(###) ###-####"
            mask="_"
            name={`${key}.phone`}
            value={department?.values?.phone ?? ''}
            onChange={(e) => {
              const newPhone = utils.strings.cleanPhoneNumber(e.target.value);
              formik.setFieldValue(`${key}.phone`, newPhone);
            }}
            onBlur={formik.handleBlur}
            error={
              getIn(department.touched, 'phone') &&
              !!getIn(department.errors, 'phone')
            }
          />
        </div>

        <div className="padding OrganizationDepartmentSubform__email-address">
          <TextField
            id={`${key}-Email-Address`}
            label="Email Address"
            variant="outlined"
            name={`${key}.email`}
            value={department?.values?.email ?? ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              getIn(department.touched, 'email') &&
              !!getIn(department.errors, 'email')
            }
            margin="dense"
          />
        </div>
      </div>
      <div className="OrganizationDepartmentSubform__schedule">
        <div className="OrganizationDepartmentSubform__schedule-grid">
          <span />
          <label className="align-right">Open:</label>
          <label className="align-right">Close:</label>
          {DAYS.map((day) => {
            const dayName = capitalize(day);
            const scheduleDay = department?.values?.schedule?.[day];
            return (
              <Fragment key={day}>
                <label>{dayName}</label>
                <div>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    type="time"
                    inputProps={{
                      step: 300,
                    }}
                    required={!!scheduleDay?.closeTime}
                    onChange={(e) => {
                      if (e.target.value === '' && !scheduleDay?.closeTime) {
                        formik.setFieldValue(`${key}.schedule.${day}`, null);
                        return;
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={scheduleDay?.openTime ?? ''}
                    name={`${key}.schedule.${day}.openTime`}
                    error={
                      getIn(department.touched, `schedule.${day}.openTime`) &&
                      getIn(department.errors, `schedule.${day}.openTime`)
                    }
                  />
                </div>
                <div>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    type="time"
                    inputProps={{
                      step: 300,
                    }}
                    required={!!scheduleDay?.openTime}
                    onChange={(e) => {
                      if (e.target.value === '' && !scheduleDay?.openTime) {
                        formik.setFieldValue(`${key}.schedule.${day}`, null);
                        return;
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={scheduleDay?.closeTime ?? ''}
                    name={`${key}.schedule.${day}.closeTime`}
                    error={
                      getIn(department.touched, `schedule.${day}.closeTime`) &&
                      getIn(department.errors, `schedule.${day}.closeTime`)
                    }
                  />
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OrganizationDepartmentSubform;
