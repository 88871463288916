import { Button, Divider, FormGroup, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';

import {
  useCreateReconVelocityPlugin,
  useReconVelocityPlugin,
  useUpdateReconVelocityPlugin,
} from 'api/organizations/plugins/reconvelocity';
import strings from 'common/strings';
import theme from 'common/theme';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { Organization } from 'models';
import {
  getDefaultSettings,
  ReconVelocityPluginItem,
} from 'models/plugins/reconVelocityPlugin';

import { DealershipCosts } from './DealershipCosts';
import { GeneralSettings } from './GeneralSettings';

import './GeneralView.scss';

interface GeneralViewProps {
  orgId: Organization['id'];
  fetchOnMount: boolean;
  isCreatingPlugin: boolean;
}

const GeneralView = ({
  orgId,
  fetchOnMount,
  isCreatingPlugin,
}: GeneralViewProps) => {
  const { data: generalSettings, isLoading: generalSettingsAreLoading } =
    useReconVelocityPlugin(orgId, {
      enabled: fetchOnMount,
    });
  const {
    updateReconVelocityPluginAsync,
    isLoading: isUpdatingReconVelocityPlugin,
  } = useUpdateReconVelocityPlugin(orgId);
  const { isLoading: isCreatingReconVelocityPlugin } =
    useCreateReconVelocityPlugin(orgId);
  const isLoading =
    generalSettingsAreLoading ||
    isUpdatingReconVelocityPlugin ||
    isCreatingReconVelocityPlugin ||
    isCreatingPlugin;

  const [currency, setCurrency] = useState(
    generalSettings?.data?.monthlySubscriptionFee.currency ?? 'USD'
  );

  const [updatedSettings, setUpdatedSettings] = useState<
    Partial<ReconVelocityPluginItem>
  >({
    ...getDefaultSettings(currency),
    ...generalSettings?.data,
  });

  const generalSettingsData = generalSettings?.data;
  useEffect(() => {
    setUpdatedSettings({
      ...getDefaultSettings(currency),
      ...generalSettingsData,
    });
  }, [currency, generalSettingsData, setUpdatedSettings]);

  const handleSaveButtonClick = () => {
    updateReconVelocityPluginAsync(updatedSettings);
  };

  const updateSetting = (
    key: keyof ReconVelocityPluginItem,
    value: ReconVelocityPluginItem[typeof key]
  ) => {
    setUpdatedSettings((settings) => ({
      ...settings,
      [key]: value,
    }));
  };

  const updateSubsetting = (inputKey: string, inputValue: Record<any, any>) => {
    setUpdatedSettings((settings) => {
      const subsetSettings = settings.settings;
      const newSettings = {
        ...subsetSettings,
        [inputKey]: inputValue,
      };

      return {
        ...settings,
        settings: newSettings,
      };
    });
  };

  const updateDealershipCost = (
    key: keyof ReconVelocityPluginItem['dealershipCosts'],
    value: ReconVelocityPluginItem['dealershipCosts'][typeof key]
  ) => {
    updateSetting('dealershipCosts', {
      ...updatedSettings.dealershipCosts,
      [key]: value,
    });
  };

  return isCreatingPlugin ? (
    <LoadingIndicator />
  ) : (
    <FormGroup className="ReconVelocityPluginDialog__GeneralView">
      <div className="full-width flex-columns flex-justify-center align-center">
        <div className="align-left">
          <GeneralSettings
            currency={currency}
            setCurrency={setCurrency}
            updatedSettings={updatedSettings}
            updateSetting={updateSetting}
            updateSubsetting={updateSubsetting}
          />
          <div className="flex-columns flex-justify-center valign-center padding">
            <Divider className="flex-grow margin-right" />
            <Typography variant="h6">{strings.DEALERSHIP_COSTS}</Typography>
            <Divider className="flex-grow margin-left" />
          </div>
          <DealershipCosts
            currency={currency}
            updatedSettings={updatedSettings}
            updateDealershipCost={updateDealershipCost}
          />
        </div>
      </div>
      <div className="align-right full-width padding">
        <Button
          id="save-button"
          color="secondary"
          variant="contained"
          style={{
            color: theme.palette.common.white,
          }}
          onClick={handleSaveButtonClick}
          disabled={isLoading}
        >
          {isLoading && <LoadingIndicator color="secondary" size={20} />}
          <b>{strings.SAVE}</b>
        </Button>
      </div>
    </FormGroup>
  );
};

export default GeneralView;
