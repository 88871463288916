import debounce from 'lodash/debounce';
import { useCallback, useState } from 'react';

type UseDebouncedInputReturnType = [
  searchInput: string,
  setSearchInput: (e: React.ChangeEvent<HTMLInputElement>) => void
];

export const useDebouncedInput = (
  setInputAfterDelay: (newInput: string) => void
): UseDebouncedInputReturnType => {
  const [searchInput, setSearchInput] = useState<string>('');
  const sendTextChange = debounce(
    (newValue) => setInputAfterDelay(newValue),
    250,
    { leading: false, trailing: true }
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handler = useCallback(debounce(sendTextChange, 10), []);
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    handler(e.target.value);
  };

  return [searchInput, handleTextChange];
};
