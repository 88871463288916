import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      //   width: 250,
    },
  },
};
const Years = [
  '2021',
  '2020',
  '2019',
  '2018',
  '2017',
  '2016',
  '2015',
  '2014',
  '2013',
  '2012',
  '2011',
  '2010',
  '2009',
  '2008',
  '2007',
  '2006',
  '2005',
  '2004',
  '2003',
  '2002',
  '2001',
  '2000',
];

var AddCustom = () => {
  const classes = useStyles();
  const [vehicleYear, setVehicleYear] = React.useState<string[]>([]);
  const [vehicleMake, setVehicleMake] = React.useState<string[]>([]);
  const [vehicleModel, setVehicleModel] = React.useState<string[]>([]);
  const handleYearChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setVehicleYear(event.target.value as string[]);
  };
  const handleMakeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setVehicleMake(event.target.value as string[]);
  };
  const handleModelChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setVehicleModel(event.target.value as string[]);
  };

  return (
    <>
      <div className="addCustom-Selection addCustom-Year-Selection">
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-mutiple-checkbox-label">Year</InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            value={vehicleYear}
            onChange={handleYearChange}
            input={<Input />}
            renderValue={(selected) => (selected as string[]).join(', ')}
            MenuProps={MenuProps}
            className={classes.selectEmpty}
          >
            {Years.map((year) => (
              <MenuItem key={year} value={year}>
                <Checkbox checked={vehicleYear.indexOf(year) > -1} />
                <ListItemText primary={year} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="addCustom-Selection addCustom-Make-Selection">
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-name-label">Make</InputLabel>
          <Select
            id="demo-select-checkbox"
            value={vehicleMake}
            onChange={handleMakeChange}
            input={<Input />}
            renderValue={(selected) => (selected as string[]).join(', ')}
            MenuProps={MenuProps}
            className={classes.selectEmpty}
          >
            {Years.map((year) => (
              <MenuItem key={year} value={year}>
                <Checkbox checked={vehicleYear.indexOf(year) > -1} />
                <ListItemText primary={year} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="addCustom-Selection addCustom-Model-Selection">
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-select-name-label">Model</InputLabel>
          <Select
            id="demo-select-checkbox"
            value={vehicleModel}
            onChange={handleModelChange}
            input={<Input />}
            renderValue={(selected) => (selected as string[]).join(', ')}
            MenuProps={MenuProps}
            className={classes.selectEmpty}
          >
            {Years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
};

export default AddCustom;
