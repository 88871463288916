import { TableCell, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { memo } from 'react';

import { ROW_HEIGHT } from 'common/constants';
import { OrganizationHistory } from 'models';
import utils from 'utils';
import time from 'utils/time';

import { Column } from './types';

export interface HistoryListRowProps {
  style: React.CSSProperties;
  index: number;
  history: OrganizationHistory;
  headerColumns: Column[];
}
type OrganizationHistoryKey = keyof OrganizationHistory;

const getColumnData = (history: OrganizationHistory, column: Column) => {
  const dataKey = column.dataKey;

  if (dataKey === 'user') {
    return `${history.user.firstName} ${history.user.lastName}`;
  }

  if (dataKey === 'date') {
    return time.formatTimestamp(new Date(history.timestamp));
  }

  return history[dataKey as OrganizationHistoryKey];
};

const HistoryListRow = memo(
  ({ style, index, history, headerColumns }: HistoryListRowProps) => (
    <TableRow component="div" className="row" style={style} key={index}>
      {headerColumns.map((column) => {
        if (!history) {
          return (
            <Skeleton
              className="loadingSkeleton"
              height={ROW_HEIGHT}
              key={`${utils.strings.createRandomCharacters()}skeleton`}
            />
          );
        }

        return (
          <TableCell
            key={`${history.id ?? 'emptyKey'}${column.dataKey}`}
            component="div"
            variant="body"
            align="left"
            className="cell"
          >
            {getColumnData(history, column)}
          </TableCell>
        );
      })}
    </TableRow>
  )
);

export default HistoryListRow;
