import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import { useState } from 'react';

import { Organization, Tag } from 'models';

import EditTagsDialog from './EditTagsDialog';
import TagChip from './TagChip';

import './TagsList.scss';

interface TagsListProps {
  orgId: Organization['id'];
  tags?: Tag[];
}

var TagsList = ({ orgId, tags }: TagsListProps) => {
  const [editTagsDialogOpen, setEditTagsDialogOpen] = useState(false);

  const renderEmpty = () => (
    <button
      type="button"
      className="TagsList-empty"
      onClick={() => setEditTagsDialogOpen(true)}
    >
      ADD TAGS...
    </button>
  );

  const renderTags = () => (
    <div className="TagsList-list">
      {tags?.map((tag) => (
        <TagChip key={tag.id} tag={tag} />
      ))}
      <IconButton onClick={() => setEditTagsDialogOpen(true)}>
        <Edit />
      </IconButton>
    </div>
  );
  return (
    <div className="TagsList">
      {tags && tags?.length > 0 ? renderTags() : renderEmpty()}
      {editTagsDialogOpen && (
        <EditTagsDialog
          orgId={orgId}
          tags={tags}
          open={editTagsDialogOpen}
          onClose={() => setEditTagsDialogOpen(false)}
        />
      )}
    </div>
  );
};

export default TagsList;
