import { configuredFetch } from 'api/base';
import { NotificationItem } from 'models';

async function getSupported(): Promise<NotificationItem[]> {
  const { data } = await configuredFetch<NotificationItem[]>(
    '/utility/notifications'
  );
  return data;
}

export default {
  getSupported,
};
