import { combineReducers } from 'redux';

import organizationsReducer from './organizations';
import utilityReducer from './utility';

const rootReducer = combineReducers({
  organizations: organizationsReducer,
  utility: utilityReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
