import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { keyBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';

import { useOrganizationUsers } from 'api';
import {
  useCreateTaskDefinition,
  useUpdateTaskDefinition,
} from 'api/organizations/plugins/reconvelocity/tasks';
import CurrencyTextField from 'components/shared/CurrencyTextField';
import Dialog from 'components/shared/Dialog';
import { Currency, Organization, User } from 'models';
import { ReconVelocityTaskDefinition } from 'models/plugins/reconVelocityPlugin';

import './EditTaskTemplateDialog.scss';

type LaborType = 'FIXED' | 'HOURLY';

export interface EditTaskTemplateDialogProps {
  orgId: Organization['id'];
  taskDefinition: ReconVelocityTaskDefinition;
  onClose?: () => void;
}

const EditTaskTemplateDialog = ({
  orgId,
  taskDefinition,
  onClose,
}: EditTaskTemplateDialogProps) => {
  const { updateTaskDefinitionAsync } = useUpdateTaskDefinition(
    orgId,
    taskDefinition.id
  );
  const { createTaskDefinitionAsync } = useCreateTaskDefinition(orgId);
  const {
    data: organizationUsersData,
    isLoading: organizationUsersAreLoading,
  } = useOrganizationUsers(orgId);
  const organizationUsersDataPages = organizationUsersData?.pages;
  const organizationUsers = useMemo(
    () =>
      organizationUsersDataPages?.reduce(
        (acc: User[], pageUsers) => [...acc, ...pageUsers.data],
        []
      ) ?? [],
    [organizationUsersDataPages]
  ); // TODO use Memo or handle flat users differently

  const [
    [taskDefinitionInState, setTaskDefinitionInState],
    [laborType, setLaborType],
    [laborRate, setLaborRate],
    [laborHours, setLaborHours],
    [laborCost, setLaborCost],
    [partsCost, setPartsCost],
    [currency, setCurrency],
  ] = [
    useState(taskDefinition),
    useState<LaborType>(
      taskDefinition.defaultLaborRate.amount ? 'HOURLY' : 'FIXED'
    ),
    useState(taskDefinition.defaultLaborRate?.amount),
    useState(taskDefinition.defaultLaborHours),
    useState(taskDefinition.defaultLaborCost?.amount),
    useState(taskDefinition.defaultPartsCost?.amount),
    useState<Currency>(taskDefinition.defaultLaborCost.currency ?? 'USD'),
  ];

  const organizationUsersMap = useMemo(
    () => keyBy(organizationUsers, 'id'),
    [organizationUsers]
  );

  useEffect(() => {
    if (laborType === 'FIXED') return;
    setLaborCost((laborRate ?? 0) * laborHours);
  }, [laborHours, laborRate, laborType, setLaborCost]);

  const onPrimary = useCallback(async () => {
    const submittableTaskDefinition = {
      ...taskDefinitionInState,
      defaultLaborHours: laborHours,
      defaultLaborCost: {
        amount: laborCost,
        currency,
      },
      defaultPartsCost: {
        amount: partsCost,
        currency,
      },
    };

    if (laborType === 'HOURLY') {
      delete submittableTaskDefinition.defaultLaborCost.amount;
      submittableTaskDefinition.defaultLaborRate = {
        amount: laborRate,
        currency,
      };
      submittableTaskDefinition.defaultLaborCost = {
        amount: (laborRate ?? 0) * laborHours,
        currency,
      };
    } else {
      submittableTaskDefinition.defaultLaborRate.amount = 0;
    }

    if (taskDefinition.id) {
      await updateTaskDefinitionAsync(submittableTaskDefinition);
    } else {
      await createTaskDefinitionAsync(submittableTaskDefinition);
    }
    onClose?.();
  }, [
    currency,
    laborCost,
    laborHours,
    laborRate,
    laborType,
    partsCost,
    taskDefinition.id,
    taskDefinitionInState,
    onClose,
    updateTaskDefinitionAsync,
    createTaskDefinitionAsync,
  ]);

  // -- Form components
  const renderTaskTitleTextField = () => (
    <TextField
      id="task-type-textfield"
      required
      variant="outlined"
      fullWidth
      label="Task"
      margin="dense"
      value={taskDefinitionInState.title}
      onChange={({ target: { value: title } }) =>
        setTaskDefinitionInState((current) => ({ ...current, title }))
      }
      autoFocus
    />
  );

  const renderDefaultAssigneeSelect = () => (
    <FormControl variant="outlined" fullWidth margin="dense">
      <InputLabel shrink variant="outlined">
        Default Assignee
      </InputLabel>
      <Select
        id="default-assignee-textfield"
        variant="outlined"
        label="Default Assignee"
        value={taskDefinitionInState.defaultAssigneeId ?? ''}
        margin="dense"
        displayEmpty
        renderValue={(value) => {
          const userId = value as string;
          if (!userId) return 'None';

          const user = organizationUsersMap[userId];
          return `${user?.firstName} ${user?.lastName}`;
        }}
        onChange={({ target: { value } }) =>
          setTaskDefinitionInState((current) => ({
            ...current,
            defaultAssigneeId: value ? (value as string) : null,
          }))
        }
      >
        <MenuItem value="">None</MenuItem>
        {organizationUsers?.map((user) => (
          <MenuItem
            key={user?.id}
            value={user?.id}
            id="default-assignee-option"
          >
            {user.firstName} {user.lastName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderDescriptionTextField = () => (
    <TextField
      variant="outlined"
      margin="dense"
      label="Description"
      multiline
      rows={4}
      fullWidth
      value={taskDefinitionInState.description ?? ''}
      onChange={({ target: { value: description } }) =>
        setTaskDefinitionInState((current) => ({
          ...current,
          description,
        }))
      }
      id="description-textfield"
    />
  );

  const renderAddByDefaultForNewReconVehiclesCheckbox = () => (
    <FormControlLabel
      control={
        <Checkbox
          checked={taskDefinitionInState.addByDefaultForNewRecon}
          onChange={({ target: { checked: addByDefaultForNewRecon } }) =>
            setTaskDefinitionInState((current) => ({
              ...current,
              addByDefaultForNewRecon,
            }))
          }
        />
      }
      label="Add by Default to New Vehicles"
    />
  );

  const renderRequiresApprovalCheckbox = () => (
    <FormControlLabel
      control={
        <Checkbox
          checked={taskDefinitionInState.requiresApproval}
          onChange={({ target: { checked: requiresApproval } }) =>
            setTaskDefinitionInState((current) => ({
              ...current,
              requiresApproval,
            }))
          }
        />
      }
      label="Requires Approval"
    />
  );

  const renderLaborTypeSelect = () => (
    <FormControl variant="outlined" fullWidth margin="dense">
      <InputLabel>Labor Type</InputLabel>
      <Select
        id="labor-type-textfield"
        variant="outlined"
        label="Labor Type"
        value={laborType}
        required
        onChange={({ target: { value } }) => setLaborType(value as LaborType)}
      >
        <MenuItem value="HOURLY">Hourly</MenuItem>
        <MenuItem value="FIXED">Fixed Cost</MenuItem>
      </Select>
    </FormControl>
  );

  const DefaultLaborHoursTextField = (
    <NumberFormat
      required
      fullWidth
      variant="outlined"
      label="Hours"
      margin="dense"
      customInput={TextField}
      value={laborHours}
      onValueChange={({ value }) => {
        if (value === '') return;
        setLaborHours(parseFloat(value));
      }}
    />
  );

  const DefaultLaborRateTextField = (
    <CurrencyTextField
      required
      fullWidth
      variant="outlined"
      label="Hourly Rate"
      name="defaultLaborRate"
      margin="dense"
      value={laborRate ?? 0}
      onValueChange={({ value }) => {
        if (value === '') return;
        setLaborRate(parseFloat(value));
      }}
    />
  );

  const DefaultLaborCostDisplay = (
    <FormControl
      fullWidth
      margin="dense"
      className="EditTaskTemplateDialog_labor_cost"
    >
      <Typography variant="subtitle1">Labor Cost</Typography>
      <NumberFormat
        value={laborCost ?? 0}
        displayType="text"
        renderText={(formattedValue) => (
          <Typography variant="subtitle1">{formattedValue}</Typography>
        )}
        prefix="$"
        thousandSeparator
        decimalScale={2}
        fixedDecimalScale
      />
    </FormControl>
  );

  const DefaultLaborCostTextField = (
    <CurrencyTextField
      id="labor-cost-textfield"
      required
      fullWidth
      variant="outlined"
      label="Labor Cost"
      margin="dense"
      value={laborCost ?? 0}
      onValueChange={({ value }) => {
        if (value === '') return;
        setLaborCost(parseFloat(value));
      }}
    />
  );

  const DefaultPartsCostTextField = (
    <CurrencyTextField
      id="parts-cost-textfield"
      required
      fullWidth
      variant="outlined"
      label="Parts Cost"
      margin="dense"
      value={partsCost ?? 0}
      onValueChange={({ value }) => setPartsCost(parseFloat(value))}
    />
  );

  const renderTotal = () => (
    <FormControl
      fullWidth
      margin="dense"
      className="EditTaskTemplateDialog_total"
    >
      <Typography variant="subtitle1">Total</Typography>
      <NumberFormat
        value={(laborCost ?? 0) + (partsCost ?? 0)}
        displayType="text"
        renderText={(formattedValue) => (
          <Typography variant="subtitle1">{formattedValue}</Typography>
        )}
        prefix="$"
        thousandSeparator
        decimalScale={2}
        fixedDecimalScale
      />
    </FormControl>
  );

  const renderCurrencySelect = () => (
    <FormControl variant="outlined" fullWidth margin="dense">
      <InputLabel>Currency</InputLabel>
      <Select
        id="current-select-textfield"
        variant="outlined"
        margin="dense"
        label="Currency"
        value={currency}
        onChange={({ target: { value } }) => setCurrency(value as Currency)}
      >
        <MenuItem value="USD">USD</MenuItem>
        <MenuItem value="CAD">CAD</MenuItem>
      </Select>
    </FormControl>
  );

  return (
    <Dialog
      open
      title={`${taskDefinition.id ? 'Edit' : 'Add'} Task Template`}
      maxWidth="md"
      primaryButtonLabel="Save"
      onPrimary={onPrimary}
      onClose={onClose}
      block={organizationUsersAreLoading}
      className="PluginDialog"
    >
      <div className="EditTaskTemplateDialog">
        <div className="EditTaskTemplateDialog_column1">
          {renderTaskTitleTextField()}
          {renderDefaultAssigneeSelect()}
          {renderDescriptionTextField()}
          {renderAddByDefaultForNewReconVehiclesCheckbox()}
          {renderRequiresApprovalCheckbox()}
        </div>

        <div className="EditTaskTemplateDialog_column2">
          {renderLaborTypeSelect()}

          {laborType === 'HOURLY' ? (
            <>
              {DefaultLaborHoursTextField}
              {DefaultLaborRateTextField}
              <hr />
              {DefaultLaborCostDisplay}
            </>
          ) : (
            DefaultLaborCostTextField
          )}

          {DefaultPartsCostTextField}
          <hr />
          {renderTotal()}
          {renderCurrencySelect()}
        </div>
      </div>
    </Dialog>
  );
};

export default EditTaskTemplateDialog;
