import { NavLink } from 'react-router-dom';

import useSession from 'api/session';
import { useUser } from 'api/users';

var ManageUsersSetup = () => {
  const { data: session } = useSession();
  const {
    query: { data: user },
  } = useUser(session?.user.id);
  return (
    <div
      style={{
        padding: '25px 15%',
        fontSize: 'medium',
        height: 'calc(100% - 60px)',
        overflow: 'auto',
      }}
    >
      <h2>Follow these steps to get started with managing users</h2>
      <ol>
        <li>
          To view all the users in your dealership{' '}
          <NavLink to={`/orgs/${user?.defaultOrganization?.id}/users`}>
            click here
          </NavLink>
        </li>
        <video style={{ margin: '10px', maxWidth: '100%' }} controls>
          <source
            src="https://velocityautomotive-public-docs.s3.amazonaws.com/HowtoAddEditDeleteUsers.webm"
            type="video/webm"
          ></source>
        </video>
      </ol>
      <div style={{ fontWeight: 700 }}>
        Please email{' '}
        <a href="mailto:performance@velocityautomotive.com">
          performance@velocityautomotive.com
        </a>{' '}
        to add users
      </div>
    </div>
  );
};

export default ManageUsersSetup;
