import { configuredFetch } from 'api/base';
import {
  GetOrganizationUserNotificationSubscriptionsResponse,
  NotificationChannel,
  NotificationEvent,
  NotificationStepDefinition,
  NotificationSubscription,
  Organization,
  RequestNotificationSubscription,
  User,
} from 'models';

export type GetSubscriptionsRequestParams = {
  orgId: Organization['id'];
  userId: User['id'];
};

function subscriptionsUrl({ orgId, userId }: GetSubscriptionsRequestParams) {
  return `/orgs/${orgId}/users/${userId}/notifications/subscriptions`;
}

async function getSubscriptions({
  orgId,
  userId,
}: GetSubscriptionsRequestParams): Promise<GetOrganizationUserNotificationSubscriptionsResponse> {
  const url = subscriptionsUrl({ orgId, userId });

  const { data } =
    await configuredFetch<GetOrganizationUserNotificationSubscriptionsResponse>(
      url
    );
  return data;
}

export type AddSubscriptionRequestParams = GetSubscriptionsRequestParams & {
  subscription: RequestNotificationSubscription;
};
async function addSubscription({
  orgId,
  userId,
  subscription,
}: AddSubscriptionRequestParams): Promise<NotificationSubscription> {
  const url = subscriptionsUrl({ orgId, userId });

  const { data } = await configuredFetch<NotificationSubscription>(url, {
    method: 'put',
    body: JSON.stringify(subscription),
  });
  return data;
}

export type UnsubscribeRequestParams = GetSubscriptionsRequestParams & {
  eventName?: NotificationEvent['name'];
  channelId?: NotificationChannel['id'];
  stepDefinitionId?: NotificationStepDefinition['id'];
};
async function unsubscribe({
  orgId,
  userId,
  eventName,
  channelId,
}: UnsubscribeRequestParams): Promise<
  Pick<UnsubscribeRequestParams, 'eventName' | 'channelId'>
> {
  const url = subscriptionsUrl({ orgId, userId });

  const { data: success } = await configuredFetch<boolean>(`${url}`, {
    method: 'delete',
    body: JSON.stringify({ eventName, channelId }),
  });

  if (!success) throw new Error('Unable to unsubscribe from notification.');

  return {
    eventName,
    channelId,
  };
}

export type StepSubscriptionRequestParams = GetSubscriptionsRequestParams & {
  eventName: NotificationEvent['name'];
  stepDefinitionId: NotificationStepDefinition['id'];
  subscribed: boolean;
};
async function setStepSubscription({
  orgId,
  userId,
  eventName,
  stepDefinitionId,
  subscribed,
}: StepSubscriptionRequestParams): Promise<
  Pick<
    StepSubscriptionRequestParams,
    'stepDefinitionId' | 'subscribed' | 'eventName'
  >
> {
  const url = subscriptionsUrl({ orgId, userId });

  const { data: success } = await configuredFetch<boolean>(`${url}`, {
    method: subscribed ? 'put' : 'delete',
    body: JSON.stringify({ eventName, stepDefinitionId }),
  });

  if (!success) throw new Error('Unable to disable notifications for step.');

  return {
    eventName,
    stepDefinitionId,
    subscribed,
  };
}

export default {
  getSubscriptions,
  addSubscription,
  unsubscribe,
  setStepSubscription,
};
