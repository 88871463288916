import { FC } from 'react';
import ReactSelect, { StylesConfig } from 'react-select';

import { SelectOption } from 'models';

import { HandleUserIdChange } from './types';

export interface HistoryUserSelectProps {
  options: SelectOption[];
  value: SelectOption;
  onChange: HandleUserIdChange;
}

const HistoryUserSelect: FC<HistoryUserSelectProps> = ({
  options,
  value,
  onChange,
}) => {
  const DEFAULT_VALUE = {
    label: 'Select user...',
    value: '',
  };
  const STYLES: StylesConfig<SelectOption, false> = {
    container: (provided) => ({
      ...provided,
      height: '40px',
      width: '200px',
    }),
    control: (provided) => ({
      ...provided,
      height: '40px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '25px',
      padding: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    input: (provided) => ({
      ...provided,
      height: '20px',
      color: 'white',
      '> input': {
        color: 'white',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      height: '20px',
      fontSize: '16px',
      margin: '0',
      paddingLeft: '5px',
    }),
  };

  return (
    <div className="HistoryUserSelect">
      <ReactSelect
        id="history-user-select"
        options={options}
        defaultValue={DEFAULT_VALUE}
        value={value}
        onChange={onChange}
        styles={STYLES}
        isClearable
      />
    </div>
  );
};

export default HistoryUserSelect;
