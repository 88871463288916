import { combineReducers } from 'redux';

import organizationOverviewReducer from './overview';
import organizationPluginsReducer from './plugins';
import organizationUsersReducer from './users';

const organizationsReducer = combineReducers({
  users: organizationUsersReducer,
  plugins: organizationPluginsReducer,
  overview: organizationOverviewReducer,
});

export type OrganizationsState = ReturnType<typeof organizationsReducer>;

export default organizationsReducer;
