import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Delete from '@material-ui/icons/Delete';
import { FC } from 'react';

import './KeyValueFieldTable.scss';

export const BLANK_REGEXP = /^\s*$/s;
export const BLANK_ROW_COUNT = 5;

interface KeyValuePair {
  key: string;
  value: string;
}

interface KeyValueFieldTableProps {
  className?: string;
  label?: string;
  matchCriteria: KeyValuePair[];
  onDeleteRow: (rowIndex: number, key: string) => void;
  onChange: <K extends keyof KeyValuePair>(
    index: number,
    changedKey: K,
    newValue: KeyValuePair[K]
  ) => void;
}

const KeyValueFieldTable: FC<KeyValueFieldTableProps> = ({
  className,
  label,
  matchCriteria,
  onDeleteRow,
  onChange,
}) => {
  const addBlankRowsToArray = (array: KeyValuePair[]) => {
    for (let index = 0; index < BLANK_ROW_COUNT; index += 1) {
      array.push({ key: '', value: '' });
    }
  };

  return (
    <div className={`KeyValueFieldTable${className ? ` ${className}` : ''}`}>
      {label && (
        <FormLabel className="KeyValueFieldTable-label">{label}</FormLabel>
      )}
      <TableContainer className="KeyValueFieldTable-table-container">
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>Column Name</TableCell>
              <TableCell>Regex Pattern</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {matchCriteria.map((criteria, index) => (
              <TableRow key={index} id={`match-criteria-${index}`}>
                <TableCell>
                  <TextField
                    id={`match-criteria-${index}-column-name`}
                    value={criteria.key}
                    margin="none"
                    onChange={(e) => {
                      onChange(index, 'key', e.target.value);
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    id={`match-criteria-${index}-pattern`}
                    value={criteria.value}
                    margin="none"
                    onChange={(e) => {
                      onChange(index, 'value', e.target.value);
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </TableCell>
                <TableCell className="KeyValueFieldTable-delete-cell">
                  {(criteria.key || criteria.value) && (
                    <IconButton
                      id={`match-criteria-${index}-delete-button`}
                      onClick={() => {
                        onDeleteRow(index, criteria.key);
                        addBlankRowsToArray(matchCriteria);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default KeyValueFieldTable;
