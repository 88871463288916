import { Currency, OdometerUnit } from 'models/common';

import {
  ReconVelocityPluginItem,
  ReconVelocityPluginItemReconMode,
} from './ReconVelocityPluginItem';

export const getDefaultSettings = (
  currency: Currency = 'USD'
): Partial<ReconVelocityPluginItem> => ({
  reconMode: ReconVelocityPluginItemReconMode.USED,
  odometerUnit: OdometerUnit.MILES,
  onboarding: true,
  excludeWholesaleUnitsFromImport: false,
  autoClearCompletedReconVehiclesAfter24Hours: false,
  enablePostRecon: false,
  restrictMoveStepToStepUserList: false,
  enableRequireLaborAndPartsOnTasks: false,
  monthlySubscriptionFee: { amount: 0, currency },
  dealershipCosts: {
    defaultLaborRate: { amount: null, currency },
    defaultEstimatedRecon: { amount: 0, currency },
    newPack: { amount: 0, currency },
    usedPack: { amount: 0, currency },
    dailyHoldingCost: { amount: 0, currency },
    dailyDepreciationCost: { amount: 0, currency },
  },
  reconTimeIgnoreSchedule: true,
  settings: {
    RECON_TIME_IGNORE_SCHEDULE: { dataType: 'BOOL', value: true },
  },
});
