export declare type CountryName = 'Canada' | 'United States';

export interface Timezone {
  timezone: 'AKT' | 'AT' | 'CT' | 'ET' | 'HT' | 'MT' | 'PT' | 'UNKNOWN';
  label?: string;
  offset?: number;
  zoneId?: string;
}

export interface MobilePhone {
  number: string;
  allowSMS: boolean;
}

export const defaultMobilePhone: MobilePhone = {
  number: '',
  allowSMS: true,
};

export interface GPSLocation {
  latitude: number;
  longitude: number;
}

export interface AddressState {
  id?: string;
  name: string;
  shortName: string;
}

export interface Address {
  id?: string;
  street1: string;
  street2?: string;
  city: string;
  state: AddressState;
  postalCode: string;
  postalCodePlus4?: string | null;
  gpsLocation?: GPSLocation | null;
  timezone?: Timezone['timezone'];
}

export const defaultAddress: Address = {
  street1: '',
  street2: '',
  city: '',
  state: {
    id: '',
    name: '',
    shortName: '',
  },
  postalCode: '',
  postalCodePlus4: '',
  gpsLocation: {
    latitude: 0,
    longitude: 0,
  },
  timezone: 'ET',
};

export interface State {
  id?: string;
  name: string;
  abbreviation: string;
  country?: CountryName;
}

export const defaultContact: Contact = {
  firstName: '',
  lastName: '',
  jobTitle: '',
  email: '',
  mobilePhone: {
    number: '',
    allowSMS: false,
  },
  address: defaultAddress,
};
export interface Contact {
  id?: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  mobilePhone: MobilePhone;
  address?: Address | null;
}

export interface ScheduleDay {
  openInSecondsUTC: number;
  closedInSecondsUTC: number;
  openTime: string;
  closeTime: string;
}

export const defaultScheduleDay: ScheduleDay = {
  openInSecondsUTC: 25200,
  closedInSecondsUTC: 66600,
  openTime: '07:00',
  closeTime: '18:30',
};

export interface ScheduleWeek {
  sunday: ScheduleDay | null;
  monday: ScheduleDay | null;
  tuesday: ScheduleDay | null;
  wednesday: ScheduleDay | null;
  thursday: ScheduleDay | null;
  friday: ScheduleDay | null;
  saturday: ScheduleDay | null;
}

export const defaultScheduleWeek: ScheduleWeek = {
  sunday: null,
  monday: null,
  tuesday: null,
  wednesday: null,
  thursday: null,
  friday: null,
  saturday: null,
};

export const DAYS: Array<keyof ScheduleWeek> = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export enum ScheduleType {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM',
  DISABLED = 'DISABLED',
}

export interface ItemLink {
  id: string;
  name: string;
}

export type Currency = 'USD' | 'CAD';

export interface Money {
  amount?: number | null;
  currency?: Currency;
}

export enum OdometerUnit {
  MILES = 'MILES',
  KILOMETERS = 'KILOMETERS',
}

export interface SelectOption {
  label: string;
  value: string;
}
