import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import React, { useCallback, useEffect, useState } from 'react';
import { AutoSizer } from 'react-virtualized';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { useUsers } from 'api/users';
import { User } from 'models';

import UsersListHeader from './UsersListHeader';
import UsersListRow from './UsersListRow';

const ROW_HEIGHT = 30;

const useScrollBarDetector = (heightOfContent: number): boolean => {
  const [isScrollbar, setIsScrollbar] = useState(false);

  useEffect(() => {
    const { innerHeight: windowHeight } = window;
    if (heightOfContent > windowHeight) {
      setIsScrollbar(true);
    } else {
      setIsScrollbar(false);
    }
  }, [heightOfContent]);

  return isScrollbar;
};

interface UsersListViewProps {
  checkedUsers: string[];
  setCheckedUsers: any;
  openEditUserDialog: (userId?: string) => void;
}

var UsersListView = ({
  checkedUsers,
  setCheckedUsers,
  openEditUserDialog,
}: UsersListViewProps) => {
  const [searchText, setSearchText] = useState<string>('');

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useUsers(searchText);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const userId = event.target.value;
    const index = checkedUsers.findIndex((uId) => uId === userId);

    if (index > -1) {
      checkedUsers.splice(index, 1);
    } else {
      checkedUsers.push(userId);
    }
    setCheckedUsers([...checkedUsers]);
  };

  const users =
    data?.pages?.reduce(
      (acc: User[], pageUsers: any) => [...acc, ...pageUsers.data],
      []
    ) ?? []; // TODO use Memo or handle flat users differently

  const isItemLoaded = (index: number) => !hasNextPage || index < users.length;

  const loadMoreItems = useCallback(
    // TODO handle fast scrolling and/or sliding the scroll bar
    (startIndex: number, stopIndex: number) => {
      if (users.length < stopIndex && !isFetchingNextPage) {
        return fetchNextPage();
      }
      return Promise.resolve();
    },
    [fetchNextPage, users.length, isFetchingNextPage]
  );

  const itemCount = data?.pages[0].meta.totalRecords || users.length;
  const isScrollbarPresent = useScrollBarDetector(itemCount * ROW_HEIGHT);

  return (
    <div className="UsersList">
      <div className="flex-grow">
        <div className="flex-columns UsersList-table">
          <Table className="table" component="div">
            <UsersListHeader
              isScrollbarPresent={isScrollbarPresent}
              totalUsers={itemCount}
              checkedUsers={checkedUsers.length}
              setSearchText={setSearchText}
            />
            <TableBody component="div" className="full-height">
              <AutoSizer>
                {({ height, width }) => (
                  <InfiniteLoader
                    isItemLoaded={isItemLoaded}
                    itemCount={itemCount}
                    threshold={100}
                    minimumBatchSize={50}
                    loadMoreItems={loadMoreItems}
                  >
                    {({ onItemsRendered, ref }) => (
                      <FixedSizeList
                        className="list"
                        itemCount={users.length}
                        height={height}
                        width={width}
                        itemSize={ROW_HEIGHT}
                        onItemsRendered={onItemsRendered}
                        ref={ref}
                      >
                        {({ index, style }: { index: number; style: any }) => (
                          <UsersListRow
                            user={users[index]}
                            style={style}
                            handleCheckboxChange={handleCheckboxChange}
                            checkedUsers={checkedUsers}
                            openEditUserDialog={openEditUserDialog}
                          />
                        )}
                      </FixedSizeList>
                    )}
                  </InfiniteLoader>
                )}
              </AutoSizer>
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default UsersListView;
