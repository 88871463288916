import { Chip } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: 200,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  })
);
type MultiSelectId = string;
interface MuiCheckboxMultiselectProps {
  options: Array<{
    id: MultiSelectId;
    label: string;
  }>;
  value: MultiSelectId[];
  onChange: (values: MultiSelectId[]) => void;
  label: string;
  helperText?: string;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MuiCheckboxMultiselect = (props: MuiCheckboxMultiselectProps) => {
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const ids = event.target.value as string[];
    props.onChange(ids);
  };
  const labelOfId = Object.fromEntries(
    props.options.map((option) => [option.id, option.label])
  );
  return (
    <FormControl className={classes.formControl}>
      <InputLabel>{props.label}</InputLabel>
      <Select
        multiple
        value={props.value}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selectedIds) => (
          <div className={classes.chips}>
            {(selectedIds as string[]).map((id) => (
              <Chip key={id} label={labelOfId[id]} className={classes.chip} />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {props.options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <Checkbox checked={props.value.includes(option.id)} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
};

export default MuiCheckboxMultiselect;
