import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useState } from 'react';

interface GoogleAnalyticsTrackingIDSettingProps {
  settingContent: string;
  onChange: (newContent: string) => void;
}

function strongJSONParse(text: string): string[] {
  let data = [];
  try {
    data = JSON.parse(text);
    if (data instanceof Array) {
      return data.filter((x) => typeof x === 'string');
    }
    return [];
  } catch (e: any) {
    return [];
  }
}

const GoogleAnalyticsTrackingIDSetting = ({
  settingContent,
  onChange,
}: GoogleAnalyticsTrackingIDSettingProps) => {
  const [newTrackingId, setNewTrackingId] = useState<string>('');
  const trackingIds: Array<{ content: string; id: string }> = strongJSONParse(
    settingContent
  ).map((content: string, index: number) => ({
    content,
    id: content + index.toString(),
  }));
  const typedOnChange = (newList: Array<{ content: string; id: string }>) => {
    const ret = newList.map((tid) => tid.content);
    onChange(JSON.stringify(ret));
  };
  const removeTrackingId = (index: string) => {
    typedOnChange(trackingIds.filter((tid) => tid.id !== index));
  };
  const addTrackingId = (content: string) => {
    typedOnChange([
      {
        content,
        id: content + trackingIds.length.toString(),
      },
      ...trackingIds,
    ]);
  };

  return (
    <>
      <h2>Google analytics Tracking IDs (Property IDs)</h2>
      <Box m={1}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <TextField
              label="Enter a property ID"
              variant="outlined"
              size="small"
              value={newTrackingId}
              onChange={(event) => setNewTrackingId(event.target.value)}
            />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                addTrackingId(newTrackingId);
                setNewTrackingId('');
              }}
            >
              Add
            </Button>
          </Grid>
        </Grid>
        {trackingIds.map((tid) => (
          <Grid container spacing={2} key={tid.id}>
            <Grid item xs={2}>
              <Typography>{tid.content}</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  removeTrackingId(tid.id);
                }}
              >
                Remove
              </Button>
            </Grid>
          </Grid>
        ))}
      </Box>
    </>
  );
};

export default GoogleAnalyticsTrackingIDSetting;
