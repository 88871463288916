import { TextField } from '@material-ui/core';
import { compact, isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { usePlugin, usePluginCreate, usePluginUpdate } from 'api';
import strings from 'common/strings';
import { AlertVariant } from 'components/shared/Alert/Alert';
import ConfirmationDialog, {
  useConfirmationDialog,
} from 'components/shared/ConfirmationDialog';
import Dialog from 'components/shared/Dialog';
import {
  BLANK_REGEXP,
  BLANK_ROW_COUNT,
} from 'components/shared/KeyValueFieldTable';
import Tabs, { InlineTab, TabHeaderItem } from 'components/shared/Tabs';
import { DataProviderDmsPluginItem } from 'models';

import MatchCriteriaList, { MatchCriteriaProps } from './MatchCriteriaList';

import './PluginDialog.scss';

export interface DmsPluginDialogProps {
  plugin: DataProviderDmsPluginItem;
  onClose?: () => void;
  orgId: string;
  isInventoryEnabled: boolean;
  openAlert: (message: string, variant: AlertVariant) => void;
}

const TabsForMatchCriteriaPerBusinessLinePluginDialog: React.FC<DmsPluginDialogProps> =
  (props) => {
    const { plugin, onClose, orgId, isInventoryEnabled, openAlert } = props;

    const [inventoryDealerId, setInventoryDealerId] = useState('');
    const [invFileName, setInvFileName] = useState('');
    const [salesDealerId, setSalesDealerId] = useState('');
    const [repairDealerId, setRepairDealerId] = useState('');
    const [matchCriteriaInventory, setMatchCriteriaInventory] = useState<
      MatchCriteriaProps[]
    >([]);
    const [matchCriteriaSales, setMatchCriteriaSales] = useState<
      MatchCriteriaProps[]
    >([]);
    const [matchCriteriaRepair, setMatchCriteriaRepair] = useState<
      MatchCriteriaProps[]
    >([]);

    const pluginTypeId = plugin.pluginName.toLowerCase().replace('_', '');
    const { data: pluginData, isInitialLoading: isReading } = usePlugin(
      orgId,
      pluginTypeId,
      plugin.id
    );
    const { updatePluginAsync, isLoading: isUpdating } = usePluginUpdate(
      orgId,
      pluginTypeId
    );
    const { createPluginAsync, isLoading: isCreating } = usePluginCreate(
      orgId,
      pluginTypeId
    );

    const navigate = useNavigate();
    const [shouldNavigate, setShouldNavigate] = useState(true);

    const BUSINESS_LINE_TABS: TabHeaderItem[] = useMemo(
      () => [
        { key: 'Inventory', label: 'Inventory', disabled: false },
        {
          key: 'Repair',
          label: 'Repair Orders',
          disabled: !isInventoryEnabled,
        },
      ],
      [isInventoryEnabled]
    );
    //CDK is the only provider that needs a business line to handle sales
    const DEFAULT_TAB_KEY = BUSINESS_LINE_TABS[0].key;
    if (plugin.pluginName === 'CDK' && BUSINESS_LINE_TABS.length - 1 < 2) {
      BUSINESS_LINE_TABS.push({
        key: 'Sales',
        label: 'Sales',
        disabled: !isInventoryEnabled,
      });
    }

    const InlineTabsCollection = [
      {
        element: (
          <MatchCriteriaList
            fileName={invFileName}
            setFileName={setInvFileName}
            dealerId={inventoryDealerId}
            setDealerId={setInventoryDealerId}
            matchCriteria={matchCriteriaInventory}
            setMatchCriteria={setMatchCriteriaInventory}
          />
        ),
        path: BUSINESS_LINE_TABS[0].key,
      },
      {
        element: (
          <MatchCriteriaList
            fileName={invFileName}
            setFileName={setInvFileName}
            dealerId={salesDealerId}
            setDealerId={setSalesDealerId}
            matchCriteria={matchCriteriaSales}
            setMatchCriteria={setMatchCriteriaSales}
          />
        ),
        path: BUSINESS_LINE_TABS[2]?.key,
      },
      {
        element: (
          <MatchCriteriaList
            fileName={invFileName}
            setFileName={setInvFileName}
            dealerId={repairDealerId}
            setDealerId={setRepairDealerId}
            matchCriteria={matchCriteriaRepair}
            setMatchCriteria={setMatchCriteriaRepair}
          />
        ),
        path: BUSINESS_LINE_TABS[1].key,
      },
    ].map(({ element, path }) => (
      <InlineTab
        key={path}
        path={path}
        className="full-height"
        element={element}
      />
    ));

    const isLoading = isReading || isUpdating || isCreating;

    useEffect(() => {
      const blankRows = Array.from(Array(BLANK_ROW_COUNT), () => ({
        key: '',
        value: '',
      }));

      const getMatchCriteriaByBusinessLine = (
        businessLineStr: string
      ): MatchCriteriaProps[] =>
        pluginData.matchCriteria
          .map((criterion: any) => ({
            key: criterion.columnName,
            value: criterion.regexPattern,
            businessLine: criterion.businessLine,
          }))
          .filter(
            (e: { businessLine: string }) => e.businessLine === businessLineStr
          );

      const getDealerId = (businessLineStr: string): string => {
        const result = pluginData.matchCriteria.find(
          (e: { businessLine: string }) => e.businessLine === businessLineStr
        );
        return result?.dealerId;
      };

      const getFileName = (businessLineStr: string): string => {
        const result = pluginData.matchCriteria.find(
          (e: { businessLine: string }) => e.businessLine === businessLineStr
        );
        return result?.fileName;
      };

      if (!isReading && pluginData) {
        if (pluginData.matchCriteria) {
          // Split for each business line:
          setMatchCriteriaInventory(
            getMatchCriteriaByBusinessLine(BUSINESS_LINE_TABS[0].key).concat(
              blankRows
            )
          );
          setMatchCriteriaSales(
            getMatchCriteriaByBusinessLine(BUSINESS_LINE_TABS[2]?.key).concat(
              blankRows
            )
          );
          setMatchCriteriaRepair(
            getMatchCriteriaByBusinessLine(BUSINESS_LINE_TABS[1].key).concat(
              blankRows
            )
          );
          setInvFileName(getFileName(BUSINESS_LINE_TABS[0].key));
          setInventoryDealerId(getDealerId(BUSINESS_LINE_TABS[0].key));
          setSalesDealerId(getDealerId(BUSINESS_LINE_TABS[2]?.key));
          setRepairDealerId(getDealerId(BUSINESS_LINE_TABS[1].key));
        }
      } else if (!isReading) {
        // Creating a new plugin.
        setMatchCriteriaInventory(blankRows);
        setMatchCriteriaSales(blankRows);
        setMatchCriteriaRepair(blankRows);
      }
    }, [pluginData, isReading, BUSINESS_LINE_TABS]);

    useEffect(() => {
      if (shouldNavigate) {
        setShouldNavigate(false);
        navigate(DEFAULT_TAB_KEY, { replace: true });
      }
    }, [navigate, DEFAULT_TAB_KEY, shouldNavigate]);

    const {
      isConfirmationDialogOpen,
      isConfirmationLoading,
      confirmationMessage,
      onAccept,
      onDeny,
      primaryButtonLabel,
    } = useConfirmationDialog();

    const createMatchCriteriaList = (
      matchCriteriaList: MatchCriteriaProps[],
      businessLine: string,
      dealerId: string,
      fileName?: string
    ) => {
      if (isEmpty(dealerId)) return [];

      const res = compact(
        matchCriteriaList.map(({ key, value }) =>
          BLANK_REGEXP.test(key) && BLANK_REGEXP.test(value)
            ? undefined
            : {
                columnName: key,
                regexPattern: value,
                businessLine,
                dealerId,
                fileName,
              }
        )
      );

      if (res.length === 0)
        return [
          {
            columnName: '',
            regexPattern: '',
            businessLine,
            dealerId,
            fileName,
          },
        ];
      return res;
    };

    const consolidateMatchCriteriaPerBusinessLine = () => [
      ...createMatchCriteriaList(
        matchCriteriaInventory,
        BUSINESS_LINE_TABS[0].key,
        inventoryDealerId,
        invFileName
      ),
      ...createMatchCriteriaList(
        matchCriteriaSales,
        BUSINESS_LINE_TABS[2]?.key,
        salesDealerId
      ),
      ...createMatchCriteriaList(
        matchCriteriaRepair,
        BUSINESS_LINE_TABS[1].key,
        repairDealerId,
        repairDealerId // fileName is required for valid repair order set up
      ),
    ];

    const save = async () => {
      const data: Partial<DataProviderDmsPluginItem> = {
        matchCriteria: consolidateMatchCriteriaPerBusinessLine(),
      };
      try {
        if (plugin.id) {
          await updatePluginAsync(data);
        } else {
          const newPlugin = { ...plugin, ...data };
          await createPluginAsync(newPlugin);
        }
        onClose?.();
      } catch (error) {
        console.error(error);
        openAlert(
          'An error occurred when saving this plug-in to the server.',
          'error'
        );
        return false;
      }
      return true;
    };

    const [pluginTypeTab, setPluginTypeTab] = useState(
      strings.INVENTORY_TAB_TITLE
    );

    const onSwitchTab = (plugin: string) => {
      setPluginTypeTab(plugin);
    };
    const showMessage = (pluginTypeTab: string): string => {
      if (pluginData && pluginTypeTab) {
        const inventoryDealerId = pluginData.matchCriteria.find(
          (plug: { businessLine: string }) =>
            plug.businessLine === strings.INVENTORY_TAB_TITLE
        );
        const repairDealerId = pluginData.matchCriteria.find(
          (plug: { businessLine: string }) =>
            plug.businessLine === strings.REPAIR_ORDERS_TAB_TITLE
        );
        const salesDealerId = pluginData.matchCriteria.find(
          (plug: { businessLine: string }) =>
            plug.businessLine === strings.SALES_TAB_TITLE
        );
        switch (pluginTypeTab) {
          case strings.INVENTORY_TAB_TITLE:
            return inventoryDealerId ? inventoryDealerId.dealerId : '';
          case strings.REPAIR_ORDERS_TAB_TITLE:
            return repairDealerId ? repairDealerId.dealerId : '';
          case strings.SALES_TAB_TITLE:
            return salesDealerId ? salesDealerId.dealerId : '';
          default:
            return '';
        }
      } else {
        return '';
      }
    };

    const disableSaveButton = () => {
      if (invFileName === '' && BUSINESS_LINE_TABS[0].key) {
        openAlert('File Name is required', 'error');
        return true;
      } else if (
        isInventoryEnabled &&
        pluginTypeTab === strings.REPAIR_ORDERS_TAB_TITLE
      ) {
        return false;
      } else if (
        isInventoryEnabled &&
        pluginTypeTab === strings.INVENTORY_TAB_TITLE &&
        pluginData
      ) {
        return false;
      } else if (
        isInventoryEnabled &&
        pluginTypeTab === strings.INVENTORY_TAB_TITLE &&
        !pluginData
      ) {
        openAlert('Only one inventory provider is allowed.', 'error');
        return true;
      }
    };

    const returnTextbox = () => {
      if (pluginTypeTab === 'Inventory') {
        return true;
      } else {
        return false;
      }
    };

    return (
      <>
        <Dialog
          open
          title={`Configure ${plugin.title} Plug-in`}
          maxWidth="sm"
          fullWidth
          primaryButtonLabel="Save"
          onPrimary={save}
          onClose={onClose}
          primaryButtonDisabled={disableSaveButton()}
          block={isLoading}
          className="PluginDialog DmsPluginDialog"
        >
          <Tabs
            tabHeaderItems={BUSINESS_LINE_TABS}
            defaultTabKey={DEFAULT_TAB_KEY}
            onSwitchTab={onSwitchTab}
          />
          {returnTextbox() && (
            <TextField
              autoFocus
              className="PluginDialog__text-input"
              label="File Name"
              variant="outlined"
              required
              value={invFileName}
              onChange={(e) => {
                setInvFileName(e.target.value);
              }}
              margin="dense"
            />
          )}
          {InlineTabsCollection}
          <div>
            {strings.INVENTORY_INGESTION_DATE_PLUGINS(
              pluginData?.ingestFileReceivedDate,
              pluginData?.ingestFileVehicleCount,
              showMessage(pluginTypeTab)
            )}
          </div>
        </Dialog>
        <ConfirmationDialog
          open={isConfirmationDialogOpen}
          primaryButtonLabel={primaryButtonLabel}
          onPrimaryButtonClick={onAccept}
          onSecondaryButtonClick={onDeny}
          isLoading={isConfirmationLoading}
          message={confirmationMessage}
        />
      </>
    );
  };

export default TabsForMatchCriteriaPerBusinessLinePluginDialog;
