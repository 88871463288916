import { Button } from '@material-ui/core';
import { AddIcon } from '@material-ui/data-grid';
import { Delete } from '@material-ui/icons';
import { useMemo, useState } from 'react';

import { useUpdateVendorOrganizations, useVendorOrganizations } from 'api';

import OrganizationModal from './OrganizationModal';

import './VendorOrganizations.scss';

interface VendorOrganizationsProps {
  organizationId: string;
}

const VendorOrganizations = ({ organizationId }: VendorOrganizationsProps) => {
  const {
    data: rooftopsData,
    isLoading: isLoadingVendorOrganizations,
    isFetching: isFetchingVendorOrganizations,
  } = useVendorOrganizations(organizationId);
  const {
    isLoading: isLoadingUpdateVendorOrganizations,
    mutate: updateVendorOrganizations,
  } = useUpdateVendorOrganizations(organizationId);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: rooftops } = rooftopsData ?? {};

  const isDeleteDisabled =
    isLoadingVendorOrganizations ||
    isFetchingVendorOrganizations ||
    isLoadingUpdateVendorOrganizations;

  const RooftopsComponent = useMemo(() => {
    const currentRooftopIds = rooftops?.map((rooftop) => rooftop.id);

    const handleDeleteOnClick = (rootopId: string) => {
      const newIds =
        currentRooftopIds?.filter(
          (currentRooftopId) => currentRooftopId !== rootopId
        ) ?? [];
      updateVendorOrganizations(newIds);
    };

    return rooftops?.map((rooftop) => {
      return (
        <div key={rooftop.id} className="VendorOrganizations_rooftop">
          <div>{rooftop.name}</div>
          <Button disabled={isDeleteDisabled}>
            <Delete onClick={() => handleDeleteOnClick(rooftop.id)} />
          </Button>
        </div>
      );
    });
  }, [isDeleteDisabled, rooftops, updateVendorOrganizations]);

  return (
    <div className="VendorOrganizations_container">
      <div className="VendorOrganizations_header">
        <div>{rooftops?.length} Organizations</div>
        <Button
          variant="contained"
          aria-label="close"
          color="secondary"
          onClick={() => setIsModalOpen(true)}
          disableRipple
          className="addIconButton"
        >
          <AddIcon style={{ color: 'white' }} />
        </Button>
      </div>
      <div className="VendorOrganizations_rooftops">{RooftopsComponent}</div>
      {isModalOpen && (
        <OrganizationModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          organizationId={organizationId}
        />
      )}
    </div>
  );
};

export default VendorOrganizations;
