import { FC, useEffect, useMemo, useState } from 'react';

import {
  useCreateIngestProcess,
  useDeleteIngestProcess,
  useIngestProcesses,
  useUpdateIngestProcess,
} from 'api/organizations/plugins/reconvelocity/ingestProcess';
import {
  useReconVelocityWorkflowSteps,
  useUpdateBeginningStep,
} from 'api/organizations/plugins/reconvelocity/workflows';
import Alert, { useAlert } from 'components/shared/Alert';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { Organization, ReconVelocityIngestProcess } from 'models';
import utils from 'utils';

import IngestProcessDetailView from './IngestProcessDetailView';
import IngestProcessesListView, {
  PartialReconVelocityIngestProcess,
} from './IngestProcessListView';

import './IngestProcessView.scss';

interface IngestProcessViewProps {
  orgId: Organization['id'];
  fetchOnMount: boolean;
}

const IngestProcessView: FC<IngestProcessViewProps> = ({
  orgId,
  fetchOnMount,
}) => {
  const { isAlertOpen, alertMessage, openAlert, closeAlert, variant } =
    useAlert();
  const { data: stepsData, isLoading } = useReconVelocityWorkflowSteps(orgId);
  const { data: ingestProcessesData } = useIngestProcesses(orgId, {
    enabled: fetchOnMount,
  });
  const { updateBeginningStep } = useUpdateBeginningStep(orgId);

  const steps = stepsData?.data;
  const ingestProcesses = ingestProcessesData?.data;

  const filteredSteps = useMemo(
    () =>
      steps?.filter((step) => {
        const childSteps = utils.workflow.getChildSteps(step.id, steps);
        return !childSteps?.length;
      }),
    [steps]
  );

  const emptyIngestProcess = useMemo<ReconVelocityIngestProcess>(() => {
    const emptyMatchCriteria = { columnName: '', regexPattern: '' };
    return {
      stepTargetId: '',
      stepTargetName: '',
      tenantId: orgId,
      matchCriteriaForIngest: [
        { ...emptyMatchCriteria },
        { ...emptyMatchCriteria },
        { ...emptyMatchCriteria },
        { ...emptyMatchCriteria },
        { ...emptyMatchCriteria },
      ],
    };
  }, [orgId]);

  const [selectedIngestProcess, setSelectedIngestProcess] =
    useState<ReconVelocityIngestProcess>(emptyIngestProcess);

  const selectedStep = useMemo(
    () =>
      filteredSteps?.find(
        (step) => step.id === selectedIngestProcess.stepTargetId
      ),
    [filteredSteps, selectedIngestProcess.stepTargetId]
  );

  // Set the first step as the default targeted step once the list of steps is fetched,
  // or after the previously selected step is deleted.
  useEffect(() => {
    if (selectedIngestProcess.stepTargetId && selectedStep) {
      return;
    }

    const defaultStepId = filteredSteps?.[0]?.id ?? '';
    const defaultStepName = filteredSteps?.[0]?.name ?? '';

    const ingestProcessForDefaultStep = ingestProcesses?.find(
      (ingestProcess) => ingestProcess.stepTargetId === defaultStepId
    );

    if (ingestProcessForDefaultStep) {
      setSelectedIngestProcess(ingestProcessForDefaultStep);
    } else {
      setSelectedIngestProcess({
        ...emptyIngestProcess,
        stepTargetId: defaultStepId,
        stepTargetName: defaultStepName,
      });
    }
  }, [
    emptyIngestProcess,
    filteredSteps,
    ingestProcesses,
    selectedIngestProcess.stepTargetId,
    selectedStep,
  ]);

  // Update or remove the data for the selected ingest process when the list of ingest processes is updated.
  // e.g. after saving or deleting a configuration.
  useEffect(() => {
    if (!ingestProcesses) {
      return;
    }

    setSelectedIngestProcess((prevSelectedIngestProcess) => {
      const updatedIngestProcess = ingestProcesses.find(
        (item) => item.stepTargetId === prevSelectedIngestProcess.stepTargetId
      );

      if (!updatedIngestProcess) {
        return {
          ...emptyIngestProcess,
          stepTargetId: prevSelectedIngestProcess.stepTargetId,
          stepTargetName: prevSelectedIngestProcess.stepTargetName,
        };
      }

      return updatedIngestProcess;
    });
  }, [emptyIngestProcess, ingestProcesses]);

  const { deleteIngestProcessAsync } = useDeleteIngestProcess(orgId);
  const { createIngestProcessAsync } = useCreateIngestProcess(orgId);
  const { updateIngestProcessAsync } = useUpdateIngestProcess(
    orgId,
    selectedIngestProcess._id
  );

  useEffect(() => {
    const MATCH_CRITERIA_MAX_LENGTH = 5;
    if (
      selectedIngestProcess.matchCriteriaForIngest.length <
      MATCH_CRITERIA_MAX_LENGTH
    ) {
      const existingCriteria = selectedIngestProcess.matchCriteriaForIngest
        ? selectedIngestProcess.matchCriteriaForIngest.length
        : 0;
      for (let i = existingCriteria; i < MATCH_CRITERIA_MAX_LENGTH; i++) {
        selectedIngestProcess.matchCriteriaForIngest.push({
          columnName: '',
          regexPattern: '',
        });
      }
    }
  }, [selectedIngestProcess]);

  const setBeginningStep = async () => {
    if (!selectedStep || !selectedStep.id) {
      return;
    }

    return updateBeginningStep({
      stepId: selectedStep.id,
    });
  };

  const saveIngestionProcessConfig = async (
    ingestProcess: ReconVelocityIngestProcess
  ) => {
    if (ingestProcess._id) {
      try {
        await updateIngestProcessAsync(ingestProcess);
      } catch (error) {
        console.error(error);
        openAlert(
          'There was an API error while updating the ingest process',
          'error'
        );
      }
    } else {
      try {
        await createIngestProcessAsync(ingestProcess);
      } catch (error) {
        console.log(error);
        openAlert(
          'There was an API error while creating the ingest process',
          'error'
        );
      }
    }
  };

  const onSelectIngestProcess = (
    ingestProcess:
      | ReconVelocityIngestProcess
      | PartialReconVelocityIngestProcess
  ) => {
    const selectedIngestProcess = {
      ...emptyIngestProcess,
      ...ingestProcess,
    };
    setSelectedIngestProcess(selectedIngestProcess);
  };

  const deleteIngestionProcessConfig = async (
    ingestProcess: ReconVelocityIngestProcess
  ) => {
    try {
      if (!ingestProcess._id) {
        throw new Error('Ingest Process ID is not defined.');
      }

      await deleteIngestProcessAsync(ingestProcess._id);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="IngestProcesses full-height flex-columns">
      <div className="flex-grow">
        {isLoading || !filteredSteps || !ingestProcesses || !selectedStep ? (
          <LoadingIndicator />
        ) : (
          <div className="IngestProcessDetailView full-height flex-rows">
            <div className="flex-columns flex-grow">
              <div className="IngestProcessDetailView__list">
                <IngestProcessesListView
                  stepsList={filteredSteps}
                  ingestProcesses={ingestProcesses}
                  selectedIngestProcess={selectedIngestProcess}
                  onSelectIngestProcess={onSelectIngestProcess}
                />
              </div>
              <div className="flex-grow">
                <IngestProcessDetailView
                  isBeginningStep={selectedStep.beginningStep}
                  ingestProcess={selectedIngestProcess}
                  onSaveIngestionProcessConfig={saveIngestionProcessConfig}
                  onDeleteIngestionProcessConfig={deleteIngestionProcessConfig}
                  onSetBeginningStep={setBeginningStep}
                />
                <Alert
                  open={isAlertOpen}
                  message={alertMessage}
                  onClose={closeAlert}
                  duration={3500}
                  variant={variant}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default IngestProcessView;
