/* eslint-disable import/no-anonymous-default-export */
export default {
  ADD: 'Add',
  YES: 'Yes',
  NO: 'No',
  LOGIN_SOURCE: 'Velocity Recon Admin',

  // Header tabs
  ORGANIZATIONS: 'Organizations',
  USERS: 'Users',
  HEALTH: 'Health',
  SETTINGS: 'Settings',
  SETUP: 'Setup',

  // Loading
  LOADING: 'Loading',
  LOADING_WITH_ELLIPSES: 'Loading...',
  EMPTY_VALUE: '--',

  // Errors
  GENERIC_SAVE_ERROR:
    'An error occurred when trying to save these changes to the server.',

  APPLICATION_DELETE_PRIMARY_WARNING:
    'SUPER DANGEROUS, ONLY DO THIS IF YOU ARE A GOLDEN GOD',
  APPLICATION_DELETE_PRIMARY_CONFIRM_LABEL: 'Delete it.',
  APPLICATION_DELETE_SECONDARY_WARNING:
    'Wait, but are you really sure, though?',
  APPLICATION_DELETE_SECONDARY_CONFIRM_LABEL: 'I am a GOLDEN GOD',
  APPLICATION_DELETE_FINAL_PROMPT:
    "Okay, well if you're REALLY sure... Type the application name to confirm.",
  FEATURE_FLAG_DESCRIPTION_PLACEHOLDER:
    'Enter a description for the feature flag...',
  FEATURE_FLAG_DELETE_CONFIRMATION:
    'Are you sure you want to delete this feature flag?',
  FEATURE_FLAG_DELETE_CONFIRM_LABEL: 'Yes, delete it.',

  // Velocity Engage Folios
  ADD_MEDIA: 'Add Media',
  ARE_YOU_SURE: 'Are You Sure?',
  API_MESSAGE: 'There was an API error. Please try again',
  CANCEL: 'Cancel',
  COMMUNITCATION_LINKS: 'Communication Links',
  CONTENT_SETTINGS: 'Content Settings',
  DELETE: 'Delete',
  EDIT_MEDIA: 'Edit Media',
  FEATURED: 'Featured',
  VEHICLE_SPECIFICATIONS: 'Vehicle Specifications',
  SIMILAR_VEHICLES: 'Similar Vehicles',
  NO_DOCUMENT_URL: 'This document does not have an associated media url',
  NO_ENGAGE_MEDIA_CONTAINER: (containerTitle: string) =>
    `No ${containerTitle} media`,
  PRICE_SETTINGS: 'Price Settings',
  SAVE: 'Save',
  SHARED_INVENTORY: 'Shared inventory',
  TOO_MANY_FEATURED_DOCS: 'You can only feature 4 documents',
  UNSAVED_CHANGES: 'Unsaved Changes',
  UNSAVED_CHANGES_DIALOG:
    'You have unsaved changes on this page. Do you wish to save them before proceeding?',
  DEALER_LOGO_URL: 'URL must begin with https://',

  // General Plugins
  NO_CATEGORY_PLUGINS: (header: string) => {
    if (header === 'Modules') {
      return 'Please add a module';
    }
    if (header === 'Data Providers') {
      return 'Please add a module before adding a data provider';
    } else {
      return 'Please add an inventory Data Provider before adding an enhancer';
    }
  },

  INVENTORY_INGESTION_DATE_PLUGINS: (
    ingestFileReceivedDate: string,
    ingestFileVehicleCount: number,
    dealerId: string
  ) => {
    if (dealerId) {
      if (ingestFileReceivedDate && ingestFileVehicleCount) {
        return `Last updated: ${ingestFileReceivedDate} EDT. Total Vehicles: ${ingestFileVehicleCount}`;
      } else {
        if (ingestFileVehicleCount && !ingestFileReceivedDate) {
          return `Total Vehicles: ${ingestFileVehicleCount} in last update`;
        }
        if (ingestFileReceivedDate && !ingestFileVehicleCount) {
          return `Last updated: ${ingestFileReceivedDate} EDT. Total Vehicles: 0`;
        } else {
          return `We have not received any vehicle information`;
        }
      }
    }
  },
  INVENTORY_TAB_TITLE: 'Inventory',
  REPAIR_ORDERS_TAB_TITLE: 'Repair',
  SALES_TAB_TITLE: 'Sales',

  // Recon Velocity Plugin
  RECON_MODE: 'Recon Mode',
  ALL: 'All',
  NEW: 'New',
  USED: 'Used',
  ODOMETER_UNITS: 'Odometer Units',
  MILES: 'Miles',
  KILOMETERS: 'Kilometers',
  CURRENCY: 'Currency',
  USD: 'USD',
  CAD: 'CAD',
  DEALERSHIP_COSTS: 'Dealership Costs',
  WHOLESALE_UNITS: 'Wholesale Units',
  INCLUDE_WHOLESALE_UNITS: 'Include Wholesale Units From Import',
  EXCLUDE_WHOLESALE_UNITS: 'Exclude Wholesale Units From Import',
  EXCLUDE_WHOLESALE_UNITS_REMOVE_FROM_RECON:
    'Exclude Wholesale Units From Import and Remove From Recon',
  // Assignable Users
  NO_ASSIGNABLE_USERS:
    'Add specific users to allow this step to be assigned to them. If no users are specifically added to this step, all available users will be assignable to this step.',
  ADD_USERS: 'Add Users',
  ADD_USER: 'Add User',
  ASSIGN_STEP_USERS_DIALOG_TITLE: (stepName: string) =>
    `Add Users to ${stepName} Step`,

  // Shared Steps
  SHARED_STEPS: 'Shared Steps',
  ADD_SHARED_STEP: 'Add Shared Step',

  // Bulk User Upload
  USERNAME_ERROR: 'Username already exists',

  // Show snapcell videos
  SHOW_SNAPCELL_VIDEOS: 'Show snapcell videos',

  // Start Recon Step
  BEGINNING_STEP: 'Default Beginning Recon Step',
};
