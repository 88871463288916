import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

interface ConfirmationDialogProps {
  openState: boolean;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
  titleText: string;
  bodyText: string;
}

var ConfirmationDialog = ({
  openState,
  onCancel,
  onConfirm,
  titleText,
  bodyText,
}: ConfirmationDialogProps) => {
  return (
    <Dialog
      open={openState}
      onClose={onCancel}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
    >
      <DialogTitle id="confirmation-dialog-title">{titleText}</DialogTitle>
      <DialogContent>
        <DialogContentText>{bodyText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary">
          No
        </Button>
        <Button onClick={onConfirm} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
