import React from 'react';

interface TimeInputProps {
  placeholder: string;
  value: number;
  onChange: (value: number) => void;
  maxLength?: number;
}

var TimeInput = ({
  placeholder,
  value,
  onChange,
  maxLength = 2,
}: TimeInputProps) => {
  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!new RegExp('[0-9]').test(event.key) || event.key.length === 0) {
      event.preventDefault();
    }
  };

  return (
    <input
      placeholder={placeholder}
      type="text"
      maxLength={maxLength}
      value={value}
      onKeyPress={onKeyPress}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        onChange(Number(event.target.value))
      }
      required
    />
  );
};

export default TimeInput;
