import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { Organization, ReconVelocityIngestProcess } from '../../../../models';
import {
  APIResponse,
  defaultMetaQueryFn,
  defaultMutationFn,
} from '../../../useAPI';

/**
 * Get ingest processes path
 * @param orgId - Organization ID
 * @returns path to get all ingest process for a given organization
 */
export const getIngestProcessesPath = (orgId: Organization['id']) =>
  `/orgs/${orgId}/configurations`;

/**
 * Get single ingest Process path
 * @param orgId - Organization ID
 * @param ingestProcessId - ingest process ID
 * @returns path to a given ingest process identified by id
 */
const getIngestProcessPath = (
  orgId: Organization['id'],
  ingestProcessId: ReconVelocityIngestProcess['_id']
) => `${getIngestProcessesPath(orgId)}/${ingestProcessId}`;

/**
 * Get Ingest Process for a given Organization
 * Endpoint: GET `/orgs/{orgId}/configurations`
 * @param orgId - Organization ID
 * @param options? - Query Options
 * @returns Full linear Recon Velocity Ingest process list
 */
export function useIngestProcesses(
  orgId: Organization['id'],
  options?: UseQueryOptions<APIResponse<ReconVelocityIngestProcess[]>>
) {
  const path = getIngestProcessesPath(orgId);

  return useQuery<APIResponse<ReconVelocityIngestProcess[]>>(
    [path],
    () => defaultMetaQueryFn(path),
    options
  );
}

/**
 * Creates a new ingest process
 * @param orgId - Organization ID
 * @param ingestProcess - ingest process
 * @returns newly created ingest process
 */
export function useCreateIngestProcess(orgId: Organization['id']) {
  const queryClient = useQueryClient();
  const mutation = useMutation((data) => {
    const path = getIngestProcessesPath(orgId);
    return defaultMutationFn(path, 'POST', data);
  });

  async function createIngestProcessAsync(ingestProcess: any) {
    await queryClient.cancelQueries(['configurations']);
    const response = await mutation.mutateAsync(ingestProcess);
    await queryClient.invalidateQueries([getIngestProcessesPath(orgId)]);
    return response;
  }

  return {
    ...mutation,
    createIngestProcessAsync,
  };
}

/**
 * Deletes a given Ingest Process
 * @param orgId - Organization ID
 * @param ingestProcessId - Ingest Process ID
 * @returns boolean indicating deletion success or failure
 */
export function useDeleteIngestProcess(orgId: Organization['id']) {
  const queryClient = useQueryClient();
  const mutation = useMutation((ingestProcessId: any) => {
    const path = getIngestProcessPath(orgId, ingestProcessId);
    return defaultMutationFn(path, 'DELETE');
  });

  async function deleteIngestProcessAsync(ingestProcessId: any) {
    await queryClient.cancelQueries(['configurations']);
    const response = await mutation.mutateAsync(ingestProcessId);
    await queryClient.invalidateQueries([getIngestProcessesPath(orgId)]);
    return response;
  }

  return {
    ...mutation,
    deleteIngestProcessAsync,
  };
}

/**
 * Updates an ingest Process
 * @param orgId - Organization ID
 * @param ingestProcessId - Ingest Process ID
 * @returns updated ingest process configuration
 */
export function useUpdateIngestProcess(
  orgId: Organization['id'],
  ingestProcessId: ReconVelocityIngestProcess['_id']
) {
  const queryClient = useQueryClient();
  const mutation = useMutation((ingestProcess) => {
    const path = getIngestProcessPath(orgId, ingestProcessId);
    return defaultMutationFn(path, 'PUT', ingestProcess);
  });

  async function updateIngestProcessAsync(ingestProcess: any) {
    await queryClient.cancelQueries(['configurations']);
    const response = await mutation.mutateAsync(ingestProcess);
    await queryClient.invalidateQueries([getIngestProcessesPath(orgId)]);
    return response;
  }

  return {
    ...mutation,
    updateIngestProcessAsync,
  };
}
