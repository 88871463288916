import { ReconVelocityPluginItem } from 'models/plugins/reconVelocityPlugin';
import {
  getReconVelocityPluginActionTypes,
  ReconVelocityPluginActionTypes,
  updateReconVelocityPluginActionTypes,
} from 'store/actions/organizations/plugins/reconvelocity';
import { FetchedData } from 'store/reducers/types';

type ReconVelocityGeneralState = FetchedData<
  ReconVelocityPluginItem | undefined
>;

export const initialState: ReconVelocityGeneralState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export default function generalReducer(
  state = initialState,
  action: ReconVelocityPluginActionTypes
): ReconVelocityGeneralState {
  switch (action.type) {
    case getReconVelocityPluginActionTypes.pending: {
      return {
        ...state,
        loading: true,
      };
    }
    case getReconVelocityPluginActionTypes.fulfilled: {
      const { data } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        data,
        error: undefined,
      };
    }
    case getReconVelocityPluginActionTypes.rejected: {
      const { error } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        error,
      };
    }

    case updateReconVelocityPluginActionTypes.pending: {
      return {
        ...state,
        loading: true,
      };
    }
    case updateReconVelocityPluginActionTypes.fulfilled: {
      const { data } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        data,
        error: undefined,
      };
    }
    case updateReconVelocityPluginActionTypes.rejected: {
      const { error } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        error,
      };
    }

    default: {
      return state;
    }
  }
}
