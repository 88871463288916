import { Button, Card, CardContent, CardHeader } from '@material-ui/core';
import { Backup, CloudUpload, Done, Warning } from '@material-ui/icons';
import axios from 'axios';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import {
  usePresignedUploadUrl,
  useSaveBrochureUrl,
} from 'api/settings/brochures';
import theme from 'common/theme';
import LoadingIndicator from 'components/shared/LoadingIndicator';

import { BrochureManagerContext } from './brochureContext';
import { useBrochureStyles } from './materialStyles';

// @todo - this entire component is a WIP
export const RVInlineDebug = ({ children }: { children: ReactNode }) => {
  if (process.env.NODE_ENV !== 'development') return null;
  const styles = { fontSize: 12 };
  return (
    <Card style={styles}>
      <CardHeader>Debug Info</CardHeader>
      <CardContent>
        <pre>{children}</pre>
      </CardContent>
    </Card>
  );
};

export const BrochureUploader = () => {
  const classes = useBrochureStyles(theme);
  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState<
    'idle' | 'loading' | 'error' | 'complete' | 'ready'
  >('idle');

  const { state } = useContext(BrochureManagerContext);
  const [currentFile, setFile] = useState<any>();

  const { vehicleYear, vehicleMake, vehicleModel, hasBrochure, uri } = state;

  const currentModelName = `${vehicleYear} ${vehicleMake} ${vehicleModel}`;
  const uploadFilename = `${vehicleMake}_US ${vehicleModel}_${vehicleYear}.pdf`;

  const presignedUrl = usePresignedUploadUrl(
    { year: vehicleYear, make: vehicleMake, model: vehicleModel },
    uploadFile
  );

  const { updateBrochureUrl } = useSaveBrochureUrl({
    year: vehicleYear,
    make: vehicleMake,
    model: vehicleModel,
  });

  useEffect(() => {
    setStatus('idle');
  }, [vehicleYear]);

  async function uploadFile(url: string) {
    if (url) {
      const response = await axios.put(url, currentFile);
      if (response.status !== 200) {
        if (response.status === 403) {
          throw new Error('Presigned URL was invalid');
        }
        throw new Error('Generic error');
      }
      await updateBrochureUrl();
      setStatus('complete');
    } else {
      throw new Error('Unable to get presigned upload URL');
    }
  }

  async function handleUploadClick() {
    setLoading(true);
    setStatus('loading');

    try {
      await presignedUrl.refetch({ throwOnError: true });
    } catch (error) {
      console.log('Ran into a problem', error);
      setStatus('error');
    }

    setLoading(false);
  }

  const DropArea = () => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept: 'application/pdf',
      onDropAccepted: (files) => {
        setStatus('ready');
        console.log(`Drop accepted for ${files.length} items`);
      },
      onDropRejected: () => {
        setStatus('error');
      },
      onDrop: (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (!file) return;
        const preparedFileName = uploadFilename;
        const preparedFile = new File([file], preparedFileName, {
          type: file.type,
        });

        console.log(acceptedFiles[0], preparedFile);
        setFile(preparedFile);
      },
    });
    return (
      <div
        {...getRootProps()}
        className={isDragActive ? classes.dropAreaActive : classes.dropArea}
      >
        <div>
          {status === 'error' && <Warning className={classes.uploadIcon} />}
          {status === 'idle' && <CloudUpload className={classes.uploadIcon} />}
          {status === 'ready' && <CloudUpload className={classes.uploadIcon} />}
          {status === 'complete' && <Done className={classes.uploadIcon} />}
          {status === 'loading' && <LoadingIndicator />}
        </div>
        <input {...getInputProps()} />
        <span className={classes.uploadTitle}>Upload a brochure.</span>
        <div className={classes.uploadText}>
          {status === 'error' && (
            <Button variant="outlined">
              Unable to upload, please try again
            </Button>
          )}
          {status === 'idle' && (
            <Button variant="outlined">
              Select or drop brochure file (PDF)
            </Button>
          )}
          {status === 'ready' && <p>Ready to upload {currentFile?.name}</p>}
          {status === 'complete' && (
            <p>Finished uploading {currentFile?.name}</p>
          )}
          {status === 'loading' && <p>Uploading {uploadFilename}</p>}
        </div>
      </div>
    );
  };

  const DropControls = () => {
    return (
      <div>
        <Button
          className={classes.buttonRow}
          href={uri}
          disabled={!hasBrochure || isLoading}
          color="secondary"
          variant="text"
        >
          {hasBrochure ? 'View Brochure' : 'No Brochure Found'}
        </Button>
        <Button
          className={classes.buttonRow}
          disabled={status !== 'ready'}
          startIcon={
            hasBrochure ? (
              <Warning style={{ color: theme.palette.common.white }} />
            ) : (
              <Backup style={{ color: theme.palette.common.white }} />
            )
          }
          color="secondary"
          style={{
            color: theme.palette.common.white,
          }}
          onClick={handleUploadClick}
          variant="contained"
        >
          {hasBrochure ? 'Upload Replacement Brochure' : 'Upload Brochure'}
        </Button>
        <div className={classes.helperText}>
          {status === 'ready' && (
            <>
              {hasBrochure &&
                ' This will replace the current brochure permanently.'}
            </>
          )}
          {status === 'error' && (
            <p>There was a problem uploading this file.</p>
          )}
          {status === 'loading' && (
            <p>Uploading {currentModelName} brochure...</p>
          )}
          {status === 'complete' && (
            <p>Succesfully uploaded {currentModelName}</p>
          )}
        </div>
        <p>WARNING: Changes are permanent and will be live immediately!</p>
      </div>
    );
  };

  return (
    <div className={classes.uploadContainer}>
      <DropArea />
      <DropControls />
    </div>
  );
};

export default { BrochureUploader };
