import { Organization } from 'models';
import {
  getOrganizationOverviewActionTypes,
  OrganizationOverviewAction,
  updateOrganizationOverviewActionTypes,
} from 'store/actions/organizations';
import { MutableFetchedData } from 'store/reducers/types';

type OrganizationOverviewState = MutableFetchedData<Organization | undefined>;

const initialState: OrganizationOverviewState = {
  loading: false,
  data: undefined,
  error: undefined,
  adding: false,
  deleting: false,
  updating: false,
};

export default function organizationOverviewReducer(
  state = initialState,
  action: OrganizationOverviewAction
): OrganizationOverviewState {
  switch (action.type) {
    case getOrganizationOverviewActionTypes.pending: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
    case getOrganizationOverviewActionTypes.fulfilled: {
      const { data = state.data } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        data,
        error: undefined,
      };
    }
    case getOrganizationOverviewActionTypes.rejected: {
      const { error } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case updateOrganizationOverviewActionTypes.pending: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
    case updateOrganizationOverviewActionTypes.fulfilled: {
      const { data = state.data } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        data,
        error: undefined,
      };
    }
    case updateOrganizationOverviewActionTypes.rejected: {
      const { error } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        error,
      };
    }
    default:
      return state;
  }
}
