import CollectionHeaderBar from 'components/shared/CollectionHeaderBar';
import Table from 'components/shared/Table';
import { PermissionItem } from 'models';

interface PermissionsViewProps {
  permissions: PermissionItem[];
}

var PermissionsView = ({ permissions }: PermissionsViewProps) => {
  return (
    <div className="full-height flex-rows">
      <CollectionHeaderBar count={permissions.length} unit="Permission" />
      <div className="flex-grow">
        <Table
          items={permissions}
          showLoadingSkeletons
          columns={[
            {
              heading: 'Application',
              dataKey: 'applicationName',
              flexGrow: 1,
            },
            {
              heading: 'Category',
              dataKey: 'category',
              flexGrow: 1,
            },
            {
              heading: 'Permission Name',
              dataKey: 'name',
              flexGrow: 4,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default PermissionsView;
