import { FC } from 'react';
import { AutoSizer, Table, TableRowProps } from 'react-virtualized';

import CollectionHeaderBar from 'components/shared/CollectionHeaderBar';
import { ReconVelocityIngestProcess, ReconVelocityWorkflowStep } from 'models';

import './IngestProcessListView.scss';

export type PartialReconVelocityIngestProcess = Pick<
  ReconVelocityIngestProcess,
  'stepTargetId' | 'stepTargetName'
>;

interface IngestProcessesListViewProps {
  ingestProcesses: ReconVelocityIngestProcess[];
  selectedIngestProcess: ReconVelocityIngestProcess;
  stepsList: ReconVelocityWorkflowStep[];
  onSelectIngestProcess: (
    selectedIngestProcess:
      | ReconVelocityIngestProcess
      | PartialReconVelocityIngestProcess
  ) => void;
}

const IngestProcessesListView: FC<IngestProcessesListViewProps> = ({
  ingestProcesses,
  selectedIngestProcess,
  stepsList,
  onSelectIngestProcess,
}) => {
  const ingestProcessesRowRenderer = ({ index, style }: TableRowProps) => {
    const stepItem = stepsList[index];
    const isBeginningStep = stepItem.beginningStep;
    const ingestProcess = ingestProcesses.find(
      (item) => item.stepTargetId === stepItem.id
    );

    const handleStepClick = () => {
      if (!ingestProcess) {
        const newIngestProcess: PartialReconVelocityIngestProcess = {
          stepTargetId: stepItem.id ?? '',
          stepTargetName: stepItem.name,
        };
        onSelectIngestProcess(newIngestProcess);
        return;
      }

      onSelectIngestProcess(ingestProcess);
    };

    const containerClassList = [
      'ReactVirtualized__Table__row',
      'Table__row IngestProcessListView__listItem',
    ];
    if (isBeginningStep) {
      containerClassList.push('beginningStep');
    }
    if (ingestProcess || isBeginningStep) {
      containerClassList.push('targeted');
    }
    if (selectedIngestProcess.stepTargetId === stepItem.id) {
      containerClassList.push('selected');
    }

    const containerClassName = containerClassList.join(' ');

    return (
      <div
        key={stepItem.id}
        id={`step-${stepItem.id}`}
        className={containerClassName}
        style={style}
        role="button"
        tabIndex={0}
      >
        <p
          className="IngestProcessListView__listItem__tag truncate"
          onClick={handleStepClick}
        >
          {stepItem.name}
        </p>
      </div>
    );
  };

  const ingestProcessesCount = ingestProcesses.length;
  const beginningStep = stepsList.find((step) => step.beginningStep);
  const beginningStepHasIngestProcessConfig = ingestProcesses.some(
    (ingestProcess) => ingestProcess.stepTargetId === beginningStep?.id
  );

  const targetedStepsCount =
    ingestProcessesCount +
    (beginningStep && !beginningStepHasIngestProcessConfig ? 1 : 0);

  return (
    <div className="IngestProcessListView full-height">
      <CollectionHeaderBar count={targetedStepsCount} unit="Targeted Steps" />
      <AutoSizer>
        {({ width, height }) => (
          <Table
            disableHeader
            width={width}
            height={height - 30}
            headerHeight={0}
            rowCount={stepsList.length}
            rowHeight={30}
            items={stepsList}
            rowGetter={({ index }) => stepsList[index]}
            rowRenderer={ingestProcessesRowRenderer}
          />
        )}
      </AutoSizer>
    </div>
  );
};
export default IngestProcessesListView;
