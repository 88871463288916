import pluralize from 'pluralize';
import React from 'react';
import { Form } from 'react-bootstrap';

import { useOrganizationGroups } from 'api/organizations/groups';

interface GroupsListViewProps {
  organizationId: string;
  checkedGroups: string[];
  setCheckedGroups: any;
}

var GroupListView = ({
  checkedGroups,
  organizationId,
  setCheckedGroups,
}: GroupsListViewProps) => {
  const { data: groups } = useOrganizationGroups(organizationId);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const groupId = event.target.value;
    const index = checkedGroups.findIndex((gId) => gId === groupId);

    if (index > -1) {
      checkedGroups.splice(index, 1);
    } else {
      checkedGroups.push(groupId);
    }
    setCheckedGroups([...checkedGroups]);
  };

  const displayText = `${pluralize('Group', groups?.length, true)}, ${
    checkedGroups.length
  } checked`;

  return (
    <div className="full-height flex-rows" style={{ width: 400 }}>
      <div className="CollectionHeaderBar">
        <div className="CollectionHeaderBar-text">{displayText}</div>
      </div>
      <div className="flex-grow full-width" id="groups-collection-container">
        <div className=" align-center tab-content">
          <div className="align-left">
            {groups && groups?.length > 0
              ? groups.map((group) => {
                  const checked = checkedGroups.find(
                    (groupId) => groupId === group.id
                  );

                  return (
                    <div key={group.id}>
                      <Form.Check
                        id={`UserOrganizationDetailView.groups.${group.id}`}
                        type="checkbox"
                        name="UserOrganizationDetailView.groups"
                        label={group.name}
                        value={group.id}
                        onChange={handleCheckboxChange}
                        checked={!!checked}
                      />
                    </div>
                  );
                })
              : 'This organization does not have any groups.'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupListView;
