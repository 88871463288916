import { useEffect, useState } from 'react';
import CreateableSelect from 'react-select/creatable';

import { useAssignTags, useOrganizationTags } from 'api/organizations/tags';
import Dialog from 'components/shared/Dialog';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { Organization } from 'models';
import { Tag } from 'models/organization';

import CreateTagsDialog from '../CreateTagsDialog';
import TagChip from '../TagChip';

import './EditTagsDialog.scss';

interface EditTagsDialogProps {
  open: boolean;
  tags?: Tag[];
  onClose: () => void;
  orgId: Organization['id'];
}

var EditTagsDialog = ({ tags, open, onClose, orgId }: EditTagsDialogProps) => {
  const { data: orgTags, isLoading } = useOrganizationTags(orgId);
  const { assignTagsAsync } = useAssignTags(orgId);
  const [isCreateTagsDialogOpen, setIsCreateTagsDialogOpen] = useState(false);
  const [newTagLabel, setNewTagLabel] = useState('');
  const [stateTags, setStateTags] = useState(tags || []);

  useEffect(() => {
    setStateTags(tags || []);
  }, [open, tags]);

  const availableTags = orgTags?.data
    .filter(
      (listTag) => !stateTags?.some((stateTag) => stateTag.id === listTag.id)
    )
    .map((availableTag) => ({
      label: availableTag.label,
      value: availableTag.id,
    }));

  return (
    <>
      <Dialog
        open={open}
        primaryButtonLabel="Save"
        className="EditTagsDialog"
        onClose={() => {
          setStateTags([]);
          onClose();
        }}
        onPrimary={async () => {
          const tagIds = stateTags.map((tag) => tag.id);
          await assignTagsAsync(tagIds);
          onClose();
        }}
        maxWidth="xs"
        fullWidth
        title="Edit Tags"
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <div className="EditTagsDialog-tags">
              {stateTags?.map((tag: Tag) => (
                <TagChip
                  key={tag.id}
                  tag={tag}
                  removable
                  onRemove={() => {
                    setStateTags(
                      stateTags.filter((listTag: Tag) => listTag.id !== tag.id)
                    );
                  }}
                />
              ))}
            </div>
            <div className="EditTagsDialog-select-container">
              <CreateableSelect
                className="EditTagsDialog-select"
                options={availableTags}
                placeholder="Add tags..."
                formatCreateLabel={(inputValue: string) =>
                  `Create new tag "${inputValue}"`
                }
                onChange={(selectedTagOption) => {
                  if (selectedTagOption) {
                    const tagToAdd = orgTags?.data.find(
                      (listTag) => listTag.id === selectedTagOption.value
                    );
                    if (tagToAdd) {
                      setStateTags([...stateTags, tagToAdd]);
                    }
                  }
                }}
                controlShouldRenderValue={false}
                closeMenuOnSelect={false}
                onCreateOption={(inputValue) => {
                  setNewTagLabel(inputValue);
                  setIsCreateTagsDialogOpen(true);
                }}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
            </div>
          </>
        )}
      </Dialog>
      {isCreateTagsDialogOpen && (
        <CreateTagsDialog
          orgId={orgId}
          open={isCreateTagsDialogOpen}
          currentLabel={newTagLabel}
          onClose={() => setIsCreateTagsDialogOpen(false)}
          onCreate={(newTag) => {
            setStateTags([...stateTags, newTag]);
          }}
        />
      )}
    </>
  );
};

export default EditTagsDialog;
