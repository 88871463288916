import { configuredFetch } from 'api/base';
import { Organization } from 'models';

function mediaUrl(orgId: string, pluginName: string) {
  return `/orgs/${orgId}/plugins/${pluginName}/media`;
}

/**
 * Create Media
 * Endpoint: POST /orgs/${orgId}/plugins/{pluginName}/media?pss_name={settingName}
 */
export type CreateMediaRequestParams = {
  orgId: Organization['id'];
  image: FormData;
  pluginName: string;
  settingName: string;
};
export async function createMediaBadgeImage({
  orgId,
  image,
  pluginName,
  settingName,
}: CreateMediaRequestParams): Promise<string> {
  const url = `${mediaUrl(orgId, pluginName)}?pss_name=${settingName}`;

  const { data } = await configuredFetch<string>(
    url,
    {
      method: 'post',
      body: image,
    },
    true
  );
  return data;
}

export default {
  createMediaBadgeImage,
};
