export interface FeatureFlagApplication {
  id: string;
  name: string;
  featureFlags: FeatureFlag[];
}

export interface FeatureFlag {
  id: string;
  name: string;
  enabled: boolean;
  description: string;
  deciderType: string;
  deciderData: DeciderData;
}

export enum MemberType {
  USER = 'USER',
  ORGANIZATION = 'ORGANIZATION',
  ORGANIZATION_USER = 'ORGANIZATION_USER',
  SECURITY_GROUP = 'SECURITY_GROUP',
}

export enum DeciderDataMembers {
  allowedOrganizations = 'allowedOrganizations',
  allowedOrganizationUsers = 'allowedOrganizationUsers',
  allowedSecurityGroups = 'allowedSecurityGroups',
  allowedUsers = 'allowedUsers',
}

export type FeatureFlagDeciderDataMembers = {
  [key in MemberType]: DeciderDataMembers;
};

export const FeatureFlagDeciderDataMembersMappers: FeatureFlagDeciderDataMembers =
  {
    [MemberType.USER]: DeciderDataMembers.allowedUsers,
    [MemberType.ORGANIZATION]: DeciderDataMembers.allowedOrganizations,
    [MemberType.ORGANIZATION_USER]: DeciderDataMembers.allowedOrganizationUsers,
    [MemberType.SECURITY_GROUP]: DeciderDataMembers.allowedUsers,
  };

export type DeciderData = {
  [key in DeciderDataMembers]: string[];
};

export interface FeatureFlagMember {
  memberId: string;
  memberType: MemberType;
  displayName: string;
}

export interface FeatureFlagMemberTypes {
  organizationMembers: FeatureFlagMember[];
  userMembers: FeatureFlagMember[];
}

export interface VehicleMakeData {
  vehicleMake: string;
  vehicleModels: VehicleModelData[];
}
export interface VehicleModelData {
  vehicleModel: string;
  vehicleYears: VehicleBrochureYear[];
}
export interface VehicleBrochureYear {
  vehicleYear: string;
  uri: string;
}
