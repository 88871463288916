import { useState } from 'react';

import TeamDetails from './TeamDetails';
import TeamsList from './TeamsList';

import './Teams.scss';

type TeamsProps = {
  orgId: string;
};

const Teams = ({ orgId }: TeamsProps) => {
  const [selectedTeamId, setSelectedTeamId] = useState<string | undefined>(
    undefined
  );

  return (
    <div className="teams-container">
      <div className="teams-side-bar">
        <TeamsList
          orgId={orgId}
          selectedTeamId={selectedTeamId}
          onTeamClick={setSelectedTeamId}
        />
      </div>
      <TeamDetails orgId={orgId} selectedTeamId={selectedTeamId} />
    </div>
  );
};

export default Teams;
