import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core';
import { ChangeEvent, useEffect, useState } from 'react';
import { AutoSizer } from 'react-virtualized';
import { FixedSizeList } from 'react-window';

import {
  useOrganizationsFlatRooftops,
  useUpdateVendorOrganizations,
  useVendorOrganizations,
} from 'api';

import './OrganizationModal.scss';

const SEARCH_DELAY_MS = 400;
const ROW_HEIGHT = 30;

interface OrganizationModalProps {
  open: boolean;
  onClose: () => void;
  organizationId: string;
}

const OrganizationModal = ({
  open,
  onClose,
  organizationId,
}: OrganizationModalProps) => {
  const { data: vendorOrganizations } = useVendorOrganizations(organizationId);
  const { mutate: updateVendorOrganizations } =
    useUpdateVendorOrganizations(organizationId);

  const [filterText, setFilterText] = useState('');
  const [searchQuery, setSearchQuery] = useState(filterText);
  const { data: rooftopsData } = useOrganizationsFlatRooftops(searchQuery);
  const [checkedOrganizations, setCheckedOrganizations] = useState<any>({});

  useEffect(() => {
    const vendorIds: any = {};
    vendorOrganizations?.data.forEach((vendorOrganization) => {
      vendorIds[vendorOrganization.id] = true;
    });
    setCheckedOrganizations({
      ...checkedOrganizations,
      ...vendorIds,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorOrganizations?.data]);

  useEffect(() => {
    const id = setTimeout(() => {
      setSearchQuery(filterText);
    }, SEARCH_DELAY_MS);

    return () => {
      clearTimeout(id);
    };
  }, [filterText]);

  const handleCheckboxSelect = (rooftopId: string, checked: boolean) => {
    setCheckedOrganizations({
      ...checkedOrganizations,
      [rooftopId]: checked,
    });
  };

  const handleOnClose = () => {
    const ids: string[] = [];
    Object.keys(checkedOrganizations).forEach((key) => {
      ids.push(key);
    });

    updateVendorOrganizations(ids);
    onClose();
  };

  const { data: rooftops } = rooftopsData ?? {};

  const renderRow = (props: any) => {
    const { index, style } = props;
    const rooftop = rooftops?.[index];
    const rooftopId = rooftop?.id ?? '';
    const rooftopName = rooftop?.name;

    return (
      <ListItem style={style} key={rooftopId}>
        <Checkbox
          checked={checkedOrganizations[rooftopId]}
          onChange={(event) =>
            handleCheckboxSelect(rooftopId, event.target.checked)
          }
        />
        <ListItemText primary={rooftopName} />
      </ListItem>
    );
  };

  const rooftopCount = rooftops?.length ?? 0;

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      title="Associate Organization"
      className="VendorOrganizations_modal_dialog"
      scroll="paper"
    >
      <DialogTitle id="scroll-dialog-title">
        <div className="VendorOrganizations_modal_header">
          <div id="organization-count"> Organizations</div>
          <TextField
            value={filterText}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setFilterText(event.target.value);
            }}
            variant="outlined"
            size="small"
            placeholder="Filter"
          />
        </div>
      </DialogTitle>

      <DialogContent dividers>
        <div className="VendorOrganizations_modal_container">
          <div className="VendorOrganizations_modal_rooftops">
            <AutoSizer>
              {({ height, width }) => (
                <FixedSizeList
                  itemCount={rooftopCount}
                  height={height}
                  width={width}
                  itemSize={ROW_HEIGHT}
                >
                  {renderRow}
                </FixedSizeList>
              )}
            </AutoSizer>
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          style={{ color: 'white' }}
          variant="contained"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          style={{ color: 'white' }}
          variant="contained"
          onClick={handleOnClose}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrganizationModal;
