import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

import { ScheduleType, ScheduleWeek } from './common';

export type NotificationItemCategory =
  | 'RECONVELOCITY'
  | 'TASKS'
  | 'VEHICLE'
  | 'UNKNOWN';

export interface NotificationItem {
  id: string;
  eventName: string;
  title: string;
  description: string;
  category: NotificationItemCategory;
}

export enum NotificationEventCategoryType {
  RECONVELOCITY = 'RECONVELOCITY',
  TASKS = 'TASKS',
  VEHICLE = 'VEHICLE',
}

export enum NotificationChannelType {
  EMAIL_TRANS = 'EMAIL_TRANS',
  MOBILE_SMS = 'MOBILE_SMS',
  MOBILE_PUSH = 'MOBILE_PUSH',
  WEB_PUSH = 'WEB_PUSH',
  SLACK = 'SLACK',
  EMAIL_BULK = 'EMAIL_BULK',
  WEB_HOOK = 'WEB_HOOK',
  PERSIST = 'PERSIST',
}

export interface NotificationChannel {
  id: string;
  name: string;
  description: string;
  type: NotificationChannelType;
  schedule: ScheduleWeek;
}

export interface NotificationEvent {
  id: string;
  name: string;
  title: string;
  description: string;
  isStepEvent: boolean;
}

export interface NotificationEventCategory {
  type: NotificationEventCategoryType;
  name: string;
  events: NotificationEvent[];
}

export interface NotificationSubscription {
  channelId: NotificationChannel['id'];
  eventName: NotificationEvent['name'];
  schedule: ScheduleWeek | null;
  scheduleType: ScheduleType;
}

export interface NotificationChannelTableColumn {
  key: string;
  title: string;
  icon?: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>>;
  enabled: boolean;
}

export interface RequestNotificationSubscription {
  eventName: string;
  channelId: NotificationChannel['id'];
}

export interface NotificationChannelSection {
  key: NotificationChannelType;
  title: string;
  icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>>;
}

export interface NotificationStepDefinition {
  id: string;
  name: string;
  childSteps?: NotificationStepDefinition[];
}

export interface UINotificationStepDefinition
  extends NotificationStepDefinition {
  level?: number;
  eventName: NotificationEvent['name'];
}

export interface GetOrganizationUserNotificationSubscriptionsResponse {
  channels: NotificationChannel[];
  eventCategories: NotificationEventCategory[];
  subscriptions: NotificationSubscription[];
  stepDefinitions: NotificationStepDefinition[];
  subscribedStepDefinitionIds: {
    [key in NotificationSubscription['eventName']]: NotificationStepDefinition['id'][];
  };
}
