import constants from '../constants';

const getBearerToken = () => localStorage.getItem(constants.BEARER_TOKEN_KEY);

const getDefaultHeaders = (omitContentType: boolean) => {
  const headers: HeadersInit = {
    'x-api-authorization': 'allowed', // TODO verify where this header is being used
    'x-api-version': '2.0',
    'x-client-type': 'WEB',
    'x-client-app-version': process.env.REACT_APP_VERSION ?? '0.1.0',
    // 'x-session-id': 'TODO-Update',
  };
  if (omitContentType === false) {
    headers['content-type'] = 'application/json';
  }
  return headers;
};

export type HttpMethod = 'GET' | 'PUT' | 'POST' | 'DELETE';

/**
 * @name getAPIHeaders
 * @description  function to generate boilerplate for API calls.
 * */

export function getAPIHeaders(
  method: HttpMethod = 'GET',
  omitContentType: boolean = false
): {
  host: string;
  options: RequestInit;
  token: string;
} {
  const host = `${process.env.REACT_APP_ADMIN_SERVICE_URL_OVERRIDE}`;
  const authBearerToken = getBearerToken();
  const options = {
    method,
    headers: {
      ...getDefaultHeaders(omitContentType),
      Authorization: `Bearer ${authBearerToken}`,
    },
  };

  return { host, options, token: `${authBearerToken}` };
}
