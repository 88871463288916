import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { APIResponse, defaultMetaQueryFn, defaultMutationFn } from 'api/useAPI';
import {
  defaultReconVelocityWorkflowStep,
  Organization,
  ReconVelocityStepDefinitionAssociation,
  ReconVelocityWorkflowStep,
  UpdateReconVelocityWorkflowBeginningStep,
} from 'models';

/**
 * Get steps for a workflow
 * Endpoint GET `/orgs/{orgId}/plugins/reconvelocity/steps
 * @returns List of steps for a Recon Velocity Workflow
 */
export function useReconVelocityWorkflowSteps(orgId: Organization['id']) {
  const path = `/orgs/${orgId}/plugins/reconvelocity/steps`;
  return useQuery<APIResponse<ReconVelocityWorkflowStep[]>>(
    [path],
    () => defaultMetaQueryFn(path),
    {
      enabled: !!orgId,
    }
  );
}

/**
 * Gets a single step from a workflow
 * Endpoint GET `/orgs/{orgId}/plugins/reconvelocity/steps/{stepId}`
 * @param orgId
 * @param workflowId
 * @param stepId
 * @returns Recon Velocity Workflow Step
 */
export function useReconVelocityWorkflowStep(
  orgId: Organization['id'],
  stepId: ReconVelocityWorkflowStep['id']
) {
  const path = `/orgs/${orgId}/plugins/reconvelocity/steps/${stepId}`;
  const enabled = !!orgId && !!stepId && stepId !== 'BLANK_STEP';
  const initialData = { data: defaultReconVelocityWorkflowStep };
  return useQuery<APIResponse<ReconVelocityWorkflowStep>>(
    [path],
    () => defaultMetaQueryFn(path),
    {
      enabled,
      initialData: () => (enabled ? undefined : initialData),
    }
  );
}

/**
 * Delete step from workflow
 * Endpoint DELETE `/orgs/{orgId}/plugins/reconvelocity/steps/{stepId}
 * @returns Recon Velocity Linear Workflow
 */
export function useDeleteReconVelocityWorkflowStep(
  orgId: Organization['id'],
  stepId: ReconVelocityWorkflowStep['id']
) {
  const queryClient = useQueryClient();
  const mutation = useMutation((data) => {
    const path = `/orgs/${orgId}/plugins/reconvelocity/steps/${stepId}`;
    return defaultMutationFn(path, 'DELETE', data);
  });

  async function deleteStepAsync(data: any) {
    const response = await mutation.mutateAsync(data);
    await queryClient.invalidateQueries([
      `/orgs/${orgId}/plugins/reconvelocity/steps`,
    ]);
    await queryClient.cancelQueries([
      `/orgs/${orgId}/plugins/reconvelocity/steps/${stepId}`,
    ]);
    if (data.precedingSteps) {
      data.precedingSteps.map(async (precedingStepId: string) => {
        await queryClient.invalidateQueries([
          `/orgs/${orgId}/plugins/reconvelocity/steps/${precedingStepId}`,
        ]);
      });
    }

    return response;
  }

  return {
    ...mutation,
    deleteStepAsync,
  };
}

/**
 * Move workflow step
 * Endpoint PUT `/orgs/{orgId}/plugins/reconvelocity/steps/{stepId}/move`
 * @returns Full linear Recon Velocity workflow
 */
interface MoveReconVelocityWorkflowStepArgs {
  id: string;
}
export function useMoveReconVelocityWorkflowStep(orgId: Organization['id']) {
  const queryClient = useQueryClient();
  const mutation = useMutation((data: MoveReconVelocityWorkflowStepArgs) => {
    const path = `/orgs/${orgId}/plugins/reconvelocity/steps/${data.id}/move`;
    return defaultMutationFn(path, 'PUT', data);
  });

  async function moveStepAsync(data: any) {
    await queryClient.cancelQueries(['workflows']);
    const response = await mutation.mutateAsync(data);
    await queryClient.invalidateQueries([
      `/orgs/${orgId}/plugins/reconvelocity/steps`,
    ]);

    return response;
  }

  return {
    ...mutation,
    moveStepAsync,
  };
}

/**
 * Update workflow step
 * Endpoint PUT `/orgs/{orgId}/plugins/reconvelocity/steps/{stepId}`
 * @returns Full linear Recon Velocity workflow
 */
export function useUpdateReconVelocityWorkflowStep(
  orgId: Organization['id'],
  stepId: ReconVelocityWorkflowStep['id']
) {
  const queryClient = useQueryClient();
  const mutation = useMutation((data) => {
    const path = `/orgs/${orgId}/plugins/reconvelocity/steps/${stepId}`;
    return defaultMutationFn(path, 'PUT', data);
  });

  async function updateStepAsync(data: any) {
    await queryClient.cancelQueries(['workflows']);
    const response = await mutation.mutateAsync(data);
    await queryClient.invalidateQueries([
      `/orgs/${orgId}/plugins/reconvelocity/steps`,
    ]);
    await queryClient.invalidateQueries([
      `/orgs/${orgId}/plugins/reconvelocity/steps/${stepId}`,
    ]);

    return response;
  }

  return {
    ...mutation,
    updateStepAsync,
  };
}

export function useUpdateBeginningStep(orgId: Organization['id']) {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (data: UpdateReconVelocityWorkflowBeginningStep) => {
      const path = `/orgs/${orgId}/plugins/reconvelocity/beginningStep`;
      return defaultMutationFn(path, 'PUT', data);
    }
  );

  async function updateBeginningStep(
    data: UpdateReconVelocityWorkflowBeginningStep
  ) {
    await queryClient.cancelQueries(['workflows']);
    const response = await mutation.mutateAsync(data);
    await queryClient.invalidateQueries([
      `/orgs/${orgId}/plugins/reconvelocity/steps`,
    ]);
    return response;
  }

  return {
    ...mutation,
    updateBeginningStep,
  };
}

/**
 * Create a new workflow step
 * Endpoint PUT `/orgs/{orgId}/plugins/reconvelocity/steps/{stepId}`
 * @returns Full linear Recon Velocity workflow
 */
export function useCreateReconVelocityWorkflowStep(orgId: Organization['id']) {
  const queryClient = useQueryClient();
  const mutation = useMutation((data) => {
    const path = `/orgs/${orgId}/plugins/reconvelocity/steps`;
    return defaultMutationFn(path, 'POST', data);
  });

  async function createStepAsync(data: any) {
    await queryClient.cancelQueries(['workflows']);
    const response = await mutation.mutateAsync(data);
    await queryClient.invalidateQueries([
      `/orgs/${orgId}/plugins/reconvelocity/steps`,
    ]);

    return response;
  }

  return {
    ...mutation,
    createStepAsync,
  };
}

/**
 * Create a new shared step association
 * Endpoint POST `/orgs/{orgId}/plugins/reconvelocity/steps/{primaryStepId}/assocations`
 * @returns ReconVelocityStepDefinitionAssociation
 */
export function useCreateReconVelocityStepDefinitionAssocation(
  orgId: Organization['id'],
  primaryStepId: ReconVelocityWorkflowStep['id']
) {
  const queryClient = useQueryClient();
  const mutation = useMutation((data: string) => {
    const path = `/orgs/${orgId}/plugins/reconvelocity/steps/${primaryStepId}/associations`;
    return defaultMutationFn(path, 'POST', data);
  });

  async function createStepDefinitionAssocationAsync(secondaryStepId: string) {
    const response = await mutation.mutateAsync(secondaryStepId);
    await queryClient.invalidateQueries([
      `/orgs/${orgId}/plugins/reconvelocity/steps/${primaryStepId}/associations`,
    ]);

    return response;
  }

  return {
    ...mutation,
    createStepDefinitionAssocationAsync,
  };
}

/**
 * Delete an existing shared step association
 * Endpoint DELETE `/orgs/{orgId}/plugins/reconvelocity/stepDefinitionAssiciation/{stepDefinitionAssociationId}`
 * @returns ReconVelocityStepDefinitionAssociation
 */
export function useDeleteReconVelocityStepDefinitionAssociation(
  orgId: Organization['id'],
  primaryStepId: ReconVelocityWorkflowStep['id']
) {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (stepDefinitionAssociationId: string) => {
      const path = `/orgs/${orgId}/plugins/reconvelocity/stepDefinitionAssiciation/${stepDefinitionAssociationId}`;
      return defaultMutationFn(path, 'DELETE');
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          `/orgs/${orgId}/plugins/reconvelocity/steps/${primaryStepId}/associations`,
        ]);
      },
    }
  );

  async function deleteStepDefinitionAssociationAsync(associationId: string) {
    const response = await mutation.mutateAsync(associationId);
    return response;
  }

  return {
    ...mutation,
    deleteStepDefinitionAssociationAsync,
  };
}

/**
 * Get a list of step associations for a specific step
 * Endpoint GET `/orgs/{orgId}/plugins/reconvelocity/steps/{primaryStepId}/associations`
 * @returns ReconVelocityStepDefinitionAssociation[]
 */
export function useGetReconVelocityStepDefinitionAssociationList(
  orgId: Organization['id'],
  primaryStepId: ReconVelocityWorkflowStep['id']
) {
  const path = `/orgs/${orgId}/plugins/reconvelocity/steps/${primaryStepId}/associations`;
  return useQuery<APIResponse<ReconVelocityStepDefinitionAssociation[]>>(
    [path],
    () => defaultMetaQueryFn(path),
    {
      enabled: !!orgId && !!primaryStepId,
    }
  );
}
