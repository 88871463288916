import IconButton from '@material-ui/core/IconButton';
import Portal from '@material-ui/core/Portal';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import React, { useState } from 'react';

import './Alert.scss';

export function useAlert() {
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [variant, setVariant] = useState<AlertVariant>('error');

  const openAlert: OpenAlert = (message: string, newVariant: AlertVariant) => {
    setAlertMessage(message);
    setVariant(newVariant);
    setIsAlertOpen(true);
  };

  const closeAlert = () => {
    setIsAlertOpen(false);
    // Using setTimeout to avoid the message changing while the Alert is fading
    setTimeout(() => {
      setAlertMessage('');
      setVariant('error');
    }, 1000);
  };

  return {
    isAlertOpen,
    alertMessage,
    openAlert,
    closeAlert,
    variant,
  };
}

export type AlertVariant = 'primary' | 'success' | 'info' | 'warning' | 'error';

export type OpenAlert = (message: string, variant: AlertVariant) => void;

interface AlertProps {
  anchor?: SnackbarOrigin;
  duration?: number;
  icon?: React.ReactElement;
  message: string;
  onClose: () => void;
  open: boolean;
  variant: AlertVariant;
}

const defaultAnchor: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

const iconMap = {
  primary: SuccessIcon,
  success: SuccessIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const Alert = ({
  anchor = defaultAnchor,
  duration,
  icon,
  message,
  onClose,
  open,
  variant,
}: AlertProps) => {
  const getDefaultIcon = () => {
    const Icon = iconMap[variant];
    return <Icon className="Alert-icon Alert-icon-variant" />;
  };

  return (
    <Portal>
      <Snackbar
        className="Alert"
        anchorOrigin={anchor}
        autoHideDuration={duration}
        onClose={onClose}
        open={open}
      >
        <SnackbarContent
          action={
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={onClose}
            >
              <CloseIcon className="Alert-icon" />
            </IconButton>
          }
          className={`Alert-${variant}`}
          message={
            <span className="Alert-message">
              {icon && icon}
              {!icon && getDefaultIcon()}
              {message}
            </span>
          }
        />
      </Snackbar>
    </Portal>
  );
};

export default Alert;
