import { FormControl, FormGroup } from '@material-ui/core';
import { FC } from 'react';

import CurrencyTextField from 'components/shared/CurrencyTextField';
import { Currency } from 'models';
import { ReconVelocityPluginItem } from 'models/plugins/reconVelocityPlugin';

export type DealershipCostsProps = {
  currency: Currency;
  updatedSettings: Partial<ReconVelocityPluginItem>;
  updateDealershipCost: (
    key: keyof ReconVelocityPluginItem['dealershipCosts'],
    value: ReconVelocityPluginItem['dealershipCosts'][typeof key]
  ) => void;
};

export const DealershipCosts: FC<DealershipCostsProps> = ({
  currency,
  updatedSettings,
  updateDealershipCost,
}) => {
  return (
    <div className="flex-columns flex-justify-center padding">
      <div className="full-width flex-justify-center">
        <FormGroup row>
          <FormControl
            style={{ flex: 1 }}
            variant="outlined"
            margin="dense"
            className="margin-sm"
          >
            <CurrencyTextField
              variant="outlined"
              margin="dense"
              placeholder="$0.00"
              name="defaultLaborRate"
              label="Default Labor Rate"
              value={
                updatedSettings.dealershipCosts?.defaultLaborRate?.amount ??
                undefined
              }
              onValueChange={({ floatValue: amount }) =>
                updateDealershipCost('defaultLaborRate', {
                  amount,
                  currency,
                })
              }
            />
          </FormControl>

          <FormControl
            style={{ flex: 1 }}
            variant="outlined"
            margin="dense"
            className="margin-sm"
          >
            <CurrencyTextField
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder="$0.00"
              name="defaultEstimatedRecon"
              label="Default Estimated Recon"
              value={
                updatedSettings.dealershipCosts?.defaultEstimatedRecon.amount ??
                0
              }
              onValueChange={({ floatValue: amount }) =>
                updateDealershipCost('defaultEstimatedRecon', {
                  amount,
                  currency,
                })
              }
              required
            />
          </FormControl>
        </FormGroup>

        <FormGroup row>
          <FormControl
            style={{ flex: 1 }}
            variant="outlined"
            margin="dense"
            className="margin-sm"
          >
            <CurrencyTextField
              variant="outlined"
              margin="dense"
              placeholder="$0.00"
              name="newPack"
              label="New Pack"
              value={updatedSettings.dealershipCosts?.newPack.amount ?? 0}
              onValueChange={({ floatValue: amount }) =>
                updateDealershipCost('newPack', {
                  amount,
                  currency,
                })
              }
              required
            />
          </FormControl>

          <FormControl
            style={{ flex: 1 }}
            variant="outlined"
            margin="dense"
            className="margin-sm"
          >
            <CurrencyTextField
              variant="outlined"
              margin="dense"
              placeholder="$0.00"
              name="usedPack"
              label="Used Pack"
              value={updatedSettings.dealershipCosts?.usedPack.amount ?? 0}
              onValueChange={({ floatValue: amount }) =>
                updateDealershipCost('usedPack', {
                  amount,
                  currency,
                })
              }
              required
            />
          </FormControl>
        </FormGroup>

        <FormGroup row>
          <FormControl
            style={{ flex: 1 }}
            variant="outlined"
            margin="dense"
            className="margin-sm"
          >
            <CurrencyTextField
              variant="outlined"
              margin="dense"
              placeholder="$0.00"
              name="dailyHoldingCost"
              label="Daily Holding"
              value={
                updatedSettings.dealershipCosts?.dailyHoldingCost.amount ?? 0
              }
              onValueChange={({ floatValue: amount }) =>
                updateDealershipCost('dailyHoldingCost', {
                  amount,
                  currency,
                })
              }
              required
            />
          </FormControl>

          <FormControl
            style={{ flex: 1 }}
            variant="outlined"
            margin="dense"
            className="margin-sm"
          >
            <CurrencyTextField
              variant="outlined"
              margin="dense"
              placeholder="$0.00"
              name="dailyDepreciationCost"
              label="Daily Depreciation"
              value={
                updatedSettings.dealershipCosts?.dailyDepreciationCost.amount ??
                0
              }
              onValueChange={({ floatValue: amount }) =>
                updateDealershipCost('dailyDepreciationCost', {
                  amount,
                  currency,
                })
              }
              required
            />
          </FormControl>
        </FormGroup>
      </div>
    </div>
  );
};
