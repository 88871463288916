import { ReconVelocityWorkflowStep } from 'models';

function doesStepHaveAncestor(
  step: ReconVelocityWorkflowStep,
  possibleAncestorStep: ReconVelocityWorkflowStep,
  allSteps: ReconVelocityWorkflowStep[]
): boolean {
  if (!step.parentStep) return false;
  if (step.parentStep.id === possibleAncestorStep.id) return true;

  const parentStep = allSteps.find(
    (possibleParent) => possibleParent.id === step.parentStep?.id
  );

  if (parentStep) {
    return doesStepHaveAncestor(parentStep, possibleAncestorStep, allSteps);
  }

  return false;
}

function getChildSteps(
  stepId?: ReconVelocityWorkflowStep['id'],
  otherSteps?: ReconVelocityWorkflowStep[]
): ReconVelocityWorkflowStep[] {
  if (!otherSteps || !stepId) return [];

  const childSteps = otherSteps.filter(
    (possibleChildStep) => possibleChildStep.parentStep?.id === stepId
  );
  return childSteps;
}

function getStepLevel(
  step: ReconVelocityWorkflowStep,
  otherSteps: ReconVelocityWorkflowStep[]
) {
  let result = 0;
  let parent = otherSteps.find(
    (possibleParentStep) =>
      step && step.parentStep && step.parentStep?.id === possibleParentStep.id
  );

  while (parent) {
    result += 1;
    parent = otherSteps.find(
      (possibleParentStep) => parent?.parentStep?.id === possibleParentStep.id
    );
  }

  return result;
}

export default {
  doesStepHaveAncestor,
  getChildSteps,
  getStepLevel,
};
