import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';

const pricePattern = /^[\d,\+\-\s.\$]*$/;
const descriptionPattern = /^[a-zA-Z\d,.\+\-\?\!\s]*$/;

interface AdditionalPricesInputProps {
  settingContent: string;
  onChange: (newContent: string) => void;
}

interface PriceItem {
  description: string;
  price: string;
}

function parseSettingsFromJsonString(jsonString: string): PriceItem[] {
  let data = [];
  try {
    data = JSON.parse(jsonString);
    if (data instanceof Array) {
      return data;
    }
    return [];
  } catch (e: any) {
    return [];
  }
}

const AdditionalPricesInput = ({
  settingContent,
  onChange,
}: AdditionalPricesInputProps) => {
  const classes = useStyles();
  const priceList: PriceItem[] = parseSettingsFromJsonString(settingContent);
  const handleListChange = (newList: PriceItem[]) => {
    onChange(JSON.stringify(newList));
  };
  const removeItem = (index: number) => {
    const newList = [...priceList];
    newList.splice(index, 1);
    handleListChange(newList);
  };
  const addNewItem = () => {
    handleListChange([
      ...priceList,
      {
        description: '',
        price: '',
      },
    ]);
  };

  const editItem = (description: string, price: string, index: number) => {
    const newList = [...priceList];
    newList[index] = { description, price };
    handleListChange(newList);
  };

  return (
    <>
      <Typography variant={'h6'}>Additional Prices</Typography>
      <Typography variant={'body1'}>
        Enter content that will be displayed in rows below the vehicle price.
        Enter all content to display, including symbols (+-$,.)
      </Typography>
      <Box mt={1} mb={3}>
        {priceList.map((item, index) => (
          <Grid
            container
            spacing={2}
            alignItems="flex-start"
            key={`additional-price-${index}`}
            className={classes.gridRow}
          >
            <Grid item xs={4}>
              <TextField
                label="Item description"
                variant="outlined"
                size="small"
                value={item.description}
                onChange={(event) => {
                  if (!descriptionPattern.test(event.target.value)) {
                    return;
                  }
                  editItem(event.target.value, item.price, index);
                }}
                className={classes.descriptionInput}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Price"
                variant="outlined"
                size="small"
                value={item.price}
                onChange={(event) => {
                  if (!pricePattern.test(event.target.value)) {
                    return;
                  }
                  editItem(item.description, event.target.value, index);
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  removeItem(index);
                }}
              >
                Remove
              </Button>
            </Grid>
          </Grid>
        ))}
        <Box mt={2}>
          <Button variant="outlined" onClick={() => addNewItem()}>
            Add Price
          </Button>
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles({
  descriptionInput: {
    width: '100%',
  },
  gridRow: {
    paddingTop: '0.5em',
  },
  helperText: {
    color: '#ccc',
  },
});

export default AdditionalPricesInput;
