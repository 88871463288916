import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { defaultMetaQueryFn, defaultMutationFn, GetUserQueryData } from 'api';
import { convertUserToRequestUser, RequestUser, User } from 'models';

import { configuredFetch } from '../base';

export type UsersRequestParams = {
  s?: string;
  startIndex?: number;
  pageSize?: number;
};

export type SpecificUsersRequestParams = {
  userId: User['id'];
};

export type CreateUsersRequestParams = {
  newUsers: RequestUser[];
};

export type UpdateUserRequestParams = {
  updatedUser: RequestUser;
  userId: User['id'];
};

export type DeleteUserRequestParams = {
  userId: User['id'];
};

/**
 * Get Users
 * Endpoint: GET `/users?pageSize=500&startIndex=0&s=`
 */
export function useUsers(
  searchText: string = '',
  startIndex: number = 0,
  pageSize: number = 500
) {
  const searchParam = searchText ? `&s=${searchText}` : '';
  const url = [
    '/users',
    `?startIndex=${startIndex}&pageSize=${pageSize}${searchParam}`,
  ];
  const defaultParams = { startIndex, pageSize };
  return useInfiniteQuery<GetUserQueryData, Error>(
    url,
    async ({ pageParam = defaultParams }) =>
      defaultMetaQueryFn(
        `/users?startIndex=${pageParam.startIndex}&pageSize=${pageParam.pageSize}${searchParam}`
      ),
    {
      getNextPageParam: (lastPage, pages) => {
        const totalUsersLoaded = pages.reduce(
          (acc, page) => acc + page.meta.returnedRecords,
          0
        );

        if (totalUsersLoaded === lastPage.meta.totalRecords) return;

        return {
          startIndex: pages.length * 50,
          pageSize,
        };
      },
    }
  );
}

/**
 * Delete user
 * Endoint: DELETE `/users/{userId}`
 * @param userId ID of user to be deleted
 * @returns boolean indicating success of user deletion
 */
export function useDeleteUser() {
  const queryClient = useQueryClient();
  const mutation = useMutation((userId: string) => {
    const path = `/users/${userId}`;
    return defaultMutationFn(path, 'DELETE');
  });

  async function deleteUserAsync(data: any) {
    const response = await mutation.mutateAsync(data);
    await queryClient.invalidateQueries(['/users*']);
    await queryClient.invalidateQueries(['/orgs/*/users']);

    return response;
  }

  return {
    ...mutation,
    deleteUserAsync,
  };
}

/**
 * CRUD User
 * Endpoint: CRUD `/users/{userId}`
 */
export function useUser(userId?: User['id'], orgId?: string) {
  const queryClient = useQueryClient();
  const path = userId ? `/users/${userId}` : '/users';
  const method = userId ? 'PUT' : 'POST';

  return {
    query: useQuery<User>([path], { enabled: !!userId }),
    mutation: useMutation(
      (data: User) =>
        defaultMutationFn(
          path,
          method,
          data.id
            ? convertUserToRequestUser(data)
            : [convertUserToRequestUser(data)]
        ),
      {
        onSuccess: async (data) => {
          if (orgId) {
            await queryClient.invalidateQueries([`/orgs/${orgId}/users`]);
          }
          queryClient.refetchQueries(['/users']);
          if (data?.data) {
            if (data?.data?.length >= 0) {
              queryClient.setQueryData<User>([path], data.data[0]);
            } else {
              queryClient.setQueryData<User>([path], data.data);
            }
          }
        },
        onSettled: () => {
          queryClient.invalidateQueries([path]);
        },
      }
    ),
  };
}

/**
 * POST sendPasswordReset
 * Endpoint: POST `/users/{userId}/security/sendPasswordReset`
 */
export async function sendPasswordReset(userId?: string) {
  const path = `/users/${userId}/security/sendPasswordReset`;
  const { data } = await configuredFetch<boolean>(path, {
    method: 'POST',
  });
  return data;
}

/**
 * POST changePassword
 * Endpoint: POST `/users/{userId}/security/changePassword`
 */
export function useChangePassword(userId?: string) {
  const path = `/users/${userId}/security/changePassword`;
  return useMutation((newPassword: string) =>
    defaultMutationFn(path, 'POST', { newPassword })
  );
}

/**
 * CRUD User
 * Endpoint: CRUD `/orgs/${organizationId}/users/${userId}/notifications/subscriptions`
 */
export function useUserSubscriptions(organizationId: string, userId: string) {
  const queryClient = useQueryClient();
  const path = `orgs/${organizationId}/users/${userId}/notifications/subscriptions`;
  return {
    query: useQuery<User>([path]),
    mutation: useMutation(
      (data: User) => defaultMutationFn(path, 'PUT', data),
      {
        onSuccess: async (data) => {
          if (data) {
            queryClient.setQueryData<User>([path], data);
          }
        },
        onSettled: () => {
          queryClient.invalidateQueries([path]);
        },
      }
    ),
  };
}
