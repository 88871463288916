import { Create, Delete } from '@material-ui/icons';

import useSession from 'api/session';
import permissions from 'common/permissions';
import PermissionGate from 'components/PermissionGate';
import { PluginItem } from 'models';
import utils from 'utils';

enum PluginsName {
  RECON_VELOCITY = 'RECON_VELOCITY',
}

const hasPluginUpdatePermission = (
  name: string,
  userPermissions?: string[]
) => {
  if (!userPermissions) return false;
  let permission: string = permissions.ORGS_PLUGINS_UPDATE;

  if (name === PluginsName.RECON_VELOCITY)
    permission = permissions.ORGS_PLUGINS_RECONVELOCITY_UPDATE;

  return utils.permissions.hasPermission(permission, userPermissions);
};

const hasPluginViewPermission = (name: string, userPermissions?: string[]) => {
  if (!userPermissions) return false;
  let permission: string = permissions.ORGS_PLUGINS_VIEW;

  if (name === PluginsName.RECON_VELOCITY)
    permission = permissions.ORGS_PLUGINS_RECONVELOCITY_VIEW;

  return utils.permissions.hasPermission(permission, userPermissions);
};

interface PluginButtonProps {
  plugin: PluginItem;
  onEditClick: (plugin: PluginItem) => void;
  onDeleteClick: (plugin: PluginItem) => void;
}

var PluginButton = ({
  plugin,
  onEditClick,
  onDeleteClick,
}: PluginButtonProps) => {
  const { data: session } = useSession();
  if (!hasPluginViewPermission(plugin.pluginName, session?.permissions))
    return null;

  const hasUpdatePermission = hasPluginUpdatePermission(
    plugin.pluginName,
    session?.permissions
  );
  if (plugin.pluginName === 'AUTHENTICOM') {
    plugin.title = 'Authenticom';
  }
  return (
    <button
      id={`${plugin.title}-plugin`.replace(/\s+/g, '-')}
      type="button"
      className={`PluginsView__plugin ${!plugin.enabled ? 'disabled' : ''}`}
    >
      <div className="icon" />
      <div className="plugin-title-container">
        <span className="plugin-title-content">{plugin.title}</span>
      </div>
      <div className="actions">
        {hasUpdatePermission && (
          <Create
            id={`${plugin.title}-edit-button`.replace(/\s+/g, '-')}
            onClick={() => onEditClick(plugin)}
          />
        )}
        <PermissionGate permissions={[permissions.ORGS_PLUGINS_DELETE]}>
          <Delete
            id={`${plugin.title}-delete-button`.replace(/\s+/g, '-')}
            onClick={() => onDeleteClick(plugin)}
          />
        </PermissionGate>
      </div>
    </button>
  );
};

export default PluginButton;
