import { useMutation, useQueryClient } from '@tanstack/react-query';

import { defaultMutationFn } from 'api';
import { configuredFetch } from 'api/base';
import { Organization, OrganizationGroup, User } from 'models';

import notifications from './notifications';

export type OrganizationUsersRequestParams = {
  orgId: Organization['id'];
};

export type UsersRequestParams = {
  userId: User['id'];
};

export type DeleteUserFromOrgRequestParams = {
  userId: User['id'];
  orgId: Organization['id'];
};

export type BulkDeleteUsersFromOrgRequestParams = {
  userIds: User['id'][];
  orgId: Organization['id'];
};

export type BulkUpdateUsersOrgGroupRequestParams = {
  orgId: Organization['id'];
  userIds: User['id'][];
  orgGroupIds: OrganizationGroup['id'][];
};

function orgUsersUrl(orgId: string) {
  return `/orgs/${orgId}/users`;
}
function orgGroupsUrl(orgId: string) {
  return `/orgs/${orgId}/groups`;
}

/**
 * Get Organization Users
 * Endpoint: GET /orgs/{orgId}/users
 */
async function getOrganizationUsers({ orgId }: OrganizationUsersRequestParams) {
  const url = orgUsersUrl(orgId);

  const { data } = await configuredFetch<User[]>(url);
  return data;
}

/**
 * Update Users Organization Groups
 * Endpoint: PUT /orgs/{orgId}/users
 */
async function updateUsersOrgGroups({
  orgId,
  userIds,
  orgGroupIds,
}: BulkUpdateUsersOrgGroupRequestParams): Promise<User[]> {
  const url = orgUsersUrl(orgId);

  const { data } = await configuredFetch<User[]>(url, {
    method: 'put',
    body: JSON.stringify({ userIds, orgGroupIds }),
  });
  return data;
}

/**
 * Update Users Organization Groups
 * Endpoint: PUT /orgs/{orgId}/users
 */
interface UseUpdateUsersOrgGroupsParams {
  orgId: string;
  userIds: string[];
  orgGroupIds: string[];
  groupIdSelected: string;
}
export function useUpdateUsersOrgGroups() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ orgId, userIds, orgGroupIds }: UseUpdateUsersOrgGroupsParams) => {
      const url = orgUsersUrl(orgId);
      return defaultMutationFn(url, 'PUT', { userIds, orgGroupIds });
    },
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([
          `/orgs/${variables.orgId}/groups/${variables.groupIdSelected}/users?startIndex=0&pageSize=500`,
        ]);
        queryClient.invalidateQueries([
          [orgUsersUrl(variables.orgId), '?startIndex=0&pageSize=500'],
        ]);
        queryClient.invalidateQueries([orgGroupsUrl(variables.orgId)]);
      },
    }
  );
}

/**
 * Bulk Remove Users from Organization
 * Endpoint: DELETE /orgs/{orgId}/users
 */
async function bulkRemoveUsersFromOrg({
  userIds,
  orgId,
}: BulkDeleteUsersFromOrgRequestParams) {
  const url = orgUsersUrl(orgId);

  const { data } = await configuredFetch<User[]>(url, {
    method: 'delete',
    body: JSON.stringify(userIds),
  });
  return data;
}

/**
 * Remove User from Organization
 * Endpoint: DELETE /orgs/{orgId}/users/{userId}
 */
async function removeUserFromOrg({
  userId,
  orgId,
}: DeleteUserFromOrgRequestParams) {
  const url = `${orgUsersUrl(orgId)}/${userId}`;

  const { data } = await configuredFetch<boolean>(url, {
    method: 'delete',
  });
  return data;
}

export function useRemoveUserFromOrg() {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    ({ orgId, userId }: { orgId: string; userId: string }) => {
      const path = `${orgUsersUrl(orgId)}/${userId}`;
      return defaultMutationFn(path, 'DELETE');
    }
  );

  async function removeUserFromOrgAsync(data: {
    orgId: string;
    userId: string;
  }) {
    await queryClient.cancelQueries([
      `${orgUsersUrl(data.orgId)}/${data.userId}`,
    ]);
    const response = await mutation.mutateAsync(data);
    await queryClient.invalidateQueries([
      `${orgUsersUrl(data.orgId)}/${data.userId}`,
    ]);
    await queryClient.invalidateQueries([orgUsersUrl(data.orgId)]);
    await queryClient.invalidateQueries([`/users/${data.userId}`]);
    return response;
  }

  return {
    ...mutation,
    removeUserFromOrgAsync,
  };
}

export default {
  getOrganizationUsers,
  updateUsersOrgGroups,
  removeUserFromOrg,
  bulkRemoveUsersFromOrg,
  notifications,
};
