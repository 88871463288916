import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import { ReactElement } from 'react';

const DEFAULT_SIZE = 80;
const THICKNESS = 2.1;
const DEFAULT_STYLING =
  'full-height full-width flex-rows flex-align-center flex-justify-center';

interface LoadingIndicatorProps {
  size?: number;
  className?: string;
  color?: CircularProgressProps['color'];
}

var LoadingIndicator = ({
  size = DEFAULT_SIZE,
  className = DEFAULT_STYLING,
  color = 'primary',
}: LoadingIndicatorProps): ReactElement => {
  const thickness = (THICKNESS * DEFAULT_SIZE) / size;
  return (
    <div className={className}>
      <CircularProgress color={color} size={size} thickness={thickness} />
    </div>
  );
};

export default LoadingIndicator;
