import {
  Address,
  Contact,
  defaultAddress,
  defaultContact,
  defaultScheduleWeek,
  ItemLink,
  ScheduleWeek,
  Timezone,
} from './common';
import { PermissionItem } from './permissions';
import { User } from './user';

export enum OrganizationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  UNKNOWN = 'UNKNOWN',
}

export enum OrganizationPositionType {
  ROOT = 'ROOT',
  NODE = 'NODE',
  LEAF = 'LEAF',
  UNKNOWN = 'UNKNOWN',
}

export enum OrganizationType {
  GROUP = 'GROUP',
  ROOFTOP = 'ROOFTOP',
  VENDOR = 'VENDOR',
  UNKNOWN = 'UNKNOWN',
}

export interface OrganizationDepartment {
  phone?: string;
  email?: string;
  schedule: ScheduleWeek | null;
}

export const defaultOrganizationDepartment = {
  phone: undefined,
  email: undefined,
  schedule: defaultScheduleWeek,
};

export enum OrganizationRoleType {
  APPLICATION = 'APPLICATION',
  INTERNAL = 'INTERNAL',
}

export interface RoleItem {
  id: string;
  name: string;
  applicationName: string;
  type: OrganizationRoleType;
  permissions: PermissionItem[];
}

export enum OrganizationGroupType {
  APPLICATION = 'APPLICATION',
  INTERNAL = 'INTERNAL',
}

export interface OrganizationGroup {
  id: string;
  name: string;
  applicationName: string;
  type: OrganizationGroupType;
  roles: RoleItem[];
}

export interface OrganizationGroupItemLink extends ItemLink {
  organization: ItemLink;
}

export interface Organization {
  id: string;
  name: string;
  accountId?: string;
  externalId: string;
  externalTenantId: string;
  status: OrganizationStatus;
  positionType: OrganizationPositionType;
  type: OrganizationType;
  billingAddress: Address;
  billingContact?: Contact;
  mailingAddress: Address;
  mailingContact?: Contact;
  timezone: Timezone;
  mainDepartment: OrganizationDepartment;
  salesDepartment?: OrganizationDepartment;
  serviceDepartment?: OrganizationDepartment;
  partsDepartment?: OrganizationDepartment;
  websiteUrl: string;
  groups?: OrganizationGroup[];
  parent?: OrganizationItemLink | Partial<Organization>;
  children?: (OrganizationItemLink | Partial<Organization>)[];
  level: number;
  tagInstances?: Tag[];
  logoUrl?: string;
  trialOrganizationExpiration?: string;
}

export const defaultOrganization: Organization = {
  id: '',
  name: '',
  externalId: '',
  externalTenantId: '',
  status: OrganizationStatus.ACTIVE,
  positionType: OrganizationPositionType.ROOT,
  type: OrganizationType.ROOFTOP,
  billingAddress: {
    ...defaultAddress,
    state: {
      ...defaultAddress.state,
    },
  },
  billingContact: {
    ...defaultContact,
    mobilePhone: {
      ...defaultContact.mobilePhone,
    },
  },
  mailingAddress: {
    ...defaultAddress,
    state: {
      ...defaultAddress.state,
    },
  },
  mailingContact: {
    ...defaultContact,
    mobilePhone: {
      ...defaultContact.mobilePhone,
    },
  },
  timezone: {
    timezone: 'ET',
    label: 'Eastern',
  },
  mainDepartment: {
    ...defaultOrganizationDepartment,
  },
  salesDepartment: {
    ...defaultOrganizationDepartment,
    schedule: { ...defaultOrganizationDepartment.schedule },
  },
  serviceDepartment: {
    ...defaultOrganizationDepartment,
    schedule: { ...defaultOrganizationDepartment.schedule },
  },
  partsDepartment: {
    ...defaultOrganizationDepartment,
    schedule: { ...defaultOrganizationDepartment.schedule },
  },
  websiteUrl: 'https://',
  level: 0,
};

export const defaultOrganizationGroup: OrganizationGroup = {
  id: '',
  name: '',
  applicationName: '',
  type: OrganizationGroupType.APPLICATION,
  roles: [],
};

export interface Country {
  country: string;
  id: string;
  isoCode: string;
  label: string;
}
export interface Region {
  country: Country;
  id: string;
  isoCode: string;
  label: string;
}

export interface OrganizationItemLink
  extends ItemLink,
    Pick<Organization, 'status' | 'positionType' | 'type' | 'groups'> {
  status: OrganizationStatus;
  positionType: OrganizationPositionType;
  type: OrganizationType;
  children?: OrganizationItemLink[];
}

export type TagType = 'SYSTEM' | 'DEALER';

export interface Tag {
  deleted?: boolean;
  color: string;
  instanceId?: string;
  tagIcon?: string;
  id?: string;
  tagType?: TagType;
  label: string;
}

export interface OrganizationHistory {
  id: string;
  message: string;
  messageType: string;
  sessionId: string;
  timestamp: string;
  user: User;
}
