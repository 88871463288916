import { AddCircle } from '@material-ui/icons';
import { useDraggable } from 'muuri-react';
import React from 'react';

import strings from 'common/strings';
import { isMobileViewport } from 'common/viewport';

import './EngageMediaPlaceholder.scss';

interface EngageMediaPlaceholderProps {
  title: string;
  onClick: () => void;
}

const EngageMediaPlaceholder: React.FC<EngageMediaPlaceholderProps> = ({
  title,
  onClick,
}: EngageMediaPlaceholderProps) => {
  const setDraggable = useDraggable();
  setDraggable(false);

  var LargeMediaPlaceholder = () => {
    return (
      <div
        className="EngageMediaPlaceholder item"
        role="none"
        onClick={onClick}
      >
        <div className="item-content">
          <div className="EngageMediaPlaceholder-content">
            <AddCircle className="EngageMediaPlaceholder-icon" />
            <span className="EngageMediaPlaceholder-title">{title}</span>
          </div>
        </div>
      </div>
    );
  };

  var SmallMediaPlaceholder = () => {
    return (
      <div
        className="EngageMediaPlaceholder-small item"
        role="none"
        onClick={onClick}
      >
        <div className="item-content">
          <div className="EngageMediaPlaceholder-content">
            <AddCircle className="EngageMediaPlaceholder-icon" />
          </div>
          {title === strings.ADD_MEDIA && (
            <span className="EngageMediaPlaceholder-title">{title}</span>
          )}
        </div>
      </div>
    );
  };

  return isMobileViewport() ? (
    <SmallMediaPlaceholder />
  ) : (
    <LargeMediaPlaceholder />
  );
};

export default EngageMediaPlaceholder;
