import api from 'api';
import { PluginsRequestParams } from 'api/organizations/plugins';
import { PluginOption } from 'models/plugins';
import {
  ActionTypeFromCreators,
  createAsyncAction,
} from 'store/actions/helpers';

export const actions = {
  getPluginOptions: 'Get Organization Plugin Options',
} as const;

// -- Get Organization Plugin Options
export const {
  types: getPluginOptionsActionTypes,
  creators: getPluginOptionsActionCreators,
  action: getPluginOptions,
} = createAsyncAction<
  typeof actions.getPluginOptions,
  PluginsRequestParams,
  PluginOption[]
>(actions.getPluginOptions, ({ orgId }) =>
  api.organizations.plugins.getOptions({ orgId })
);

type GetOrganizationPluginOptionsAction = ActionTypeFromCreators<
  typeof actions.getPluginOptions,
  PluginsRequestParams,
  PluginOption[],
  typeof getPluginOptionsActionCreators
>;
// --

export type OrganizationPluginOptionsActionTypes =
  GetOrganizationPluginOptionsAction;
