import { IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { useState } from 'react';

import { GetUserQueryData } from 'api';
import { useTeam, useTeamMutation } from 'api/organizations/teams';
import ConfirmationDialog, {
  useConfirmationDialog,
} from 'components/shared/ConfirmationDialog';
import { Column } from 'components/shared/UsersList/types';
import UsersList from 'components/shared/UsersList/UsersList';
import { User } from 'models';
import { displayFullName } from 'utils/strings';

import AddTeamDialog from './AddTeamDialog';

const headerColumns = [
  {
    dataKey: 'fullName',
    label: 'Name',
  },
  {
    dataKey: 'jobTitle',
    label: 'Title',
  },
  {
    dataKey: 'phone',
    label: 'Phone',
    maxWidth: '100px',
  },
  {
    dataKey: 'email',
    label: 'Email',
    width: '300px',
  },
  {
    dataKey: 'actionButtons',
    label: '',
    width: '42px',
  },
] as Column[];

type TeamDetailsProps = {
  orgId: string;
  selectedTeamId: string | undefined;
};

const TeamDetails = ({ orgId, selectedTeamId }: TeamDetailsProps) => {
  const { data, status, isLoading } = useTeam(selectedTeamId ?? '');
  const teamMutation = useTeamMutation(orgId, selectedTeamId);
  const users = data?.users ?? [];
  const usersQueryData = {
    data: { pages: [{ data: users, meta: { totalRecords: users.length } }] },
    status,
  } as UseInfiniteQueryResult<GetUserQueryData, Error>;

  const [isAddTeamDialogOpen, setIsAddTeamDialogOpen] = useState(false);
  const handleAddTeamDialogOnClose = () => {
    setIsAddTeamDialogOpen(false);
  };

  const {
    isConfirmationDialogOpen,
    isConfirmationLoading,
    confirmationMessage,
    primaryButtonLabel,
    onAccept,
    onDeny,
    openConfirmationDialog,
  } = useConfirmationDialog();

  if (selectedTeamId === undefined) {
    return null;
  }

  const handleOnDeleteUserClick = (user: User) => {
    const team = {
      id: data?.id ?? '',
      tenantId: data?.tenantId ?? '',
      teamName: data?.teamName ?? '',
      userIds: users.filter(({ id }) => id !== user.id).map(({ id }) => id),
    };
    openConfirmationDialog({
      messageOverride: `Are you sure you want to delete user, ${displayFullName(
        user
      )}?`,
      primaryButtonLabelOverride: 'Delete',
      onAcceptOverride: () => teamMutation.mutate(team),
    });
  };

  return (
    <div className="teams-details">
      <div className="teams-details-header">
        <div className="teams-details-header-title">ID: {selectedTeamId}</div>
        <IconButton
          size="small"
          onClick={() => setIsAddTeamDialogOpen(true)}
          disabled={teamMutation.isLoading}
        >
          <Edit />
        </IconButton>
      </div>
      <UsersList
        usersQueryData={usersQueryData}
        showSearchField={false}
        showAddIcon={false}
        headerColumns={headerColumns}
        onDeleteUser={handleOnDeleteUserClick}
        isLoading={isLoading}
      />
      {isAddTeamDialogOpen && (
        <AddTeamDialog
          teamId={selectedTeamId}
          orgId={orgId}
          open={isAddTeamDialogOpen}
          onClose={handleAddTeamDialogOnClose}
        />
      )}
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        isLoading={isConfirmationLoading}
        primaryButtonLabel={primaryButtonLabel}
        message={confirmationMessage}
        onPrimaryButtonClick={onAccept}
        onSecondaryButtonClick={onDeny}
      />
    </div>
  );
};
export default TeamDetails;
