import { useState } from 'react';

import { useUsers } from 'api/users';
import UsersList from 'components/shared/UsersList';

import EditUserDialog from './EditUserDialog';

import './UsersView.scss';

const UsersView = () => {
  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = useState(false);
  const [userId, setUserId] = useState<string>('');
  const [searchText, setSearchText] = useState('');
  const usersQueryData = useUsers(searchText);
  const editUserDialog = (openDialog: boolean, selectedUserId: string = '') => {
    setUserId(selectedUserId);
    setIsEditUserDialogOpen(openDialog);
  };

  return (
    <>
      <div className="UsersView page-container">
        <UsersList
          usersQueryData={usersQueryData}
          setSearchText={setSearchText}
          editUserDialog={editUserDialog}
        />
        {isEditUserDialogOpen && (
          <EditUserDialog
            onClose={editUserDialog}
            userId={userId}
            refreshActionAfterSave={() => {
              /* TODO */
            }}
          />
        )}
      </div>
    </>
  );
};

export default UsersView;
