import { configuredFetch } from 'api/base';
import { PluginItem } from 'models/plugins';

/**
 * Get Supported Plugins
 * Endpoint: GET /utility/plugins
 */
async function getSupported() {
  const { data } = await configuredFetch<PluginItem[]>('/utility/plugins');
  return data;
}

export default {
  getSupported,
};
