import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { APIResponse, defaultMetaQueryFn, defaultMutationFn } from 'api/useAPI';
import { Tag } from 'models';

/**
 * Get tag definitions for an organization
 * Endpoint GET `/orgs/{orgId}/tags`
 * @returns List of tag definitions for an organization
 */
export function useOrganizationTags(orgId?: string) {
  const path = `/orgs/${orgId}/tags`;

  return useQuery<APIResponse<Tag[]>>([path], () => defaultMetaQueryFn(path), {
    enabled: !!orgId,
  });
}

/**
 * Create a tag definition for an organization
 * Endpoint: POST /orgs/{orgId}/tags
 */
export function useCreateTagDefinition(orgId: string) {
  const queryClient = useQueryClient();

  const mutation = useMutation((data: Tag) => {
    const path = `/orgs/${orgId}/tags`;
    return defaultMutationFn(path, 'POST', data);
  });

  async function createTagDefinitionAsync(data: Tag) {
    const response = await mutation.mutateAsync(data);
    await queryClient.invalidateQueries([`/orgs/${orgId}`]);
    await queryClient.invalidateQueries([`/orgs/${orgId}/tags`]);
    return response;
  }

  return {
    ...mutation,
    createTagDefinitionAsync,
  };
}

/**
 * Add/Remove tags to an organization
 * Endpoint: PUT /orgs/{orgId}/tagInstances
 */
export function useAssignTags(orgId: string) {
  const queryClient = useQueryClient();

  const mutation = useMutation((data: (string | undefined)[]) => {
    const path = `/orgs/${orgId}/tagInstances`;
    return defaultMutationFn(path, 'PUT', data);
  });

  async function assignTagsAsync(data: (string | undefined)[]) {
    const response = await mutation.mutateAsync(data);
    await queryClient.invalidateQueries([`/orgs/${orgId}`]);
    return response;
  }

  return {
    ...mutation,
    assignTagsAsync,
  };
}
