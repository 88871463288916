import {
  Button,
  IconButton,
  Dialog as MaterialDialog,
  DialogActions as MaterialDialogActions,
  DialogContent as MaterialDialogContent,
  DialogTitle as MaterialDialogTitle,
  Snackbar,
  SnackbarContent,
} from '@material-ui/core';
import { Close, Delete, Edit } from '@material-ui/icons';
import React, { useState } from 'react';

import strings from 'common/strings';
import { isMobileViewport } from 'common/viewport';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { FolioDocumentTemplate } from 'models';

import EngageMediaPlaceholder from '../EngageMediaPlaceholder';
import EngageMediaItemEditModal from './EngageMediaItemEditModal';

import './EngageMediaItem.scss';

interface EngageMediaItemProps {
  orgId: string;
  folioId: string;
  folderId: string;
  media: FolioDocumentTemplate;
  onDelete: (document: FolioDocumentTemplate) => void;
  onEdit: (
    document: FolioDocumentTemplate,
    folderId: string,
    title: string,
    previewImageUrl: string,
    isAddMedia: boolean,
    mediaUrl?: string
  ) => void;
  isDragging: boolean;
  availableDocuments: FolioDocumentTemplate[];
}

interface EngageMediaItemComponentProps {
  media: FolioDocumentTemplate;
  onMediaClick: () => void;
  onEditClick: () => void;
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: (isOpen: boolean) => void;
  isDragging: boolean;
}

const EngageMediaItemOverlay: React.FC<EngageMediaItemComponentProps> = ({
  media,
  onMediaClick,
  onEditClick,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  isDragging,
}: EngageMediaItemComponentProps) => {
  const { displayLabel } = media;

  return (
    <div className="EngageMediaItem item">
      <div className="item-content">
        <div>
          <div
            className="EngageMediaItem-info"
            role="none"
            onClick={() => {
              if (!isDragging) {
                onMediaClick();
              }
            }}
          >
            {media.url || media.type === 'DYNAMIC' ? (
              <>
                <img
                  className="EngageMediaItem-info-image"
                  src={media.thumbnailUrl}
                  alt="car"
                />
                <div className="EngageMediaItem-info-image-overlay" />
                <span className="EngageMediaItem-info-title">
                  {displayLabel}
                </span>
              </>
            ) : (
              <EngageMediaPlaceholder
                title={media.displayLabel}
                onClick={onEditClick}
              />
            )}
          </div>
          <div className="EngageMediaItem-icons">
            {(media.id || media.url) && (
              <>
                <Edit className="EngageMediaItem-icon" onClick={onEditClick} />
                <Delete
                  className="EngageMediaItem-icon"
                  onClick={() => {
                    if (isDeleteModalOpen) {
                      setIsDeleteModalOpen(false);
                    }
                    setIsDeleteModalOpen(!isDeleteModalOpen);
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const EngageMediaItemHorizontal: React.FC<EngageMediaItemComponentProps> = ({
  media,
  onMediaClick,
  onEditClick,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  isDragging,
}: EngageMediaItemComponentProps) => {
  const { displayLabel } = media;

  return (
    <div className="item EngageMediaItemHorizontal-margin">
      <div className="item-content">
        <div className="EngageMediaItemHorizontal">
          <div
            className="EngageMediaItemHorizontal-image-container"
            role="none"
            onClick={() => {
              if (!isDragging) {
                onMediaClick();
              }
            }}
          >
            {media.url ? (
              <img
                className="EngageMediaItemHorizontal-image"
                src={media.thumbnailUrl}
                alt="car"
              />
            ) : (
              <EngageMediaPlaceholder
                title={media.displayLabel}
                onClick={onEditClick}
              />
            )}
          </div>
          <div
            className={`EngageMediaItemHorizontal-info${
              media.url ? '' : ' EngageMediaItemHorizontal-info-padding'
            }`}
          >
            <span className="EngageMediaItemHorizontal-title">
              {displayLabel}
            </span>
            <div className="EngageMediaItemHorizontal-icons">
              {(media.id || media.url) && (
                <>
                  <Edit
                    className="EngageMediaItemHorizontal-icon"
                    onClick={onEditClick}
                  />
                  <Delete
                    className="EngageMediaItemHorizontal-icon"
                    onClick={() => {
                      if (isDeleteModalOpen) {
                        setIsDeleteModalOpen(false);
                      }
                      setIsDeleteModalOpen(!isDeleteModalOpen);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EngageMediaItem: React.FC<EngageMediaItemProps> = ({
  folioId,
  folderId,
  orgId,
  media,
  onEdit,
  onDelete,
  isDragging,
  availableDocuments,
}: EngageMediaItemProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const onMediaClick = () => {
    if (media.url) {
      window.open(media.url, '_blank');
    } else {
      setErrorMessage(strings.NO_DOCUMENT_URL);
    }
  };

  const onEditClick = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  return (
    <>
      {isMobileViewport() ? (
        <EngageMediaItemHorizontal
          media={media}
          onMediaClick={onMediaClick}
          onEditClick={onEditClick}
          isDeleteModalOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          isDragging={isDragging}
        />
      ) : (
        <EngageMediaItemOverlay
          media={media}
          onMediaClick={onMediaClick}
          onEditClick={onEditClick}
          isDeleteModalOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          isDragging={isDragging}
        />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={!!errorMessage}
        onClose={() => setErrorMessage('')}
      >
        <SnackbarContent
          aria-describedby="client-snackbar"
          message={<span>{errorMessage}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={() => setErrorMessage('')}
            >
              <Close />
            </IconButton>,
          ]}
        />
      </Snackbar>
      {isEditModalOpen && (
        <EngageMediaItemEditModal
          folioId={folioId}
          folderId={folderId}
          media={media}
          onClose={() => setIsEditModalOpen(false)}
          onEdit={onEdit}
          open={isEditModalOpen}
          orgId={orgId}
          availableDocuments={availableDocuments}
        />
      )}
      <MaterialDialog
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        maxWidth="xs"
        aria-labelledby="edit-media-delete-dialog-title"
      >
        <MaterialDialogTitle id="edit-media-delete-dialog-title">
          {strings.ARE_YOU_SURE}
        </MaterialDialogTitle>
        <MaterialDialogContent>
          <div>
            Are you sure you want to delete media from this folio template?
          </div>
        </MaterialDialogContent>
        <MaterialDialogActions>
          <Button onClick={() => setIsDeleteModalOpen(false)} color="primary">
            {strings.CANCEL}
          </Button>
          <Button
            onClick={async () => {
              if (onDelete) {
                setIsDeleting(true);
                onDelete(media);
                setIsDeleting(false);
                setIsDeleteModalOpen(false);
              }
            }}
            color="primary"
          >
            {isDeleting ? <LoadingIndicator /> : strings.DELETE}
          </Button>
        </MaterialDialogActions>
      </MaterialDialog>
    </>
  );
};

export default EngageMediaItem;
