import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { defaultMutationFn } from 'api/useAPI';
import { ScheduleWeek, User } from 'models';
import { ApiResponse } from 'models/api';

export type GetNotificationWindowRequestParams = {
  userId: User['id'];
};

function notificationWindowUrl({ userId }: GetNotificationWindowRequestParams) {
  return `/users/${userId}/notificationWindow`;
}

export function useGetNotificationWindow({
  userId,
}: GetNotificationWindowRequestParams) {
  const path = notificationWindowUrl({ userId });
  return useQuery<ScheduleWeek | null>([path]);
}

export function useSaveNotificationWindow({
  userId,
}: GetNotificationWindowRequestParams) {
  const queryClient = useQueryClient();
  const path = notificationWindowUrl({ userId });
  return useMutation(
    (payload: ScheduleWeek) => defaultMutationFn(path, 'PUT', payload),
    {
      onSuccess: async (data: ApiResponse<ScheduleWeek>) => {
        queryClient.setQueryData<ScheduleWeek | null>([path], data?.data);
      },
    }
  );
}
