import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import React, { useEffect, useState } from 'react';

import { usePlugin, usePluginCreate, usePluginUpdate } from 'api';
import { AlertVariant } from 'components/shared/Alert/Alert';
import Dialog from 'components/shared/Dialog';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { DataProviderPluginItem, PluginItem } from 'models';

import './PluginDialog.scss';

export interface VelocityInsightPluginDialogProps {
  plugin: PluginItem;
  onClose?: () => void;
  openAlert: (message: string, variant: AlertVariant) => void;
  orgId: string;
}

const VelocityInsightPluginDialog: React.FC<VelocityInsightPluginDialogProps> =
  (props) => {
    const { plugin, onClose, openAlert, orgId } = props;

    const [enabled, setEnabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const pluginTypeId = plugin.pluginName.toLowerCase().replace('_', '');
    const { data: pluginData, isInitialLoading: isReading } = usePlugin(
      orgId,
      pluginTypeId,
      plugin.id
    );
    const { updatePluginAsync, isLoading: isUpdating } = usePluginUpdate(
      orgId,
      pluginTypeId
    );
    const { createPluginAsync, isLoading: isCreating } = usePluginCreate(
      orgId,
      pluginTypeId
    );

    useEffect(() => {
      setIsLoading(isReading || isUpdating || isCreating);
    }, [isReading, isCreating, isUpdating]);

    useEffect(() => {
      if (!isReading && pluginData) {
        setEnabled(pluginData.enabled ?? true);
      }
    }, [pluginData, isReading]);

    const save = async () => {
      const data: Partial<DataProviderPluginItem> = {
        enabled,
      };
      try {
        if (plugin.id) {
          await updatePluginAsync(data);
        } else {
          const newPlugin = { ...plugin, ...data };
          await createPluginAsync(newPlugin);
        }
        onClose?.();
      } catch (error) {
        console.error(error);
        openAlert(
          'An error occurred when saving this plug-in to the server.',
          'error'
        );
        return false;
      }
      return true;
    };

    return (
      <>
        <Dialog
          open
          title={`Configure ${plugin.title} Plug-in`}
          maxWidth="sm"
          fullWidth
          primaryButtonLabel="Save"
          onPrimary={save}
          onClose={onClose}
          block={isLoading}
          className="PluginDialog VelocityInsightPluginDialog"
        >
          <FormControlLabel
            className="PluginDialog__switch"
            label={
              isLoading ? (
                <LoadingIndicator size={26} />
              ) : (
                <FormLabel>{enabled ? 'Enabled' : 'Disabled'}</FormLabel>
              )
            }
            labelPlacement="start"
            control={
              <Switch
                inputProps={{
                  'aria-label': 'Velocity Insight Plugin Dialog Enable switch',
                }}
                checked={enabled}
                disabled={isLoading}
                onChange={(event) => setEnabled(event.target.checked)}
              />
            }
          />
        </Dialog>
      </>
    );
  };

export default VelocityInsightPluginDialog;
