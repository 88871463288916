import { Checkbox } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import {
  useCreateOrganization,
  useOrganizationsFlat,
  useUpdateOrganization,
} from 'api/organizations';
import useSession from 'api/session';
import permissions from 'common/permissions';
import testIds from 'common/testIds';
import Alert, { useAlert } from 'components/shared/Alert';
import ConfirmationDialog, {
  useConfirmationDialog,
} from 'components/shared/ConfirmationDialog';
import Dialog from 'components/shared/Dialog';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import Tabs, { TabHeaderItem } from 'components/shared/Tabs';
import {
  defaultOrganization,
  Organization,
  OrganizationType,
  Timezone,
} from 'models';
import utils from 'utils';

import ContactForm from './ContactForm';
import OrganizationDepartmentSubform from './OrganizationDepartmentSubform';
import { groupValidationSchema, validationSchema } from './validationSchema';

import './OrganizationDialog.scss';

const TIMEZONE_LIST: Array<Pick<Timezone, 'timezone' | 'label'>> = [
  {
    timezone: 'AKT',
    label: 'Alaska',
  },
  {
    timezone: 'AT',
    label: 'Atlantic',
  },
  {
    timezone: 'CT',
    label: 'Central',
  },
  {
    timezone: 'ET',
    label: 'Eastern',
  },
  {
    timezone: 'HT',
    label: 'Hawaii',
  },
  {
    timezone: 'MT',
    label: 'Mountain',
  },
  {
    timezone: 'PT',
    label: 'Pacific',
  },
];
const DEFAULT_TAB_KEY = 'location';

// TODO We need to refactor the router
const getOrgTabPath = (locationPathname: string) => {
  const pathTokens = locationPathname.split('/');
  const indexOfEditToken = pathTokens.indexOf('org');
  return pathTokens
    .slice(3, indexOfEditToken === -1 ? pathTokens.length : indexOfEditToken)
    .join('/');
};

interface OrganizationDialogProps {
  organization?: Organization;
  onClose?: () => void;
}

const OrganizationDialog: FC<OrganizationDialogProps> = ({
  organization = defaultOrganization,
  onClose,
}) => {
  const { data: session } = useSession();
  const allowedCreate = {
    normal: session?.permissions.includes(permissions.ORGS_CREATE),
    trial: session?.permissions.includes(permissions.ORGS_CREATE_TRIAL),
  };
  const { isAlertOpen, alertMessage, variant, closeAlert, openAlert } =
    useAlert();
  const {
    isConfirmationLoading,
    isConfirmationDialogOpen,
    confirmationMessage,
    onDeny,
    onAccept,
  } = useConfirmationDialog();
  // TODO remove crazy pageSize and instead use a different ui component in the form,
  // e.g. one that allows infinite scrolling and/ord virtualized paginated autocompletion
  const { data: organizations, isLoading } = useOrganizationsFlat(
    '',
    0,
    10_000
  );
  const location = useLocation();
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState('location');
  const [currentOrgBasePath] = useState(getOrgTabPath(location.pathname));
  const [organizationType, setOrganizationType] = useState<OrganizationType>(
    organization.id ? organization.type : OrganizationType.ROOFTOP
  );
  const [products, setProducts] = useState({
    windowSticker: true,
    recon: allowedCreate.normal,
  });
  const [isWindowStickerTrial, setWIndowStickerTrial] = useState(
    !allowedCreate.normal
  );
  const { updateOrganizationAsync, isLoading: isUpdating } =
    useUpdateOrganization(organization.id);
  const { createOrganizationAsync, isLoading: isCreating } =
    useCreateOrganization();

  const isMutating = isUpdating || isCreating;

  useEffect(() => {
    if (currentOrgBasePath) {
      navigate(`${currentOrgBasePath}/org/${currentTab}`, {
        replace: true,
      });
    } else {
      navigate(`org/${currentTab}`, { replace: true });
    }
  }, [currentOrgBasePath, navigate, currentTab]);

  const formik = useFormik({
    initialValues: organization,
    validationSchema:
      organizationType === OrganizationType.GROUP
        ? groupValidationSchema
        : validationSchema,
    onSubmit: async (values) => {
      values = {
        ...values,
        type: organizationType,
        mainDepartment: {
          ...values.mainDepartment,
          phone: values.mailingContact?.mobilePhone?.number,
          email: values.mailingContact?.email,
        },
      };

      try {
        if (values.id) {
          await updateOrganizationAsync(values);
          onClose?.();
        } else {
          const addWindowStickerPlugin = isGroup
            ? false
            : products.windowSticker;
          const addReconVelocityPlugin = isGroup ? false : products.recon;
          const response = await createOrganizationAsync({
            ...values,
            parentId: values.parent?.id,
            addWindowStickerPlugin,
            addReconVelocityPlugin,
            trialOrganization:
              isWindowStickerTrial &&
              organizationType === OrganizationType.ROOFTOP,
          });
          const newOrganizationId = response?.data?.id;
          if (newOrganizationId) {
            onClose?.();
            navigate(`/orgs/${response?.data?.id}/overview`);
          }
        }
      } catch (error: any) {
        openAlert(`Error: ${error?.message ?? 'the Organization'}`, 'error');
      }
    },
  });

  const isVendor = organizationType === OrganizationType.VENDOR;
  const isGroup = organizationType === OrganizationType.GROUP;

  const orgDialogTabs: TabHeaderItem[] = [
    {
      key: 'location',
      label: 'Location',
      hidden: isVendor,
      showBadge:
        (formik.touched.mailingAddress && !!formik.errors.mailingAddress) ||
        (formik.touched.mailingContact && !!formik.errors.mailingContact),
    },
    {
      key: 'billing',
      label: 'Billing',
      hidden: isVendor,
      showBadge:
        (formik.touched.billingAddress && !!formik.errors.billingAddress) ||
        (formik.touched.billingContact && !!formik.errors.billingContact),
    },
    {
      key: 'sales',
      label: 'Sales',
      hidden: isVendor,
      showBadge:
        formik.touched.salesDepartment && !!formik.errors.salesDepartment,
    },
    {
      key: 'service',
      label: 'Service',
      hidden: isVendor,
      showBadge:
        formik.touched.serviceDepartment && !!formik.errors.serviceDepartment,
    },
    {
      key: 'parts',
      label: 'Parts',
      hidden: isVendor,
      showBadge:
        formik.touched.partsDepartment && !!formik.errors.partsDepartment,
    },
  ];

  const organizationsList = organizations?.pages?.[0]?.data ?? [];

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (organization === undefined) {
    return <div>NO Organization Info</div>;
  }

  const hasSettingsFeatureFlagUpdate = utils.permissions.hasPermission(
    permissions.SUPER_ADMIN,
    session?.permissions
  );

  const basename = currentOrgBasePath ? `${currentOrgBasePath}/org/` : 'org/';

  let height = 635;
  if (isVendor) {
    height = 530;
  } else if (organization?.id) {
    height = 580;
  }

  const titleType = organization?.id ? 'Edit' : 'New';
  const title = isVendor ? 'Vendor' : 'Organization';

  return (
    <>
      <Dialog
        open
        title={`${titleType} ${title}`}
        height={height}
        maxWidth="lg"
        fullWidth
        primaryButtonLabel="Save"
        block={isLoading || isMutating}
        onSubmit={formik.handleSubmit}
        onClose={onClose}
        className="OrganizationDialog"
      >
        <div className="flex-columns flex-justify-center">
          <div
            data-vas-testing={testIds.NEW_ORG_MAIN_1}
            className="OrganizationDialog-flex-container side-padding"
            style={{ maxWidth: '332px' }}
          >
            <TextField
              id="OrganizationDialog-Org-Name"
              className={`${testIds.NEW_ORG_NAME} OrganizationDialog-text-input OrganizationDialog-text-input-name`}
              autoFocus
              label="Name"
              name="name"
              variant="outlined"
              value={formik.values.name ?? ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={!!formik.errors.name}
              margin="dense"
            />
            <div className="OrganizationDialog-radio-group">
              <FormLabel className="OrganizationDialog-radio-group-label">
                Type
              </FormLabel>
              <RadioGroup
                row
                name="type"
                value={organizationType}
                onChange={(e) =>
                  setOrganizationType(e.target.value as OrganizationType)
                }
                onBlur={formik.handleBlur}
              >
                <FormControlLabel
                  value={OrganizationType.ROOFTOP}
                  control={<Radio disabled={isWindowStickerTrial} />}
                  label="Rooftop"
                />
                <FormControlLabel
                  value={OrganizationType.GROUP}
                  control={<Radio disabled={isWindowStickerTrial} />}
                  label="Group"
                />
                {hasSettingsFeatureFlagUpdate && (
                  <FormControlLabel
                    value={OrganizationType.VENDOR}
                    control={<Radio disabled={isWindowStickerTrial} />}
                    label="Vendor"
                  />
                )}
              </RadioGroup>
            </div>
            {(!isVendor && (
              <FormControl
                variant="outlined"
                className="OrganizationDialog-select OrganizationDialog-parent-select"
              >
                <InputLabel id="organization-dialog-parent-select">
                  Parent
                </InputLabel>
                <Select
                  id="OrganizationDialog-Organization-Parent"
                  labelId="parent-organization-select-label"
                  variant="outlined"
                  value={formik.values.parent?.id ?? '-'}
                  onChange={(event) => {
                    if (event.target.value === '-') {
                      formik.setFieldValue('parent', {
                        id: undefined,
                        name: undefined,
                      });
                      return;
                    }
                    formik.handleChange(event);
                  }}
                  onBlur={formik.handleBlur}
                  error={!!formik.errors.parent}
                  name="parent.id"
                  label="Parent"
                >
                  <MenuItem value="-" id="OrganizationDialog-no-parent-option">
                    No Parent (Root Organization)
                  </MenuItem>
                  {organizationsList.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )) || <></>}
            {!isVendor && (
              <TextField
                id="OrganizationDialog-Org-AccountId"
                className={`OrganizationDialog-text-input OrganizationDialog-text-input-name`}
                label="Account Id"
                name="accountId"
                variant="outlined"
                value={formik.values.accountId ?? ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={!!formik.errors.accountId}
                margin="dense"
                helperText={formik.errors.accountId}
              />
            )}
            {!organization?.id && !isVendor && !isGroup && (
              <div
                className="OrganizationDialog-radio-group"
                style={{ width: '500px' }}
              >
                <FormLabel className="OrganizationDialog-radio-group-label">
                  Products
                </FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={products?.windowSticker}
                      onChange={({ target: { checked } }) =>
                        setProducts({ ...products, windowSticker: checked })
                      }
                      disabled={isWindowStickerTrial}
                    />
                  }
                  name="windowSticker"
                  label="Window Sticker"
                />
                {
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={products?.recon}
                        onChange={({ target: { checked } }) =>
                          setProducts({ ...products, recon: checked })
                        }
                        disabled={isWindowStickerTrial}
                      />
                    }
                    name="recon"
                    label="Recon"
                  />
                }
                {!organization?.id &&
                  !isVendor &&
                  !isGroup &&
                  allowedCreate.trial && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isWindowStickerTrial}
                          onChange={({ target: { checked } }) => {
                            setWIndowStickerTrial(checked);
                            setProducts({
                              windowSticker: true,
                              recon: false,
                            });
                            setOrganizationType(OrganizationType.ROOFTOP);
                          }}
                          disabled={!allowedCreate.normal}
                        />
                      }
                      name="Window sticker trial"
                      label="Window sticker trial"
                    />
                  )}
              </div>
            )}
          </div>
          <div
            data-vas-testing={testIds.NEW_ORG_MAIN_2}
            className="OrganizationDialog-flex-container side-padding"
          >
            <TextField
              id="OrganizationDialog-Website-Url"
              className="OrganizationDialog-text-input OrganizationDialog-text-input-large-margin"
              label="Website URL"
              inputMode="url"
              type="url"
              variant="outlined"
              name="websiteUrl"
              value={formik.values.websiteUrl}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              margin="dense"
              error={!!formik.errors.websiteUrl}
            />
            <FormControl
              variant="outlined"
              className="OrganizationDialog-select OrganizationDialog-time-zone-select"
            >
              <InputLabel id="organization-dialog-time-zone-select">
                Time Zone
              </InputLabel>
              <Select
                id="OrganizationDialog-Time-Zone"
                labelId="time-zone-select-label"
                name="mainDepartment.timezone.timezone"
                value={formik.values.timezone.timezone ?? ''}
                onChange={(e) => {
                  const newTimeZone = TIMEZONE_LIST.find(
                    (listZone) => listZone.timezone === e.target.value
                  );
                  formik.setFieldValue('timezone', newTimeZone);
                }}
                onBlur={formik.handleBlur}
                placeholder="Choose Time Zone"
                label="Time Zone"
              >
                {TIMEZONE_LIST.map((item) => (
                  <MenuItem key={item.timezone} value={item.timezone}>
                    {`${item.timezone} - ${item.label}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {!isVendor && (
              <TextField
                className="OrganizationDialog-text-input"
                label="Customer Id"
                name="externalTenantId"
                variant="outlined"
                value={formik.values.externalTenantId ?? ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={!!formik.errors.externalTenantId}
                margin="dense"
                helperText={formik.errors.externalTenantId}
              />
            )}
            {isVendor && (
              <TextField
                id="OrganizationDialog-Logo-Url"
                className="OrganizationDialog-text-input OrganizationDialog-text-input-large-margin"
                label="Logo URL"
                inputMode="url"
                type="url"
                variant="outlined"
                name="logoUrl"
                value={formik.values.logoUrl}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="dense"
                error={!!formik.errors.logoUrl}
              />
            )}
          </div>
        </div>
        {!isGroup && (
          <>
            <Tabs
              tabHeaderItems={orgDialogTabs}
              defaultTabKey={DEFAULT_TAB_KEY}
              basename={basename}
              onSwitchTab={(value: any) => setCurrentTab(value)}
            />
            <Routes>
              <Route path={basename}>
                <Route
                  path="location"
                  element={<ContactForm type="LOCATION" formik={formik} />}
                />
                <Route
                  path="billing"
                  element={
                    <ContactForm
                      type="BILLING"
                      formik={formik}
                      copyMailingAddressToBillingAddress={() => {
                        formik.setValues({
                          ...formik.values,
                          billingAddress: formik.values.mailingAddress,
                        });
                      }}
                      copyMailingContactToBillingContact={() => {
                        formik.setValues({
                          ...formik.values,
                          billingContact: formik.values.mailingContact,
                        });
                      }}
                    />
                  }
                />
                <Route
                  path="sales"
                  element={
                    <OrganizationDepartmentSubform
                      departmentType="SALES"
                      formik={formik}
                    />
                  }
                />
                <Route
                  path="service"
                  element={
                    <OrganizationDepartmentSubform
                      departmentType="SERVICE"
                      formik={formik}
                    />
                  }
                />
                <Route
                  path="parts"
                  element={
                    <OrganizationDepartmentSubform
                      departmentType="PARTS"
                      formik={formik}
                    />
                  }
                />
              </Route>
            </Routes>
          </>
        )}
      </Dialog>
      <Alert
        open={isAlertOpen}
        message={alertMessage}
        variant={variant}
        onClose={closeAlert}
        duration={3500}
      />
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        isLoading={isConfirmationLoading}
        onPrimaryButtonClick={onAccept}
        onSecondaryButtonClick={onDeny}
        message={confirmationMessage}
      />
    </>
  );
};

export default OrganizationDialog;
