import { Button, TextField } from '@material-ui/core';
import { ChangeEvent, useEffect, useState } from 'react';

import { useUpdateOrganization } from 'api';
import { Organization } from 'models';

interface LogoUrlProps {
  organization?: Organization;
}

const LogoUrl = ({ organization }: LogoUrlProps) => {
  const [logoUrlText, setLogoUrlText] = useState('');
  const [showImage, setShowImage] = useState(false);
  const { updateOrganizationAsync, isLoading: isUpdating } =
    useUpdateOrganization(organization?.id ?? '');

  const handleOnClick = () => {
    updateOrganizationAsync({ ...organization, logoUrl: logoUrlText });
    setShowImage(true);
  };

  useEffect(() => {
    setLogoUrlText(organization?.logoUrl ?? '');
    setShowImage(!!organization?.logoUrl);
  }, [organization?.logoUrl]);

  return (
    <div className="Overview_logo">
      <div className="Overview_logoForm">
        <TextField
          disabled={isUpdating}
          value={logoUrlText}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setLogoUrlText(event.target.value);
            setShowImage(false);
          }}
          variant="outlined"
          size="small"
          placeholder="Logo URL"
        />
        <Button
          disabled={isUpdating}
          color="secondary"
          style={{ color: 'white' }}
          variant="contained"
          onClick={handleOnClick}
        >
          Save
        </Button>
      </div>
      {showImage && (
        <img
          className="Overview_logoUrl"
          onError={() => setShowImage(false)}
          alt="logo"
          src={logoUrlText}
        />
      )}
    </div>
  );
};

export default LogoUrl;
