import {
  Box,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import strings from 'common/strings';
import Alert, { useAlert } from 'components/shared/Alert';
import { VelocityEngagePluginItem } from 'models';

import ColorPicker from './ColorPicker';

const useStyles = makeStyles(() =>
  createStyles({
    editFields: {
      width: '100%',
    },
  })
);

interface StyleTabProps {
  engagePluginSettings: {
    data: VelocityEngagePluginItem | undefined;
    loading: boolean;
    error?: Error;
  };
  saveEngageSettings: (engageSettings: VelocityEngagePluginItem) => void;
  setIsUnsavedChanges: (open: boolean) => void;
  setUnsavedChanges: (engageSettings: VelocityEngagePluginItem) => void;
}

var StyleTab = ({
  engagePluginSettings,
  saveEngageSettings,
  setIsUnsavedChanges,
  setUnsavedChanges,
}: StyleTabProps) => {
  const [ephemeralSettings, setEphemeralSettings] = useState(
    engagePluginSettings.data
  );
  const { isAlertOpen, alertMessage, variant, openAlert, closeAlert } =
    useAlert();
  const classes = useStyles();
  const [isLogoUrlValid, setIsLogoUrlValid] = useState(true);

  useEffect(() => {
    setEphemeralSettings(engagePluginSettings.data);
  }, [engagePluginSettings]);

  const handleClickSave = async () => {
    try {
      if (ephemeralSettings) {
        await saveEngageSettings(ephemeralSettings);
        openAlert('Saved settings.', 'success');
        setIsUnsavedChanges(false);
      }
    } catch (error) {
      openAlert(error.message, 'error');
    }
  };

  const onColorChange = async (color: string, colorPickerId: string) => {
    const updatedSettings: { [key: string]: any } = {
      ...ephemeralSettings?.settings,
      [colorPickerId]: {
        dataType: 'STRING',
        value: color,
      },
    };
    await setEphemeralSettings({
      ...ephemeralSettings,
      settings: updatedSettings,
    } as VelocityEngagePluginItem);
    setIsUnsavedChanges(true);
    if (ephemeralSettings) {
      setUnsavedChanges({
        ...ephemeralSettings,
        settings: updatedSettings,
      } as VelocityEngagePluginItem);
    }
  };

  const handleLogoUrlChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsLogoUrlValid(
      event.target.value.length === 0 ||
        event.target.value.startsWith('https://')
    );

    const updatedSettings: { [key: string]: any } = {
      ...ephemeralSettings?.settings,
      LOGO_URL: {
        dataType: 'STRING',
        value: event.target.value,
      },
    };
    await setEphemeralSettings({
      ...ephemeralSettings,
      settings: updatedSettings,
    } as VelocityEngagePluginItem);
    setIsUnsavedChanges(true);
    if (ephemeralSettings) {
      setUnsavedChanges({
        ...ephemeralSettings,
        settings: updatedSettings,
      } as VelocityEngagePluginItem);
    }
  };

  const handleLayoutTemplateChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedSettings: { [key: string]: any } = {
      ...ephemeralSettings?.settings,
      LAYOUT_TEMPLATE: {
        dataType: 'STRING',
        value: event.target.value,
      },
    };
    await setEphemeralSettings({
      ...ephemeralSettings,
      settings: updatedSettings,
    } as VelocityEngagePluginItem);
    setIsUnsavedChanges(true);
    if (ephemeralSettings) {
      setUnsavedChanges({
        ...ephemeralSettings,
        settings: updatedSettings,
      } as VelocityEngagePluginItem);
    }
  };

  const handleSettingChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    settingKey: string,
    settingType: string = 'STRING'
  ) => {
    const updatedSettings: { [key: string]: any } = {
      ...ephemeralSettings?.settings,
      [settingKey]: {
        dataType: settingType,
        value: event.target.value,
      },
    };
    await setEphemeralSettings({
      ...ephemeralSettings,
      settings: updatedSettings,
    } as VelocityEngagePluginItem);
    setIsUnsavedChanges(true);
    if (ephemeralSettings) {
      setUnsavedChanges({
        ...ephemeralSettings,
        settings: updatedSettings,
      } as VelocityEngagePluginItem);
    }
  };

  return (
    <>
      <div className="folioContainer">
        <Grid container spacing={3} className="color-logo-template-wrapper">
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <button
                type="button"
                className="button"
                onClick={handleClickSave}
                style={
                  !isLogoUrlValid
                    ? { boxShadow: 'none', opacity: 0.1, cursor: 'not-allowed' }
                    : {}
                }
                disabled={!isLogoUrlValid}
              >
                Save
              </button>
            </Box>
          </Grid>
          <Grid item xs={12} className="row1">
            <Grid item xs={4}>
              <Typography variant="h6">Color Settings</Typography>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Typography variant="body1">Header</Typography>
                </Grid>
                <Grid item xs={1}>
                  <ColorPicker
                    colorPickerId="HEADER_COLOR"
                    currentHexValue={
                      ephemeralSettings?.settings?.HEADER_COLOR?.value
                    }
                    onChange={onColorChange}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1">
                    {ephemeralSettings?.settings?.HEADER_COLOR?.value}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1">Background</Typography>
                </Grid>
                <Grid item xs={1}>
                  <ColorPicker
                    colorPickerId="BACKGROUND_COLOR"
                    currentHexValue={
                      ephemeralSettings?.settings?.BACKGROUND_COLOR?.value
                    }
                    onChange={onColorChange}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1">
                    {ephemeralSettings?.settings?.BACKGROUND_COLOR?.value}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1">Text</Typography>
                </Grid>
                <Grid item xs={1}>
                  <ColorPicker
                    colorPickerId="TEXT_COLOR"
                    currentHexValue={
                      ephemeralSettings?.settings?.TEXT_COLOR?.value
                    }
                    onChange={onColorChange}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1">
                    {ephemeralSettings?.settings?.TEXT_COLOR?.value}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1">Links</Typography>
                </Grid>
                <Grid item xs={1}>
                  <ColorPicker
                    colorPickerId="LINK_COLOR"
                    currentHexValue={
                      ephemeralSettings?.settings?.LINK_COLOR?.value
                    }
                    onChange={onColorChange}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1">
                    {ephemeralSettings?.settings?.LINK_COLOR?.value}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1">Buttons</Typography>
                </Grid>
                <Grid item xs={1}>
                  <ColorPicker
                    colorPickerId="BUTTON_COLOR"
                    currentHexValue={
                      ephemeralSettings?.settings?.BUTTON_COLOR?.value
                    }
                    onChange={onColorChange}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1">
                    {ephemeralSettings?.settings?.BUTTON_COLOR?.value}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1">Button Text</Typography>
                </Grid>
                <Grid item xs={1}>
                  <ColorPicker
                    colorPickerId="BUTTON_TEXT_COLOR"
                    currentHexValue={
                      ephemeralSettings?.settings?.BUTTON_TEXT_COLOR?.value
                    }
                    onChange={onColorChange}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1">
                    {ephemeralSettings?.settings?.BUTTON_TEXT_COLOR?.value}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1">Widgets</Typography>
                </Grid>
                <Grid item xs={1}>
                  <ColorPicker
                    colorPickerId="WIDGET_COLOR"
                    currentHexValue={
                      ephemeralSettings?.settings?.WIDGET_COLOR?.value
                    }
                    onChange={onColorChange}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1">
                    {ephemeralSettings?.settings?.WIDGET_COLOR?.value}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6">Logo</Typography>
              <TextField
                label="Logo URL"
                value={ephemeralSettings?.settings?.LOGO_URL?.value || ''}
                onChange={handleLogoUrlChange}
                margin="normal"
                variant="outlined"
                className={classes.editFields}
                size="small"
                error={!isLogoUrlValid}
                helperText={!isLogoUrlValid && strings.DEALER_LOGO_URL}
              />
              <Typography variant="h6">Template</Typography>
              <TextField
                label="Layout Template"
                value={
                  ephemeralSettings?.settings?.LAYOUT_TEMPLATE?.value || ''
                }
                onChange={handleLayoutTemplateChange}
                margin="normal"
                variant="outlined"
                className={classes.editFields}
                size="small"
              />
            </Grid>
            <Grid item xs={4} />
          </Grid>
          <Divider />
          <Grid item xs={8} className="advanced-style-setting-wrapper">
            <Typography variant="h6">Advanced Style Settings</Typography>
            <TextField
              label="CSS Override URL"
              value={ephemeralSettings?.settings?.CSS_OVERRIDE_URL?.value || ''}
              onChange={(event) =>
                handleSettingChange(
                  event as React.ChangeEvent<HTMLInputElement>,
                  'CSS_OVERRIDE_URL'
                )
              }
              margin="normal"
              variant="outlined"
              className={classes.editFields}
              size="small"
            />
            <TextField
              label="JavaScript Override URL"
              value={
                ephemeralSettings?.settings?.JAVASCRIPT_OVERRIDE_URL?.value ||
                ''
              }
              onChange={(event) =>
                handleSettingChange(
                  event as React.ChangeEvent<HTMLInputElement>,
                  'JAVASCRIPT_OVERRIDE_URL'
                )
              }
              margin="normal"
              variant="outlined"
              className={classes.editFields}
              size="small"
            />
          </Grid>
          <Grid item xs={4} />
        </Grid>
        <Alert
          open={isAlertOpen}
          message={alertMessage}
          variant={variant}
          onClose={closeAlert}
          duration={6000}
        />
      </div>
    </>
  );
};

export default StyleTab;
