import {
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
} from '@material-ui/core';
import {
  ArrowDropDown,
  ChatBubble,
  Check,
  Close,
  Email,
  Notifications,
  Public,
  Smartphone,
} from '@material-ui/icons';
import { flatMapDeep, keyBy, mapValues } from 'lodash';
import {
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AutoSizer,
  Column,
  Index,
  Table,
  TableRowProps,
} from 'react-virtualized';

import LoadingIndicator from 'components/shared/LoadingIndicator';
import {
  NotificationChannel,
  NotificationChannelTableColumn,
  NotificationChannelType,
  NotificationEvent,
  NotificationEventCategory,
  NotificationStepDefinition,
  NotificationSubscription,
  ScheduleType,
  UINotificationStepDefinition,
} from 'models';
import {
  addSubscription,
  getSubscriptions,
  subscribeStep,
  unsubscribe,
  unsubscribeStep,
} from 'store/actions/organizations/users/notifications';
import { RootState } from 'store/reducers';

import 'react-virtualized/styles.css';
import './UserNotificationsView.scss';

const NOTIFICATION_CHANNELS: NotificationChannelTableColumn[] = [
  {
    key: NotificationChannelType.PERSIST,
    title: 'Persistent',
    icon: Notifications,
    enabled: false,
  },
  {
    key: NotificationChannelType.EMAIL_TRANS,
    title: 'Email',
    icon: Email,
    enabled: true,
  },
  {
    key: NotificationChannelType.MOBILE_SMS,
    title: 'SMS',
    icon: ChatBubble,
    enabled: true,
  },
  {
    key: NotificationChannelType.MOBILE_PUSH,
    title: 'Mobile Push',
    icon: Smartphone,
    enabled: true,
  },
  {
    key: NotificationChannelType.WEB_PUSH,
    title: 'Web Push',
    icon: Public,
    enabled: true,
  },
];

interface UserNotificationsViewProps {
  organizationId: string;
  userId: string;
}

const UserNotificationsView = ({
  organizationId,
  userId,
}: UserNotificationsViewProps): ReactElement => {
  const dispatch = useDispatch();

  const { notifications: userNotifications } = useSelector(
    ({ organizations: { users } }: RootState) => ({ ...users })
  );

  const initialLoadingNotifications = useMemo(
    () =>
      mapValues(
        keyBy(userNotifications.data.subscriptions, 'eventName'),
        () => ({
          channels: [],
          stepDefinitions: [],
        })
      ),
    [userNotifications.data]
  );

  const initialShowIndividualStepSubscriptions = useMemo(
    () =>
      mapValues(
        keyBy(userNotifications.data.subscriptions, 'eventName'),
        () => false
      ),
    [userNotifications.data]
  );

  const [
    [loadingNotifications, setLoadingNotifications],
    [showIndividualStepSubscriptions, setShowIndividualStepSubscriptions],
    notificationsTable,
  ] = [
    useState<{
      [key: string]: {
        channels: NotificationChannel['id'][];
        stepDefinitions: NotificationStepDefinition['id'][];
      };
    }>(initialLoadingNotifications),
    useState<{
      [key: string]: boolean;
    }>(initialShowIndividualStepSubscriptions),
    useRef<Table>(null),
  ];

  useEffect(() => {
    setLoadingNotifications(initialLoadingNotifications);
  }, [initialLoadingNotifications, setLoadingNotifications]);

  const subscriptionMap = useMemo(
    () =>
      keyBy(
        userNotifications.data.subscriptions,
        ({ eventName, channelId }) => `${eventName}-${channelId}`
      ),
    [userNotifications.data]
  );

  const tableColumns: NotificationChannelTableColumn[] = [
    {
      title: 'Type',
      key: 'type',
      enabled: true,
    },
    ...NOTIFICATION_CHANNELS,
  ];

  const allSteps = useMemo(
    () =>
      flatMapDeep(userNotifications.data.stepDefinitions, (stepDefinition) =>
        stepDefinition.childSteps
          ? [
              stepDefinition,
              ...stepDefinition.childSteps.map((step) => ({
                ...step,
                level: 1,
              })),
            ]
          : stepDefinition
      ),
    [userNotifications.data]
  );

  const tableData: Array<
    NotificationEventCategory | NotificationEvent | UINotificationStepDefinition
  > = useMemo(
    () =>
      userNotifications.data.eventCategories.flatMap((category) => [
        category,
        ...category.events.flatMap((event) => {
          if (
            !event.isStepEvent ||
            !showIndividualStepSubscriptions[event.name]
          )
            return event;

          return [
            event,
            ...allSteps.map((step) => ({
              ...step,
              eventName: event.name,
            })),
          ];
        }),
      ]),
    [
      userNotifications.data.eventCategories,
      showIndividualStepSubscriptions,
      allSteps,
    ]
  );

  useEffect(() => {
    dispatch(
      getSubscriptions({
        orgId: organizationId!,
        userId: userId!,
      })
    );
  }, [dispatch, organizationId, userId]);

  const updateSubscription = async (subscription: NotificationSubscription) => {
    setLoadingNotifications((previousLoadingNotifications) => ({
      ...previousLoadingNotifications,
      [subscription.eventName]: {
        ...previousLoadingNotifications[subscription.eventName],
        channels: [
          ...previousLoadingNotifications[subscription.eventName].channels,
          subscription.channelId,
        ],
      },
    }));

    try {
      if (subscription.scheduleType === 'DISABLED') {
        await dispatch(
          unsubscribe({
            orgId: organizationId!,
            userId: userId!,
            eventName: subscription.eventName,
            channelId: subscription.channelId,
          })
        );
      } else {
        await dispatch(
          addSubscription({
            orgId: organizationId!,
            userId: userId!,
            subscription,
          })
        );
      }
    } finally {
      setLoadingNotifications((previousLoadingNotifications) => ({
        ...previousLoadingNotifications,
        [subscription.eventName]: {
          ...previousLoadingNotifications[subscription.eventName],
          channels: previousLoadingNotifications[
            subscription.eventName
          ].channels.filter(
            (channelId) => subscription.channelId !== channelId
          ),
        },
      }));
    }
  };

  const toggleIndividualStepSubscriptions = (eventName: string) => {
    const updatedShowIndividualStepSubscriptions = {
      ...showIndividualStepSubscriptions,
      [eventName]: !showIndividualStepSubscriptions[eventName],
    };
    setShowIndividualStepSubscriptions(updatedShowIndividualStepSubscriptions);
  };

  const updateIndividualStepSubscription = async (
    stepDefinition: UINotificationStepDefinition,
    subscribed: boolean
  ) => {
    setLoadingNotifications((previousLoadingNotifications) => ({
      ...previousLoadingNotifications,
      [stepDefinition.eventName]: {
        ...previousLoadingNotifications[stepDefinition.eventName],
        stepDefinitions: [
          ...previousLoadingNotifications[stepDefinition.eventName]
            .stepDefinitions,
          stepDefinition.id,
        ],
      },
    }));

    try {
      const payload = {
        orgId: organizationId!,
        userId: userId!,
        eventName: stepDefinition.eventName,
        stepDefinitionId: stepDefinition.id,
        subscribed,
      };

      if (subscribed) await dispatch(subscribeStep(payload));
      else await dispatch(unsubscribeStep(payload));
    } finally {
      setLoadingNotifications((previousLoadingNotifications) => ({
        ...previousLoadingNotifications,
        [stepDefinition.eventName]: {
          ...previousLoadingNotifications[stepDefinition.eventName],
          stepDefinitions: previousLoadingNotifications[
            stepDefinition.eventName
          ].stepDefinitions.filter(
            (stepDefinitionId) => stepDefinition.id !== stepDefinitionId
          ),
        },
      }));
    }
  };

  const notificationScheduleRow = ({
    style,
    rowData,
  }: TableRowProps): ReactNode => {
    const eventCategory =
      'events' in rowData ? (rowData as NotificationEventCategory) : undefined;
    if (eventCategory) {
      return (
        <div
          className="ReactVirtualized__Table__row NotificationTypeSchedule__typeContainer padding-sm"
          style={style}
          key={eventCategory.type}
        >
          <Button startIcon={<ArrowDropDown />}>
            <b>{eventCategory.name}</b>
          </Button>
        </div>
      );
    }

    if ('isStepEvent' in rowData) {
      const event = rowData as NotificationEvent;

      return (
        <div
          className="ReactVirtualized__Table__row"
          style={style}
          key={event.name}
        >
          <span className="ReactVirtualized__Table__rowColumn NotificationTypeSchedule__typeContainer flex-rows">
            <span>{event.title}</span>
            {event.isStepEvent && (
              <button
                type="button"
                className="ShowIndividualButton"
                onClick={() => toggleIndividualStepSubscriptions(event.name)}
              >
                {showIndividualStepSubscriptions[event.name] ? 'Hide' : 'Show'}{' '}
                Individual Steps
              </button>
            )}
          </span>

          {userNotifications.data.channels?.map((channel) => {
            const subscription = subscriptionMap[
              `${event.name}-${channel.id}`
            ] ?? {
              eventName: event.name,
              channelId: channel.id,
              scheduleType: ScheduleType.DEFAULT,
              schedule: null,
            };

            let selectedValue = 'off';
            switch (subscription.scheduleType) {
              case ScheduleType.DISABLED:
                selectedValue = 'off';
                break;
              case ScheduleType.CUSTOM:
                selectedValue = 'custom';
                break;
              case ScheduleType.DEFAULT:
              default:
                selectedValue = 'on';
                break;
            }

            const notificationIsLoading = loadingNotifications[
              event.name
            ]?.channels.includes(subscription.channelId);

            return (
              <span
                key={`${channel.id}-${channel.name}`}
                className="NotificationTypeSchedule__selectContainer center-align align-center ReactVirtualized__Table__rowColumn padding-left-sm padding-right-sm"
              >
                <FormControl variant="outlined" size="small" fullWidth>
                  <Select
                    disabled={notificationIsLoading}
                    IconComponent={() => {
                      if (notificationIsLoading) {
                        return <LoadingIndicator size={24} />;
                      }

                      const Icon = selectedValue === 'off' ? Close : Check;
                      const color =
                        selectedValue === 'off' ? '#888' : '#29a14f';

                      return (
                        <Icon
                          className="NotificationsSubscriptionSettingIcon"
                          style={{ color }}
                        />
                      );
                    }}
                    value={selectedValue}
                    onChange={({ target: { value } }) =>
                      updateSubscription({
                        ...subscription,
                        scheduleType:
                          value === 'on'
                            ? ScheduleType.DEFAULT
                            : ScheduleType.DISABLED,
                      })
                    }
                  >
                    <MenuItem value="off">Off</MenuItem>
                    <MenuItem value="on">On</MenuItem>
                  </Select>
                </FormControl>
              </span>
            );
          })}
        </div>
      );
    }

    const stepDefinition = rowData as UINotificationStepDefinition;
    const isSubscribed = userNotifications.data.subscribedStepDefinitionIds[
      stepDefinition.eventName
    ]?.includes(stepDefinition.id);
    const stepDefinitionIsLoading = loadingNotifications[
      stepDefinition.eventName
    ]?.stepDefinitions.includes(stepDefinition.id);

    return (
      <div
        className="ReactVirtualized__Table__row padding-left-lg padding-right-lg"
        key={stepDefinition.id}
        style={style}
      >
        <span
          className="flex-grow"
          style={{ paddingLeft: `${10 * (stepDefinition.level ?? 0)}px` }}
        >
          {stepDefinition.name}
        </span>

        {!stepDefinition.childSteps?.length && (
          <FormControlLabel
            value="left"
            control={
              <Switch
                color="primary"
                checked={stepDefinitionIsLoading ? !isSubscribed : isSubscribed}
                onChange={() =>
                  updateIndividualStepSubscription(
                    stepDefinition,
                    !isSubscribed
                  )
                }
                name={`${stepDefinition.id}_subscriptionCheckbox`}
              />
            }
            label={stepDefinitionIsLoading && <LoadingIndicator size={20} />}
            labelPlacement="start"
          />
        )}
      </div>
    );
  };

  const getRowHeight = ({ index }: Index) => {
    const rowData = tableData[index];

    // event category
    if ('events' in rowData) return 40;

    // event
    if ('isStepEvent' in rowData) return 60;

    // step definition
    if ('childSteps' in rowData) return 30;

    return 40;
  };

  return (
    <div className="full-height">
      {userNotifications.error && <p>{userNotifications.error.message}</p>}

      {userNotifications.loading ? (
        <LoadingIndicator />
      ) : (
        <AutoSizer style={{ flex: 1 }}>
          {({ width, height }) => (
            <Table
              ref={notificationsTable}
              width={width}
              height={height}
              rowHeight={getRowHeight}
              headerHeight={40}
              rowCount={tableData.length}
              rowGetter={({ index }) => tableData[index]}
              rowRenderer={notificationScheduleRow}
            >
              {tableColumns
                .filter(({ enabled }) => enabled)
                .map((column, index) => {
                  const Icon = column.icon;
                  return (
                    <Column
                      key={`${column.title}-${column.key}`}
                      label={
                        <span className="valign-center">
                          {Icon && (
                            <Icon className="NotificationsTableColumnHeader_icon" />
                          )}
                          <span>{column.title}</span>
                        </span>
                      }
                      width={index === 0 ? 230 : 140}
                      dataKey={column.key}
                      columnData={column}
                    />
                  );
                })}
            </Table>
          )}
        </AutoSizer>
      )}
    </div>
  );
};
export default UserNotificationsView;
