import fetchIntercept from 'fetch-intercept';
import { useEffect } from 'react';
import { Socket } from 'socket.io-client';

import { useSocket } from 'hooks/useSocket';

const mutationMethods = ['POST', 'PUT', 'PATCH', 'DELETE'];

let interceptorTenantId: string | undefined;
let interceptorSocket: Socket | undefined;

export const registerFetchIntercept = () => {
  fetchIntercept.register({
    response: function (response: any) {
      const method = response?.request?.method;
      const url = response.url;
      if (
        response.ok &&
        mutationMethods.includes(method) &&
        interceptorSocket !== undefined
      ) {
        const tagsPattern = /\/plugins\/reconvelocity\/tags/;
        const isTagsMatch = !!url.match(tagsPattern);
        const tasksPattern = /\/plugins\/reconvelocity\/tasks/;
        const isTasksMatch = !!url.match(tasksPattern);
        const stepsPattern = /\/plugins\/reconvelocity\/steps/;
        const isStepsMatch = !!url.match(stepsPattern);

        let room;
        if (isTagsMatch) {
          room = `tenant/${interceptorTenantId}/tags`;
        } else if (isTasksMatch) {
          room = `tenant/${interceptorTenantId}/taskTemplates`;
        } else if (isStepsMatch) {
          room = `tenant/${interceptorTenantId}/workflowSteps`;
        }

        if (room !== undefined) {
          const event = `${room}/update`;
          interceptorSocket.emit('reconVelocity', { room, event });
        }
      }
      return response;
    },
  });
};

export const useSendInventoryUpdates = (tenantId: string | undefined) => {
  const { socket } = useSocket();

  useEffect(() => {
    if (socket && tenantId) {
      interceptorSocket = socket;
      interceptorTenantId = tenantId;
      return () => {
        interceptorSocket = undefined;
        interceptorTenantId = undefined;
      };
    }
  }, [socket, tenantId]);
};
