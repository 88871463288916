import { Box, IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { FC } from 'react';

import Table from 'components/shared/Table';
import { ReconVelocityStepDefinitionAssociation } from 'models';

interface SharedStepsMappingProps {
  sharedSteps: ReconVelocityStepDefinitionAssociation[];
  deleteStepAssociation: (associationId: string) => Promise<void>;
}

const SharedStepsMapping: FC<SharedStepsMappingProps> = ({
  sharedSteps,
  deleteStepAssociation,
}) => {
  const handleDeleteStepAssociation = async (associationId: string) => {
    await deleteStepAssociation(associationId);
  };

  return (
    <Box className="steps-mapping">
      <Table
        columns={[
          {
            heading: 'Dealership',
            dataKey: 'secondaryTenant.name',
            content: ({ rowData }) => rowData.secondaryTenant.name || '',
            width: 325,
          },
          {
            heading: 'Step',
            dataKey: 'secondaryStep.name',
            content: ({ rowData }) => rowData.secondaryStep.name || '',
            width: 325,
          },
          {
            heading: '',
            dataKey: '__delete__',
            content: ({ rowData }) => (
              <IconButton
                onClick={() => handleDeleteStepAssociation(rowData.id)}
              >
                <Delete />
              </IconButton>
            ),
          },
        ]}
        items={sharedSteps}
        totalRowCount={sharedSteps.length}
        height={250}
      />
    </Box>
  );
};

export default SharedStepsMapping;
