import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { Link } from 'react-router-dom';

import './SettingsSectionLink.scss';

interface SettingsSectionLinkProps {
  icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>>;
  label: string;
  to: string;
}

var SettingsSectionLink = ({
  icon: Icon,
  label,
  to,
}: SettingsSectionLinkProps) => {
  return (
    <Link className="SettingsSectionLink-container padding" to={to}>
      <div
        className="SettingsSectionLink-icon-container"
        id={`${label.replace(' ', '-')}-icon-container`}
      >
        <Icon fontSize="inherit" />
      </div>
      <div className="SettingsSectionLink-label">{label}</div>
    </Link>
  );
};

export default SettingsSectionLink;
