import React from 'react';
import { Form } from 'react-bootstrap';

import { useOrganizationGroups } from 'api/organizations/groups';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import Tabs, { InlineTab, TabHeaderItem } from 'components/shared/Tabs';
import { OrganizationItemLink, User } from 'models';

import UserNotificationsView from './UserNotificationsView/UserNotificationsView';

import './UserOrganizationDetailView.scss';

const NOTIFICATIONS_TAB_KEY = 'notifications';

interface UserOrganizationDetailViewProps {
  organization?: OrganizationItemLink;
  user: User;
  setUser: (user: User) => void;
}

const editUserTabs: TabHeaderItem[] = [
  { key: 'groups', label: 'Groups' },
  { key: NOTIFICATIONS_TAB_KEY, label: 'Notifications' },
];

var UserOrganizationDetailView = (props: UserOrganizationDetailViewProps) => {
  const { organization, user, setUser } = props;

  const { data: groups, isFetching } = useOrganizationGroups(organization?.id);

  if (!organization?.id) {
    return null;
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const userGroups = user.groups || [];
    const groupId = event.target.value;
    const index = userGroups.findIndex((g) => g.id === groupId);

    if (index > -1) {
      // remove group
      userGroups.splice(index, 1);
    } else {
      // add group
      const group = groups?.find(({ id }) => groupId === id);
      if (!group || !organization) return;

      userGroups.push({
        id: group.id,
        name: group.name,
        organization: {
          id: organization.id,
          name: organization.name,
        },
      });
    }
    setUser({ ...user, groups: userGroups });
  };

  return (
    <div
      id="UserOrganizationDetailView"
      className="UserOrganizationDetailView full-height flex-rows"
    >
      <div className="heading full-width flex-columns flex-align-center">
        <h3>{organization?.name}</h3>
        <div className="flex-grow align-right">
          <Form.Check
            id="UserOrganizationDetailView.defaultOrganization"
            type="checkbox"
            name="UserOrganizationDetailView.defaultOrganization"
            label="Default Organization"
            value={user?.defaultOrganization?.id}
            onChange={() =>
              setUser({ ...user, defaultOrganization: organization })
            }
            checked={user?.defaultOrganization?.id === organization?.id}
          />
        </div>
      </div>
      <Tabs
        tabHeaderItems={
          user?.id && organization?.id
            ? editUserTabs
            : editUserTabs.filter((tab) => tab.key !== NOTIFICATIONS_TAB_KEY)
        }
        defaultTabKey="groups"
        basename={`${user?.id ? user.id : 'userId'}/`}
      />
      <InlineTab path="groups">
        {isFetching ? (
          <div className="full-height full-width relative">
            <LoadingIndicator />
          </div>
        ) : (
          <div className=" align-center tab-content">
            <div className="align-left">
              {groups && groups?.length > 0
                ? groups.map((group) => {
                    const checked = user?.groups?.find(
                      (userGroup) => userGroup.id === group.id
                    );
                    return (
                      <div key={group.id}>
                        <Form.Check
                          id={`UserOrganizationDetailView.groups.${group.id}`}
                          type="checkbox"
                          name="UserOrganizationDetailView.groups"
                          label={group.name}
                          value={group.id}
                          onChange={handleCheckboxChange}
                          checked={!!checked}
                        />
                      </div>
                    );
                  })
                : 'This organization does not have any groups.'}
            </div>
          </div>
        )}
      </InlineTab>
      <InlineTab
        path="notifications"
        className="UserNotifications__section full-height"
      >
        {user?.id && organization?.id && (
          <UserNotificationsView
            organizationId={organization?.id}
            userId={user?.id}
          />
        )}
      </InlineTab>
    </div>
  );
};

export default UserOrganizationDetailView;
