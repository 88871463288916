type TestIdParams = { [key in string]?: string };

export const generateSelectorId = (testId: string) =>
  `[data-vas-testing="${testId}"]`;
export const generateNameSelector = (testId: string) => `[name="${testId}"]`;
export const generateEventKeySelector = (testId: string) =>
  `[data-rb-event-key="${testId}"]`;

export function generateTestId(message: string, params?: TestIdParams): string {
  let newMessage = message;
  if (params) {
    Object.keys(params).forEach((key) => {
      const value = params[key];
      if (!value) return;
      newMessage = newMessage.replace(`{${key}}`, value);
    });
  }
  return newMessage;
}

export default {
  // testId format screenLocation-elementName-elementType

  // New Organization
  NEW_ORG_MAIN_1: 'newOrganization-main1-container',
  NEW_ORG_MAIN_2: 'newOrganization-main2-container',
  NEW_ORG_NAME: 'newOrganization-name-textField',

  // Organization Search List
  ORGANIZATION_LIST_ITEM:
    'organization-list-{organization}-{organizationType}-item',

  // Organization View
  ORGANIZATION_NAME: 'organization-view-name-text',
  ORGANIZATION_DEPT_PHONE: 'organization-view-{type}-phone-text',
  ORGANIZATION_DEPT_EMAIL: 'organization-view-{type}-email-text',
  ORGANIZATION_DEPT_EDIT_ICON: 'organization-view-{type}-edit-icon',

  ORGANIZATION_SALES_PHONE: 'organization-view-sales-phone-text',
  ORGANIZATION_SALES_EMAIL: 'organization-view-sales-email-text',
  ORGANIZATION_SERVICE_PHONE: 'organization-view-service-phone-text',
  ORGANIZATION_SERVICE_EMAIL: 'organization-view-service-email-text',
  ORGANIZATION_PARTS_PHONE: 'organization-view-parts-phone-text',
  ORGANIZATION_PARTS_EMAIL: 'organization-view-parts-email-text',

  ORGANIZATION_SALES_EDIT_ICON: 'organization-view-sales-edit-icon',
  ORGANIZATION_SERVICE_EDIT_ICON: 'organization-view-service-edit-icon',
  ORGANIZATION_PARTS_EDIT_ICON: 'organization-view-parts-edit-icon',

  ORGANIZATIONS_CONTAINER_TABS: 'organizations-conatiner-{label}-tab',
  ORGANIZATIONS_PLUGINS_VIEW_MODULES: 'plugins-view-{label}-modules',
  ORGANIZATIONS_PLUGINS_MODULES_EDIT_BUTTON: 'plugins-{label}-edit-button',

  RECON_VELOCITY_PLUGIN_DIALOG_SWITCH_BUTTON:
    'recon-velocity-plugin-dialog-switch-button',
  RECON_VELOCITY_PLUGIN_DIALOG_SWITCH_BUTTON_LABEL:
    'recon-velocity-plugin-dialog-switch-button-label',
  RECON_VELOCITY_DIALOG_CLOSE_BUTTON: 'reconvelocity-modal-close-button',

  ADMIN_HEADER_NAVBAR: 'admin-header-navbar',
};
