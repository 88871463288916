import { combineReducers } from 'redux';

import generalReducer, { initialState as generalInitialState } from './general';

const reconvelocityReducer = combineReducers({
  general: generalReducer,
});

export type ReconVelocityPluginState = ReturnType<typeof reconvelocityReducer>;

export const initialState: ReconVelocityPluginState = {
  general: generalInitialState,
};

export default reconvelocityReducer;
