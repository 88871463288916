import { HelpOutline } from '@material-ui/icons';
import React from 'react';

import { useBrochureList } from 'api/settings/brochures';

import {
  BrochureManagerContext,
  brochureReducer,
  initialBrochureState,
} from './brochureContext';
import { BrochureManagerToolbar } from './BrochureToolbar';
import { BrochureUploader } from './BrochureUploader';
import { useBrochureStyles } from './materialStyles';

import 'components/shared/CollectionHeaderBar/CollectionHeaderBar.scss';
import '../SettingsView.scss';

/**
 * @name BrochureManager
 * @description Allow browsing and updating brochure catalog for RV Admin.
 * @see https://www.figma.com/file/UzbAzGFerwBekhrYog9fnK/Velocity-Admin?node-id=6633%3A582
 */

export var BrochureMangerLayout = () => {
  const classes = useBrochureStyles();
  const { data } = useBrochureList();
  const [state, dispatch] = React.useReducer(
    brochureReducer,
    initialBrochureState
  );
  const isInitialState = state.modelOptions.length < 1;
  const ctxState = { ...state, data: data || [] };

  var TitleBar = () => {
    const title = 'Upload Brochure';
    const text = 'Please select make, model and year';
    return (
      <div className={classes.uploadContainer}>
        <div className={classes.uploadContainer}>
          <div>
            <HelpOutline className={classes.uploadIcon} />
          </div>
          <span className={classes.uploadTitle}>{title}</span>
          <div className={classes.uploadText}>{text}</div>
        </div>
      </div>
    );
  };

  return (
    <BrochureManagerContext.Provider value={{ state: ctxState, dispatch }}>
      <BrochureManagerToolbar />
      {isInitialState ? <TitleBar /> : <BrochureUploader />}
    </BrochureManagerContext.Provider>
  );
};

export default BrochureMangerLayout;
