import { TextField } from '@material-ui/core';
import React, { FC } from 'react';
import { SingleValue } from 'react-select';

import { useOrganizationHistoryUserOptions } from 'api/organizations/history/useOrganizationHistoryUserOptions';
import { SelectOption } from 'models';

import HistoryUserSelect from './HistoryUserSelect';
import { HandleUserIdChange } from './types';

import './HistoryHeader.scss';

export interface HistoryHeaderProps {
  organizationId: string;
  searchInput: string;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  userId: SelectOption;
  onUserIdChange: (option: SingleValue<SelectOption>) => void;
}

const HistoryHeader: FC<HistoryHeaderProps> = ({
  organizationId,
  searchInput,
  onSearchChange,
  userId,
  onUserIdChange,
}) => {
  const { data } = useOrganizationHistoryUserOptions(organizationId);

  const handleUserIdChange: HandleUserIdChange = (newValue, meta) => {
    if (meta.action === 'remove-value') {
      return onUserIdChange({ label: 'Select User...', value: '' });
    }

    onUserIdChange(newValue as SingleValue<SelectOption>);
  };

  return (
    <div className="HistoryHeader">
      <div className="HistorySearchInput">
        <TextField
          id="history-search-input"
          variant="outlined"
          size="small"
          className="search-input"
          value={searchInput}
          onChange={onSearchChange}
          placeholder="Search"
          autoFocus
        />
      </div>

      <HistoryUserSelect
        options={data ?? []}
        value={userId}
        onChange={handleUserIdChange}
      />
    </div>
  );
};

export default HistoryHeader;
