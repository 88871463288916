import { configuredFetch } from 'api/base';
import { InventoryFilter, Organization } from 'models';

export type InventoryRequestParams = {
  orgId: Organization['id'];
};

function baseUrl({ orgId }: InventoryRequestParams) {
  return `/orgs/${orgId}/plugins/velocityvdp/inventory`;
}

/**
 * Get a folio template filter options for a tenant.
 * Endpoint: GET /orgs/${orgId}/plugins/velocityvdp/inventory/filters
 */
async function getInventoryFilters({
  orgId,
}: InventoryRequestParams): Promise<InventoryFilter[]> {
  const url = `${baseUrl({ orgId })}/filters`;

  const { data } = await configuredFetch<InventoryFilter[]>(url);
  return data;
}

export default {
  getInventoryFilters,
};
