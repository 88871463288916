import api from 'api';
import {
  AssignPluginRequestParams,
  PluginRequestParams,
  PluginsRequestParams,
} from 'api/organizations/plugins';
import { PluginItem } from 'models/plugins';
import {
  ActionTypeFromCreators,
  createAsyncAction,
} from 'store/actions/helpers';

import { OrganizationPluginOptionsActionTypes } from './options';
import { ReconVelocityPluginActionTypes } from './reconvelocity';
import { VelocityEngagePluginActionTypes } from './velocityengage';

export const actions = {
  getPlugins: 'Get Organization Plugins',
  getPlugin: 'Get Organization Plugin',
  assignPlugin: 'Assign Organization Plugin',
  unassignPlugin: 'Unassign Organization Plugin',
} as const;

// -- Get Organization Plugins
export const {
  types: getPluginsActionTypes,
  creators: getPluginsActionCreators,
  action: getPlugins,
} = createAsyncAction<
  typeof actions.getPlugins,
  PluginsRequestParams,
  PluginItem[]
>(actions.getPlugins, ({ orgId }) =>
  api.organizations.plugins.getPlugins({ orgId })
);

type GetOrganizationPluginsAction = ActionTypeFromCreators<
  typeof actions.getPlugins,
  PluginsRequestParams,
  PluginItem[],
  typeof getPluginsActionCreators
>;
// --

// -- Get Organization Plugin
export const {
  types: getPluginActionTypes,
  creators: getPluginActionCreators,
  action: getPlugin,
} = createAsyncAction<
  typeof actions.getPlugin,
  PluginRequestParams,
  PluginItem
>(actions.getPlugin, ({ orgId, pluginId }) =>
  api.organizations.plugins.getPlugin({ orgId, pluginId })
);

type GetOrganizationPluginAction = ActionTypeFromCreators<
  typeof actions.getPlugin,
  PluginRequestParams,
  PluginItem,
  typeof getPluginActionCreators
>;
// --

// -- Assign Organization Plugin
export const {
  types: assignPluginActionTypes,
  creators: assignPluginActionCreators,
  action: assignPlugin,
} = createAsyncAction<
  typeof actions.assignPlugin,
  AssignPluginRequestParams,
  PluginItem
>(actions.assignPlugin, ({ orgId, pluginId, data }) =>
  api.organizations.plugins.assignPlugin({ orgId, pluginId, data })
);

type AssignOrganizationPluginAction = ActionTypeFromCreators<
  typeof actions.assignPlugin,
  AssignPluginRequestParams,
  PluginItem,
  typeof assignPluginActionCreators
>;
// --

// -- Unassign Organization Plugin
export const {
  types: unassignPluginActionTypes,
  creators: unassignPluginActionCreators,
  action: unassignPlugin,
} = createAsyncAction<
  typeof actions.unassignPlugin,
  PluginRequestParams,
  boolean
>(actions.unassignPlugin, ({ orgId, pluginId }) =>
  api.organizations.plugins.unassignPlugin({ orgId, pluginId })
);

type UnassignOrganizationPluginAction = ActionTypeFromCreators<
  typeof actions.unassignPlugin,
  PluginRequestParams,
  boolean,
  typeof unassignPluginActionCreators
>;
// --

export type OrganizationPluginsActionTypes =
  | OrganizationPluginOptionsActionTypes
  | GetOrganizationPluginsAction
  | GetOrganizationPluginAction
  | AssignOrganizationPluginAction
  | UnassignOrganizationPluginAction
  | ReconVelocityPluginActionTypes
  | VelocityEngagePluginActionTypes;
