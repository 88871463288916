import { NavLink } from 'react-router-dom';

var VelocityWindowStickerSetup = () => {
  return (
    <div
      style={{
        padding: '25px 15%',
        fontSize: 'medium',
        height: 'calc(100% - 60px)',
        overflow: 'auto',
      }}
    >
      <h2>Follow these steps to get started with Velocity Window Stickers</h2>
      <ol>
        <li>
          Navigate to our Velocity Window Stickers URL by{' '}
          <a href="https://windowsticker.velocityengage.com/login">
            clicking here to open in your web browser
          </a>
        </li>
        <li>Next, login using the credentials provided in your email</li>
        <li>Enter or scan a VIN to pull a window sticker</li>
        <video style={{ margin: '10px', maxWidth: '100%' }} controls>
          <source src="https://velocityautomotive-public-docs.s3.amazonaws.com/loginWindowStickerReport.mp4"></source>
        </video>
        <li>
          Congrats on purchasing Velocity Window Stickers! You are ready to go!
          Start pulling window stickers!
        </li>
        <li>Product Demo</li>
        <video style={{ margin: '10px', maxWidth: '100%' }} controls>
          <source
            src="https://velocityautomotive-public-docs.s3.amazonaws.com/VelocityWindowStickers.webm"
            type="video/webm"
          ></source>
        </video>
      </ol>
      <div style={{ fontWeight: 700 }}>
        If you need additional users,{' '}
        <NavLink to="/setup/users">click here</NavLink> to learn how to manage
        users.
      </div>
    </div>
  );
};

export default VelocityWindowStickerSetup;
