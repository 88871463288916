import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import OrganizationIdContext from '../../OrganizationIdContext';
import MuiCheckboxMultiselect from './MuiCheckboxMultiselect';

interface GoogleAnalyticsMethodSelectorProps {
  value: string;
  onChange: (value: string) => void;
  visible: boolean;
}
interface EngagementType {
  id: string;
  name: string;
  description: string;
}
const GoogleAnalyticsMethodSelector = (
  props: GoogleAnalyticsMethodSelectorProps
) => {
  const orgId = useContext(OrganizationIdContext);
  const { data } = useQuery<EngagementType[]>(
    [`/orgs/${orgId}/plugins/velocityvdp/getEngagementTypes`],
    {
      enabled: orgId !== null,
    }
  );
  const value = JSON.parse(props.value) as string[];
  const handleChange = (optionIds: string[]) => {
    props.onChange(JSON.stringify(optionIds));
  };
  const multiselectOptions =
    data?.map((engagementType) => ({
      id: engagementType.id,
      label: engagementType.name,
    })) ?? [];
  return props.visible ? (
    <MuiCheckboxMultiselect
      onChange={handleChange}
      options={multiselectOptions}
      value={value}
      label="Enabled Methods"
      helperText={
        !!(props.value && props.value !== '[]')
          ? ''
          : 'You must select at least one engagement method to track in Google Analytics.'
      }
    />
  ) : null;
};

export default GoogleAnalyticsMethodSelector;
