import { Create } from '@material-ui/icons';
import React, { useLayoutEffect, useState } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import { useOrganization } from 'api/organizations';
import permissions from 'common/permissions';
import PermissionGate from 'components/PermissionGate';
import Alert, { useAlert } from 'components/shared/Alert';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import Tabs, { TabHeaderItem } from 'components/shared/Tabs';
import TagsList from 'components/shared/TagsList';
import TagChip from 'components/shared/TagsList/TagChip/TagChip';
import { OrganizationType } from 'models';

import OrganizationDialog from '../OrganizationDialog';
import GroupsView from './GroupsView';
import HistoryView from './HistoryView';
import OverviewView from './OverviewView';
import PluginsView from './PluginsView';
import TasksView from './PluginsView/ReconVelocityPluginDialog/TasksView';
import Teams from './TeamsView/Teams';
import OrgUsersView from './UsersView';
import VendorOrganizations from './VendorOrganizations';

import './OrganizationDetailView.scss';

const PARENT_ORG_TABS: TabHeaderItem[] = [
  { key: 'overview', label: 'Overview' },
  { key: 'users', label: 'Users', permission: permissions.USERS_VIEW },
  { key: 'groups', label: 'Groups', permission: permissions.ORGS_GROUPS_VIEW },
];

const ROOFTOP_TABS: TabHeaderItem[] = [
  { key: 'overview', label: 'Overview' },
  {
    key: 'plugins',
    label: 'Plug-ins',
    permission: permissions.ORGS_PLUGINS_VIEW,
  },
  { key: 'users', label: 'Users', permission: permissions.USERS_VIEW },
  { key: 'groups', label: 'Groups', permission: permissions.ORGS_GROUPS_VIEW },
  { key: 'teams', label: 'Teams', permission: permissions.USERS_VIEW },
  { key: 'history', label: 'History' },
];

const VENDOR_TABS: TabHeaderItem[] = [
  { key: 'overview', label: 'Overview' },
  { key: 'tasks', label: 'tasks' },
  { key: 'organizations', label: 'Organizations' },
  { key: 'users', label: 'Users', permission: permissions.USERS_VIEW },
  { key: 'groups', label: 'Groups', permission: permissions.ORGS_GROUPS_VIEW },
  { key: 'teams', label: 'Teams', permission: permissions.USERS_VIEW },
  { key: 'history', label: 'History' },
];

const DEFAULT_TAB_KEY = 'overview';

const OrganizationDetailView: React.FC = (props) => {
  const params = useParams();
  const { organizationId } = params;
  const tabPath = params['*']?.split('/')?.[0];
  const { data: organization, isLoading } = useOrganization(organizationId);

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { isAlertOpen, alertMessage, openAlert, closeAlert, variant } =
    useAlert();

  useLayoutEffect(() => {
    if (!organizationId) {
      openAlert('Unable to load organization. Please try again.', 'error');
    }
  }, [openAlert, organizationId]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (organization === undefined) {
    return null;
  }

  const isRooftop = organization.type === OrganizationType.ROOFTOP;
  const isVendor = organization.type === OrganizationType.VENDOR;
  let determinedTabs = PARENT_ORG_TABS;
  if (isRooftop) {
    determinedTabs = ROOFTOP_TABS;
  }
  if (isVendor) {
    determinedTabs = VENDOR_TABS;
  }

  if (organization && organizationId) {
    return (
      <div className="OrganizationDetailView">
        <div className="heading">
          <div className="full-width flex-columns flex-space-between">
            <div className="inline-block">
              <h2 id="organization-name" className="inline">
                {organization?.name ?? ''}
              </h2>
              &nbsp;{' '}
              <span className="nowrap">
                ID:
                {organization.id}
              </span>
              {organization.trialOrganizationExpiration && (
                <div className="inline side-margin">
                  <TagChip
                    tag={{
                      color: 'rgb(198,0,1)',
                      label: 'Window Sticker Trial',
                    }}
                  />
                </div>
              )}
            </div>
            {organization.type !== 'UNKNOWN' && (
              <PermissionGate permissions={[permissions.ORGS_UPDATE]}>
                <Create
                  className="icon-hover"
                  onClick={() => setIsDialogOpen(true)}
                />
              </PermissionGate>
            )}
          </div>
          <TagsList orgId={organizationId} tags={organization?.tagInstances} />
        </div>
        <>
          <Tabs
            tabHeaderItems={determinedTabs}
            defaultTabKey={tabPath as string}
          />
          <Routes>
            <Route
              path="overview/*"
              element={<OverviewView organizationId={organizationId} />}
            />
            <Route
              path="users/*"
              element={<OrgUsersView organizationId={organizationId} />}
            />
            <Route
              path="groups/*"
              element={<GroupsView organizationId={organizationId} />}
            />
            <Route
              path="plugins/*"
              element={<PluginsView organization={organization} />}
            />
            <Route
              path="history/*"
              element={<HistoryView organizationId={organizationId} />}
            />
            <Route
              path="tasks/*"
              element={<TasksView orgId={organizationId} fetchOnMount />}
            />
            <Route
              path="organizations/*"
              element={<VendorOrganizations organizationId={organizationId} />}
            />
            <Route path="teams/*" element={<Teams orgId={organizationId} />} />
            <Route path="*" element={<Navigate to={DEFAULT_TAB_KEY} />} />
          </Routes>
        </>
        {isDialogOpen && (
          <OrganizationDialog
            organization={organization}
            onClose={() => setIsDialogOpen(false)}
          />
        )}
        <Alert
          open={isAlertOpen}
          message={alertMessage}
          onClose={closeAlert}
          duration={3500}
          variant={variant}
        />
      </div>
    );
  }
  return null;
};

export default OrganizationDetailView;
