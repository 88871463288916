import api from 'api';
import {
  ReconVelocityPluginRequestParams,
  UpdateReconVelocityPluginRequestParams,
} from 'api/organizations/plugins/reconvelocity';
import { ReconVelocityPluginItem } from 'models/plugins/reconVelocityPlugin';
import {
  ActionTypeFromCreators,
  createAsyncAction,
} from 'store/actions/helpers';

export const actions = {
  getPlugin: 'Get ReconVelocity Plugin Settings',
  updatePlugin: 'Update ReconVelocity Plugin',
} as const;

export const {
  types: getReconVelocityPluginActionTypes,
  creators: getReconVelocityPluginActionCreators,
  action: getReconVelocityPlugin,
} = createAsyncAction<
  typeof actions.getPlugin,
  ReconVelocityPluginRequestParams,
  ReconVelocityPluginItem
>(actions.getPlugin, ({ orgId }) =>
  api.organizations.plugins.reconvelocity.getPlugin({ orgId })
);

type GetReconVelocityPluginAction = ActionTypeFromCreators<
  typeof actions.getPlugin,
  ReconVelocityPluginRequestParams,
  ReconVelocityPluginItem,
  typeof getReconVelocityPluginActionCreators
>;

export const {
  types: updateReconVelocityPluginActionTypes,
  creators: updateReconVelocityPluginActionCreators,
  action: updateReconVelocityPlugin,
} = createAsyncAction<
  typeof actions.updatePlugin,
  UpdateReconVelocityPluginRequestParams,
  ReconVelocityPluginItem
>(actions.updatePlugin, ({ orgId, data, isNewPlugin }) =>
  api.organizations.plugins.reconvelocity.updatePlugin({
    orgId,
    data,
    isNewPlugin,
  })
);

type UpdateReconVelocityPluginAction = ActionTypeFromCreators<
  typeof actions.updatePlugin,
  UpdateReconVelocityPluginRequestParams,
  ReconVelocityPluginItem,
  typeof updateReconVelocityPluginActionCreators
>;

export type ReconVelocityPluginActionTypes =
  | GetReconVelocityPluginAction
  | UpdateReconVelocityPluginAction;
