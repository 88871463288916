import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { defaultMutationFn } from 'api';
import apiConstants from 'api/constants';
import strings from 'common/strings';
import { AuthenticationResponse } from 'models';

const { BEARER_TOKEN_KEY, BEARER_TOKEN_EXPIRATION_KEY, REFRESH_TOKEN_KEY } =
  apiConstants;

/**
 * Saves the bearer and refresh tokens to local storage
 * @param authBearerToken
 * @param authRefreshToken
 * @param authBearerTokenExpiration
 */
export const setTokens = (
  authBearerToken: string,
  authRefreshToken: string,
  authBearerTokenExpiration: string
) => {
  window.localStorage.setItem(BEARER_TOKEN_KEY, authBearerToken);
  window.localStorage.setItem(REFRESH_TOKEN_KEY, authRefreshToken);
  window.localStorage.setItem(
    BEARER_TOKEN_EXPIRATION_KEY,
    authBearerTokenExpiration
  );
};

/**
 * Retrieves tokens from local storage
 * @returns auth and refresh tokens
 */
export const getTokens = () => {
  const authBearerToken = window.localStorage.getItem(BEARER_TOKEN_KEY);
  const authRefreshToken = window.localStorage.getItem(REFRESH_TOKEN_KEY);
  const authBearerTokenExpiration =
    window.localStorage.getItem(BEARER_TOKEN_EXPIRATION_KEY) || '';

  return {
    authBearerToken,
    authRefreshToken,
    authBearerTokenExpiration,
  };
};

/**
 * Get session user
 * @returns Current session's user
 */
export default function useSession() {
  return useQuery<AuthenticationResponse>(['/authenticate'], {
    onSuccess: (data) => {
      setTokens(data.token, data.refreshToken, data.expirationDate);
    },
  });
}

/**
 * Post login user
 * @returns Current session's user
 */
interface UseLogoutParams {
  username: string;
  password: string;
}
export function useLogin() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ username, password }: UseLogoutParams) => {
      setTokens('', '', '');

      return defaultMutationFn(
        `/authenticate?source=${strings.LOGIN_SOURCE}`,
        'POST',
        {
          username,
          password,
        }
      ) as Promise<AuthenticationResponse>;
    },
    {
      onSuccess: (data) => {
        queryClient.clear();
        setTokens(data.token, data.refreshToken, data.expirationDate);
        queryClient.setQueryData(['/authenticate'], data);
      },
    }
  );
}

/**
 * Post logout user
 * @returns true is logged out successfully
 */
export function useLogout() {
  return useMutation(() => defaultMutationFn('/authenticate/logout', 'POST'), {
    onSuccess: () => {
      setTokens('', '', '');
    },
  });
}
