import {
  Button,
  ButtonProps,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog as MaterialDialog,
  DialogProps as MaterialDialogProps,
} from '@material-ui/core';
import { ReactNode } from 'react';

import LoadingIndicator from 'components/shared/LoadingIndicator';

import './Dialog.scss';

export interface DialogProps {
  auxiliaryContent?: ReactNode;
  block?: boolean;
  children?: ReactNode;
  className?: string;
  fullWidth?: MaterialDialogProps['fullWidth'];
  height?: number;
  hideCancelButton?: boolean;
  hidePrimaryButton?: boolean;
  onClose?: () => void;
  // NOTE: Need to either include onPrimary or onSubmit
  //       Supplying onSubmit will convert to a form
  onPrimary?: ButtonProps['onClick'];
  // onSubmit returns a boolean indicating if we should close the dialog
  onSubmit?: () => void;
  open: boolean;
  primaryButtonLabel?: string;
  primaryButtonDisabled?: boolean;
  title?: string;
  maxWidth?: MaterialDialogProps['maxWidth'];
  showCloseButton?: boolean;
  closeElement?: ReactNode;
  noPadding?: boolean;
}

var Dialog = ({
  auxiliaryContent,
  block,
  className,
  children,
  fullWidth,
  height,
  hideCancelButton = false,
  hidePrimaryButton = false,
  onClose,
  onPrimary,
  onSubmit,
  open,
  primaryButtonLabel,
  primaryButtonDisabled,
  title,
  maxWidth,
  showCloseButton = false,
  closeElement = null,
  noPadding = false,
}: DialogProps) => {
  const style = {
    height,
  };

  const noPaddingClassName = noPadding ? 'Dialog-no-padding' : '';
  const dialogClassName = ['Dialog', className, noPaddingClassName].join(' ');

  const hideDialogActions = hideCancelButton && hidePrimaryButton;

  return (
    <MaterialDialog
      open={open}
      className={dialogClassName}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      <form onSubmit={onSubmit}>
        {title && (
          <div className="Dialog-title-container">
            <DialogTitle>{title}</DialogTitle>
            {showCloseButton && closeElement}
          </div>
        )}
        <DialogContent style={style}>{children}</DialogContent>
        {!hideDialogActions && (
          <DialogActions className="Dialog-actions">
            {auxiliaryContent && <>{auxiliaryContent}</>}
            <div className="Dialog-divider" />
            {!hideCancelButton && (
              <Button id="Dialog-secondary-button" onClick={() => onClose?.()}>
                Cancel
              </Button>
            )}
            {!hidePrimaryButton && (
              <Button
                id="Dialog-primary-button"
                color="secondary"
                style={{ color: 'white' }}
                variant="contained"
                onClick={(event) => onPrimary?.(event)}
                disabled={block || primaryButtonDisabled}
                type={onSubmit ? 'submit' : 'button'}
              >
                {block ? <LoadingIndicator size={18} /> : primaryButtonLabel}
              </Button>
            )}
          </DialogActions>
        )}
      </form>
    </MaterialDialog>
  );
};

export default Dialog;
