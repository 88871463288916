import { Delete } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { AutoSizer, Table, TableRowProps } from 'react-virtualized';

import {
  useDeleteTagDefinition,
  useTagsDefinitions,
} from 'api/organizations/plugins/reconvelocity/tags';
import useSession from 'api/session';
import permissions from 'common/permissions';
import PermissionGate from 'components/PermissionGate';
import Alert, { useAlert } from 'components/shared/Alert';
import CollectionHeaderBar from 'components/shared/CollectionHeaderBar';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { Organization, ReconVelocityTagDefinition } from 'models';

import TagDefinitionDeleteDialog from './TagDefinitionDeleteDialog';

interface TagDefinitionsListViewProps {
  orgId: Organization['id'];
  selectedTagDefinition: ReconVelocityTagDefinition | undefined;
  fetchOnMount: boolean;
  setSelectedTagDefinition: (
    selectedTagDefinition?: ReconVelocityTagDefinition
  ) => void;
  openTagDefinitionDialog: () => void;
}

const TagDefinitionsListView: React.FC<TagDefinitionsListViewProps> = ({
  orgId,
  selectedTagDefinition,
  fetchOnMount,
  setSelectedTagDefinition,
  openTagDefinitionDialog,
}: TagDefinitionsListViewProps) => {
  const { isAlertOpen, alertMessage, openAlert, closeAlert, variant } =
    useAlert();

  const {
    data: tagDefinitions,
    isLoading: tagDefinitionsIsLoading,
    isError: tagDefinitionsError,
  } = useTagsDefinitions(orgId, { enabled: fetchOnMount });

  const { deleteTagDefinitionAsync } = useDeleteTagDefinition(orgId);

  const {
    data: session,
    isLoading: sessionIsLoading,
    isError: sessionError,
  } = useSession();

  const isLoading = tagDefinitionsIsLoading || sessionIsLoading;

  const [
    [tagDefinitionIdToDelete, setTagDefinitionIdToDelete],
    [isDeletingTagDefinition, setIsDeletingTagDefinition],
  ] = [
    useState<{ id?: string; index?: number } | undefined>(undefined),
    useState(false),
  ];

  useEffect(() => {
    if (tagDefinitionsError || sessionError) {
      openAlert(
        'There was an API error while loading the tag definitions',
        'error'
      );
    }
  }, [tagDefinitionsError, sessionError, openAlert]);

  const handleAdd = () => {
    openTagDefinitionDialog();
  };

  const handleTagClick = (tagDefinition: ReconVelocityTagDefinition) => {
    setSelectedTagDefinition(tagDefinition);
  };

  const handleDeleteClick = async () => {
    setIsDeletingTagDefinition(true);
    await deleteTagDefinitionAsync(tagDefinitionIdToDelete?.id);
    setTagDefinitionIdToDelete(undefined);
    setIsDeletingTagDefinition(false);
    setSelectedTagDefinition(tagDefinitions?.data[0]);
  };

  const tagDefinitionsRowRenderer = ({ index, style }: TableRowProps) => {
    const tagDefinition = tagDefinitions?.data[index];
    if (!selectedTagDefinition) {
      setSelectedTagDefinition(tagDefinitions?.data[0]);
    }
    let containerClassName = 'ReactVirtualized__Table__row Table__row';
    if (tagDefinition?.id === selectedTagDefinition?.id) {
      containerClassName = `${containerClassName} Table__selectedRow`;
    }
    return (
      <div
        key={tagDefinition?.id}
        className={containerClassName}
        style={style}
        role="button"
        tabIndex={0}
        onClick={() => handleTagClick(tagDefinition!)}
      >
        <p className="truncate">{tagDefinition?.label}</p>
        <div>
          <PermissionGate
            permissions={[
              permissions.ORGS_PLUGINS_RECONVELOCITY_TAG_DEFINITIONS_DELETE,
            ]}
          >
            <Delete
              onClick={() =>
                setTagDefinitionIdToDelete({ id: tagDefinition?.id, index })
              }
            />
          </PermissionGate>
        </div>
      </div>
    );
  };

  const noRowsRenderer = () => (
    <div>There are currently no tags created. Create one now!</div>
  );

  const COLLECTION_HEADER_BAR_HEIGHT = 31;

  const hasAddPermission = session?.permissions.includes(
    permissions.ORGS_PLUGINS_RECONVELOCITY_TAG_DEFINITIONS_CREATE
  );

  return (
    <div className="full-height" id="tag-definition-list">
      <CollectionHeaderBar
        count={tagDefinitions?.data.length}
        unit="Tags"
        onAdd={hasAddPermission ? handleAdd : undefined}
      />
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <AutoSizer>
          {({ width, height }) => (
            <Table
              disableHeader
              width={width}
              height={height - COLLECTION_HEADER_BAR_HEIGHT}
              headerHeight={0}
              rowCount={tagDefinitions?.data?.length ?? 0}
              rowHeight={30}
              items={tagDefinitions?.data}
              rowGetter={({ index }) => tagDefinitions?.data[index]}
              rowRenderer={tagDefinitionsRowRenderer}
              noRowsRenderer={noRowsRenderer}
            />
          )}
        </AutoSizer>
      )}
      {tagDefinitionIdToDelete && (
        <TagDefinitionDeleteDialog
          onClose={() => {
            setTagDefinitionIdToDelete(undefined);
          }}
          isDeletingTagDefinition={isDeletingTagDefinition}
          handleDelete={handleDeleteClick}
        />
      )}
      <Alert
        open={isAlertOpen}
        message={alertMessage}
        onClose={closeAlert}
        duration={3500}
        variant={variant}
      />
    </div>
  );
};

export default TagDefinitionsListView;
