import api from 'api';
import {
  BulkDeleteUsersFromOrgRequestParams,
  BulkUpdateUsersOrgGroupRequestParams,
  DeleteUserFromOrgRequestParams,
  OrganizationUsersRequestParams,
} from 'api/organizations/users';
import { User } from 'models';
import {
  ActionTypeFromCreators,
  createAsyncAction,
} from 'store/actions/helpers';

import * as notifications from './notifications';
import { NotificationsAction } from './notifications';

const actions = {
  getOrganizationUsers: 'Get Organization Users',
  updateUsersOrgGroups: "Update User's Organization Groups",
  bulkRemoveUsersFromOrg: 'Bulk Remove Users From Org',
  removeUserFromOrg: 'Remove User From Org',
} as const;

/**
 * Get Organization Users
 */
export const {
  types: getOrganizationUsersActionTypes,
  creators: getOrganizationUsersActionCreators,
  action: getOrganizationUsers,
} = createAsyncAction<
  typeof actions.getOrganizationUsers,
  OrganizationUsersRequestParams,
  User[]
>(actions.getOrganizationUsers, ({ orgId }) =>
  api.organizations.users.getOrganizationUsers({ orgId })
);

type GetOrganizationUsersAction = ActionTypeFromCreators<
  typeof actions.getOrganizationUsers,
  OrganizationUsersRequestParams,
  User[],
  typeof getOrganizationUsersActionCreators
>;

export const {
  types: updateUsersOrgGroupsActionTypes,
  creators: updateUsersOrgGroupsActionCreators,
  action: updateUsersOrgGroups,
} = createAsyncAction<
  typeof actions.updateUsersOrgGroups,
  BulkUpdateUsersOrgGroupRequestParams,
  User[]
>(actions.updateUsersOrgGroups, ({ orgId, userIds, orgGroupIds }) =>
  api.organizations.users.updateUsersOrgGroups({
    orgId,
    userIds,
    orgGroupIds,
  })
);

type UpdateUsersOrgGroupsAction = ActionTypeFromCreators<
  typeof actions.updateUsersOrgGroups,
  BulkUpdateUsersOrgGroupRequestParams,
  User[],
  typeof updateUsersOrgGroupsActionCreators
>;

export const {
  types: bulkRemoveUsersFromOrgActionTypes,
  creators: bulkRemoveUsersFromOrgActionCreators,
  action: bulkRemoveUsersFromOrg,
} = createAsyncAction<
  typeof actions.bulkRemoveUsersFromOrg,
  BulkDeleteUsersFromOrgRequestParams,
  User[]
>(actions.bulkRemoveUsersFromOrg, ({ userIds, orgId }) =>
  api.organizations.users.bulkRemoveUsersFromOrg({ userIds, orgId })
);

type BulkDeleteUsersFromOrgAction = ActionTypeFromCreators<
  typeof actions.bulkRemoveUsersFromOrg,
  BulkDeleteUsersFromOrgRequestParams,
  User[],
  typeof bulkRemoveUsersFromOrgActionCreators
>;

export const {
  types: removeUsersFromOrgActionTypes,
  creators: removeUsersFromOrgActionCreators,
  action: removeUsersFromOrg,
} = createAsyncAction<
  typeof actions.removeUserFromOrg,
  DeleteUserFromOrgRequestParams,
  boolean
>(actions.removeUserFromOrg, ({ userId, orgId }) =>
  api.organizations.users.removeUserFromOrg({ userId, orgId })
);

type RemoveUsersFromOrgAction = ActionTypeFromCreators<
  typeof actions.removeUserFromOrg,
  DeleteUserFromOrgRequestParams,
  boolean,
  typeof removeUsersFromOrgActionCreators
>;

/**
 * Export Consolidation
 */
export type OrganizationUsersAction =
  | GetOrganizationUsersAction
  | UpdateUsersOrgGroupsAction
  | BulkDeleteUsersFromOrgAction
  | RemoveUsersFromOrgAction
  | NotificationsAction;

export default {
  notifications,
};
