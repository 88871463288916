import api from 'api';
import {
  UpdateVelocityEngagePluginRequestParams,
  VelocityEngagePluginRequestParams,
} from 'api/organizations/plugins/velocityengage';
import { VelocityEngagePluginItem } from 'models/plugins/velocityEngagePlugin';
import {
  ActionTypeFromCreators,
  createAsyncAction,
} from 'store/actions/helpers';

export const actions = {
  getPlugin: 'Get VelocityEngage Plugin Settings',
  updatePlugin: 'Update VelocityEngage Plugin',
} as const;

export const {
  types: getVelocityEngagePluginActionTypes,
  creators: getVelocityEngagePluginActionCreators,
  action: getVelocityEngagePlugin,
} = createAsyncAction<
  typeof actions.getPlugin,
  VelocityEngagePluginRequestParams,
  VelocityEngagePluginItem
>(actions.getPlugin, ({ orgId }) =>
  api.organizations.plugins.velocityengage.getPlugin({ orgId })
);

type GetVelocityEngagePluginAction = ActionTypeFromCreators<
  typeof actions.getPlugin,
  VelocityEngagePluginRequestParams,
  VelocityEngagePluginItem,
  typeof getVelocityEngagePluginActionCreators
>;

export const {
  types: updateVelocityEngagePluginActionTypes,
  creators: updateVelocityEngagePluginActionCreators,
  action: updateVelocityEngagePlugin,
} = createAsyncAction<
  typeof actions.updatePlugin,
  UpdateVelocityEngagePluginRequestParams,
  VelocityEngagePluginItem
>(actions.updatePlugin, ({ orgId, data }) =>
  api.organizations.plugins.velocityengage.updatePlugin({ orgId, data })
);

type UpdateVelocityEngagePluginAction = ActionTypeFromCreators<
  typeof actions.updatePlugin,
  UpdateVelocityEngagePluginRequestParams,
  VelocityEngagePluginItem,
  typeof updateVelocityEngagePluginActionCreators
>;

export type VelocityEngagePluginActionTypes =
  | GetVelocityEngagePluginAction
  | UpdateVelocityEngagePluginAction;
