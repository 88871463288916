import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';

import strings from 'common/strings';

import './UsersList.scss';

var UsersListLoading = () => {
  return (
    <div className="UsersList">
      <div className="flex-grow">
        <div className="UsersList-header">
          <div>{strings.LOADING_WITH_ELLIPSES}</div>
          <div className="SearchAndActions">
            <TextField
              id="outlined-basic"
              variant="outlined"
              size="small"
              className="usersInput"
              value=""
              onChange={() => {}}
              placeholder="Search"
            />
            <Button
              size="small"
              variant="contained"
              aria-label="close"
              color="secondary"
              onClick={() => {}}
              disableRipple
              className="addIconButton"
            >
              <AddIcon style={{ color: 'white' }} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersListLoading;
