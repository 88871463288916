import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { FlagRounded, Map } from '@material-ui/icons';
import { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import allPermissions from 'common/permissions';
import PermissionGate from 'components/PermissionGate';

import ApplicationsListDetailLayout from './ApplicationsListDetailLayout';
import BrochureMangerLayout from './BrochureMangerLayout';
import SettingsSectionLink from './SettingsSectionLink';

interface SettingsItem {
  key: string;
  icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>>;
  label: string;
  path: string;
  element: ReactElement;
  permissions?: Array<keyof typeof allPermissions>;
}

const SETTINGS: Array<SettingsItem> = [
  {
    key: 'featureflags',
    icon: FlagRounded,
    label: 'Feature Flags',
    path: '/featureflags/*',
    element: <ApplicationsListDetailLayout />,
    permissions: [allPermissions.SETTINGS_FEATUREFLAG_VIEW],
  },
  {
    key: 'brochures',
    icon: Map,
    label: 'Manage Brochures',
    path: '/brochures/*',
    element: <BrochureMangerLayout />,
    permissions: [allPermissions.CROSS_TENANT_ACCESS],
  },
];

var SettingsView = () => {
  return (
    <div className="page-container">
      <Routes>
        <Route
          path="/"
          element={
            <div className="margin align-center" id="settings-view-container">
              {SETTINGS.map(({ key, icon, label, permissions }) => (
                <PermissionGate key={key} permissions={permissions}>
                  <SettingsSectionLink icon={icon} label={label} to={key} />
                </PermissionGate>
              ))}
            </div>
          }
        />

        {SETTINGS.map(({ key, path, element, permissions }) => (
          <Route
            key={`${key}_route`}
            path={path}
            element={
              <PermissionGate permissions={permissions}>
                {element}
              </PermissionGate>
            }
          />
        ))}
      </Routes>
    </div>
  );
};

export default SettingsView;
