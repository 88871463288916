import { ThemeProvider } from '@material-ui/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FC } from 'react';
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { defaultQueryFn, queryCacheStrategies } from 'api';
import theme from 'common/theme';
import { registerFetchIntercept } from 'hooks/usePubSubUpdates';
import store from 'store';

import Authenticate from './Authenticate';
import Header from './Header';
import LoginView from './LoginView';
import OrganizationsView from './OrganizationsView';
import OrganizationsDetailView from './OrganizationsView/OrganizationDetailView';
import SettingsView from './SettingsView';
import SetupView from './SetupView';
import UsersView from './UsersView';

registerFetchIntercept();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      ...(queryCacheStrategies.basic as any),
      retry: false,
      networkMode: 'offlineFirst',
    },
    mutations: {
      networkMode: 'offlineFirst',
    },
  },
});

const App: FC = () => (
  <ThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route path="/login" element={<LoginView />} />
            <Route
              path="/"
              element={
                <Authenticate>
                  <Header />
                </Authenticate>
              }
            >
              <Route path="orgs/*" element={<OrganizationsView />}>
                <Route
                  path=":organizationId/*"
                  element={<OrganizationsDetailView />}
                />
              </Route>
              <Route path="users/*" element={<UsersView />} />
              <Route path="settings/*" element={<SettingsView />} />
              <Route path="setup/*" element={<SetupView />} />
              <Route path="/" element={<Navigate to="orgs" replace />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ThemeProvider>
);

export default hot(module)(App);
