import { Button, TextField } from '@material-ui/core';
import { capitalize } from 'lodash';
import {
  ChangeEvent,
  Fragment,
  ReactElement,
  useEffect,
  useState,
} from 'react';

import {
  useGetNotificationWindow,
  useSaveNotificationWindow,
} from 'api/users/notifications';
import Alert, { useAlert } from 'components/shared/Alert';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { DAYS, defaultScheduleWeek, ScheduleWeek } from 'models';
import { ApiResponse } from 'models/api';

import './UserNotificationWindowView.scss';

interface UserNotificationWindowViewProps {
  userId: string;
}

const UserNotificationWindowView = ({
  userId,
}: UserNotificationWindowViewProps): ReactElement => {
  const [schedule, setSchedule] = useState<ScheduleWeek>(defaultScheduleWeek);
  const { isAlertOpen, alertMessage, openAlert, closeAlert, variant } =
    useAlert();

  const notificationWindowQuery = useGetNotificationWindow({ userId });
  const notificationWindowMutation = useSaveNotificationWindow({ userId });
  const { data, isInitialLoading: isLoading } = notificationWindowQuery;

  useEffect(() => {
    setSchedule(data || defaultScheduleWeek);
  }, [data]);

  if (isLoading) {
    return <LoadingIndicator size={13} color="primary" />;
  }

  const openScheduleAlert = () =>
    openAlert(
      'Notification window close time cannot be equal or before open time',
      'error'
    );

  const hourAndMinuteRegex = /([0-9][0-9]):([0-9][0-9])/;
  const validateScheduleTimes = (
    openTime: string | null | undefined,
    closeTime: string | null | undefined,
    isSubmit: boolean
  ) => {
    if (!openTime && !closeTime) {
      return true;
    }
    if (!openTime || !closeTime) {
      return !isSubmit;
    }
    const openMatches = openTime.match(hourAndMinuteRegex);
    const openHours = Number(openMatches?.[1]);
    const closeMatches = closeTime.match(hourAndMinuteRegex);
    const closeHours = Number(closeMatches?.[1]);
    if (openHours > closeHours) {
      return false;
    }

    if (openHours === closeHours) {
      const openMinutes = Number(openMatches?.[2]);
      const closeMinutes = Number(closeMatches?.[2]);
      if (openMinutes >= closeMinutes) {
        return false;
      }
    }

    return true;
  };

  const validateWeekScheduleTimes = (scheduleWeek: ScheduleWeek) =>
    DAYS.reduce((acc, day) => {
      const openTime = scheduleWeek[day]?.openTime;
      const closeTime = scheduleWeek[day]?.closeTime;
      return acc && validateScheduleTimes(openTime, closeTime, true);
    }, true);

  const handleOnChangeOpenTime = async (
    day: keyof ScheduleWeek,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const isValid = validateScheduleTimes(
      e.target.value,
      schedule?.[day]?.closeTime,
      false
    );
    if (!isValid) {
      openScheduleAlert();
    }
    if (!e.target.value && !schedule?.[day]?.closeTime) {
      setSchedule({
        ...schedule,
        [day]: null,
      });
      return;
    }
    setSchedule({
      ...schedule,
      [day]: {
        ...schedule?.[day],
        openTime: e.target.value && isValid ? e.target.value : null,
        openInSecondsUTC: null,
        closedInSecondsUTC: null,
      },
    });
  };

  const handleOnChangeCloseTime = async (
    day: keyof ScheduleWeek,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const isValid = validateScheduleTimes(
      schedule?.[day]?.openTime,
      e.target.value,
      false
    );
    if (!isValid) {
      openScheduleAlert();
    }
    if (!e.target.value && !schedule?.[day]?.openTime) {
      setSchedule({
        ...schedule,
        [day]: null,
      });
      return;
    }
    setSchedule({
      ...schedule,
      [day]: {
        ...schedule?.[day],
        closeTime: e.target.value && isValid ? e.target.value : null,
        openInSecondsUTC: null,
        closedInSecondsUTC: null,
      },
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!validateWeekScheduleTimes(schedule)) {
      openScheduleAlert();
      return;
    }

    try {
      notificationWindowMutation
        .mutateAsync(schedule)
        .then((resp: ApiResponse<ScheduleWeek>) => {
          if (resp) {
            setSchedule(resp.data);
          }
          openAlert('Notification window successfully saved', 'success');
        });
    } catch (error: unknown) {
      if (error instanceof Error) {
        openAlert(error.message, 'error');
      } else {
        const message =
          'There was an API error when saving notification window';
        openAlert(message, 'error');
      }
    }
  };

  return (
    <>
      <div className="NotificationWindowView">
        <h3 className="NotificationWindowView__timezone-note">
          All times configured will be applied in Eastern Standard Time (EST)
        </h3>
        <div className="NotificationWindowView__schedule">
          <div className="NotificationWindowView__schedule-grid">
            <strong> </strong>
            <label>Open:</label>
            <label>Close:</label>
            {DAYS.map((day) => {
              const dayName = capitalize(day);
              return (
                <Fragment key={day}>
                  <label className="align-right">{dayName}:</label>
                  <div>
                    <TextField
                      id={`open-time-${dayName.toLowerCase()}`}
                      type="time"
                      className="NotificationWindowView__time-input"
                      variant="outlined"
                      required={!!schedule?.[day]?.closeTime}
                      value={schedule?.[day]?.openTime ?? ''}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleOnChangeOpenTime(day, e)
                      }
                      margin="dense"
                    />
                  </div>
                  <div>
                    <TextField
                      id={`close-time-${dayName.toLowerCase()}`}
                      type="time"
                      className="NotificationWindowView__time-input"
                      variant="outlined"
                      required={!!schedule?.[day]?.openTime}
                      value={schedule?.[day]?.closeTime ?? ''}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleOnChangeCloseTime(day, e)
                      }
                      margin="dense"
                    />
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
        <Button
          id="notification-window-save-button"
          color="secondary"
          style={{ color: 'white' }}
          variant="contained"
          disabled={isLoading}
          onClick={handleSubmit}
        >
          {isLoading ? (
            <LoadingIndicator size={13} color="primary" />
          ) : (
            'Save Notification Window'
          )}
        </Button>
      </div>
      <Alert
        open={isAlertOpen}
        variant={variant}
        message={alertMessage}
        onClose={closeAlert}
        duration={3500}
      />
    </>
  );
};

export default UserNotificationWindowView;
