import { IconButton } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { useEffect, useState } from 'react';

import { useTeamMutation, useTeams } from 'api/organizations/teams';
import ConfirmationDialog, {
  useConfirmationDialog,
} from 'components/shared/ConfirmationDialog';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { Team } from 'models/team';

import AddTeamDialog from './AddTeamDialog/AddTeamDialog';

type TeamsListProps = {
  orgId: string;
  selectedTeamId: string | undefined;
  onTeamClick: (teamId: string | undefined) => void;
};

const TeamsList = ({ orgId, selectedTeamId, onTeamClick }: TeamsListProps) => {
  const { data: teams, isLoading } = useTeams(orgId);
  const teamMutation = useTeamMutation(orgId, selectedTeamId);
  const [isAddTeamDialogOpen, setIsAddTeamDialogOpen] = useState(false);

  useEffect(() => {
    if (teams?.length) {
      if (selectedTeamId) {
        const teamIndex = teams?.findIndex(
          (team) => team.id === selectedTeamId
        );
        if (teamIndex < 0) {
          onTeamClick(teams[0].id!);
        }
      } else {
        onTeamClick(teams[0].id!);
      }
    } else if (selectedTeamId) {
      onTeamClick(undefined);
    }
  }, [selectedTeamId, teams, onTeamClick]);

  const handleAddTeamDialogOnClose = () => {
    setIsAddTeamDialogOpen(false);
  };

  const {
    isConfirmationDialogOpen,
    isConfirmationLoading,
    confirmationMessage,
    primaryButtonLabel,
    onAccept,
    onDeny,
    openConfirmationDialog,
  } = useConfirmationDialog();

  const handleOnDeleteTeamClick = (team: Team) => {
    const teamToDelete = { ...team, delete: true };
    openConfirmationDialog({
      messageOverride: `Are you sure you want to delete team, ${team.teamName}?`,
      primaryButtonLabelOverride: 'Delete',
      onAcceptOverride: () => teamMutation.mutate(teamToDelete),
    });
  };

  return (
    <div className="teams-list-container">
      <div className="teams-list-header">
        {teams?.length} Teams
        <IconButton size="small" onClick={() => setIsAddTeamDialogOpen(true)}>
          <Add />
        </IconButton>
      </div>
      <div className="teams-list">
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          !isLoading &&
          teams?.map((team) => {
            const teamId = team.id ?? '';
            return (
              <div
                key={teamId}
                className={`teams-list-item ${
                  teamId === selectedTeamId ? 'selected' : ''
                }`}
                onClick={() => onTeamClick(teamId!)}
              >
                {team.teamName}
                <IconButton
                  size="small"
                  onClick={() => handleOnDeleteTeamClick(team)}
                  disabled={teamMutation.isLoading}
                >
                  <Delete />
                </IconButton>
              </div>
            );
          })
        )}
      </div>
      {isAddTeamDialogOpen && (
        <AddTeamDialog
          orgId={orgId}
          open={isAddTeamDialogOpen}
          onClose={handleAddTeamDialogOnClose}
        />
      )}
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        isLoading={isConfirmationLoading}
        primaryButtonLabel={primaryButtonLabel}
        message={confirmationMessage}
        onPrimaryButtonClick={onAccept}
        onSecondaryButtonClick={onDeny}
      />
    </div>
  );
};

export default TeamsList;
