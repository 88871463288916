import { ItemLink, ScheduleWeek } from 'models';

export enum ReconVelocityWorkflowType {
  LINEAR = 'LINEAR',
  DYNAMIC = 'DYNAMIC',
}

export type WorkflowStepCategory =
  | 'INCOMING'
  | 'TRADE_WALK_INSPECTION'
  | 'MECHANICAL'
  | 'DETAIL'
  | 'PICTURES_SYNDICATION'
  | 'MISCELLANEOUS'
  | 'BODY_SHOP'
  | 'INSPECTION'
  | 'PICTURES'
  | 'APPROVAL'
  | 'SYNDICATION'
  | 'FINISHED';

export interface GoalTime {
  goalTimeAsSeconds: number;
  warningTimeAsSeconds: number;
}

export interface AssignableUser {
  id: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
}

export interface ReconVelocityWorkflowStep {
  activeVehicleCount: number;
  assignableUsers?: AssignableUser[];
  beginningStep: boolean;
  category: WorkflowStepCategory;
  endingStep: boolean;
  enhancedStateTrackingEnabled: boolean;
  goal?: GoalTime;
  id?: string;
  name: string;
  nextStep?: Pick<ReconVelocityWorkflowStep, 'id' | 'name'>;
  parentStep?: Pick<ReconVelocityWorkflowStep, 'id' | 'name'>;
  schedule?: ScheduleWeek;
  shouldIncludeInReporting: boolean;
  shouldRemoveSoldInEndingStep: boolean;
  stepOrder: number;
}

export const defaultReconVelocityWorkflowStep: ReconVelocityWorkflowStep = {
  activeVehicleCount: 0,
  assignableUsers: undefined,
  beginningStep: false,
  category: 'INCOMING',
  endingStep: false,
  enhancedStateTrackingEnabled: false,
  id: '',
  name: '',
  shouldIncludeInReporting: false,
  shouldRemoveSoldInEndingStep: false,
  stepOrder: 0,
};

export interface ReconVelocityWorkflow {
  allowExcludingStepsFromReporting: boolean;
  id: string;
  idleGoal: GoalTime;
  name: string;
  postReconEnabled: boolean;
  reconTimeGoal: GoalTime;
  retailReadyGoal: GoalTime;
  steps: ReconVelocityWorkflowStep[];
  type: ReconVelocityWorkflowType;
  version: string;
}

export interface ReconVelocityWorkflowStepMoveData {
  id: string;
  parentStepId?: string;
  nextOrderedStepId?: string;
  previousOrderedStepId?: string;
  parentsNewIdForExistingInstances?: string;
  parentsNewNextId?: string;
}

export interface DeleteReconVelocityWorkflowStepData {
  id?: string;
  newIdForExistingInstances?: string;
  newNextId?: string;
  newBeginningStepId?: string;
  precedingSteps?: (string | undefined)[];
}

export interface UpdateReconVelocityWorkflowStepData
  extends Omit<
    ReconVelocityWorkflowStep,
    | 'activeVehicleCount'
    | 'beginningStep'
    | 'stepOrder'
    | 'parentStep'
    | 'nextStep'
  > {
  parentStepId?: ReconVelocityWorkflowStep['id'];
  nextStepId?: ReconVelocityWorkflowStep['id'];
}

export interface UpdateReconVelocityWorkflowBeginningStep {
  stepId: NonNullable<ReconVelocityWorkflowStep['id']>;
}

export interface ReconVelocityStepDefinitionAssociation {
  id: string;
  primaryStep: ItemLink;
  secondaryStep: ItemLink;
  primaryTenant: ItemLink;
  secondaryTenant: ItemLink;
}
