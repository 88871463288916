import { get, keyBy, set } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import {
  useMoveReconVelocityWorkflowStep,
  useReconVelocityWorkflowSteps,
} from 'api/organizations/plugins/reconvelocity/workflows';
import useSession from 'api/session';
import { useOnChange } from 'common/hooks';
import permissions from 'common/permissions';
import theme from 'common/theme';
import Alert, { useAlert } from 'components/shared/Alert';
import CollectionHeaderBar from 'components/shared/CollectionHeaderBar';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { ReconVelocityWorkflowStep } from 'models';
import utils from 'utils';

import StepDefinitionRow from './StepDefinitionRow';

import './WorkflowStepsListView.scss';

interface WorkflowStepsListViewProps {
  orgId: string;
  selectedStepId?: string;
  setSelectedStepId: (id: string | undefined) => void;
}

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver
    ? theme.palette.action.hover
    : theme.palette.background.paper,
});

interface DisplayStepDefinition
  extends Pick<ReconVelocityWorkflowStep, 'id' | 'name' | 'stepOrder'> {
  childSteps?: DisplayStepDefinitions;
}
type DisplayStepDefinitions = {
  [id: string]: DisplayStepDefinition;
};

var WorkflowStepsListView = ({
  orgId,
  selectedStepId,
  setSelectedStepId,
}: WorkflowStepsListViewProps) => {
  const { isAlertOpen, alertMessage, variant, closeAlert, openAlert } =
    useAlert();
  const { data: session } = useSession();
  const { data: allSteps, isLoading: workflowStepsIsLoading } =
    useReconVelocityWorkflowSteps(orgId);
  const { moveStepAsync } = useMoveReconVelocityWorkflowStep(orgId);
  const topLevelWorkflowSteps = useMemo(
    () => (allSteps ? allSteps?.data.filter((step) => !step.parentStep) : []),
    [allSteps]
  );

  const convertToDisplaySteps = useCallback(
    (steps: ReconVelocityWorkflowStep[]): DisplayStepDefinitions => {
      const mapped = keyBy(
        steps.map((listStep) => {
          const { id, name, stepOrder } = listStep;
          const childSteps = utils.workflow.getChildSteps(
            listStep.id,
            allSteps?.data
          );
          const step: DisplayStepDefinition = {
            id,
            name,
            stepOrder,
          };

          if (childSteps?.length) {
            step.childSteps = convertToDisplaySteps(childSteps);
          }

          return step;
        }),
        'id'
      );

      return mapped;
    },
    [allSteps]
  );

  const [[displaySteps, setDisplaySteps], [movingStep, setMovingStep]] = [
    useState(convertToDisplaySteps(topLevelWorkflowSteps ?? [])),
    useState<ReconVelocityWorkflowStep['id']>(),
  ];

  // keep the selection state index in sync with the currently selected item
  const allStepsData = allSteps?.data;
  const onSelectedItemChange = useCallback(() => {
    if (selectedStepId === 'BLANK_STEP') return;

    const step = allStepsData?.find((s) => s.id === selectedStepId);
    setSelectedStepId(step?.id);
  }, [allStepsData, selectedStepId, setSelectedStepId]);

  useOnChange(
    onSelectedItemChange,
    [allSteps?.data.length, selectedStepId, allSteps],
    { callOnMount: true, strinfigy: true }
  );

  const syncDisplayStepsCallback = useCallback(() => {
    setDisplaySteps(convertToDisplaySteps(topLevelWorkflowSteps ?? []));
  }, [convertToDisplaySteps, setDisplaySteps, topLevelWorkflowSteps]);

  useOnChange(
    syncDisplayStepsCallback,
    [topLevelWorkflowSteps?.length, allSteps?.data.length, allSteps],
    { callOnMount: true, strinfigy: true }
  );

  const handleAdd = () => {
    setSelectedStepId('BLANK_STEP');
  };

  const onDragEnd = useCallback(
    async (result: DropResult) => {
      try {
        if (!result.destination) return;
        if (result.destination.index === result.source.index) return;

        const stepDefinitionId = result.draggableId.replace(
          'workflowStepDefinitionDraggable_',
          ''
        );
        const workflowStepDefinition = allSteps?.data.find(
          ({ id }) => id === stepDefinitionId
        );

        if (!workflowStepDefinition) return;

        setMovingStep(workflowStepDefinition.id);

        const pathToParent = result.destination.droppableId
          .split('->')
          .join('.childSteps.');

        const isTopLevel = pathToParent === 'topLevelStepDefinitionsDroppable';

        const parentDisplayStep = get(displaySteps, `${pathToParent}`);
        const parentStep = allSteps?.data.find(
          (listStep) =>
            parentDisplayStep && parentDisplayStep.id === listStep.id
        );
        const parentStepChildren = utils.workflow.getChildSteps(
          parentStep?.id,
          allSteps?.data
        );
        const siblingSteps = isTopLevel ? displaySteps : parentStepChildren;

        const sortedSiblingSteps = Object.values(siblingSteps ?? {}).sort(
          ({ stepOrder: A }, { stepOrder: B }) => A - B
        );
        const { index: previousIndex } = result.source;
        const { index: newIndex } = result.destination;

        const siblingStepsWithUpdatedOrders = sortedSiblingSteps.map(
          ({ stepOrder, ...step }, index) => {
            // moved step was previously after current step
            if (index < previousIndex) {
              // moved step is now before current step
              if (index >= newIndex) {
                return { ...step, stepOrder: index + 1 };
              }

              // moved step is now after current step
              return { ...step, stepOrder: index };
            }

            // moved step was previously before current step
            if (index > previousIndex) {
              // moved step is now after current step
              if (index <= newIndex) {
                return { ...step, stepOrder: index - 1 };
              }

              // moved step is now before current step
              return { ...step, stepOrder: index };
            }

            // moved step was previously in the same place as current step (?)
            return {
              ...step,
              stepOrder: newIndex,
            };
          }
        );

        let clonedDisplaySteps = { ...displaySteps };

        if (isTopLevel) {
          clonedDisplaySteps = {
            ...displaySteps,
            ...keyBy(siblingStepsWithUpdatedOrders, 'id'),
          };
        } else {
          set(
            clonedDisplaySteps,
            `${pathToParent}.childSteps`,
            keyBy(siblingStepsWithUpdatedOrders, 'id')
          );
        }

        try {
          // update UI
          setDisplaySteps(clonedDisplaySteps);

          const previousOrderedStep =
            siblingStepsWithUpdatedOrders[
              previousIndex < newIndex ? newIndex : newIndex - 1
            ];
          const nextOrderedStep =
            siblingStepsWithUpdatedOrders[
              previousIndex < newIndex ? newIndex + 1 : newIndex
            ];

          await moveStepAsync({
            id: stepDefinitionId,
            parentStepId: workflowStepDefinition.parentStep?.id,
            previousOrderedStepId: previousOrderedStep?.id,
            nextOrderedStepId: nextOrderedStep?.id,
          });
        } catch (error) {
          openAlert('There was an issue saving the step orders.', 'error');
        }
      } catch (error) {
        openAlert('There was an issue saving the step orders.', 'error');
      } finally {
        setMovingStep(undefined);
      }
    },
    [
      allSteps,
      displaySteps,
      openAlert,
      setDisplaySteps,
      setMovingStep,
      moveStepAsync,
    ]
  );

  const sortedDisplaySteps = useMemo(
    () =>
      Object.values(displaySteps).sort(
        ({ stepOrder: A }, { stepOrder: B }) => A - B
      ),
    [displaySteps]
  );

  const hasAddPermission = utils.permissions.hasPermission(
    permissions.ORGS_PLUGINS_RECONVELOCITY_WORKFLOWS_STEPS_CREATE,
    session?.permissions
  );

  if ((workflowStepsIsLoading && !allSteps) || !allSteps) {
    return <LoadingIndicator />;
  }

  return (
    <div className="full-height flex-rows">
      <>
        <CollectionHeaderBar
          count={allSteps.data.length}
          unit="Step"
          onAdd={hasAddPermission ? handleAdd : undefined}
        />
        <div className="flex-grow">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="topLevelStepDefinitionsDroppable">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  className="full-height WorkflowStepsListView"
                  {...provided.droppableProps}
                  style={{
                    overflow: 'auto',
                    ...getListStyle(snapshot.isDraggingOver),
                  }}
                >
                  {sortedDisplaySteps.map((step, i) => (
                    <StepDefinitionRow
                      key={`workflow-step-${step.id}`}
                      orgId={orgId}
                      displayStep={step}
                      index={i}
                      allSteps={allSteps?.data}
                      selectedStepId={selectedStepId}
                      setSelectedStepId={setSelectedStepId}
                      getListStyle={getListStyle}
                      movingStep={movingStep}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </>
      <Alert
        open={isAlertOpen}
        message={alertMessage}
        variant={variant}
        onClose={closeAlert}
        duration={3500}
      />
    </div>
  );
};

export default WorkflowStepsListView;
