import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';

import { usePlugin, usePluginCreate, usePluginUpdate } from 'api';
import { AlertVariant } from 'components/shared/Alert/Alert';
import Dialog from 'components/shared/Dialog';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { DataProviderPluginItem } from 'models';

import './PluginDialog.scss';
import './EnhancerPluginDialog.scss';

const useStyles = makeStyles({
  root: {
    display: 'flex !important',
    alignItems: 'center !important',
  },
});

export interface EnhancerPluginDialogProps {
  plugin: DataProviderPluginItem;
  orgId: string;
  openAlert: (message: string, variant: AlertVariant) => void;
  onClose?: () => void;
}

const EnhancerPluginDialog: React.FC<EnhancerPluginDialogProps> = (props) => {
  const { plugin, orgId, openAlert, onClose } = props;
  const classes = useStyles();

  const [enabled, setEnabled] = useState(true);
  const [dealerId, setDealerId] = useState('');
  const [dealerCode, setDealerCode] = useState('');

  const pluginTypeId = plugin.pluginName.toLowerCase().replace('_', '');
  const { data: pluginData, isInitialLoading } = usePlugin(
    orgId,
    pluginTypeId,
    plugin.id
  );
  const { updatePluginAsync, isLoading: isUpdating } = usePluginUpdate(
    orgId,
    pluginTypeId
  );
  const { createPluginAsync, isLoading: isCreating } = usePluginCreate(
    orgId,
    pluginTypeId
  );

  useEffect(() => {
    if (!isInitialLoading && pluginData) {
      setEnabled(pluginData.enabled);
      setDealerId(pluginData.dealerId ?? '');
      setDealerCode(pluginData.dealerCode ?? '');
    }
  }, [isInitialLoading, pluginData]);

  async function save() {
    const data: Partial<DataProviderPluginItem> = {
      enabled,
      dealerId,
      dealerCode,
    };

    try {
      // If there's already a plugin ID, do an update. Otherwise create.
      if (plugin.id) {
        await updatePluginAsync(data);
      } else {
        const newPlugin = { ...plugin, ...data };
        await createPluginAsync(newPlugin);
      }
      onClose?.();
    } catch (error) {
      let message = 'An error occurred when saving this plug-in to the server.';
      if (
        error instanceof Error &&
        error.message === 'Conflict' &&
        (plugin.pluginName === 'AUTOCHECK' || plugin.pluginName === 'CARFAX')
      ) {
        message =
          plugin.pluginName === 'CARFAX'
            ? 'Autocheck is already enabled. Please disable Autocheck first then enable Carfax.'
            : 'Carfax is already enabled. Please disable Carfax first then enable Autocheck.';
      }
      openAlert(message, 'error');
    }
  }

  return (
    <Dialog
      open
      title={`Configure ${plugin.title} Plug-in`}
      maxWidth="sm"
      fullWidth
      primaryButtonLabel="Save"
      onPrimary={save}
      onClose={onClose}
      block={isInitialLoading || isUpdating || isCreating}
      className="PluginDialog EnhancerPluginDialog"
    >
      <div className={`main-content ${classes.root}`}>
        {plugin.pluginName === 'AUTOCHECK' ? (
          <TextField
            id="dealerCodeInput"
            className="PluginDialog_Text-input"
            label="Dealer Code"
            variant="outlined"
            value={dealerCode}
            onChange={(e) => {
              setDealerCode(e.target.value);
            }}
            margin="dense"
          />
        ) : (
          ''
        )}
        {(plugin.pluginName === 'HOMENET_ENHANCER' ||
          plugin.pluginName === 'NETLOOK') && (
          <TextField
            className="PluginDialog__text-input"
            label="File Name"
            variant="outlined"
            required
            value={dealerId}
            onChange={(e) => {
              setDealerId(e.target.value);
            }}
            margin="dense"
          />
        )}
        <FormControlLabel
          className="PluginDialog__switch"
          label={
            isInitialLoading ? (
              <LoadingIndicator size={26} />
            ) : (
              <FormLabel>{enabled ? 'Enabled' : 'Disabled'}</FormLabel>
            )
          }
          labelPlacement="start"
          control={
            <Switch
              inputProps={{
                'aria-label': 'Enhancer Plugin Dialog Enable switch',
              }}
              checked={enabled ?? false}
              disabled={isInitialLoading}
              onChange={(_, checked) => {
                setEnabled(checked);
              }}
            />
          }
        />
      </div>
    </Dialog>
  );
};

export default EnhancerPluginDialog;
