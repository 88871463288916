import ReactDOM from 'react-dom';

import App from 'components/App';
import 'typeface-open-sans';
import 'typeface-source-sans-pro';

import 'scss/index.scss';

if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./mocks/browser');
  worker.start();
}

const render = (AppComponent: any) => {
  ReactDOM.render(<AppComponent />, document.getElementById('root'));
};

async function start() {
  // TODO should we have a force update
  // onForceUpgrade: () => {
  //   window.location.reload();
  //   return false;
  // },
  render(App);
}

start();

// Hot Module Replacement (HMR).
if ((module as any).hot) {
  (module as any).hot.accept('./components/App.tsx', () => {
    const NextApp = App; // eslint-disable-line global-require
    render(NextApp);
  });
}
