import { union } from 'lodash';
import { Form } from 'react-bootstrap';

import { SelectionState } from 'common/types';
import { OrganizationGroup, PermissionItem } from 'models';

interface EditGroupDialogListViewProps {
  selectionState: SelectionState<OrganizationGroup | 'OTHER' | 'ALL'>;
  updateSelectionState: (
    newSelection: Partial<SelectionState<OrganizationGroup | 'OTHER' | 'ALL'>>
  ) => void;
  groups?: OrganizationGroup[];
  checkedPermissionIds: PermissionItem['id'][];
  permissions?: PermissionItem[];
  updateCheckedPermissionIds: (permissionIds: PermissionItem['id'][]) => void;
}

var EditGroupDialogListView = ({
  selectionState,
  updateSelectionState,
  groups,
  checkedPermissionIds,
  permissions,
  updateCheckedPermissionIds,
}: EditGroupDialogListViewProps) => {
  const allPermissionsChecked =
    !!permissions?.length &&
    permissions?.length === checkedPermissionIds.length;
  const allPermissionsIndeterminate =
    !allPermissionsChecked &&
    !!(permissions?.length && checkedPermissionIds.length);
  return (
    <div className="EditGroupDialog__list full-height padding no-side-padding scrolling">
      <div className="EditGroupDialog__sectionHeading">Roles</div>
      <div className="d-flex flex-row valign-center">
        <Form.Check
          type="checkbox"
          checked={allPermissionsChecked}
          onChange={() => {
            if (
              permissions &&
              (allPermissionsIndeterminate || !allPermissionsChecked)
            ) {
              const allPermissionIds = permissions?.map(
                (listPermission) => listPermission.id
              );
              updateCheckedPermissionIds(union(allPermissionIds));
            } else if (allPermissionsChecked) {
              updateCheckedPermissionIds([]);
            }
          }}
          ref={(el: HTMLInputElement) => {
            if (el) el.indeterminate = allPermissionsIndeterminate;
          }}
        />
        <div
          role="button"
          tabIndex={-1}
          onClick={() => {
            updateSelectionState({ item: 'ALL' });
          }}
          onKeyPress={({ key }) => {
            if (key === 'Enter') {
              updateSelectionState({ item: 'ALL' });
            }
          }}
          className={`EditGroupDialog__listItem ${
            selectionState.item === 'ALL' ? 'selected' : ''
          }`}
        >
          All Permissions
        </div>
      </div>
      <div className="EditGroupDialog__spacing" />
      {groups?.map((group, index) => {
        const checked = group.roles[0].permissions.every((groupPermission) =>
          checkedPermissionIds.includes(groupPermission.id)
        );
        const indeterminate =
          !checked &&
          !!(
            group.roles[0].permissions?.length &&
            group.roles[0].permissions.some((groupPermission) =>
              checkedPermissionIds.includes(groupPermission.id)
            )
          );
        return (
          <div className="d-flex flex-row valign-center">
            <Form.Check
              type="checkbox"
              checked={checked}
              onChange={() => {
                const groupPermissionIds = group.roles[0].permissions?.map(
                  (listPermission) => listPermission.id
                );
                if (group.roles[0].permissions && !checked) {
                  updateCheckedPermissionIds(
                    union([...checkedPermissionIds, ...groupPermissionIds])
                  );
                } else if (checked) {
                  const filteredPermissionIds = checkedPermissionIds.filter(
                    (checkedId) => !groupPermissionIds.includes(checkedId)
                  );
                  updateCheckedPermissionIds(union(filteredPermissionIds));
                }
              }}
              ref={(el: HTMLInputElement) => {
                if (el) el.indeterminate = indeterminate;
              }}
            />
            <div
              role="button"
              tabIndex={-1}
              onClick={() => {
                updateSelectionState({
                  item: group,
                  index,
                });
              }}
              onKeyPress={({ key }) => {
                if (key === 'Enter') {
                  updateSelectionState({
                    item: group,
                    index,
                  });
                }
              }}
              className={`EditGroupDialog__listItem ${
                selectionState.item === group ? 'selected' : ''
              }`}
            >
              {group.name}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default EditGroupDialogListView;
