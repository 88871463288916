import { Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useBrochureStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    buttonRow: {
      margin: theme.spacing(1),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    uploadIcon: {
      fontSize: 48,
    },
    uploadTitle: {
      fontSize: 32,
      fontWeight: 400,
      margin: theme.spacing(2),
    },
    uploadText: {
      fontSize: 18,
      margin: theme.spacing(2),
    },
    uploadContainer: {
      textAlign: 'center',
      padding: 20,
    },
    dropArea: {
      border: '3px solid transparent',
      textAlign: 'center',
      padding: 20,
    },
    dropAreaActive: {
      textAlign: 'center',
      padding: 20,
      border: '3px dashed #ccc',
    },
    helperText: {
      textAlign: 'center',
      fontSize: 14,
      margin: theme.spacing(1),
    },
  })
);

export default useBrochureStyles;
