import { isUndefined } from 'lodash';
import React, { useState } from 'react';

import { SelectionState } from 'common/types';

import './ListDetailLayout.scss';

type Subview<T> =
  | React.ReactElement
  | ((
      state: SelectionState<T>,
      updateSelectionState: (newSelection: Partial<SelectionState<T>>) => void
    ) => React.ReactElement);

interface ListDetailLayoutProps<T> {
  listView: Subview<T>;
  detailView: Subview<T>;
  listWidth?: number | string;
}

const renderSubview = <T,>(
  subview: Subview<T>,
  selectionState: SelectionState<T>,
  updateSelectionState: (newSelection: Partial<SelectionState<T>>) => void
) =>
  typeof subview === 'function'
    ? subview(selectionState, updateSelectionState)
    : subview;

const ListDetailLayout = <T extends unknown>({
  detailView,
  listView,
  listWidth,
}: ListDetailLayoutProps<T>) => {
  const [selectionState, setSelectionState] = useState<SelectionState<T>>({
    index: undefined,
    item: undefined,
  });

  const updateSelectionState = (newSelection: Partial<SelectionState<T>>) =>
    setSelectionState((prevState) => ({ ...prevState, ...newSelection }));

  const listStyle = isUndefined(listWidth) ? {} : { width: listWidth };

  return (
    <div className="Admin__ListDetailLayout full-height flex-columns">
      <div className="Admin__ListDetailLayout__list" style={listStyle}>
        {renderSubview(listView, selectionState, updateSelectionState)}
      </div>
      <div className="Admin__ListDetailLayout__detail flex-grow">
        {renderSubview(detailView, selectionState, updateSelectionState)}
      </div>
    </div>
  );
};

export default ListDetailLayout;
