import { useMutation, useQuery } from '@tanstack/react-query';

import { defaultMutationFn } from 'api';
import { VehicleMakeData } from 'models/settings';

interface BrochureUploadProps {
  year: string;
  make: string;
  model: string;
}

/**
 * Get Vehicle Brochure Catalog
 * Endpoint GET `/brochures/list`
 * @param placeholderData - dummy data for use with useAPI
 * @returns Full brochure catalog with URLs
 */
export function useBrochureList() {
  const path = '/brochures/list';
  return useQuery<VehicleMakeData[]>([path], {
    keepPreviousData: true,
  });
}

/**
 * Get presigned url to upload a file direct to S3
 * Endpoint GET `/brochures/{year}/{make}/{model}/upload
 * @param BrochureUploadProps - year make model
 * @param successFn - function to run when presigned url returns good
 * @returns UseQueryResult
 */
export function usePresignedUploadUrl(
  { year, make, model }: BrochureUploadProps,
  successFn: (data: string) => void
) {
  const path = `/brochures/save/${year}/${make}/${model}/upload`;
  return useQuery<string>([path], { enabled: false, onSuccess: successFn });
}

/**
 * Endpoint PUT /brochures/save/{year}/{make}/{model}
 * @param BrochureUploadProps - year make model
 * @returns mutation
 */
export function useSaveBrochureUrl({ year, make, model }: BrochureUploadProps) {
  const path = `/brochures/save/${year}/${make}/${model}`;

  const mutation = useMutation(() => {
    return defaultMutationFn(path, 'PUT');
  });

  async function updateBrochureUrl() {
    return mutation.mutateAsync();
  }
  return {
    ...mutation,
    updateBrochureUrl,
  };
}

export default useBrochureList;
